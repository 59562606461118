<template>
  <v-container id="icon-notification" fluid tag="section">
    <filter-form :filter-form="filterForm" :types="types" />
    <icon-table :filter-form="filterForm" :icons="icons" :types="types" />
    <v-row class="mt-4">
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem, limit)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { iconStatusOptions } from "../../helpers/constants";
import FilterForm from "./components/FilterForm.vue";
import IconTable from "./components/IconTable.vue";
import RepositoryFactory from "../../repositories/RepositoryFactory";
const IconRepository = RepositoryFactory.get("icon");

export default {
  components: { FilterForm, IconTable },
  data() {
    return {
      filterForm: {
        keyword: "",
        type: "",
        status: undefined,
      },
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      icons: [],
      loading: true,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getIconList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getIconList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  async created() {
    this.getIconList();
    Bus.$on("icon-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getIconList();
    });
    Bus.$on("icon-reload", () => {
      this.getIconList();
    });
  },
  beforeDestroy() {
    Bus.$off("icon-list-filter");
  },
  methods: {
    async getIconList() {
      this.loading = true;
      const params = {
        limit: this.limit,
        page: this.currentPage,
        keyword: this.filterForm.keyword,
        status: this.filterForm.status,
      };

      const loader = this.$loading.show();
      const response = await IconRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.icons = _.get(response, "data.list_icon", []).map((b) => ({
          ...b,
          statusText: _.get(
            iconStatusOptions.find((s) => s.value == b.status),
            "text"
          ),
        }));
        this.totalItem = _.get(response, "data.total_item", 0);
      }
    },
  },
};
</script>
