<template>
  <v-container id="edit-about-us" fluid tag="section">
    <v-card class="mx-auto pa-5" outlined>
      <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(update)">
          <v-alert
            v-show="alertMessageText"
            v-model="alert"
            :type="alertMessageType"
            dismissible
          >
            {{ alertMessageText }}
          </v-alert>

          <v-row>
            <v-col cols="12" sm="12">
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%; margin-bottom: 10px"
                >Mô tả chung về công ty</span
              >
              <validation-provider
                v-slot="{ errors }"
                name="Mô tả chung về công ty"
              >
                <template>
                  <vue-editor
                    v-model="information.description"
                    :editor-toolbar="customToolbar"
                  />
                </template>
                <span
                  v-if="errors[0]"
                  class="errors-span-required-content"
                  color="primary"
                  >* {{ errors[0] }}</span
                >
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12">
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%; margin-bottom: 10px"
                >Nội dung chính</span
              >
              <validation-provider
                v-slot="{ errors }"
                name="Nội dung chính"
              >
                <template>
                  <vue-editor
                    v-model="information.content"
                    :editor-toolbar="customToolbar"
                  />
                </template>
                <span
                  v-if="errors[0]"
                  class="errors-span-required-content"
                  color="primary"
                  >* {{ errors[0] }}</span
                >
              </validation-provider>
            </v-col>
          </v-row>

          <div class="mt-2 text-center">
            <v-btn
              class="mb-2"
              color="primary"
              @click.prevent="handleSubmit(update)"
            >
              Cập nhật
            </v-btn>
          </div>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template> 
<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { VueEditor } from "vue2-editor";
const AboutUsRepository = RepositoryFactory.get("about_us");

export default {
  components: { VueEditor },
  $_veeValidate: {
    validator: "new",
  },
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      information: {
        id: 0,
        content: "",
        description: "",
      },
      alertMessageText: null,
      alertMessageType: "success",
      alert: true,
      loading: false,
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        ["image", "code-block"],
      ],
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async update() {
      this.loading = this.$loading.show();
      const response = await AboutUsRepository.update(
        _.get(this.information, "id", 0),
        {
          content: _.get(this.information, "content", ""),
          description: _.get(this.information, "description", ""),
        }
      );
      this.loading.hide();
      if (response.error) {
        this.setAlertMessage(true, "error", _.get(response, "message"));
      } else {
        this.$swal({
          title: "Cập nhật thành công!",
          confirmButtonText: "Đồng ý",
        });
      }
    },
    setAlertMessage(alert, type, text) {
      this.alert = alert;
      this.alertMessageText = text;
      this.alertMessageType = type;
    },
    async getDetail() {
      const response = await AboutUsRepository.list();
      if (response.success) {
        this.information = _.get(response, "data.introduction", {});
      }
    },
  },
};
</script>

