<template>
  <v-container id="order-list" fluid tag="section">
    <v-card class="mt-0 px-2">
      <v-row>
        <v-col cols="4">
          <form-send-sms :formSendSms="formSendSms" :customerList="customerList" />
        </v-col>

        <v-col cols="8">
          <v-card class="mt-0">
            <v-tabs
                @change="changeTab"
                v-model="tabIndex"
                color="primary"
                style="background-color: #FFFFFF; padding: 5px 0px 10px 5px;"
            >
              <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab }}</v-tab>
            </v-tabs>

            <template-sms-table 
                v-if="tabIndex == 0" 
                :smsList="smsList" 
                :users="users"
                :filterForm="filterFormSms"
                :loadingSms="loadingSms"
                :totalItemSms="totalItemSms"
                :limitSms="limitSms"
            />

            <customer-list-table
                v-if="tabIndex == 1"
                :customerList="customerList"
                :users="users"
                :productGroups="productGroups"
                :contactStatusList="contactStatusList"
                :contactSources="contactSources"
                :products="products"
                :limitCustomerFile="limitCustomerFile"
                :totalItemCustomerFile="totalItemCustomerFile"
                :loading="loading"
            />

            <contact-list-modal />
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import CustomerListTable from './components/CustomerListTable';
import TemplateSmsTable from './components/TemplateSmsTable';
import ContactListModal from './components/ContactListModal';

import RepositoryFactory from '../../../repositories/RepositoryFactory';
import TokenService from '../../../helpers/token';
import * as moment from "moment";
import FormSendSms from "./components/FormSendSms";

const SmsRepository = RepositoryFactory.get('sms');
const CustomerFileRepository = RepositoryFactory.get('customer_file');
const UserRepository = RepositoryFactory.get('user');
const DepartmentRepository = RepositoryFactory.get('department');
const ProductGroupRepository = RepositoryFactory.get('product_group');
const ProductRepository = RepositoryFactory.get('product');
const ContactSourceRepository = RepositoryFactory.get('contact_source');
const OrderRepository = RepositoryFactory.get('order');
const ContactStatusRepository = RepositoryFactory.get('contact_status')

export default {
  components: {
    FormSendSms,
    CustomerListTable,
    TemplateSmsTable,
    ContactListModal
  },
  data() {
    return {
      contactStatusList:[],
      products: [],
      productGroups: [],
      contactSources: [],
      currentPageCustomerFile: 1,
      limitCustomerFile: 10,
      totalItemCustomerFile: 0,
      currentPageSms: 1,
      limitSms: 10,
      totalItemSms: 0,
      filterForm: {},
      filterFormCustomerFile: {
        title: '',
        userId: '',
        code: '',
        dateRange: {
          startDate: '',
          endDate: '',
        }
      },
      filterFormSms: {
        createdAt: [],
        codeSms: '',
        typeSms: '',
        userId: '',
        content: ''
      },
      formSendSms: {
        channel: '',
        contactFilterId: '',
        exceptCustomers: [],
        numberSend: 0,
      },

      orders: [],
      loading: true,
      loadingSms: false,
      loadingContact: false,
      tabs: ['Mẫu tin nhắn', 'Tệp khách hàng'],
      tabIndex: 0,
      smsList: [],
      customerList: [],
      users: [],
      usersArr: {},
      conditionFilterMap: {
        assignedAt: 'Ngày được cấp contact',
        importUpdatedAt: 'Ngày đăng ký gần nhất',
        orderCreatedAt: 'Ngày tạo đơn',
        orderTotalPrice: 'Tổng gía trị đơn',

        userIds: 'Người phụ trách',
        contactSourceIds: 'Nguồn khách hàng',
        expiredDate: 'Ngày hết hạn',
        customerResult: 'Kết quả học tập',

        statusId: 'Mối quan hệ',
        productGroupIds: 'Nhóm sản phẩm',
        boughtProduct: 'Sản phẩm đã mua',
        years: 'Năm sinh',

        numberOfCall: 'Số lần gọi',
        callAt: 'Ngày liên hệ gần nhất',
        unBoughtProduct: 'Sản phẩm chưa mua',
        childName: 'Tên con'
      },
      productsArr: {},
      contactSourcesArr: {},
      productGroupsArr: {},
      contactStatusListArr: {}
    };
  },
  async created() {
    this.tabIndex = parseInt(this.$route.query.tab)
    const loader = this.$loading.show();
    await Promise.all([
      this.getUserList(), 
      this.getProducts(),
      this.getProductGroups(),
      this.getContactSources(),
      this.getContactStatusList(),
    ])
    await Promise.all([
      this.getSmsList(),
      this.getCustomerList()
    ]);
    loader.hide();

    const dataUser = TokenService.getUser() || {};
    Bus.$on('search-template-sms',  (filterForm) => {
      this.filterFormSms = filterForm
      this.getSmsList()
    })

    Bus.$on('change-page-sms', (currentPageSms) => {
      this.currentPageSms = currentPageSms
      this.getSmsList()
    })

    Bus.$on('create-sms-reload', () => {
      this.currentPageSms = 1
      this.getSmsList()
    })

    Bus.$on('update-sms-reload', () => {
      this.getSmsList()
    })

    Bus.$on('search-customer-file',  (filterForm) => {
      this.filterFormCustomerFile = filterForm;
      this.getCustomerList()
    })

    Bus.$on('change-page-customer-file', currentPageCustomerFile => {
      this.currentPageCustomerFile = currentPageCustomerFile
      this.getCustomerList()
    })

    Bus.$on('create-customer-file-reload', () => {
      this.newDialog = false
      this.getCustomerList()
    })
    // await this.getUserList();

    // this.getSmsList();
  },
  beforeDestroy() {
    Bus.$off('order-list-filter');
  },
  methods: {
    changeTab() {
      if (this.tabIndex === 0) {
        // this.filterFormSms = [];
        this.currentPageSms = 1;
        this.getSmsList()
      } else if (this.tabIndex === 1) {
        // this.filterFormCustomerFile = [];
        this.currentPageCustomerFile = 1;
        this.getCustomerList()
      }
    },
    async getSmsList() {
      const params = {
        limit: this.limitSms,
        page: this.currentPageSms - 1,
        userId: this.filterFormSms.userId,
        code: this.filterFormSms.codeSms,
        type: this.filterFormSms.typeSms,
        createdAt: this.filterFormSms.createdAt,
        content: this.filterFormSms.content
      };

      if (!this.checkAuthorization('order_view_all')) {
        const dataUser = TokenService.getUser() || {};
        if ( !params.userId && this.isStaff()) {
          params.userId = _.get(dataUser, 'id', '');
        }
        // if (!params.departmentId && this.isLeader()) {
        //   params.departmentId = dataUser.departmentId;
        // }
      }
      
      this.loadingSms = true;
      const loader = this.$loading.show();
      const response = await SmsRepository.list(params);
      loader.hide();
      this.loadingSms = false;
      if (response.success) {
        this.smsList = _.get(response, 'data', [])
        this.totalItemSms = _.get(response, 'totalItem', 0)
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
        this.smsList = [];
        this.totalItemSms = 0;
      }
    },
    async getCustomerList () {
      const params = {
        limit: this.limitCustomerFile,
        page: this.currentPageCustomerFile - 1,
        title: this.filterFormCustomerFile.title,
        startDate: this.filterFormCustomerFile.dateRange.startDate,
        endDate: this.filterFormCustomerFile.dateRange.endDate,
        userId: this.filterFormCustomerFile.userId,
      };
      this.loadingContact = true;
      const loader = this.$loading.show();
      const response = await CustomerFileRepository.list(params)
      loader.hide();
      this.loadingContact = false;
      if(response.success) {
        this.customerList = _.get(response, 'data', []);
        this.totalItemCustomerFile = _.get(response, 'totalItem', 0);
        this.handleCustomerFileData()
      } else {
        this.customerList = []
        this.totalItemCustomerFile = 0
      }

    },
    async getUserList () {
      this.loading = true
      const params = {
        status: 'active',
        limit: 1000
      }

      const loader = this.$loading.show()
      const response = await UserRepository.list(params)
      loader.hide()
      this.loading = false
      if (response.success) {
        this.users = _.get(response, 'data', []).map(u => ({
          ...u,
          productGroups: _.get(u, 'productGroups', []).map(group => group.id),
          listdepartmentId: _.get(u, 'listDepartment', []).map(item => item.departmentId)
        }))
        this.users.forEach(item => {
          this.usersArr[item.id] = item.fullName
        })
      }
    },
    async getProducts() {
      const respond = await ProductRepository.list({
        limit: 100,
        groupId: this.filterForm.groupId
      });
      if (_.get(respond, 'success', false)) {
        this.products = _.get(respond, 'data', []);
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(respond, 'message', '')
        });
      }
    },
    async getProductGroups() {
      const respond = await ProductGroupRepository.list({ limit: 100 });
      if (_.get(respond, 'success', false)) {
        this.productGroups = _.get(respond, 'data', []);
        this.products.forEach(item => {
          this.productsArr[item.id] = item.name
        })
        this.productGroups.forEach(item => {
          this.productGroupsArr[item.id] = item.name
        });
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(respond, 'message', '')
        });
      }
    },
    async getContactSources() {
      const respond = await ContactSourceRepository.list({ limit: 100 });
      if (_.get(respond, 'success', false)) {
        this.contactSources = _.get(respond, 'data', []);
        this.contactSources.forEach(item => {
          this.contactSourcesArr[item.id] = item.name
        })
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(respond, 'message', '')
        });
      }
    },
    async getContactStatusList() {
      const loading = this.$loading.show();
      const typeArray = ['T2', 'T3', 'T4', 'T5', 'C2', 'C3', 'C4', 'C5'];
      const response = await ContactStatusRepository.list({});
      loading.hide();
      if (response.success) {
        const contactStatusList = [];
        _.get(response, 'data', []).forEach(contactStatus => {
          if (typeArray.includes(_.get(contactStatus, 'type', ''))) {
            _.get(contactStatus, 'data', []).forEach(data => {
              contactStatusList.push({
                type: contactStatus.type,
                id: _.get(data, 'id', 0),
                name: _.get(data, 'name', 0)
              });
            });
          }
        });
        this.contactStatusList = contactStatusList;
        this.contactStatusList.forEach(item => {
          this.contactStatusListArr[item.id] = item.name
        })
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
      }
    },
    handleCustomerFileData() {
      this.customerList.forEach(item => {
        item['condition'] = {};
        item['createdUser'] = this.usersArr[item.createdBy];
        Object.keys(item.operatorValue).forEach(key => {

          // if(item.operatorType[key] == 'lt') {
          //   item['condition'][this.conditionFilterMap[key]] = ' < ' + item.operatorValue[key]
          // } else if(item.operatorType[key] == 'gt') {
          //   item['condition'][this.conditionFilterMap[key]]= ' > ' + item.operatorValue[key]
          // } else {
          //   item['condition'][this.conditionFilterMap[key]] = ' : ' + item.operatorValue[key]
          // }

          switch (key) {
            case 'userIds':
              let userTemp = []
              item['condition'][this.conditionFilterMap[key]] = ': ';
              item.operatorValue[key] = JSON.parse(item.operatorValue[key]);
              item.operatorValue[key].forEach(i => {
                userTemp.push(this.usersArr[i])
              });
              item['condition'][this.conditionFilterMap[key]] += userTemp.join(", ")
              break;

            case 'productGroupIds':
              item['condition'][this.conditionFilterMap[key]] = ': ';
              item.operatorValue[key] = JSON.parse(item.operatorValue[key]);
              item.operatorValue[key].forEach(i => {
                item['condition'][this.conditionFilterMap[key]] += this.productGroupsArr[i]
              });
              break;

            case 'boughtProduct':
              let boughtProduct = []
              item['condition'][this.conditionFilterMap[key]] = ': ';
              item.operatorValue[key] = JSON.parse(item.operatorValue[key]);
              item.operatorValue[key].forEach(i => {
                boughtProduct.push(this.productsArr[i])
              });
              item['condition'][this.conditionFilterMap[key]] += boughtProduct.join(", ")
              break;

            case 'unBoughtProduct':
              let unBoughtProduct = []
              item['condition'][this.conditionFilterMap[key]] = ': ';
              item.operatorValue[key] = JSON.parse(item.operatorValue[key]);
              item.operatorValue[key].forEach(i => {
                unBoughtProduct.push(this.productsArr[i])
              });
              item['condition'][this.conditionFilterMap[key]] += unBoughtProduct.join(", ")
              break;

            case 'statusId':
              item.operatorValue[key] = parseInt(item.operatorValue[key]);
              item['condition'][this.conditionFilterMap[key]] = ' : ' + this.contactStatusListArr[item.operatorValue[key]];
              break;
            case 'contactSourceIds':
              item['condition'][this.conditionFilterMap[key]] = ': ';
              item.operatorValue[key] = JSON.parse(item.operatorValue[key]);
              item.operatorValue[key].forEach(i => {
                item['condition'][this.conditionFilterMap[key]] += this.contactSourcesArr[i]
              });
              break;

            case 'assignedAt':
            case 'importUpdatedAt':
            case 'orderCreatedAt':
            case 'expiredDate':
            case 'callAt':
              item['condition'][this.conditionFilterMap[key]] = ': ';
              item.operatorValue[key] = JSON.parse(item.operatorValue[key]);
              if(item.operatorValue[key].length) {
                item['condition'][this.conditionFilterMap[key]] += moment(item.operatorValue[key][0]).format("DD/MM/YYYY") + ' - ' + moment(item.operatorValue[key][1]).format("DD/MM/YYYY");
              }
              break;

            default:
              item['condition'][this.conditionFilterMap[key]] = ' : ' + item.operatorValue[key]
          }

        })
      })
    }
  },
  watch: {
    limitCustomerFile: {
      handler() {
        if (this.currentPageCustomerFile === 1) {
          this.getCustomerList();
        } else {
          this.currentPageCustomerFile = 1;
        }
      }
    },
    limitSms: {
      handler() {
        if (this.currentPageSms === 1) {
          this.getSmsList();
        } else {
          this.currentPageSms = 1;
        }
      }
    }
  }
};
</script>
<style scoped>
#order-list {
  padding: 0px 15px;
}
.order-list__wrapper {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
}

.v-tab {
  width: 50% !important;
  max-width: 900px;
}
</style>