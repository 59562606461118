<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
    :retain-focus="false"
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Tạo thông báo </template>
          <template v-else> Cập nhật thông báo </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Tiêu đề"
          >
            <v-text-field
              v-model="notification.title"
              label="Tiêu đề *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Liên kết"
          >
            <v-text-field
              v-model="notification.url"
              label="Liên kết *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            name="Mô tả"
            rules="max: 255"
          >
            <v-textarea
              v-model="notification.description"
              label="Mô tả"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <span
            class="title-span-content text-left"
            style="display: inline-block; width: 100%"
            >Nội dung *</span
          >
          <validation-provider
            v-slot="{ errors }"
            name="Nội dung"
            rules="required"
          >
            <template>
              <vue-editor
                v-model="notification.content"
                :editor-toolbar="customToolbar"
              />
            </template>
            <span
              v-if="errors[0]"
              class="errors-span-required-content"
              color="primary"
              >* {{ errors[0] }}</span
            >
          </validation-provider>
          <validation-provider class="mb-2" name="Icon">
            <v-select
              v-model="notification.iconId"
              label="Biểu tượng thông báo"
              color="primary"
              :items="icons"
              item-text="title"
              item-value="id"
            />
          </validation-provider>
          <validation-provider class="mb-2" name="Thành viên">
            <template v-if="isCreate">
              <v-select
                v-model="notification.listUser"
                label="Thành viên"
                color="primary"
                :items="users"
                item-text="username"
                item-value="id"
                multiple
              />
            </template>
            <template v-else>
              <v-select
                v-model="notification.userId"
                label="Thành viên"
                color="primary"
                :items="users"
                item-text="username"
                item-value="id"
              />
            </template>
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn
            color="success"
            @click.prevent="handleSubmit(saveNotification)"
          >
            Lưu lại
          </v-btn>
          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  notificationStatusOptions,
  PAGE_LIMIT,
} from "../../../helpers/constants";
import { VueEditor } from "vue2-editor";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const NotificationRepository = RepositoryFactory.get("notification");
const IconRepository = RepositoryFactory.get("icon");
const UserRepository = RepositoryFactory.get("user");

export default {
  components: { VueEditor },
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      notification: {},
      isCreate: true,
      errorMessage: "",
      statusOptions: notificationStatusOptions,
      inputFile: [],
      icons: [],
      users: [],
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        ["image", "code-block"],
      ],
    };
  },
  async created() {
    this.getIconList();
    this.getListUser();
    Bus.$on("notification-show-form", (notification) => {
      this.showModal = true;
      this.notification = _.cloneDeep(notification);
      if (notification.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  computed: {},
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveNotification() {
      const notification = {
        title: _.trim(this.notification.title),
        url: _.trim(this.notification.url),
        description: _.trim(this.notification.description),
        content: _.trim(this.notification.content),
        iconId: this.notification.iconId,
        listUser: this.notification.listUser,
        userId: this.notification.userId,
      };
      const loader = this.$loading.show();
      let response = {};
      if (this.isCreate) {
        response = await NotificationRepository.create(notification);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("notification-reload");
          });
        }
      } else {
        response = await NotificationRepository.update(
          this.notification.id,
          notification
        );
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("notification-reload");
          });
        }
      }
    },
    async getIconList() {
      this.loading = true;
      const loader = this.$loading.show();
      const response = await IconRepository.list({
        limit: PAGE_LIMIT,
      });
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.icons = _.get(response, "data.list_icon", []);
      }
    },
    async getListUser() {
      let res = await UserRepository.list({
        limit: PAGE_LIMIT,
      });
      if (res.success) {
        this.users = _.get(res, "data", []);
      }
    },
  },
};
</script>
