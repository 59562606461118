<template>
  <v-container id="order-list" fluid tag="section">
    <base-material-card
      outlined
      icon="mdi-cash-multiple"
      class="overflow-visible order-list__wrapper"
    >
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">Danh sách đơn hàng</div>
      </template>
      <v-btn
        class="mb-2 mr-0 ctv-create-btn"
        color="primary"
        v-if="checkAuthorization('order-ctv-create_read')"
        @click="ctvCreate()"
      >
        <span>Tạo mới</span>
        <v-icon style="margin-left: 10px; margin-right: -20px">mdi-plus</v-icon>
      </v-btn>
      <filer-form :filterForm="filterForm" />
      <v-tabs
        @change="changeTab"
        v-model="tabIndex"
        color="primary"
        style="background-color: #ffffff; padding: 5px 0px 10px 5px"
      >
        <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab }}</v-tab>
      </v-tabs>
      <!-- <v-divider class="ma-4 mb-2"  style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"/> -->
      <order-table :orders="orders" />
      <common-no-result v-if="!loading && totalItem === 0" />
      <v-row>
        <v-col cols="12" sm="6" />
        <v-col cols="12" sm="6">
          <v-pagination
            v-if="totalItem > limit"
            v-model="currentPage"
            :length="getTotalItem(totalItem)"
            class="justify-sm-end"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          />
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import { orderStatus } from "../../helpers/constants";
import FilerForm from "./components/FilerForm";
import OrderTable from "./components/OrderTable";
import RepositoryFactory from "../../repositories/RepositoryFactory";
const OrderRepository = RepositoryFactory.get("order");

export default {
  components: {
    FilerForm,
    OrderTable,
  },
  data() {
    return {
      filterForm: {
        keyword: "",
        phone: "",
        openMenu: false,
        code: "",
        departmentIds: [],
        userIds: [],
        customerIds: [],
        productIds: "",
        paymentType: "",
        createdAt: [],
        statusIds: [],
        positions: [],
      },
      orders: [],
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      loading: true,
      tabs: [
        "Tất cả",
        "Đang chờ",
        "Đang chuyển hàng",
        "Đã giao hàng",
        "Đã thanh toán",
        "Đơn hàng thành công",
        "Đã hủy",
      ],
      tabIndex: 0,
    };
  },
  async created() {
    this.getOrderList();
    Bus.$on("order-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.filterForm.openMenu = false;
      this.currentPage = 1;
      this.getOrderList();
    });
  },
  beforeDestroy() {
    Bus.$off("order-list-filter");
  },
  methods: {
    changeTab() {
      if (this.tabIndex === 0) {
        this.filterForm.statusIds = [];
      } else if (this.tabIndex === 1) {
        this.filterForm.statusIds = [orderStatus.PENDING];
      } else if (this.tabIndex === 2) {
        this.filterForm.statusIds = [orderStatus.SHIPPING];
      } else if (this.tabIndex === 3) {
        this.filterForm.statusIds = [orderStatus.DELIVERING];
      } else if (this.tabIndex === 4) {
        this.filterForm.statusIds = [orderStatus.PAID];
      } else if (this.tabIndex === 5) {
        this.filterForm.statusIds = [orderStatus.SUCCESS];
      } else if (this.tabIndex === 6) {
        this.filterForm.statusIds = [orderStatus.CANCELLED];
      }
      this.currentPage = 1;
      this.getOrderList();
    },
    async getOrderList() {
      const params = {
        limit: this.limit,
        page: this.currentPage,
        keyword: this.filterForm.keyword,
        phone: this.filterForm.phone,
        code: this.filterForm.code,
        departmentIds: this.filterForm.departmentIds,
        userIds: this.filterForm.userIds,
        customerIds: this.filterForm.customerIds,
        productIds: this.filterForm.productIds,
        paymentType: this.filterForm.paymentType,
        createdAt: this.filterForm.createdAt,
        positions: this.filterForm.positions,
        statusIds: this.filterForm.statusIds.filter(Boolean),
      };

      if (this.tabIndex > 0 && params.statusIds.length == 0) {
        this.tabIndex = 0;
      }

      this.loading = true;
      const loader = this.$loading.show();
      const response = await OrderRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.orders = this.mappingOrderList(
          _.get(response, "data.list_order", [])
        );
        this.orderStatusOptions = _.get(
          response,
          "data.order_status_options",
          []
        );
        this.totalItem = _.get(response, "data.total_item", 0);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
        this.orders = [];
        this.totalItem = 0;
      }
    },
    mappingOrderList(orders) {
      return orders.map((order) => {
        const orderTemp = order;
        let objProductCombo = {};
        let productComboList = [];
        _.get(orderTemp, "combos", []).forEach((dataCombo) => {
          const quantity = _.get(dataCombo, "OrderProduct.quantity", 0);
          let productComboId = dataCombo.id + "_combo";
          if (!objProductCombo[productComboId]) {
            objProductCombo[productComboId] = {
              name: dataCombo.name,
              quantity: 0,
            };
          }
          objProductCombo[productComboId].quantity += quantity;
        });
        _.get(orderTemp, "products", []).forEach((dataProduct) => {
          const quantity = _.get(dataProduct, "OrderProduct.quantity", 0);
          let productName = dataProduct.name;
          let productId = dataProduct.id;
          if (!objProductCombo[productId]) {
            objProductCombo[productId] = {
              name: productName,
              quantity: 0,
            };
            objProductCombo[productId].quantity += quantity;
          }
        });

        for (const productId in objProductCombo) {
          productComboList.push(objProductCombo[productId]);
        }
        orderTemp.rowspan =
          productComboList.length === 0 ? 1 : productComboList.length;
        orderTemp.productComboList = productComboList;
        return orderTemp;
      });
    },

    ctvCreate() {
      this.$router.push("/orders/order-create");
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.getOrderList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getOrderList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
};
</script>
<style scoped>
#order-list {
  padding: 0px 15px;
}
.order-list__wrapper {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
}
.ctv-create-btn {
  position: absolute;
  right: 22px;
  top: 10px;
}
</style>