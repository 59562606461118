<template>
  <div>
    <v-tabs grow v-model="autoAllocation">
      <v-tab @change="searchUser('true')"
      >Tự động phân bổ</v-tab>

      <v-tab @change="searchUser('false')"
      >Không tự động phân bổ</v-tab>
    </v-tabs>
    <v-simple-table class="table-layout pa-5" v-model="autoAllocation" v-if="users.length != 0">
      <span>{{autoAllocation}}</span>
      <template v-slot:default>
        <thead>
        <tr>
          <th rowspan="2" class="border-table">Nhóm khách hàng</th>
          <th rowspan="2" class="border-table">Nhân viên</th>
          <th rowspan="2" class="border-table">Kho phụ trách</th>
          <th colspan="3" class="border-table">Hạn mức riêng</th>
          <th colspan="3" class="border-table">Số contact thực tế nhận</th>
          <th rowspan="2" class="border-table">Quản lý phân bổ</th>
        </tr>
        <tr>
          <th class="border-table">S</th>
          <th class="border-table">C</th>
          <th class="border-table">T</th>
          <th class="border-table">S</th>
          <th class="border-table">C</th>
          <th class="border-table">T</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(user) in users">
          <tr
              :key="user.id"
              class="custom-v-table-tr"
          >

            <td class="border-table"
                v-if="user.rowSpan"
                :rowspan="_.get(user, 'rowSpan', null)"
            >
              {{user.departmentName}}
            </td>
            <td class="border-table" > {{user.fullName}} </td>
            <td @dblclick="beforeEdit(user, 'store')" class="border-table">
              <div v-if="user.showEdit.store">
                <v-autocomplete
                    v-model="user.store"
                    :items="stores"
                    item-text="name"
                    item-value="value"
                    @change="updateUser(user)"
                />
              </div>
              <div v-else>
                {{user.store | storeFilter}}
              </div>
            </td>
            <td @dblclick="beforeEdit(user, 'number_of_contact_m')" class="border-table">
              <div v-if="user.showEdit.number_of_contact_m">
                <v-text-field
                    v-model="user.number_of_contact_m"
                    color="primary"
                    type="number"
                    min="0"
                    step="1"
                    @change="updateUser(user)"
                />
              </div>
              <div v-else>
                {{user.number_of_contact_m}}
              </div>
            </td>
            <td @dblclick="beforeEdit(user, 'number_of_contact_a')" class="border-table">
              <div v-if="user.showEdit.number_of_contact_a">
                <v-text-field
                    v-model="user.number_of_contact_a"
                    color="primary"
                    type="number"
                    min="0"
                    step="1"
                    @change="updateUser(user)"
                />
              </div>
              <div v-else>
                {{user.number_of_contact_a}}
              </div>
            </td>
            <td class="border-table" @dblclick="beforeEdit(user, 'number_of_contact_e')">
              <div v-if="user.showEdit.number_of_contact_e">
                <v-text-field
                    v-model="user.number_of_contact_e"
                    color="primary"
                    type="number"
                    min="0"
                    step="1"
                    @change="updateUser(user)"
                />
              </div>
              <div v-else>
                {{user.number_of_contact_e}}
              </div>
            </td>
            <td class="border-table">
                {{user.real_contacts_m}}
            </td>
            <td class="border-table">
                {{user.real_contacts_a}}
            </td>
            <td class="border-table">
                {{user.real_contacts_e}}
            </td>
            <td class="border-table">
              <div style="width: 85px; margin: 0 auto">
                <v-switch
                    v-model="user.assignContact"
                    inset
                    @change="updateUser(user)"
                    class="switch-assign"
                />
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
    <common-no-result
        v-if="users.length === 0"
    />
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => {[]}
    },
    dataForm: {
      type: Object,
      default: () => {{}}
    },
    filterForm: {
      type: Object,
      default: () => {}
    }
  },
  filters: {
    storeFilter(value) {
      if (value == 'T') {
        return 'Kho T'
      } else if (value == 'G') {
        return 'Kho G'
      } else if (value == 'C') {
        return 'Kho C'
      } else {
        return 'Kho M'
      }
    }
  },
  data() {
    return {
      stores: [
        {
          name: 'Kho T',
          value: 'T'
        },
        {
          name: 'Kho C',
          value: 'C'
        },
        {
          name: 'Kho G',
          value: 'G'
        },
        {
          name: 'Kho M',
          value: 'M'
        }
      ],
      autoAllocation: true,
      assignContacts: [
        {
          name: 'Tự động',
          value: 'true'
        },
        {
          name: 'Không tự động',
          value: 'false'
        }
      ]
    }
  },
  methods: {
    updateUser(user) {
      Bus.$emit('update-user-setting-contact-v1', user)
    },
    beforeEdit(user, param) {
      user.showEdit[param] = !user.showEdit[param]
    },
    searchUser(status) {
      this.filterForm.assignContact = status
      Bus.$emit('search-setting-contact-v1', this.filterForm)
    },
  }
}
</script>

<style scoped>
/deep/.switch-assign.v-input--switch--inset .v-input--switch__thumb {
  transform: translate(0px, 0px) !important;
  width: 12px !important;
  height: 12px !important;
}
/deep/.switch-assign.v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
  transform: translate(60px, 0px) !important;
  width: 12px !important;
  height: 12px !important;
}
/deep/.switch-assign.v-input--switch--inset .v-input--selection-controls__ripple {
  transform: translate(3px, 3px) !important;
  width: 20px !important;
  height: 20px !important;
}
/deep/.switch-assign.v-input--switch--inset.v-input--is-dirty .v-input--selection-controls__ripple {
  transform: translate(63px, 3px) !important;
  width: 20px !important;
  height: 20px !important;
}
/deep/.switch-assign.v-input--switch--inset .v-input--switch__track {
  width: 80px !important;
  height: 20px !important;
}
/deep/ table tbody tr {
  height: 30px !important;
}
/deep/ table thead tr {
  height: 45px !important;
}
/deep/ table tbody tr td {
  height: 45px !important;
  padding: 0px 8px !important
}
/deep/ table thead tr th {
  height: 30px !important;
  padding: 0px 8px !important;
}
.switch-assign {
  height: 24px;
  margin-top: 0px !important;
}
/deep/.v-tabs-bar.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}
div.v-tab {
  width: 50%;
  padding-left: 5px;
}
</style>