<template>
    <v-row class="mx-1">
      <v-col
          cols="12"
          md="4"
          sm="12"
      >
        <base-material-card
            color="warning"
            class="px-3 py-3 overflow-visible"
            mobileShow
        >
          <template v-slot:heading>
            <v-row>
              <v-icon class="custom-icon-title" size="30">mdi-gift-outline</v-icon>
              <div class="display-2 font-weight-light text-left pa-2 custom-title">
                Đơn hàng
              </div>
            </v-row>
          </template>
          <v-card-text class="text-right d-flex custom-v-cart-text">

              <v-btn
                  color="info"
                  dark
                  to="orders/order-create"
                  v-if="checkAuthorization('order-ctv-create_read')">
                <v-icon class="mr-1">mdi-plus</v-icon>Tạo đơn hàng
              </v-btn>

              <v-btn
                  class="ml-2"
                  color="info"
                  dark
                  to="orders/order-list"
                  v-if="checkAuthorization('order_read')">
                <v-icon class="mr-2">mdi-format-list-bulleted</v-icon>Xem danh sách
              </v-btn>

          </v-card-text>
        </base-material-card>
      </v-col>


     <v-col
         cols="12"
         md="4"
         sm="12"
     >
       <base-material-card
           color="warning"
           class="px-5 py-3 overflow-visible"
           mobileShow
       >
         <template v-slot:heading>
           <v-row>
             <v-icon class="custom-icon-title" size="30">mdi-chart-bar</v-icon>
             <div class="display-2 font-weight-light text-left pa-2 custom-title">
               Báo cáo
             </div>
           </v-row>
         </template>
         <v-card-text class="px-0">

           <v-simple-table
               class="custom-table-simpl"
           >
             <template v-slot:default>
               <thead>
               <tr>
                 <th></th>
                 <th class="text-center">Số đơn TC</th>
                 <th class="text-center">Doanh thu TC</th>
               </tr>
               </thead>
               <tbody>
               <tr>
                 <td>Tổng</td>
                 <td class="text-center">{{ personalReport.successCountOrders }}</td>
                 <td class="text-center">{{ formatPrice(parseFloat(personalReport.successTurnOver)) }}</td>
               </tr>
               </tbody>
             </template>
           </v-simple-table>
         </v-card-text>
       </base-material-card>
     </v-col>

      <v-col
          cols="12"
          md="4"
          sm="12"
      >
        <base-material-card
            color="warning"
            class="px-3 py-3 overflow-visible"
            mobileShow
        >
          <template v-slot:heading>
            <v-row>
              <v-icon class="custom-icon-title" size="30">mdi-account-voice</v-icon>
              <div class="display-2 font-weight-light text-left pa-2 custom-title">
                Hỗ trợ CSKH
              </div>
            </v-row>
          </template>
          <v-card-text class="text-right custom-v-cart-text">
            <v-btn
                class="custom-order-list-button"
                color="info"
                dark
                to="/customer-support">
              <v-icon class="mr-1">mdi-plus</v-icon>Tạo yêu cầu
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
</template>


<script>
import { formatPrice } from '@/helpers/utils'
import moment from 'moment'
import TokenService from '../../helpers/token';
import RepositoryFactory from '../../repositories/RepositoryFactory'
const ReportRepository = RepositoryFactory.get('report')
export default {
  data: () => ({
      headers: [
        { text: '', align: 'center', value: '' },
        { text: 'Số đơn TC', align: 'center', value: 'countOrdersSuccess' },
        { text: 'Doanh thu TC', align: 'center', value: 'turnoverSuccess' },
      ],
    personalReport: {
      successCountOrders: 0,
      successTurnOver: 0,
    },
  }),
  created() {
    // this.getReportTotal()
  },
  methods: {
    async getReportTotal() {
      let loading = this.$loading.show()
      let userId = TokenService.getId()
      let res = await ReportRepository.personalReport({
        startDate: '2020-09-01',
        endDate: moment().format('YYYY-MM-DD'),
        userId
      })
      if (res.success) {
        this.personalReport = _.get(res, 'data', {})
      } else {
        this.personalReport = {
          successCountOrders: 0,
          successTurnOver: 0,
        }
      }
      loading.hide()
    }
  }
}
</script>

<style scoped>
.custom-icon-title {
  float: left;
}
.custom-table-simple tr th, td {
  font-weight: 400 !important;
}
/deep/.text-start.v-card--material__heading {
  padding: 10px 20px !important;
  width: 85% !important;
}
.custom-title {
  font-size: 14px !important;
  font-weight: bold !important;
  width: calc(100% - 30px) !important;
}
/deep/.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab), .v-btn.v-size--large:not(.v-btn--icon):not(.v-btn--fab).custom-order-list-button {
  padding: 6px 8px !important;
  max-width: 50%;
}

.custom-v-cart-text {
  justify-content: flex-end !important;
  padding: 16px 0px !important;
}
</style>