<template>
    <div>
        <div class="order-container">
            <v-row v-for="(order, index) in orders" :key="index">
                <v-col cols="12" class="order-info" @click="order.expanded = !order.expanded">
                    <div>
                        <span><b>{{_.get(order, 'receiver', '')}}</b></span>
                        <span class="float-right">{{formatDate(order.createdAt)}}</span>
                    </div>
                    <div>
                        <span>
                            <span class="status-order" :style="'background-color:' + getStatusColor(order.statusId)">
                                {{getStatusName(order.statusId)}}
                            </span>&nbsp;- {{_.get(order, 'phone', '')}}</span>
                        <span class="float-right"><b>{{formatPrice(order.totalPrice)}}&#8363;</b></span>
                    </div>
                </v-col>
                <v-row v-if="order.expanded">
                    <v-col cols="4" class="text-center px-2 py-0" v-if="checkAuthorization('order-payment_read') || checkAuthorization('order-contact_read')">
                        <v-btn small
                            class="mr-0 order-action-btn"
                            color="primary"
                            v-if="checkAuthorization('order-payment_read') && (getStatusType(order.statusId) == 'T4' || getStatusType(order.statusId) == 'C4')"
                            @click="confirmPayment(order.id)">
                            Xác nhận thanh toán
                        </v-btn>
                        <v-btn small
                            class="mr-0 order-action-btn"
                            color="primary"
                            @click="showAccount(order.id)"
                            v-if="checkAuthorization('order-contact_read') && (getStatusType(order.statusId) == 'T5' || getStatusType(order.statusId) == 'C5')">
                            Xem tài khoản
                        </v-btn>
                    </v-col>
                    <v-col cols="4" class="text-center px-2 py-0" v-if="checkAuthorization('order_read')">
                        <v-btn small
                            class="mr-0 order-action-btn"
                            color="success"
                            @click="toOrderDetail(order.id, 'read')">
                            Xem
                        </v-btn>
                    </v-col>
                    <v-col cols="4" class="text-center px-2 py-0" v-if="checkAuthorization('order_update')">
                        <v-btn small
                            class="mr-0 order-action-btn"
                            color="info"
                            @click="toOrderDetail(order.id, 'update')">
                            Sửa
                        </v-btn>
                    </v-col>
                </v-row>
            </v-row>
            <v-row v-if="checkEmpty()">
                <v-col cols="12" class="order-info">
                    <common-no-result />
                </v-col>
            </v-row>
            <v-row style="display: block; height: 120px"></v-row>
        </div>
    </div>
</template>

<script>
import { contactStatusDefaultColor } from '@/helpers/constants'
import moment from 'moment'
import RepositoryFactory from '../../../repositories/RepositoryFactory'
const OrderRepository = RepositoryFactory.get('order')

export default {
    props: {
        orders: {
            type: Array,
            default: () => {[]}
        },
        contactStatusList: {
            type: Array,
            default: () => {[]}
        }
    },
    methods: {
        formatDate(date) {
            if (date) {
                return moment(date).format('DD, [thg] MM')
            } 
            return ''
        },

        getStatusColor(statusId) {
            let index = this.contactStatusList.findIndex(status => status.id == statusId)
            if (index > -1) {
                return this.contactStatusList[index].color
            }
            return contactStatusDefaultColor
        },

        getStatusName(statusId) {
            let index = this.contactStatusList.findIndex(status => status.id == statusId)
            if (index > -1) {
                return this.contactStatusList[index].name
            }
            return ''
        },

        checkEmpty() {
            return _.isEmpty(this.orders)
        },

        getStatusType(statusId) {
            let index = this.contactStatusList.findIndex(status => status.id == statusId)
            if (index > -1) {
                return this.contactStatusList[index].type
            }
            return ''
        },

        toOrderDetail(id, type) {
            this.$router.push({
                name: 'OrderDetail',
                params: {
                    id
                },
                query: {
                    orderList: true,
                    mobile: true,
                    allowUpdate: type == 'read' ? false : true
                },
            })
        },

        confirmPayment(id) {
            let me = this
            this.$swal({
                title: 'Bạn có chắc chắc đã nhận được tiền học phí của đơn hàng này không?',
                confirmButtonText: 'Xác nhận',
                showCancelButton: true,
                cancelButtonText: 'Hủy'
            }).then(async response => {
                if (response.value) {
                    let res = await OrderRepository.confirmPayment({id})
                    if (res.success) {
                        Bus.$emit('update-orders-mobile')
                    }
                }
            })
        },

        showAccount(id) {
            this.$router.push(`/orders/${id}/account`)
        }
    }
}
</script>

<style scoped>
.row {
    margin: 0px !important;
}

.order-container {
    height: calc(100vh - 110px);
    overflow-x: hidden;
    overflow-y: auto;
}

.order-info {

}

.float-right {
    float: right;
}

.status-order {
    color: white;
    padding: 3px 5px;
    margin-right: 3px;
}

.order-action-btn {
    padding: 0px 4px !important;
    width: 100%;
}

/deep/.v-btn__content {
  width: 100% !important;
  white-space: normal !important;
  word-break: break-word !important;
}
</style>