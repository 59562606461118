<template>
    <div>
        <v-simple-table class="table-layout pa-5">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Ngày gửi</th>
                        <th>Tệp khách hàng nhận</th>
                        <th>Mã tin nhắn</th>
                        <th>Số đã gửi</th>
                        <th>Người gửi</th>
                        <th>Kênh gửi</th>
                        <th>Trạng thái</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody v-if="!loading && smsHistoryList.length">
                    <tr v-for="(item, index) in smsHistoryList" :key="index">
                        <td>{{ item.createdAt }}</td>
                        <td>{{ item.contactFilterTitle }}</td>
                        <td>{{ item.smsCode }}</td>
                        <td>{{ item.numberOfContact }}</td>
                        <td>{{ showCreatedBy(item.createdBy) }}</td>
                        <td>{{ item.channel }}</td>
                        <td>{{ showStatus(item.status) }}</td>
                        <td>
                            <v-icon color="primary" class="icon-pointer" @click="showDetailSend(item)">mdi-file-document</v-icon>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="!loading && smsHistoryList.length == 0">
                    <tr>
                        <td colspan="1000" class="text-center">
                            <common-no-result/>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    props: {
        smsHistoryList: {
            type: Array,
            default: () => {[]}
        },
        loading: {
            type: Boolean,
            default: false
        },
        users: {
            type: Array,
            default: () => {[]}
        }
    },
    data() {
        return {

        }
    },
    methods: {
        showStatus(value) {
            if (value == 'SUCCESS') {
                return 'Thành công'
            } else if (value == 'FAILED') {
                return 'Thất bại'
            }

            return 'Đang gửi'
        },

        showCreatedBy(value) {
            if (_.isArray(this.users) && this.users.length) {
                let index = this.users.findIndex(item => item.id == value)
                if (index > -1) {
                    return this.users[index].fullName
                }
            }

            return ""
        },

        showDetailSend(history) {
            this.$emit("showDetailHistory", history)
        }
    }
}
</script>

<style scoped>
/deep/ table tbody tr {
    height: 40px;
}
</style>