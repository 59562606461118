<template>
    <div>
        <div class="order-account-container">
          <v-row>
            <span @click="goBack" class="mobile-show span-icon-custom">
              <v-icon size="25">mdi-arrow-left</v-icon>
            </span>
              <span class="font-weight-light card-title mt-2 span-custom">Danh sách tài khoản</span>
          </v-row>

          <v-row v-for="(item, index) in orderAccounts" :key="index" :class="(index%2) ? 'odd-border' : 'even-border'" class="ma-2">
            <v-col cols="2" class="text-center">
              <v-icon v-if="item.gender == 0" size="40">mdi-face-outline</v-icon>
              <v-icon v-else size="40">mdi-face-woman-outline</v-icon>
            </v-col>

            <v-col cols="10">
              <div>
                <span>Tài khoản: <b>{{item.username}}</b></span>
              </div>
              <div>
                <span>Mật khẩu: <b>{{item.password}}</b></span>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="checkEmpty()">
              <v-col cols="12">
                  <common-no-result />
              </v-col>
          </v-row>
          <v-row style="display: block; height: 120px"></v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      orderAccounts: {
            type: Array,
            default: () => {[]}
        }
    },
    methods: {
      checkEmpty() {
          return _.isEmpty(this.orderAccounts)
      },
      goBack() {
        this.$router.go(-1)
      }
    }
}
</script>

<style scoped>
.order-account-container {
    height: calc(100vh - 110px);
    overflow-x: hidden;
    overflow-y: auto;
}

.even-border {
  border: #1E90FF solid 2px;
}

.odd-border {
  border: #4caf50 solid 2px;
}
span.span-custom {
  margin: 5px auto !important;
}
span.span-icon-custom {
  position: absolute;
  top: 5px;
  left: 6px;
}
</style>