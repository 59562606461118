<template>
    <v-container id="announcement" fluid tag="section">
        <filter-form :filterForm="filterForm" :departments="departments" />
        <div class="text-sm-right mb-4" v-if="checkAuthorization('announcement_create')">
            <v-btn
                    class="primary mx-0"
                    @click="createAnnouncement()"
            >
                <v-icon>mdi-plus</v-icon>Thêm Mới
            </v-btn>
        </div>
        <v-card>
            <announcement-table 
                    :announcementList="announcementList" 
                    :departments="departments"
                    @updateAnnouncement="updateAnnouncement" 
                    @deleteAnnouncement="deleteAnnouncement"/>

            <v-row class="mt-4">
                <v-col cols="12" sm="6" />
                <v-col cols="12" sm="6">
                <v-pagination
                    v-if="totalItem > limit"
                    v-model="currentPage"
                    class="justify-sm-end"
                    :length="getTotalItem(totalItem, limit)"
                    prev-icon="mdi-menu-left"
                    next-icon="mdi-menu-right"
                />
                </v-col>
            </v-row>
        </v-card>

        <announcement-form :departments="departments" />
    </v-container>
</template>

<script>
import moment from 'moment'
import AnnouncementTable from './components/AnnouncementTable'
import FilterForm from './components/FilterForm'
import AnnouncementForm from './components/AnnouncementForm'
import RepositoryFactory from '../../repositories/RepositoryFactory';
import TokenService from '../../helpers/token';
const AnnouncementRepository = RepositoryFactory.get('announcement')
const DepartmentRepository = RepositoryFactory.get('department')

export default {
    components: { AnnouncementTable, FilterForm, AnnouncementForm },
    data() {
        return {
            announcementList: [],
            currentPage: 1,
            totalItem: 0,
            limit: 20,
            filterForm: {
                departmentIds: '',
                content: '',
                dateRange: {
                  startDate: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
                  endDate: moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
                }
            },
            departments: []
        }
    },
    created() {
        this.getList()
        this.getDepartmentList()
        Bus.$on("announcement-list-filter", filterForm => {
            this.filterForm = filterForm
            this.currentPage = 1
            this.getList()
        })
        Bus.$on("create-new-announcement", () => {
            this.currentPage = 1
            this.getList()
        })
        Bus.$on("update-announcement", () => {
            this.getList()
        })
    },
    watch: {
        currentPage: {
            deep: true,
            handler() {
                this.getList()
            }
        }
    },
    methods: {
        async getList() {
            const loading = this.$loading.show()
            const params = {
                time: this.filterForm.time,
                departmentIds: this.filterForm.departmentIds,
                content: this.filterForm.content,
                limit: this.limit,
                page: this.currentPage - 1,
                startDate: this.filterForm.dateRange.startDate,
                endDate: this.filterForm.dateRange.endDate,
            }

            const res = await AnnouncementRepository.list(params)
            if (res.success) {
                let announcementList = _.get(res, 'data', [])
                announcementList = announcementList.map(item => {
                    return {
                        ...item,
                        departmentIds: item.departmentIds ? item.departmentIds.split(",").map(i => parseInt(i)) : []
                    }
                })
                this.announcementList = announcementList
                this.totalItem = _.get(res, 'totalItem', 0)
            } else {
                this.announcementList = []
                this.totalItem = 0
            }
            loading.hide()
        },
        createAnnouncement() {
            Bus.$emit("create-announcement-form")
        },
        updateAnnouncement(announcement) {
            Bus.$emit("update-announcement-form", announcement)
        },
        async deleteAnnouncement(id) {
            const res = await AnnouncementRepository.delete(id) 
            if (res.success) {
                this.getList()
            } else {
                this.$swal({
                    title: "Lỗi!",
                    text: _.get(res, "message", "")
                })
            }
        },
        async getDepartmentList () {
            const response = await DepartmentRepository.list({ limit: 1000 })
            if (_.get(response, 'success', false)) {
                const departments = _.get(response, 'data', [])
                this.departments = departments
            }
        },
    },
}
</script>