<template>
  <v-container id="product-list" fluid tag="section">
    <div class="md-layout">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>

      <FilterForm :filterForm="filterForm" @promotions-filter="search" />

      <div class="text-sm-right mb-3" style="margin-top: 20px">
        <v-btn
          v-show="selected.length ? true : false"
          color="default"
          dark
          class="mb-2 mr-2"
          @click="deleteMultipleItem"
        >
          Xóa tất cả
        </v-btn>
        <v-btn
          v-if="checkAuthorization('promotion_create')"
          color="primary"
          dark
          class="mb-2"
          @click="gotoNewPage"
        >
          <v-icon>mdi-plus</v-icon>Thêm Mới
        </v-btn>
      </div>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="tableData"
        :single-select="singleSelect"
        item-key="id"
        class="table-layout pa-5"
        hide-default-footer
        :show-select="checkAuthorization('promotion_delete')"
      >
        <template v-slot:[`item.thumnail`]="{ item }">
          <div>
            <v-img
              :src="getThumbnailUrl(item.image)"
              max-width="120"
              align="center"
            />
          </div>
        </template>

        <template v-slot:no-data>
          <common-no-result />
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom v-if="checkAuthorization('promotion_update')">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mr-2"
                @click="editItem(item)"
                icon
                small
                color="primary"
                dark
                v-on="on"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>

            <span>Cập nhật chương trình</span>
          </v-tooltip>
          <v-tooltip bottom v-if="checkAuthorization('promotion_delete')">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="deleteItem(item)"
                size="22"
                icon
                small
                dark
                color="red"
                v-on="on"
                ><v-icon>mdi-window-close</v-icon></v-btn
              >
            </template>
            <span>Xóa chương trình</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- Pagination -->

      <v-row style="padding: 20px 20px">
        <v-col cols="12" sm="6" />
        <v-col cols="12" sm="6">
          <v-pagination
            v-if="totalItem > limit"
            v-model="currentPage"
            :length="getTotalItem(totalItem, limit)"
            class="justify-sm-end"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import FilterForm from "./components/FilterForm";
const PromotionsRepository = RepositoryFactory.get("promotions");

export default {
  components: { FilterForm },
  data() {
    return {
      singleSelect: false,
      selected: [],
      tableData: [],
      headers: [
        { text: "Tên chương trình", value: "title", align: "center" },
        { text: "Hình ảnh", value: "thumnail", align: "center" },
        { text: "Liên kết", value: "link", align: "center" },
        { text: "Mô tả", value: "description", align: "center" },
        { text: "Thời gian bắt đầu", value: "startTime", align: "center" },
        { text: "Thời gian kết thúc", value: "endTime", align: "center" },
      ],
      errorMessage: "",
      loading: false,
      filterForm: {},
      currentPage: 1,
      limit: 10,
      totalItem: 0,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.fetchTableData();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.fetchTableData();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  mounted() {
    this.fetchTableData();
  },
  created() {
    if (
      this.checkAuthorization("promotion_update") ||
      this.checkAuthorization("promotion_create")
    ) {
      this.headers.push({
        text: "Thao tác",
        value: "action",
        align: "center",
        sortable: false,
      });
    }
  },
  methods: {
    search(filterForm) {
      if (filterForm) {
        this.filterForm = filterForm;
      }
      this.fetchTableData();
    },

    getThumbnailUrl(image) {
      return _.get(image, "filePath", "");
    },

    gotoNewPage() {
      this.$router.push({
        name: "CreatePromotions",
      });
    },

    editItem(item) {
      this.$router.push({
        name: "EditPromotions",
        params: {
          id: _.get(item, "id", ""),
        },
      });
    },

    async deleteItem(item) {
      this.$swal({
        title: "Xóa chương trình",
        text: "Bạn có thực sự muốn xoá chương trình này?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const response = await PromotionsRepository.deleteOneItem(
            _.get(item, "id", 0)
          );
          loader.hide();
          if (response.success) {
            this.$swal({
              title: "Xoá thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              this.fetchTableData();
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(response, "message", ""),
            });
          }
        }
      });
    },

    async deleteMultipleItem() {
      this.$swal({
        title: "Xóa toàn bộ chương trình",
        text: "Bạn có thực sự muốn xoá các chương trình này?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const response = await PromotionsRepository.deleteManyItems(
            this.selected.map((s) => s.id)
          );
          loader.hide();
          if (response.success) {
            this.selected = [];
            this.$swal({
              title: "Xoá thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              this.fetchTableData();
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(response, "message", ""),
            });
          }
        }
      });
    },

    async fetchTableData() {
      this.loading = this.$loading.show();
      const response = await PromotionsRepository.list({
        limit: this.limit,
        page: this.currentPage,
        keyword: this.filterForm.keyword,
        status: this.filterForm.status,
        dateRange: this.filterForm.dateRange,
        listCustomer: this.filterForm.listCustomer,
        listProduct: this.filterForm.listProduct,
      });

      if (response.success) {
        this.errorMessage = "";
        this.tableData = _.get(response, "data.list_promotion_program");
        this.totalItem = _.get(response, "data.total_item", 0);
      } else {
        this.errorMessage = _.get(response, "message", "");
      }
      this.loading.hide();
    },
  },
};
</script>
