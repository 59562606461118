<template>
  <v-container id="edit-product" fluid tag="section">
    <v-card class="mx-auto pa-5" outlined>
      <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(update)">
          <h3 style="padding-bottom: 35px">Thông chương trình khuyến mãi</h3>
          <v-alert type="error" v-if="errorMessage">
            {{ errorMessage }}
          </v-alert>

          <v-row>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Tên chương trình khuyến mãi"
                rules="required|max:512"
              >
                <v-text-field
                  v-model="details.title"
                  label="Tên *"
                  :error-messages="errors"
                  color="primary"
                  @change="convertToSlug()"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Đường dẫn"
                :rules="{
                  required: true,
                  regex: regex.isSlug,
                }"
              >
                <v-text-field
                  v-model="details.slug"
                  label="Đường dẫn *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Alt" :rules="{}">
                <v-text-field
                  v-model="details.alt"
                  label="Thẻ alt"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Nhà tài trợ"
                :rules="{ required: true }"
              >
                <v-text-field
                  v-model="details.sponsor"
                  label="Nhà tài trợ"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Alt" :rules="{}">
                <v-text-field
                  v-model="details.link"
                  label="Liên kết"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Alt" :rules="{}">
                <v-text-field
                  v-model="details.description"
                  label="Mô tả"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="Thời gian ngày bắt đầu"
                rules="required"
              >
                <v-menu
                  max-width="300"
                  ref="menu"
                  v-model="start.menuStartTime"
                  :close-on-content-click="false"
                  :nudge-right="30"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start.startTime"
                      label="Bắt đầu từ"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="start.menuStartTime"
                    v-model="start.startTime"
                    full-width
                    @click:minute="$refs.menu.save(start.startTime)"
                    :error-messages="errors"
                  ></v-time-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="Ngày bắt đầu"
                rules="required"
              >
                <div class="style-datePicker">
                  <label class="style-datePicker_title">Ngày bắt đầu</label>
                  <date-picker
                    format="YYYY-MM-DD"
                    v-model="start.startDate"
                    type="date"
                    placeholder="Chọn ngày"
                    :error-messages="errors"
                  ></date-picker>
                </div>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="Thời gian ngày kết thúc"
                rules="required"
              >
                <v-menu
                  ref="menu"
                  max-width="300"
                  v-model="end.menuEndTime"
                  :close-on-content-click="false"
                  :nudge-right="30"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end.endTime"
                      label="Kết thúc vào"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="end.menuEndTime"
                    v-model="end.endTime"
                    full-width
                    @click:minute="$refs.menu.save(end.endTime)"
                    :error-messages="errors"
                  ></v-time-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="Ngày kết thúc"
                rules="required"
              >
                <div class="style-datePicker">
                  <label class="style-datePicker_title">Ngày kết thúc</label>
                  <date-picker
                    format="YYYY-MM-DD"
                    v-model="end.endDate"
                    type="date"
                    placeholder="Chọn ngày"
                    :error-messages="errors"
                  ></date-picker>
                </div>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Sản phẩm *"
              >
                <v-autocomplete
                  v-model="details.products"
                  :items="products"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Sản phẩm *"
                  color="primary"
                  multiple
                  no-data-text="Không có dữ liệu"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="details.products"
                      :item="details.products"
                      :items="products.map((s) => s.id)"
                    />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }" name="Nhóm khách hàng">
                <v-autocomplete
                  v-model="details.groupCustomers"
                  :items="listGroupCustomer"
                  :error-messages="errors"
                  item-text="departmentName"
                  item-value="departmentId"
                  label="Nhóm khách hàng"
                  color="primary"
                  multiple
                  no-data-text="Không có dữ liệu"
                  @change="changeGroupCustomer()"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="details.groupCustomers"
                      :item="details.groupCustomers"
                      :items="listGroupCustomer.map((s) => s.departmentId)"
                      @change="changeGroupCustomer()"
                    />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Khách hàng *"
              >
                <v-autocomplete
                  v-model="details.customers"
                  :items="listCustomerForSelect"
                  :error-messages="errors"
                  item-text="username"
                  item-value="id"
                  label="Khách hàng *"
                  color="primary"
                  multiple
                  no-data-text="Không có dữ liệu"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="details.customers"
                      :item="details.customers"
                      :items="listCustomerForSelect.map((s) => s.id)"
                    />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12" align="center">
              <v-file-input
                show-size
                v-model="details.files"
                accept="image/*"
                label="Hình ảnh quảng cáo"
                prepend-icon="mdi-paperclip"
                placeholder="Ảnh"
                @change="changeFiles"
                :rules="ruleImg"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>

              <div v-if="details.imageId" class="display-image-block">
                <div class="delete-image-block">
                  <v-icon color="white" @click="deleteImage()" title="Xóa ảnh">
                    mdi-delete
                  </v-icon>
                </div>
                <v-img :src="_.get(details, 'image.filePath', '')" />
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <v-card-actions class="px-0 group-btn--form">
                <v-btn color="success" @click.prevent="handleSubmit(update)">
                  {{ isNew ? "Tạo" : "Cập nhật" }}
                </v-btn>

                <v-btn class="ml-0" color="primary" @click="goToList">
                  Hủy bỏ
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>
<script>
import moment from "moment";
import { regex, MAX_SIZE_IMAGE, PAGE_LIMIT } from "@/helpers/constants";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { filterItemValidArray } from "../../../helpers/utils";

const PromotionsRepository = RepositoryFactory.get("promotions");
const CustomerRepository = RepositoryFactory.get("customer");
const ProductRepository = RepositoryFactory.get("product");
const ImageRepository = RepositoryFactory.get("image");

export default {
  components: {},
  $_veeValidate: {
    validator: "new",
  },
  name: "EditPromotionsForm",
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      regex,
      isNew: true,
      details: {
        id: "",
        title: "",
        slug: "",
        alt: "",
        imageId: "",
        link: "",
        description: "",
        sponsor: "",
        startTime: "",
        endTime: "",
        groupProduct: [],
        listCustomer: [],
        file: {},
        groupCustomers: [],
      },
      alert: true,
      loading: false,
      sites: [],
      datePicker: false,
      listCustomer: [],
      products: [],
      start: {
        menuStartTime: false,
        startTime: "",
        startDate: "",
      },
      end: {
        endTime: "",
        endDate: "",
        menuEndTime: false,
      },
      errorMessage: "",
      ruleImg: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
        (value) => !!value || "Ảnh là bắt buộc!",
      ],
      listGroupCustomer: [],
      listCustomerForSelect: [],
    };
  },
  computed: {
    pageTitle() {
      return this.isNew
        ? "Tạo chương trình khuyến mãi"
        : "Sửa chương trình khuyến mãi";
    },
  },
  mounted() {
    if (this.$route.params && this.$route.params.id) {
      this.details.id = parseInt(this.$route.params.id);
      this.isNew = false;
    }
    this.getDetails();
    this.getListProduct();
    this.getListCustomer();
  },
  methods: {
    async changeFiles() {
      if (this.details.file && this.details.file.size > MAX_SIZE_IMAGE) {
        this.setAlertMessage(
          true,
          "error",
          "Không thể tải file ảnh lớn hơn 2 MB !"
        );
        return;
      }

      this.loading = this.$loading.show();
      const response = await ImageRepository.upload(this.details.files);
      this.loading.hide();
      if (response.error) {
        this.setAlertMessage(true, "error", "Tải file thất bại!");
      } else {
        this.details.imageId = _.get(response, "data.id", "");
      }
    },

    async update() {
      const details = {
        title: this.details.title,
        alt: this.details.alt,
        imageId: this.details.imageId,
        slug: this.details.slug,
        groupProduct: filterItemValidArray(this.details.products),
        listCustomer: filterItemValidArray(this.details.customers),
        description: this.details.description,
        link: this.details.link,
        sponsor: this.details.sponsor,
        startTime: `${moment(this.start.startDate).format("YYYY-MM-DD")} ${
          this.start.startTime
        }:00`,
        endTime: `${moment(this.end.endDate).format("YYYY-MM-DD")} ${
          this.end.endTime
        }:00`,
      };

      if (this.isNew) {
        this.loading = this.$loading.show();
        const response = await PromotionsRepository.create(details);
        this.loading.hide();
        if (response.error) {
          this.setAlertMessage(true, "error", _.get(response, "message"));
        } else {
          this.$swal({
            title: "Thêm chương trình khuyến mãi thành công!",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.goToList();
          });
        }
      } else {
        this.loading = this.$loading.show();
        const response = await PromotionsRepository.update(
          _.get(this.details, "id", ""),
          details
        );
        this.loading.hide();
        if (response.error) {
          this.setAlertMessage(true, "error", _.get(response, "message"));
        } else {
          this.$swal({
            title: "Cập nhật thành công!",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.goToList();
          });
        }
      }
    },
    setAlertMessage(alert, type, text) {
      this.$swal({
        type,
        text,
      });
    },
    async getDetails() {
      this.loading = this.$loading.show();
      const response = await PromotionsRepository.getDetails(this.details.id);
      this.loading.hide();
      if (response.error) {
        this.setAlertMessage(true, "error", _.get(response, "message"));
      } else {
        this.details = _.get(response, "data");
        if (this.details) {
          this.details.products = this.details.products.map((o) => o.id);
          this.details.customers = this.details.customers.map((o) => o.id);
          this.start.startTime = moment(this.details.startTime).format("HH:mm");
          this.start.startDate = new Date(this.details.startTime);
          this.end.endTime = moment(this.details.endTime).format("HH:mm");
          this.end.endDate = new Date(this.details.endTime);
        }
      }
    },
    goToList() {
      this.$router.push({
        name: "Promotions",
      });
    },

    async getListCustomer() {
      let res = await CustomerRepository.listCustomerGroup();
      if (res.success) {
        let customerList = [];
        _.get(res, "data.list_department", []).forEach(
          (groupCustomer, index) => {
            this.listGroupCustomer.push(groupCustomer);
            const { listCustomer = [] } = groupCustomer;
            customerList.push({
              header: groupCustomer.departmentName,
              name: groupCustomer.departmentName,
              departmentId: groupCustomer.departmentId,
            });
            listCustomer.forEach((cus) => {
              customerList.push({
                ...cus,
                departmentId: groupCustomer.departmentId,
              });
            });
          }
        );
        this.listCustomer = _.cloneDeep(customerList);
        this.listCustomerForSelect = _.cloneDeep(customerList);
      }
    },

    async getListProduct() {
      let res = await ProductRepository.list({
        limit: PAGE_LIMIT,
      });
      if (res.success) {
        this.products = _.get(res, "data.list_product", []);
      } else {
        this.products = [];
      }
    },
    convertToSlug() {
      let title = _.cloneDeep(this.details.title);

      // Đổi chữ hoa thành chữ thường
      let slug = title.toLowerCase();

      // Đổi ký tự có dấu thành không dấu
      slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
      slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
      slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
      slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
      slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
      slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      // Xóa các ký tự đặt biệt
      slug = slug.replace(
        /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi,
        ""
      );
      // Đổi khoảng trắng thành ký tự gạch ngang
      slug = slug.replace(/ /gi, "-");
      // Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
      // Phòng trường hợp người nhập vào quá nhiều ký tự trắng
      slug = slug.replace(/\-\-\-\-\-/gi, "-");
      slug = slug.replace(/\-\-\-\-/gi, "-");
      slug = slug.replace(/\-\-\-/gi, "-");
      slug = slug.replace(/\-\-/gi, "-");
      // Xóa các ký tự gạch ngang ở đầu và cuối
      slug = "@" + slug + "@";
      slug = slug.replace(/\@\-|\-\@|\@/gi, "");
      this.details.slug = slug;
    },
    deleteImage() {
      this.details.imageId = "";
    },
    changeGroupCustomer() {
      this.listCustomerForSelect = this.listCustomer.filter((item) =>
        this.details.groupCustomers.includes(item.departmentId)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.service-form-pictures {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa !important;
  line-height: 1.4;
}
.display-image-block {
  position: relative;
  width: 100%;
  max-width: 400px;
  border: 1px solid #f3f3f3;
  padding: 5px;
  display: inline-block;
}
.delete-image-block {
  position: absolute;
  right: 10px;
  bottom: 20px;
  z-index: 9;
}
.v-card--material {
  overflow: hidden;
}
.clear-both {
  clear: both;
}
.display-none {
  display: none;
}
.thumbnail-validation {
  .v-text-field__slot {
    display: none !important;
  }
}
.v-input--radio-group__input .v-input--selection-controls__ripple {
  left: -12px;
}
.errors-span-required-content {
  display: inline-block;
  height: 20px;
  width: 100%;
  text-align: left;
  color: #ff5252;
  font-size: 12px;
}
.title-span-content {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  margin-bottom: 8px;
}
</style>
