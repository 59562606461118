var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[(_vm.isCreate)?[_vm._v(" Thêm mới nhà sản xuất ")]:[_vm._v(" Cập nhật thông tin nhà sản xuất ")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v(" mdi-close ")])],2),_c('v-card-text',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:255","name":"Tên nhà sản xuất"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên nhà sản xuất *","error-messages":errors,"color":"primary"},model:{value:(_vm.manufacture.name),callback:function ($$v) {_vm.$set(_vm.manufacture, "name", $$v)},expression:"manufacture.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Đường dẫn","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Đường dẫn *","error-messages":errors,"color":"primary"},model:{value:(_vm.manufacture.slug),callback:function ($$v) {_vm.$set(_vm.manufacture, "slug", $$v)},expression:"manufacture.slug"}})]}}],null,true)})],1),_c('v-col',[_c('span',{staticClass:"title-span-content text-left",staticStyle:{"display":"inline-block","width":"100%"}},[_vm._v("Nội dung *")]),_c('validation-provider',{attrs:{"name":"Nội dung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.manufacture.description),callback:function ($$v) {_vm.$set(_vm.manufacture, "description", $$v)},expression:"manufacture.description"}})],(errors[0])?_c('span',{staticClass:"errors-span-required-content",attrs:{"color":"primary"}},[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveManufacture)}}},[_vm._v(" Lưu lại ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy bỏ ")])],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }