<template>
  <div>
    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên khách hàng</th>
            <th>Ảnh đại diện</th>
            <th>Số điện thoại</th>
            <th>Loại tài khoản</th>
            <th>Nhóm khách hàng</th>
            <th>Đăng nhập lần cuối</th>
            <th>Ngày tạo</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(user, index) in users">
            <tr :key="user.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(user, "username") }}</td>
              <td>
                <v-list-item-avatar class="align-self-center" color="grey">
                  <img
                    v-if="_.get(user, 'avatar.id')"
                    class=""
                    :src="_.get(user, 'avatar.filePath', '')"
                  />
                  <v-img v-else class="" src="@/assets/default-avatar.jpg" />
                </v-list-item-avatar>
              </td>
              <td>{{ _.get(user, "phone") }}</td>
              <td>
                {{
                  _.get(
                    customerPositionOptions.find(
                      (position) => position.value === user.position
                    ),
                    "text",
                    ""
                  )
                }}
              </td>
              <td>
                {{
                  _.get(
                    departments.find(
                      (department) => department.id === user.departmentId
                    ),
                    "name",
                    ""
                  )
                }}
              </td>
              <td>{{ _.get(user, "lastLoginAt") }}</td>
              <td>{{ _.get(user, "createdAt") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip v-if="user.status === 'inactive'" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        @click="convertStatus(user.id, 'active')"
                        size="22"
                        color="info"
                        icon
                        v-on="on"
                        >mdi-account-check-outline</v-icon
                      >
                    </template>
                    <span>Chuyển sang trạng thái hoạt động</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        @click="convertStatus(user.id, 'inactive')"
                        size="22"
                        color="success"
                        icon
                        v-on="on"
                        >mdi-account-cancel-outline</v-icon
                      >
                    </template>
                    <span>Chuyển sang trạng thái ngừng hoạt động</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('customer_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editCustomer(user.id)"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin khách hàng</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('customer_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="resetPassword(user)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-lock-reset</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật mật khẩu khách hàng</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('customer_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(user)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa khách hàng</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { customerPositionOptions } from "../../../helpers/constants";

const CustomerRepository = RepositoryFactory.get("customer");

export default {
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Array,
      default: () => [],
    },
    departments: {
      default: () => [],
    },
  },
  data() {
    return {
      selectedUser: {},
      modalAlert: false,
      errorMessage: "",
      customerPositionOptions,
    };
  },
  computed: {
    showAction() {
      return (
        this.checkAuthorization("customer_update") ||
        this.checkAuthorization("customer_delete")
      );
    },
  },
  methods: {
    async convertStatus(id, status) {
      const statusUser = {
        status,
      };
      this.$swal({
        title: "Chuyển đổi trạng thái",
        text: "Bạn có thực sự chắc chắn?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const response = await CustomerRepository.updateStatus(id, statusUser);
          loader.hide();
          if (response.success) {
            this.$swal({
              title: "Chuyển đổi thành công!",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("customer-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(response, "message", ""),
            });
          }
        }
      });
    },
    editCustomer(id) {
      let route = this.$router.resolve({
        name: "EditCustomer",
        params: {
          id: id,
        },
      });
      window.open(route.href, "_self");
    },
    resetPassword(user) {
      this.selectedUser = user;
      Bus.$emit("reset-password-customer", this.selectedUser);
    },
    async onClickDelete(user) {
      this.$swal({
        title: "Xóa khách hàng",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await CustomerRepository.delete(user.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("customer-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.table-layout {
  text-align: center;
}
.table-layout th {
  text-align: center !important;
  min-width: 130px;
  font-size: 14px;
  font-weight: 500 !important;
  position: relative;
}
.table-layout th.th-id {
  width: 50px;
}

.table-layout tbody tr {
  height: 80px;
}
.table-layout tbody tr:hover {
  background: rgba(0, 0, 0, 0.11) !important;
}
</style>
