var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card',{staticClass:"mx-auto pa-2",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","md":"3"}},[_c('v-switch',{attrs:{"label":"Tự động phân bổ contact"},model:{value:(_vm.dataForm.assignContact),callback:function ($$v) {_vm.$set(_vm.dataForm, "assignContact", $$v)},expression:"dataForm.assignContact"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Số contact phân bổ chung cho nhân viên","rules":"min_value:1|max_value:999|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Số contact phân bổ chung cho nhân viên","error-messages":errors,"color":"primary","type":"number"},model:{value:(_vm.dataForm.contactForTelesales),callback:function ($$v) {_vm.$set(_vm.dataForm, "contactForTelesales", $$v)},expression:"dataForm.contactForTelesales"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Số contact phân bổ chung cho trưởng nhóm","rules":"min_value:1|max_value:999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Số contact phân bổ chung cho trưởng nhóm","error-messages":errors,"color":"primary","type":"number"},model:{value:(_vm.dataForm.contactForSaleLeader),callback:function ($$v) {_vm.$set(_vm.dataForm, "contactForSaleLeader", $$v)},expression:"dataForm.contactForSaleLeader"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}}),_c('v-col',{staticClass:"d-flex",staticStyle:{"text-align":"right"},attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"internal-activator":true,"content-class":"menu__customer--filter no-contain overflow-visible","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","dark":""}},on),[_c('span',[_vm._v("Tìm kiếm")]),_c('v-icon',{staticStyle:{"margin-left":"10px","margin-right":"-20px"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(_vm.filterForm.openMenu),callback:function ($$v) {_vm.$set(_vm.filterForm, "openMenu", $$v)},expression:"filterForm.openMenu"}},[_c('card-filter-form',{attrs:{"filterForm":_vm.filterForm}})],1)],1)],1),_c('div',{staticClass:"text-center mb-4"},[(_vm.checkAuthorization('setting-contact_update'))?_c('v-btn',{staticClass:"primary mx-0",on:{"click":function($event){$event.preventDefault();handleSubmit(_vm.saveSetting); _vm.scrollToError()}}},[_vm._v("Lưu")]):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }