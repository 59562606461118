<template>
  <v-container id="" fluid tag="section">
    <base-material-card icon="mdi-account-outline" class="overflow-visible pb-0 mt-0">
      <template v-slot:after-heading>
        <div class="header mobile-hidden">
          <span class="font-weight-light card-title mt-2">Tạo đơn hàng</span>
        </div>
      </template>
      <span @click="goBack" class="mobile-show span-icon-custom"><v-icon>mdi-arrow-left</v-icon></span>
      <div class="mobile-show header-mobile">
        <span class="font-weight-light card-title mt-2 span-custom">Tạo đơn hàng</span>
      </div>
      <validation-observer v-slot="{ handleSubmit }">
        <div style="position: relative">
          <div class="header-group-btn mobile-hidden">
            <v-menu offset-y v-if="dataForm.contactType == 'main'">
                  <template v-slot:activator="{ on: on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="info" class="mb-2">
                      <v-icon>mdi-phone</v-icon>Gọi điện
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="clickExecuteCall(dataForm, dataForm.customers[0].mobile)"
                      class="main-mobile"
                    >
                      <v-list-item-title>{{dataForm.customers[0].mobile}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-for="(phone, index) in dataForm.phoneRelated"
                      :key="index"
                      @click="clickExecuteCall(dataForm, phone)"
                      class="sub-mobile"
                    >
                      <v-list-item-title>{{phone}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
            </v-menu>
            <v-btn @click="clickExecuteCall(dataContact, phone)" v-else color="info" class="mb-2">
              <v-icon>mdi-phone</v-icon>Gọi điện
            </v-btn>
          </div>
        </div>
        <v-row class="px-3" :class="stepOrder===3 ? 'active-step-3' : ''">
          <v-col v-if="stepOrder == 1" cols="12" sm="12" class="py-0">
            <customer-card
              :childCustomers="childCustomers"
              :dataForm="dataForm"
              :provinces="provinces"
              :stepOrder="stepOrder"
              :showChat="showChat"
            />
          </v-col>
          <v-col v-if="stepOrder > 1" class="pb-0" cols="12" :md="stepOrder === 2 ? 8 : 10">
            <order-card
              :shippingAddress="shippingAddress"
              :contactStatusList="contactStatusList"
              :contentOrder="contentOrder"
              :courseRegisters="courseRegisters"
              :childCustomers="childCustomers"
              :stepOrder="stepOrder"
              :showChat="showChat"
            />
          </v-col>
          <v-col v-if="stepOrder === 2" cols="12" md="4" class="pb-0">
            <address-card 
              :shippingAddress="shippingAddress" 
              :provinces="provinces" 
              :showChat="showChat" 
              :contentOrder="contentOrder"/>
          </v-col>
          <!-- <v-col cols="12" sm="12" style="text-align: center;" v-if="!checkIncludesT4NT5(dataForm.contactStatus.type)"> -->
          <v-col cols="12" sm="12" style="text-align: center;">
            <v-btn
              v-if="stepOrder === 2"
              color="default"
              class="mb-3"
              @click="stepOrder = stepOrder -1"
            >Quay lại</v-btn>
            <v-btn
              v-else
              color="success"
              class="mb-3"
              @click.prevent="handleSubmit(clickStep); scrollToError('v-messages__message', 'customer-info-wrapper')"
            >Tiếp Theo</v-btn>
            <v-btn
              v-if="stepOrder === 2 && checkAuthorization('order-ctv-create_read')"
              color="success"
              class="mb-3"
              @click.prevent="handleSubmit(beforeCreate); scrollToError('v-messages__message', 'order-products-wrapper')"
            >Tạo đơn</v-btn>
            <v-btn color="primary" class="mb-3" @click="clickCancel">Huỷ Bỏ</v-btn>
          </v-col>
        </v-row>
      </validation-observer>
    </base-material-card>
  </v-container>
</template>

<script>
import TokenService from '../../helpers/token';
import { contactDetail, PAGE_LIMIT } from '../../helpers/constants';
import CustomerCard from './components/CustomerCard';
import AddressCard from './components/AddressCard';
import OrderCard from './components/OrderCard';
import { showFbMessengerIcon } from '@/helpers/utils'
import RepositoryFactory from '../../repositories/RepositoryFactory';
const ContactRepository = RepositoryFactory.get('contact');
const ProductRepository = RepositoryFactory.get('product');
const ContactStatusRepository = RepositoryFactory.get('contact_status');
const AddressRepository = RepositoryFactory.get('address');
const OrderRepository = RepositoryFactory.get('order');

export default {
  components: { CustomerCard, AddressCard, OrderCard },
  data() {
    return {
      showConfirm: false,
      mobile: '',
      // showModalCall: false,
      appointmentId: '',
      courseRegisters: [],
      dataForm: {
        contactSourceIds: [],
        groupIds: [],
        customers: [
          {
            isDeleted: false,
            mobile: "",
            isMainCustomer: true,
            fullName: "",
            birthday: null,
            birthdayDescription: "",
            email: "",
            capability: "",
            position: "mother",
            positionTitle: "Mẹ",
            provinceId: '',
            districtId: '',
            wardId: '',
            detailAddress: '',
            description: "",
            phones: [],
            productDescription: ""
          },
          {
            isMainCustomer: false,
            position: 'child',
            courseRegister: []
          }
        ],
        productGroups: []
      },
      dataFormOld: '',
      mainCustomer: {},
      stepOrder: 1,
      firstStep: false,
      provinces: [],
      contactStatusList: [],
      shippingAddress: {
        wardName: '',
        provinceName: '',
        districtName: '',
        receiver: '',
        phone: '',
        email: '',
        provinceId: 0,
        wardId: 0,
        districtId: 0,
        detailAddress: ''
      },
      contentOrder: {
        paidAmount: 0,
        totalPrice: 0,
        products: [
          {
            productId: '',
            quantity: 1,
            price: 0,
            customerId: ''
          }
        ],
        description: '', //ghi chu don hang,
        paymentType: ''
      },
      loadContactDetailSuccess: false,
      showChat: false,
      dataUpdate: {},
      dataDialog: {},
      createDialog: false,
      contactId: 0
    };
  },
  computed: {
    childCustomers() {
      return (
        this.dataForm.customers.filter(
          customer =>
            customer.position === 'child' &&
            !_.get(customer, 'isDeleted', false)
        ) || [{}]
      );
    }
  },
  created() {
    // this.getContactDetail();
    this.getProvinces();
    this.getProductComboList();
    Bus.$on('order-back-step', () => {
      --this.stepOrder;
    });
    Bus.$on('order-step', () => {
      this.clickStep();
    });


  },
  beforeDestroy() {
    Bus.$off('order-back-step');
    Bus.$off('order-step');
  },
  methods: {
    clickCancel() {
      this.$router.push({
        path: '/dashboard'
      });
    },
    clickExecuteCall(dataContact, phone) {
      const data = {
        mobile: phone,
        showCreateAppointment: false,
        contactId: dataContact.id,
        mainId: dataContact.mainId,
        mainMobile: dataContact.mobile
      }
      Bus.$emit('voice-call-show-popup', data)
    },
    async clickCreateOrder() {
      this.closeModal()
      // Combine the same products and customers
      this.loading = this.$loading.show();
      // delete this.dataUpdate.listEndDate;
      const response = await OrderRepository.ctvCreate(this.dataUpdate);
      this.loading.hide();

      if (response.error) {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
      } else if (_.get(response, 'data.isExist', false)) {
        this.$swal({
          title: 'Thêm mới đơn hàng không thành công, Số điện thoại người nhận đã tồn tại!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Xem contact',
          closeOnConfirm: false,
          cancelButtonText: 'Huỷ Bỏ'
        }).then(result => {
          if (result.value) {
            return this.$router.push({
              name: 'ContactDetail',
              params: {
                id: _.get(response, 'data.contactId', null)
              }
            });
          }
        });
      } else {
        this.$swal({
          title: 'Tạo Đơn hàng thành công!',
          confirmButtonText: 'Đồng ý'
        }).then(() => {
          this.$router.push('/orders/order-list');
        });
      }
    },
    async getProvinces() {
      const respond = await AddressRepository.provinces({ limit: 100 });
      if (_.get(respond, 'success', false)) {
        this.provinces = _.get(respond, 'data', []).filter(
          data => data.id != 0
        );
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(respond, 'message', '')
        });
      }
    },
    async getContactDetail(dataContact) {
      if (dataContact) {
        const loader = this.$loading.show()
        this.loadContactDetailSuccess = true
        const contactSourceIds = _.get(dataContact, 'contactSources', []).map(
            contactSource => contactSource.id
        );
        showFbMessengerIcon(_.get(dataContact, 'contactSources', []).map(contactSource => contactSource.name))
        const groupIds = [];
        if (!Array.isArray(dataContact.customers)) {
          dataContact.customers = [];
        }
        const customers = dataContact.customers.map(customer => {
          const customerTemp = customer;
          customerTemp.customerId = customerTemp.id;
          customerTemp.phones = _.get(customer, 'phones', []).map(
              data => data.phoneNumber
          );
          customerTemp.courseRegister = [];
          customerTemp.combos.forEach(combo => {
            customerTemp.courseRegister.push(`combo-${combo.id}`);
            _.get(combo, 'products', []).forEach(product => {
              if (
                  _.get(product, 'group.id', null) &&
                  !groupIds.includes(product.group.id)
              )
                groupIds.push(product.group.id);
            });
          });
          customerTemp.products.forEach(product => {
            customerTemp.courseRegister.push(`product-${product.id}`);
            if (
                _.get(product, 'group.id', null) &&
                !groupIds.includes(product.group.id)
            )
              groupIds.push(product.group.id);
          });

          if (!_.get(customer, 'isMainCustomer', false)) {
            if (!Array.isArray(customerTemp.phones)) {
              customerTemp.phones = [];
            }
            customerTemp.mobile = _.get(customerTemp, 'phones[0]', '');
            customerTemp.phones.shift();
          }

          customerTemp.isNew = false;

          if (customer.isMainCustomer && customer.fullName === '') {
            customerTemp.fullName = customerTemp.mobile;
          }

          return customerTemp;
        });

        const mainCustomer = customers.find(
            customer => customer.isMainCustomer
        ) || {
          mobile: '',
          isMainCustomer: true,
          position: 'other',
          phones: [],
          courseRegister: []
        };

        const customersTemp = [
          mainCustomer,
          ...customers.filter(customer => !customer.isMainCustomer)
        ];

        let customerChildFirst = customersTemp.find(c => c.position === 'child');
        if (!customerChildFirst) {
          customersTemp.push( {
            customerId: new Date().getTime(),
            isMainCustomer: false,
            position: 'child',
            fullName: '',
            birthday: 0,
            courseRegister: [],
            isDataTemp: true,
          });
        }


        this.dataForm = {
          ...dataContact,
          contactSourceIds,
          groupIds,
          customers: customersTemp,
          productGroups: dataContact.groups.map(dataGroup => dataGroup.id),
        };
        this.dataFormOld = JSON.stringify(this.dataForm);
        loader.hide()
      }
    },
    async getProductComboList() {
      const respond = await ProductRepository.listAll({ limit: PAGE_LIMIT });
      if (_.get(respond, 'success', false)) {
        const products = _.get(respond, 'data.products', []).map(product => {
          const productTemp = product;
          productTemp.idTemp = `product-${product.id}`;
          return productTemp;
        });
        const combos = _.get(respond, 'data.combos', []).map(combo => {
          const comboTemp = combo;
          comboTemp.idTemp = `combo-${combo.id}`;
          comboTemp.price = _.get(comboTemp, 'products', []).reduce(
            (accumulator, product) => {
              return accumulator + _.get(product, 'price', 0);
            },
            0
          );
          return comboTemp;
        });
        this.courseRegisters = [
          { header: 'Sản Phẩm' },
          ...products,
          { divider: true },
          { header: 'Combo' },
          ...combos
        ];
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(respond, 'message', '')
        });
      }
    },
    checkDuplicateCustomer() {
      let checkObjectChild = {};
      let checkObjectNotChild = {};
      let statusChildError = false;
      this.dataForm.customers.forEach(dataCustomer => {
        if (!_.get(dataCustomer, 'isDeleted', false)) {
          const position = _.get(dataCustomer, 'position', 'other');
          if (position === 'child') {
            const keyChild = dataCustomer.birthday + '-' + _.trim(dataCustomer.fullName).toLocaleLowerCase();
            if (checkObjectChild[keyChild]) {
              statusChildError = true;
            }
            checkObjectChild[keyChild] = true;
          }
        }
      });
      if (statusChildError) {
        return 'Thông tin con bị trùng !!!';
      }
      return '';
    },
    async clickStep() {
      this.contentOrder.paidAmount = this.contentOrder.paidAmount << 0;
      if (this.stepOrder === 1) {
        setTimeout(window.scrollTo({
          top: 0,
          behavior: 'smooth'
        }), 3000)
        return this.updateContact();
      }
    },
    async updateContact() {
      let checkDuplicateCustomer = this.checkDuplicateCustomer();
      if (checkDuplicateCustomer) {
        return this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: checkDuplicateCustomer
        });
      }
      Object.assign(this.shippingAddress, {
        receiver: _.get(this.dataForm, 'customers[0].fullName', ''),
        phone: _.get(this.dataForm, 'customers[0].mobile', ''),
        email: _.get(this.dataForm, 'customers[0].email', ''),
        provinceId: _.get(this.dataForm, 'customers[0].provinceId') == 0 ? '' : _.get(this.dataForm, 'customers[0].provinceId'),
        districtId: _.get(this.dataForm, 'customers[0].districtId') == 0 ? '' : _.get(this.dataForm, 'customers[0].districtId'),
        wardId: _.get(this.dataForm, 'customers[0].wardId') == 0 ? '' : _.get(this.dataForm, 'customers[0].wardId'),
        detailAddress: _.get(this.dataForm, 'customers[0].detailAddress', '')
      });
      if (JSON.stringify(this.dataForm) === this.dataFormOld) {
        this.stepOrder += 1;
        return;
      }
      const dataUser = TokenService.getUser() || {};

      const dataUpdate = {
        productGroups: _.get(this.dataForm, 'productGroups', []),
        statusId: _.get(this.dataForm, 'statusId', null),
        userId: _.get(this.dataForm, 'userId', null) || +_.get(dataUser, 'id', 0),
        userMarketingName: _.trim(this.dataForm.userMarketingName),
        contactSourceIds: _.get(this.dataForm, 'contactSourceIds', []),
        description: _.trim(this.dataForm.description),
        customers: []
      };
      let checkErrorPhone = false;
      dataUpdate.customers = this.dataForm.customers.map(customer => {
        const customerTemp = { ...customer };
        const customerTempMobile = customerTemp.mobile;
        let customerId = _.get(customer, 'isNew', true)
          ? null
          : (customerId = _.get(customer, 'customerId', null));
        if (
          !_.get(customer, 'isMainCustomer', false) &&
          customerTemp.mobile &&
          customerTemp.mobile !== ''
        ) {
          if (!Array.isArray(customerTemp.phones)) {
            customerTemp.phones = [];
          }
          customerTemp.phones.unshift(customerTemp.mobile);
          customerTemp.mobile = '';
        }

        if (customerTemp.phoneSub) {
          if (customerTemp.phoneSub === customerTempMobile) {
            checkErrorPhone = true;
          }
          customerTemp.phones.push(customerTemp.phoneSub);
        }
        if(customerTemp.isMainCustomer && customerTempMobile && customerTemp.phones.includes(customerTempMobile)) {
          checkErrorPhone = true;
        }

        if (customerTemp.birthday < 1900) {
          customerTemp.birthday = null;
        }

        if (!customerTemp.positionTitle) {
          customerTemp.positionTitle = 'Khác';
        }

        if (
          _.get(customerTemp, 'isMainCustomer', false) &&
          !_.get(customerTemp, 'fullName', '')
        ) {
          customerTemp.fullName = _.get(customerTemp, 'mobile', '');
        }

        return {
          customerId,
          isDeleted: _.get(customerTemp, 'isDeleted', false),
          // isNew:  _.get(customer, 'isNew', true),
          mobile: _.get(customerTemp, 'mobile', ''),
          isMainCustomer: _.get(customerTemp, 'isMainCustomer', false),
          fullName: _.trim(customerTemp.fullName),
          birthday: _.get(customerTemp, 'birthday', null),
          birthdayDescription: _.trim(customer.birthdayDescription),
          email: _.get(customerTemp, 'email', ''),
          capability: _.trim(customerTemp.capability),
          position: _.get(customerTemp, 'position', 'other'),
          positionTitle: _.trim(customerTemp.positionTitle) || 'Khác',
          provinceId: _.get(customerTemp, 'provinceId', 0) || 0,
          districtId: _.get(customerTemp, 'districtId', 0) || 0,
          wardId: _.get(customerTemp, 'wardId', 0) || 0,
          detailAddress: _.trim(customerTemp.detailAddress),
          description: _.trim(customerTemp.description),
          phones: _.get(customerTemp, 'phones', []),
          productDescription: _.trim(customer.productDescription),
        };
      });
      if (checkErrorPhone) {
        return this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: 'Số điện thoại phụ không được giống số điện thoại chính',
        });
      }
      this.loading = this.$loading.show();
      const response = await ContactRepository.updateByOrder(
        dataUpdate
      );
      this.loading.hide();
      if (response.error) {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
      } else if (_.get(response, 'data.isExist', false)) {
        this.$swal({
          title: 'Cập nhật contact không thành công, Số điện thoại đã tồn tại!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Xem contact',
          closeOnConfirm: false,
          cancelButtonText: 'Huỷ Bỏ'
        }).then(result => {
          if (result.value) {
            return this.$router.push({
              name: 'ContactDetail',
              params: {
                id: _.get(response, 'data.contactId', null)
              }
            });
          }
        });
      } else {
        await this.getContactDetail(_.get(response, 'data', {}));
        console.log(response)
        this.stepOrder = 2;
        this.contactId = _.get(response, 'data.id', null)
      }
    },
    async beforeCreate() {
      let objectCheckProducts = {};
      const productsTemp = [];
      let indexProductsTemp = 0;
      _.get(this.contentOrder, 'products', []).forEach(product => {
        const idTemp = product.productId + '_' + product.customerId;
        const indexTemp = objectCheckProducts[idTemp];
        if (indexTemp == null) {
          objectCheckProducts[idTemp] = indexProductsTemp;
          indexProductsTemp++;
          productsTemp.push({ ...product });
        } else {
          productsTemp[indexTemp].quantity += +product.quantity;
        }
      });
      this.contentOrder.totalPrice = productsTemp.reduce((accumulator, product) => {return accumulator + _.get(product, 'price', 0) * _.get(product, 'quantity', 0)}, 0) || 0
      // End Combine the same products and customers
      this.contentOrder.products = productsTemp;
      let firstIndexParent = -1;
      let fullNameParent = '';
      const contentOrderUpdate = {
        description: _.trim(this.contentOrder.description),
        paymentType: this.contentOrder.paymentType,
        paidAmount: !this.contentOrder.paidAmount
          ? 0
          : Number(this.contentOrder.paidAmount),
        totalPrice: !this.contentOrder.totalPrice
          ? 0
          : Number(this.contentOrder.totalPrice)
      };
      contentOrderUpdate.products = this.contentOrder.products.map(product => {
        const productTemp = { ...product };
        const productArray = productTemp.productId.split('-');
        const customerChild = this.childCustomers.find(
          customer => customer.customerId == product.customerId
        );
        return {
          productId: +productArray[1],
          productType: productArray[0],
          quantity: +product.quantity,
          customerId: +product.customerId,
          price: product.price
        };
      });
      const shippingAddressUpdate = {
        receiver: _.trim(this.shippingAddress.receiver),
        phone: _.get(this.shippingAddress, 'phone', ''),
        email: _.get(this.shippingAddress, 'email', ''),
        provinceId: _.get(this.shippingAddress, 'provinceId', ''),
        districtId: _.get(this.shippingAddress, 'districtId', ''),
        wardId: _.get(this.shippingAddress, 'wardId', ''),
        detailAddress: _.trim(this.shippingAddress.detailAddress)
      };
      const dataUpdate = {
        contactId: this.contactId,
        paymentType: _.get(contentOrderUpdate, 'paymentType', 'BANK'),
        description: _.trim(contentOrderUpdate.description),
        paidAmount: +_.get(contentOrderUpdate, 'paidAmount', 0),
        totalPrice: +_.get(contentOrderUpdate, 'totalPrice', 0),
        products: _.get(contentOrderUpdate, 'products', []),
        shippingAddress: shippingAddressUpdate
      };
      this.dataUpdate = dataUpdate
      this.loading = this.$loading.show()
      // dataUpdate.contactId = this.contactId
      dataUpdate.listEndDate = {};
      let response = await OrderRepository.getEndDate(dataUpdate)
      if (response.success) {
        dataUpdate.products.forEach((item, key) => {
          if(typeof dataUpdate.listEndDate[item.customerId] == 'undefined') {
            dataUpdate.listEndDate[item.customerId] = {}
          }
          if(item.productId){
            dataUpdate.listEndDate[item.customerId][item.productId] = {end_date: response.data[item.customerId][item.productId]};
          }
        })
        this.dataDialog = {
          ...dataUpdate,
          // products: _.get(response, 'data', []),
          shippingAddress: {
            ...this.shippingAddress
          }
        }
        this.loading.hide()
        this.clickCreateOrder()
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        })
      }
    },
    closeModal() {
      this.createDialog = false
    },
    goBack() {
      this.stepOrder > 1 ? this.stepOrder = this.stepOrder-1 : this.$router.go(-1)
    }
  }
};
</script>
<style lang="scss" scoped>
  .active-step-3 {
    .sort-order {
      order: 2;
    }
  }

  span.span-icon-custom {
    position: absolute;
    top: 15px;
    left: 20px;
  }

 .header-mobile {
   width: 100%;
   text-align: center;
 }
</style>
