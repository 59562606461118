<template>
    <v-expansion-panels
      class="mb-4"
    >
        <v-expansion-panel style="border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;">
            <v-expansion-panel-header id="support-filter-header">Tìm kiếm yêu cầu</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-list-item three-line class="overflow-visible pa-0">
                    <v-list-item-content class="overflow-visible pa-0">
                        <validation-observer v-slot="{ handleSubmit }">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                    class="mobile-hidden"
                                >
                                    <common-range-picker
                                        :aLight="'left'"
                                        label="Ngày hỗ trợ"
                                        :defaultRange="defaultRange"
                                        @change-date-range="changeDateRange"
                                        clearable
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                    class="mobile-show"
                                >
                                    <v-menu
                                        ref="menuStart"
                                        v-model="menuStart"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="filterForm.startDateTime"
                                                label="Ngày hỗ trợ (bắt đầu)"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filterForm.startDateTime" @input="menuStart = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                    class="mobile-show"
                                >
                                    <v-menu
                                        ref="menuEnd"
                                        v-model="menuEnd"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="filterForm.endDateTime"
                                                label="Ngày hỗ trợ (kết thúc)"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filterForm.endDateTime" @input="menuEnd = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                >
                                    <validation-provider
                                        v-slot="{ errors }"
                                        class="mb-2"
                                        name="SĐT"
                                        :rules="{ regex: regex.isVNPhoneMobile }"
                                    >
                                        <v-text-field
                                            v-model="filterForm.mobile"
                                            :error-messages="errors"
                                            label="Số điện thoại"
                                            placeholder="Nhập số điện thoại..."
                                            clearable
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                            <div class="text-center mb-4">
                                <v-btn
                                        class="primary mx-0"
                                        @click="handleSubmit(searchList)"
                                >
                                    <v-icon>mdi-magnify</v-icon>Tìm kiếm
                                </v-btn>
                            </div>
                        </validation-observer>
                    </v-list-item-content>
                </v-list-item>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import moment from 'moment'
import { regex } from '@/helpers/constants'

export default {
    props: {
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            regex,
            filterForm: {
                mobile: '',
                startDateTime: moment().startOf('isoWeek').format('YYYY-MM-DD'),
                endDateTime: moment().endOf('isoWeek').format('YYYY-MM-DD')
            },
            defaultRange: {
                startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
                endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
            },
            menuStart: false,
            menuEnd: false
        }
    },
    methods: {
        changeDateRange (dateRange) {
            this.filterForm.startDateTime = dateRange.startDate
            this.filterForm.endDateTime = dateRange.endDate
        },
        searchList() {
            let element = document.getElementById('support-filter-header')
            if (element) {
                element.click()
            }
            if (this.isMobile) {
                let element = document.getElementById('support-filter-header')
                if (element) {
                    element.click()
                }
                Bus.$emit('search-request-support-list-mobile', this.filterForm)
            } else {
                Bus.$emit('search-request-support-list', this.filterForm)
            }
        }
    }
}
</script>

<style scoped>

</style>