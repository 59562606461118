<template>
  <div>
    <div class="text-sm-right mb-4" style="margin-top: 20px;">
      <v-btn
        v-if="checkAuthorization('product_link_customer_user_create')"
        class="primary mx-0"
        @click="addNewManagement"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Quản lý</th>
            <th>Khách hàng</th>
            <th>Sản phẩm</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in listItem">
            <tr :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(item, "user.username") }} - {{ _.get(item, "user.phone") }}</td>
              <td>{{ _.get(item, "customer.username") }} - {{ _.get(item, "customer.phone") }}</td>
              <td>{{ _.get(item, "product.name") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="
                      checkAuthorization('product_link_customer_user_delete')
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(item)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa quyền quản lý</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <management-form />
  </div>
</template>
<script>
import ManagementForm from "./ManagementForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user");

export default {
  components: { ManagementForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    listItem: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedManagement: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("product_link_customer_user_update") ||
        this.checkAuthorization("product_link_customer_user_delete")
      );
    },
  },
  methods: {
    addNewManagement() {
      this.selectedManagement = {};
      Bus.$emit("management-show-form", this.selectedManagement);
    },
    editManagement(department) {
      this.selectedManagement = department;
      Bus.$emit("management-show-form", this.selectedManagement);
    },
    async onClickDelete(item) {
      this.$swal({
        title: "Xóa quyền quản lý",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await UserRepository.deleteLinkCustomerToUserProducts(item.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("management-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
