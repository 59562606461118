<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('product_pathology_create')"
        class="primary mx-0"
        @click="addNewPathology"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên nhóm dược lý</th>
            <th>Đường dẫn</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(pathology, index) in pathologies">
            <tr :key="pathology.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(pathology, "name") }}</td>
              <td>{{ _.get(pathology, "slug") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('product_pathology_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editPathology(pathology)"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin nhóm dược lý</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('product_pathology_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(pathology)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa nhóm dược lý</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <pathology-form />
  </div>
</template>
<script>
import PathologyForm from "./PathologyForm.vue";
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const PathologyRepository = RepositoryFactory.get("pathology");
export default {
  components: { PathologyForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    pathologies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedPathology: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("product_pathology_update") ||
        this.checkAuthorization("product_pathology_delete")
      );
    },
  },
  methods: {
    addNewPathology() {
      this.selectedPathology = {};
      Bus.$emit("pathology-show-form", this.selectedPathology);
    },
    editPathology(pathology) {
      this.selectedPathology = pathology;
      Bus.$emit("pathology-show-form", this.selectedPathology);
    },
    async onClickDelete(pathology) {
      this.$swal({
        title: "Xóa nhóm dược lý",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await PathologyRepository.delete(pathology.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("pathology-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
