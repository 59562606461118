<template>
    <v-card class="my-0">
        <v-simple-table class="table-layout pa-5">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="th-id">#</th>
                        <th>Tên sản phẩm</th>
                        <th>Đơn vị</th>
                        <th>Số lượng đã bán</th>
                        <th>Tổng thanh toán</th>
                    </tr>
                </thead>
                <tbody v-if="_.isArray(productData) && productData.length">
                    <template v-for="(item, index) in productData">
                        <tr :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.productName }}</td>
                            <td>{{ item.unitName }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ formatPrice(item.totalSalePrice) }}</td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="100">
                            <common-no-result />
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
export default {
    props: {
        productData: {
            type: Array,
            default: () => {[]}
        }
    }
}
</script>

<style scoped>
/* .table-layout tbody tr {
    height: 40px !important;
}

.table-layout th {
    font-size: 18px !important;
}

.table-layout td {
    font-size: 16px !important;
} */
</style>