<template>
  <div>
    <v-row class="mx-0">
      <v-col cols="12" sm="6" class="text-left">
        <v-btn
          class="primary mb-0 mx-2"
          @click="addNewTemplateSms"
          height="30px"
          style="padding: 0px 25px !important"
        >
          <v-icon>mdi-plus</v-icon>Tạo mẫu tin nhắn
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="text-right">
        <v-menu
            :internal-activator="true"
            v-model="filterForm.openMenu"
            content-class="menu__customer--filter no-contain overflow-visible"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn style="padding: 0px 25px !important; margin-right: 10px;" class="" color="primary" dark v-on="on" height="30px">
              <span>Tìm kiếm</span>
              <v-icon style="margin-left: 10px; margin-right: -20px;">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <filter-form-sms
              :filterForm="filterForm"
              :users="users"
          />
        </v-menu>
      </v-col>
    </v-row>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Ngày tạo</th>
            <th>Mã</th>
            <th>Tiều đề</th>
            <th>Người tạo</th>
            <th>Loại tin nhắn</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody v-if="!loadingSms && totalItemSms > 0">
          <template v-for="(sms) in smsList">
            <tr
              :key="sms.id"
            >
              <td>{{ _.get(sms, 'createdAt') }}</td>
              <td>{{ _.get(sms, 'code') }}</td>
              <td>{{ _.get(sms, 'title') }}</td>
              <td>{{ _.get(sms, 'fullName') }}</td>
              <td>{{ _.get(sms, 'type') }}</td>
              <td>
                <div class="action">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mr-2"  @click="chooseSms(sms)" icon small color="primary" dark v-on="on"><v-icon>mdi-plus-circle</v-icon></v-btn>
                        </template>
                        <span>Chọn tin nhắn</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mr-2"  @click="editSms(sms)" icon small color="primary" dark v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
                        </template>
                        <span>Chỉnh sửa tin nhắn</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="onClickDelete(sms)" icon small color="default" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                        </template>
                        <span>Xóa tin nhắn</span>
                    </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-if="!loadingSms && totalItemSms === 0">
          <tr>
            <td colspan="1000" class="text-center">
              <common-no-result/>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row>
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
            v-if="totalItemSms > limitSms"
            v-model="currentPageSms"
            :length="getTotalItem(totalItemSms, limitSms)"
            class="justify-sm-end"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>

    <FormCreateUpdateSms :typeSmsList="typeSmsList"/>
  </div>
</template>
<script>
  import RepositoryFactory from '../../../../repositories/RepositoryFactory'
  import SmsRepository from "@/repositories/SmsRepository";
  import FormCreateUpdateSms from "./FormCreateUpdateSms";
  import FilterFormSms from "./FilterFormSms";
  import * as moment from "moment";

  const DepartmentRepository = RepositoryFactory.get('department')
  export default {
    components: { FilterFormSms, FormCreateUpdateSms },
    props: {
      smsList: {
        type: Array,
        default: () => []
      },
      users: {
        type: Array,
        default: () => []
      },
      filterForm: {
        type: Object,
        default: () => {}
      },
      totalItemSms: {
        type: Number,
        default: 0
      },
      limitSms: {
        type: Number,
        default: 10
      },
      loadingSms: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        modalAlert: false,
        errorMessage: '',
        typeSmsList: ["default", "MKT"],
        selectedSms: {},
        currentPageSms: 1
      }
    },
    watch: {
      currentPageSms: {
        deep: true,
        handler() {
          Bus.$emit("change-page-sms", this.currentPageSms)
        }
      }
    },
    methods: {
      async onClickDelete (sms) {
        let me = this
        this.$swal({
          title: 'Xóa Sms',
          text: 'Bạn có thực sự muốn xóa ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = me.$loading.show()
            const respond = await SmsRepository.delete(sms.id)
            loader.hide()
            if (_.get(respond, 'success', false)) {
              me.$swal({
                title: 'Đã xóa thành công !',
                confirmButtonText: 'Đồng ý',
              })
              Bus.$emit("update-sms-reload")
            } else {
              me.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(respond, 'message', ''),
              })
            }
          }
        })
      },
      addNewTemplateSms () {
        this.selectedSms = {};
        Bus.$emit('sms-show-form-create-update', this.selectedSms);
      },
      editSms (item) {
        this.selectedSms = item;
        Bus.$emit('sms-show-form-create-update', this.selectedSms);
      },
      chooseSms(item) {
        this.selectedSms = item;
        Bus.$emit('show-preview-sms', this.selectedSms);
      }
    }
  }
</script>

<style scoped>
  .menu__customer--filter {
    top: calc(30% - 45px) !important;
    left: 96% !important;
    transform: translateX(-96%) !important;
  }
</style>