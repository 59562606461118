<template>
  <div style="padding-top: 20px">
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('discount_delete')"
        v-show="selected.length ? true : false"
        color="default"
        dark
        class="mr-2"
        @click="deleteMultipleItem"
      >
        Xóa tất cả
      </v-btn>
      <v-btn
        v-if="checkAuthorization('discount_create')"
        class="primary mx-0"
        @click="addNewDiscount"
      >
        <v-icon>mdi-plus</v-icon>Thêm Mới
      </v-btn>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="discounts"
      :single-select="singleSelect"
      item-key="id"
      :show-select="checkAuthorization('discount_delete')"
      class="table-layout pa-5"
      hide-default-footer
    >
      <template v-slot:top />
      <template v-slot:no-data>
        <common-no-result />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom v-if="checkAuthorization('discount_delete')">
          <template v-slot:activator="{ on }">
            <v-icon @click="onClickDelete(item)" color="default" icon v-on="on"
              >mdi-delete</v-icon
            >
          </template>
          <span>Xóa</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <discount-form />
  </div>
</template>
<script>
import DiscountForm from "./DiscountForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { discountStatusOptions } from "../../../helpers/constants";
const DiscountRepository = RepositoryFactory.get("discount");

export default {
  components: { DiscountForm },
  props: {
    discounts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedDiscount: {},
      errorMessage: "",
      headers: [
        {
          text: "",
          value: "index",
          width: "20px",
          align: "center",
        },
        {
          text: "Tên khách hàng",
          value: "percentDiscountToCustomer.username",
          align: "center",
        },
        {
          text: "Số điện thoại",
          value: "percentDiscountToCustomer.phone",
          align: "center",
        },
        {
          text: "Mã sản phẩm",
          value: "percentDiscountToProduct.code",
          align: "center",
        },
        {
          text: "Tên sản phẩm",
          value: "percentDiscountToProduct.name",
          align: "center",
        },
        {
          text: "Giá gốc",
          value: "cost",
          align: "center",
        },
        {
          text: "Phần trăm chiết khấu (%)",
          value: "percentDiscount",
          align: "center",
        },
        { text: "Bắt đầu", value: "startTime", align: "center" },
        { text: "Kết thúc", value: "endTime", align: "center" },
      ],
      singleSelect: false,
      selected: [],
      discountStatusOptions,
      tabIndex: 0,
      filterForm: {},
    };
  },
  created() {
    if (
      this.checkAuthorization("discount_update") ||
      this.checkAuthorization("discount_create")
    ) {
      this.headers.push({
        text: "Thao tác",
        value: "action",
        sortable: false,
        align: "center",
      });
    }
  },
  methods: {
    addNewDiscount() {
      this.selectedDiscount = {};
      Bus.$emit("discount-show-form", this.selectedDiscount);
    },
    async onClickDelete(discount) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await DiscountRepository.delete(discount.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công!",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("discount-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
    async deleteMultipleItem() {
      this.$swal({
        title: "Xóa toàn bộ",
        text: "Bạn có thực sự muốn xoá các tham số này?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const response = await DiscountRepository.deleteManyItems(
            this.selected.map((s) => s.id)
          );
          loader.hide();
          if (response.success) {
            this.selected = [];
            this.$swal({
              title: "Xoá thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("discount-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(response, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
