<template>
    <v-expansion-panels
            class="mb-4"
    >
        <v-expansion-panel>
        <v-expansion-panel-header>Tìm kiếm lịch sử</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card class="ma-0 overflow-visible" outlined>
            <v-list-item class="pa-0" three-line>
                <v-list-item-content class="pa-0 overflow-visible">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                    v-model="filterForm.customerName"
                                    label="Tên khách hàng"
                                    placeholder="Nhập tên khách hàng"
                                    clearable
                                    @keyup.enter="searchAppointment"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <common-range-picker
                                v-model="filterForm.createdAt"
                                :clearable="true"
                                :aLight="'left'"
                                label="Ngày gửi"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                    v-model="filterForm.smsCode"
                                    label="Mã tin nhắn"
                                    placeholder="Nhập mã tin nhắn"
                                    clearable
                                    @keyup.enter="searchAppointment"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <common-select-user
                                    class="btn-style-all"
                                    v-model="filterForm.createdBy"
                                    :items="users"
                                    item-text="fullName"
                                    item-value="id"
                                    placeholder="Chọn người gửi"
                                    label="Người gửi"
                                    color="primary"
                                    multiple
                                    clearable
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete
                                    v-model="filterForm.channel"
                                    :items="channelOption"
                                    label="Chọn kênh gửi"
                                    placeholder="Kênh gửi"
                                    color="primary"
                                    clearable
                                    multiple
                            />
                        </v-col>
                    </v-row>
                <div class="text-center mb-4">
                    <v-btn class="primary mx-0" @click="searchSmsHistory()">
                    <v-icon>mdi-magnify</v-icon>Tìm kiếm
                    </v-btn>
                </div>
                </v-list-item-content>
            </v-list-item>
            </v-card>
        </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
export default {
    props: {
        filterForm: {
            type: Object,
            default: () => ({})
        },
        users: {
            type: Array,
            default: () => {[]}
        }
    },
    data() {
        return {
            channelOption: ["zalo", "facebook", "email", "sms"]
        };
    },
    created() {
    },
    methods: {
        searchSmsHistory() {
            Bus.$emit("search-sms-history", this.filterForm)
        }
    }
};
</script>

<style scoped>
</style>