<template>
    <div>
        <v-simple-table class="px-5 pb-5">
            <thead>
                <tr>
                    <th class="th_mobile" rowspan="2">Thời gian tạo</th>
                    <th class="th_mobile" rowspan="2">SĐT</th>
                    <th rowspan="2">CSKH<br>(Mô tả chi tiết)</th>
                    <th rowspan="2">Ticket gấp</th>
                    <th rowspan="2">Nguồn yêu cầu</th>
                    <th class="th_status" rowspan="2">Kết quả hỗ trợ</th>
                    <th rowspan="2">CSKH<br>(Mô tả hỗ trợ)</th>
                    <th rowspan="2">Người nhận</th>
                    <th colspan="2">Thời gian xử lý</th>
                </tr>
                <tr>
                    <th>Bắt đầu</th>
                    <th>Kết thúc</th>
                </tr>
            </thead>
            <tbody v-for="(item, index) in requestList" :key="index">
                <tr @click="item.expanded = !item.expanded">
                    <td class="text-center">{{ formatDate(item.createdAt) }}</td>
                    <td class="text-center">{{ item.mobile }}</td>
                    <td class="td_request">
                        <b>{{ item.request }}</b>:<br>
                        {{ item.descriptionRequest }}
                    </td>
                    <td class="text-center td_urgent">{{ item.urgentRequest }}</td>
                    <td class="text-center">{{ item.userFullName }}</td>
                    <td><div :style="getStatusStyle(item.status)">{{ getStatus(item.status) }}</div></td>
                    <td class="td_request">{{ item.descriptionSupport }}</td>
                    <td>{{ item.supporterName }}</td>
                    <td class="text-center">{{ formatDate(item.startCallTime) }}</td>
                    <td class="text-center">{{ formatDate(item.endCallTime) }}</td>
                </tr>
                <tr v-if="item.expanded">
                    <td colspan="100">
                        <v-row style="min-height: 50px;">
                            <v-col cols="12" md="6">
                                <b>Mô tả chi tiết</b><br>
                                {{ item.descriptionRequest }}
                            </v-col>
                            <v-col cols="12" md="6">
                                <b>Mô tả hỗ trợ</b><br>
                                {{ item.descriptionSupport }}
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </tbody>

            <tbody v-if="requestList.length <= 0">
                <tr>
                    <td colspan="100">
                        <common-no-result />
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <div class="pb-3">
            <v-pagination
                v-if="totalItem > limit"
                v-model="currentPage"
                class="justify-sm-end"
                :length="getTotalItem(totalItem)"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
            />
        </div>
    </div>
</template>

<script>
import TokenService from '@/helpers/token'
import moment from 'moment'
import RepositoryFactory from '../../../repositories/RepositoryFactory'
const SupportRepository = RepositoryFactory.get('support')

export default {
    data() {
        return {
            currentPage: 1,
            requestList: [],
            statusList: [],
            totalItem: 0,
            limit: 10,
            filterForm: {
                mobile: '',
                startDateTime: moment().startOf('isoWeek').format('YYYY-MM-DD'),
                endDateTime: moment().endOf('isoWeek').format('YYYY-MM-DD')
            },
        }
    },
    async created() {
        await this.getStatusList()
        this.getRequestList()
        Bus.$on('search-request-support-list', filterForm => {
            this.filterForm = filterForm
            this.getRequestList()
        })
        Bus.$on('fetch-table-while-change-tab', () => {
            this.getRequestList()
        })
    },
    watch: {
        currentPage: {
            handler() {
                this.getRequestList()
            }
        }
    },
    methods: {
        async getRequestList() {
            const loading = this.$loading.show()
            const userId = await TokenService.getId()
            const params = {
                userId,
                ...this.filterForm,
                startDateTime: this.filterForm.startDateTime == '' ? '' : this.filterForm.startDateTime + ' 00:00:00',
                endDateTime: this.filterForm.endDateTime == '' ? '' : this.filterForm.endDateTime + ' 23:59:59',
                limit: this.limit,
                page: this.currentPage,
                order: 'createdAt'
            }
            let res = await SupportRepository.list(params)
            loading.hide()
            if (res.success) {
                this.requestList = _.get(res, 'data', []).map(item => {
                    return {
                        ...item,
                        expanded: false
                    }
                }),
                this.totalItem = _.get(res, 'totalItem', [])
            } else {
                this.requestList = []
                this.totalItem = 0
            }
        },

        getStatusStyle(status) {
            let style = 'text-align: center; color: white; font-weight: 500; background-color: '

            switch (status) {
                case 'DONE':
                    style += '#67c23a';
                    break;
                case 'CANCELLED':
                    style += '#f56c6c';
                    break;
                case 'NHANYEUCAU':
                    style += '#409eff';
                    break;
                case 'DANGXULY':
                    style += '#e6a23c';
                    break;
                default: 
                    style += '#909399'
            }

            return style
        },

        formatDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY H:mm')
            } else {
                return ''
            }
        },

        async getStatusList() {
            const loading = this.$loading.show()
            let res = await SupportRepository.statusList()
            if (res.success) {
                this.statusList = _.get(res, 'data', [])
            }
            loading.hide()
        },

        getStatus(status) {
            let index = this.statusList.findIndex(item => item.code == status)

            if (index > -1) {
                return this.statusList[index].requests
            } else {
                return ''
            }
        }
    }
}
</script>

<style scoped>
/deep/table tr th {
    text-align: center !important;
    border: 1px solid #d2d2d2 !important;
    height: 30px;
    font-weight: 500 !important;
}

/deep/table tr td {
    border: 1px solid #d2d2d2 !important;
    height: 35px !important;
    padding: 0px 5px !important;
}

.th_mobile {
    width: 110px;
}

.th_status {
    width: 130px;
}

.td_request {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 250px;
}

.td_urgent {
    max-width: 150px !important;
    min-width: 100px !important;
}
</style>