<template>
  <v-container id="order-list" fluid tag="section">
    <base-material-card outlined icon="mdi-cash-multiple" class="overflow-visible order-list__wrapper">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">Danh sách tài khoản</div>
      </template>
      <order-account-table :orderAccounts="orderAccounts" />
      <common-no-result v-if="!loading && totalItem === 0" />
      <v-row>
        <v-col cols="12" sm="6" />
        <v-col cols="12" sm="6">
          <v-pagination
              v-if="totalItem > limit"
              v-model="currentPage"
              :length="getTotalItem(totalItem)"
              class="justify-sm-end"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
          />
        </v-col>
      </v-row>

    </base-material-card>
  </v-container>
</template>

<script>

import RepositoryFactory from '../../repositories/RepositoryFactory';
import OrderAccountTable from "@/pages/order/components/OrderAccountTable";
const OrderRepository = RepositoryFactory.get('order');

export default {
  components: {
    OrderAccountTable
  },
  data() {
    return {
      filterForm: {},
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      orderAccounts: [],
      loading: true,
    };
  },
  computed: {
  },
  created() {
    this.getOrderAccountList();
  },
  methods: {
    async getOrderAccountList() {
      const params = {
        limit: this.limit,
        page: this.currentPage - 1,
      };

      params['orderId'] =  this.$route.params.id;
      this.loading = true;
      const loader = this.$loading.show();
      const response = await OrderRepository.getListOrderAccount(params);
      console.log(response)
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.orderAccounts = _.get(response, 'data.accountList', []);
        this.totalItem = _.get(response, 'data.totalItem', 0);
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
        this.orderAccounts = [];
        this.totalItem = 0;
      }
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.getOrderAccountList();
      }
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getOrderAccountList();
        } else {
          this.currentPage = 1;
        }
      }
    }
  }
};
</script>
<style scoped>
#order-list {
  padding: 0px 15px;
}
.order-list__wrapper {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
}
</style>