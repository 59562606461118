<template>
  <v-expansion-panels
    class="mb-4"
  >
    <v-expansion-panel>
      <v-expansion-panel-header> Tìm kiếm combo sản phẩm</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card
          class="ma-0"
          outlined
        >
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0">
              <v-row>
                <v-col
                  cols="12"
                  :md="checkAuthorization('order-ctv-create_read') && !checkAuthorization('contact_read') ? '12' : '6'"
                >
                  <v-text-field
                    v-model="filterForm.keyword"
                    placeholder="Tên combo, mã combo..."
                    label="Tìm kiếm theo tên, mã"
                    clearable
                    @keyup.enter="searchCombo"
                  />
                </v-col>
                <v-col cols="12" md="6"
                       :class="checkAuthorization('order-ctv-create_read') && !checkAuthorization('contact_read') ? 'hiden-filter-siteId' : ''"
                >
                  <v-autocomplete
                      v-model="filterForm.siteId"
                      :items="sites"
                      item-text="name"
                      item-value="id"
                      label="Tìm kiếm theo công ty"
                      placeholder="Công ty"
                      color="primary"
                      clearable
                      multiple
                  />
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn
                  class="primary mx-0"
                  @click="searchCombo"
                >
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const SiteRepository = RepositoryFactory.get('site');
  export default {
    name: 'FilterForm',
    props: {
      filterForm: {
        default: () => ({}),
      },
    },
    data () {
      return {
        sites: []
      }
    },
    created () {
      if(this.checkAuthorization('order-ctv-create_read') && !this.checkAuthorization('contact_read')) {
        // this.getSites();
      } else {
        this.getSites();
      }
    },
    methods: {
      searchCombo () {
        Bus.$emit('combo-list-filter', this.filterForm)
      },
      async getSites() {
        let res = await SiteRepository.list()
        if (res.success) {
          this.sites = _.get(res, 'data', [])
          this.sites.unshift({
            id: 0,
            name: 'Tất cả'
          })
        } else {
          this.sites = []
        }
      }
    },
  }
</script>
<style scoped>
.hiden-filter-siteId {
  display: none;
}
</style>