<template>
    <div class="pa-0">
        <v-row class="s-btn-filter">
            <v-col cols="12" class="d-flex">
                <div :class="showCreate ? 'mb-2 btn-search-order' : 'mb-2 btn-search-order expand'">
                    <v-icon>mdi-magnify</v-icon>
                    <span>
                        <input
                            class="input-search"
                            type="text"
                            v-model="filterForm.contactMobile"
                            placeholder="Nhập SĐT"
                            @change="searchOrder()"
                        />
                    </span>
                </div>
                <v-btn light
                    small 
                    :class="showCreate ? 'mr-0 mb-0 create-btn' : 'mr-0 mb-0 create-btn inactive'"
                    @click="toCreateOrder()">
                    <v-icon size="25">mdi-plus</v-icon> Tạo đơn
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
// import CardFilterForm from './CardFilterForm';
export default {
    // components: { CardFilterForm },
    props: {
        filterForm: {
            default: () => ({})
        },
        filterContactStatusList: {
            type: Array,
            default: () => ([]),
        },
        showCreate: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selectContactStatusList() {
            const contactStatusList = [];
            const checkType = {};
            this.contactStatusList.forEach(contactStatus => {
                const typeContactStatus = contactStatus.type;
                if (!checkType[typeContactStatus]) {
                    checkType[typeContactStatus] = true;
                    if (contactStatusList.length > 0) {
                        contactStatusList.push({ divider: true });
                    }
                    contactStatusList.push({ header: typeContactStatus });
                }
                contactStatusList.push(contactStatus);
            });
            return contactStatusList;
        }
    },
    methods: {
        searchOrder() {
            Bus.$emit('search-orders-mobile', this.filterForm)
        },

        toCreateOrder() {
            this.$router.push('/orders/order-create')
        }
    }
};
</script>
<style scoped>
.s-btn-filter {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100%;
}

.btn-search-order {
    margin: 0px !important;
    width: 100%;
    background-color: transparent !important;
    color: #9C9C9C !important;
    padding: 10px 15px !important;
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.btn-search-order i {
    margin-right: 10px;
}

.btn-search-order.expand {
    border-radius: 10px !important;
}

.create-btn {
    height: 41px !important;
    border-radius: 0px 10px 10px 0px;
    padding: 10px 15px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: transparent !important;
    font-size: 14px;
    color: #e91e63 !important;
    text-transform: unset;
}

.create-btn.inactive {
    display: none !important;
}

.input-search, .input-search:hover, .input-search:focus {
    width: calc(100% - 40px);
    border: none;
    outline: unset;
}
</style>
