<template>
  <v-dialog v-model="showModal" max-width="1000" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validation" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template> Quản lý kinh doanh - sản phẩm - khách hàng </template>
          <v-spacer />
          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6" style="padding-top: 30px">
          <v-row>
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Chọn quản lý"
                :rules="{ required: true }"
              >
                <v-autocomplete
                  v-model="instance.userId"
                  :items="users"
                  item-text="username"
                  item-value="id"
                  label="Chọn quản lý *"
                  color="primary"
                  clearable
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Chọn sản phẩm"
                :rules="{ required: true }"
              >
                <v-autocomplete
                  v-model="instance.productIds"
                  :items="products"
                  item-text="name"
                  item-value="id"
                  label="Chọn sản phẩm *"
                  color="primary"
                  clearable
                  :error-messages="errors"
                  multiple
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Chọn khách hàng"
                :rules="{ required: true }"
              >
                <v-autocomplete
                  v-model="instance.customerIds"
                  :items="customers"
                  item-text="username"
                  item-value="id"
                  label="Chọn khách hàng *"
                  color="primary"
                  clearable
                  :error-messages="errors"
                  multiple
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn
            color="success"
            @click.prevent="handleSubmit(saveLinkUserToProductCustomer)"
          >
            Thêm mới
          </v-btn>

          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import {
  regex,
  productStatus,
  PAGE_LIMIT,
  userPositions,
} from "../../../helpers/constants";
const ProductRepository = RepositoryFactory.get("product");
const UserRepository = RepositoryFactory.get("user");
const CustomerRepository = RepositoryFactory.get("customer");

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      regex,
      showModal: false,
      isCreate: true,
      instance: {},
      products: [],
      customers: [],
      users: [],
      errorMessage: "",
    };
  },
  computed: {},
  created() {
    this.getProductList();
    this.getCustomerList();
    this.getListUser();

    Bus.$on("management-show-form", (instance) => {
      this.showModal = true;
      this.instance = _.cloneDeep(instance);
      if (instance.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validation.reset();
    },

    getProductOptions(product) {
      const productIds = this.products.map((p) => p.productId);
      return this.products.filter(
        (p) => !productIds.includes(p.id) || p.id === product.productId
      );
    },

    async getProductList() {
      this.loading = true;
      const loader = this.$loading.show();
      const response = await ProductRepository.list({
        limit: PAGE_LIMIT,
        statusArray: [productStatus.DRAFF, productStatus.ACTIVE],
      });
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.products = _.get(response, "data.list_product", []);
      }
    },

    async getListUser() {
      let res = await UserRepository.list({
        limit: PAGE_LIMIT,
        position: userPositions.MANAGEMENT,
      });
      if (res.success) {
        this.users = _.get(res, "data", []);
      }
    },

    async getCustomerList() {
      this.loading = true;
      const loader = this.$loading.show();
      let res = await CustomerRepository.listCustomerGroup();
      loader.hide();
      this.loading = false;
      if (res.success) {
        let customerList = [];
        _.get(res, "data.list_department", []).forEach(
          (groupCustomer, index) => {
            const { listCustomer = [] } = groupCustomer;
            customerList.push({
              header: groupCustomer.departmentName,
              name: groupCustomer.departmentName,
              departmentId: groupCustomer.departmentId,
            });
            listCustomer.forEach((cus) => {
              customerList.push(cus);
            });
          }
        );
        this.customers = customerList;
      }
    },

    async saveLinkUserToProductCustomer() {
      if (this.isCreate) {
        const loader = this.$loading.show();
        const response = await UserRepository.postLinkCustomerToUserProduct(this.instance);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("management-list-reload");
          });
        }
      }
    },
  },
};
</script>
