var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-sm-right mb-4"},[(_vm.checkAuthorization('product_delete'))?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:((_vm.selected.length ? true : false)),expression:"(selected.length ? true : false)"}],staticClass:"mr-2",attrs:{"color":"default","dark":""},on:{"click":_vm.deleteMultipleItem}},[_vm._v(" Xóa tất cả ")]):_vm._e(),(_vm.checkAuthorization('product_create'))?_c('v-btn',{staticClass:"primary mx-0",on:{"click":_vm.addNewCombo}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Thêm Mới ")],1):_vm._e()],1),_c('v-tabs',{staticClass:"mt-0 pl-3",staticStyle:{"background-color":"#FFFFFF","padding":"5px 0px 10px 5px"},attrs:{"color":"primary"},on:{"change":_vm.changeTab},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('v-tab',{key:0},[_vm._v("Tất Cả")]),_vm._l((_vm.productStatusOptions),function(tab,i){return _c('v-tab',{key:i+1},[_vm._v(_vm._s(tab.text))])})],2),_c('v-data-table',{staticClass:"table-layout pa-5",attrs:{"headers":_vm.headers,"items":_vm.combos,"single-select":_vm.singleSelect,"item-key":"id","show-select":_vm.checkAuthorization('product_delete'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"no-data",fn:function(){return [_c('common-no-result')]},proxy:true},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(item.products.length)?_c('div',[_vm._v(" "+_vm._s(_vm.formatPrice(item.products.map(function (s) { return s.discountPrice; }).reduce(function (sum, x) { return +sum + +x; })))+" ")]):_vm._e()]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.products.map(function (s) { return s.name; }).join(', '))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status === 'active')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"info","icon":""},on:{"click":function($event){return _vm.convertStatus(item.id, 'inactive')}}},on),[_vm._v("mdi-block-helper")])]}}],null,true)},[_c('span',[_vm._v("Chuyển sang trạng thái ngừng hoạt động")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"24","color":"success","icon":""},on:{"click":function($event){return _vm.convertStatus(item.id, 'active')}}},on),[_vm._v("mdi-checkbox-marked-circle")])]}}],null,true)},[_c('span',[_vm._v("Chuyển sang trạng thái hoạt động")])]),(_vm.checkAuthorization('product_update'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2 ml-2",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editCombo(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Cập nhật thông tin Combo")])]):_vm._e(),(_vm.checkAuthorization('product_delete'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"default","icon":""},on:{"click":function($event){return _vm.onClickDelete(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Xóa Combo")])]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('combo-form',{attrs:{"updateCombo":_vm.updateCombo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }