<template>
  <v-card
    class="mx-auto overflow-visible"
    width="800px"
    style="margin-top: 0px; margin-bottom: 0px"
    outlined
  >
    <v-list-item three-line>
      <validation-observer v-slot="{ handleSubmit }">
        <v-list-item-content class="overflow-visible">
          <v-row class="align-center">
            <v-col cols="10">
              <v-list-item-title class="headline mb-2"
                >Tìm kiếm nâng cao</v-list-item-title
              >
            </v-col>
            <v-col cols="2" class="text-right">
              <v-icon aria-label="Close" @click="closeMenu">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                placeholder="Nhập mã đơn hàng"
                v-model="filterForm.code"
                label="Mã đơn hàng"
                clearable
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="filterForm.productIds"
                :items="products"
                item-text="name"
                item-value="id"
                label="Sản phẩm"
                placeholder="Chọn sản phẩm"
                multiple
                clearable
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="filterForm.userIds"
                :items="users"
                color="primary"
                item-text="fullName"
                item-value="id"
                placeholder="Chọn người cập nhật cuối"
                label="Người cập nhật cuối"
                multiple
                clearable
                class="selectAll-group-product"
              />
              <template #prepend-item>
                <common-select-all
                  v-model="filterForm.userIds"
                  :item="filterForm.userIds"
                  :items="users.map((s) => s.id)"
                ></common-select-all>
              </template>
            </v-col>

            <v-col cols="12" md="4">
              <common-range-picker
                :clearable="true"
                :aLight="'center'"
                label="Ngày đặt hàng"
                v-model="filterForm.createdAt"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="filterForm.paymentType"
                :items="paymentTypes"
                label="Phương thức thanh toán"
                placeholder="Chọn phương thức thanh toán"
                clearable
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="filterForm.departmentIds"
                :items="departments"
                item-text="name"
                item-value="id"
                label="Nhóm khách hàng"
                placeholder="Chọn Nhóm khách hàng"
                @change="listCustomer"
                multiple
                class="selectAll-group-product"
                clearable
              >
                <template #prepend-item>
                  <common-select-all
                    v-model="filterForm.departmentIds"
                    :item="filterForm.departmentIds"
                    :items="departments.map((s) => s.id)"
                  ></common-select-all>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="filterForm.customerIds"
                :items="listCustomer"
                item-text="username"
                item-value="id"
                label="Khách hàng"
                placeholder="Khách hàng"
                color="primary"
                clearable
                multiple
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                placeholder="Nhập số điện thoại khách hàng"
                v-model="filterForm.phone"
                label="Số điện thoại khách hàng"
                clearable
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="filterForm.positions"
                :items="customerPositionOptions"
                item-text="text"
                item-value="value"
                label="Loại tài khoản"
                placeholder="Chọn loại tài khoản"
                multiple
                clearable
              />
            </v-col>
          </v-row>
          <div class="d-flex text-center mb-4 justify-center">
            <v-btn
              class="primary mx-0"
              @click.prevent="handleSubmit(searchOrder)"
            >
              <v-icon>mdi-dropdown</v-icon>Tìm kiếm
            </v-btn>
            <!-- <div class="d-flex ml-5 group-input">
              <v-btn
                v-if="checkAuthorization('contact_download')"
                class="primary mx-0 download"
                @click.prevent="handleSubmit(exportExcel)"
              >
                <v-icon>mdi-download</v-icon>Download
              </v-btn>
              <template v-if="checkAuthorization('contact_download')">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="dropdown"
                      color="#E91E63"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in itemsExport"
                      :key="index"
                      @click="handleSubmit(exportExcelDoiSoat)"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </div> -->
          </div>
        </v-list-item-content>
      </validation-observer>
    </v-list-item>
  </v-card>
</template>

<style>
.download {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.dropdown {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  min-width: 35px !important;
  width: 30px;
  position: absolute;
  right: -30px;
}
</style>

<script>
// import { downloadFile } from "../../../helpers/utils";
import { customerPositionOptions, PAGE_LIMIT } from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const DepartmentRepository = RepositoryFactory.get("department");
const CustomerRepository = RepositoryFactory.get("customer");
const UserRepository = RepositoryFactory.get("user");
const ProductRepository = RepositoryFactory.get("product");
const OrderRepository = RepositoryFactory.get("order");

export default {
  props: {
    filterForm: {
      default: () => ({}),
    },
    filterContactStatusList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      users: [],
      listCustomer: [],
      departments: [],
      products: [],
      paymentTypes: ["COD", "BANK"],
      customerPositionOptions: customerPositionOptions,
      // itemsExport: [{ title: "Đối soát" }],
    };
  },
  mounted() {
    this.getListCustomer();
  },
  async created() {
    const loader = this.$loading.show();
    await Promise.all([
      this.getUsers(),
      this.getProducts(),
      this.getDepartments(),
    ]);
    loader.hide();
  },

  methods: {
    closeMenu() {
      this.filterForm.openMenu = false;
    },
    async getProducts() {
      const respond = await ProductRepository.list({
        limit: PAGE_LIMIT,
      });
      if (_.get(respond, "success", false)) {
        this.products = _.get(respond, "data.list_product", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(respond, "message", ""),
        });
      }
    },

    async getListCustomer() {
      let res = await CustomerRepository.listCustomerGroup();
      if (res.success) {
        let customerList = [];
        _.get(res, "data.list_department", []).forEach(
          (groupCustomer, index) => {
            const { listCustomer = [] } = groupCustomer;
            customerList.push({
              header: groupCustomer.departmentName,
              name: groupCustomer.departmentName,
              departmentId: groupCustomer.departmentId,
            });
            listCustomer.forEach((cus) => {
              customerList.push(cus);
            });
          }
        );
        this.listCustomer = customerList;
      }
    },

    async getUsers() {
      let res = await UserRepository.list();
      if (res.success) {
        this.users = _.get(res, "data", []);
      }
    },

    async getDepartments() {
      const response = await DepartmentRepository.list({ limit: 100 });
      if (response.success) {
        this.departments = _.get(response, "data.list_department", []);
      }
    },

    searchOrder() {
      Bus.$emit("order-list-filter", this.filterForm);
    },

    // checkEmptyFilterForm() {
    //   let isExist = false;
    //   const isExistKeyword = !!this.filterForm.keyword;
    //   const isExistDepartmentId = !!this.filterForm.departmentIds;
    //   const isExistUserId = !!this.filterForm.userIds;
    //   const isExistProductId = !!this.filterForm.productIds;
    //   const isExistPosition = !!this.filterForm.positions;
    //   const isExistPaymentType = !!this.filterForm.paymentType;
    //   const isExistCreatedAt = _.isArray(this.filterForm.createdAt)
    //     ? !!this.filterForm.createdAt.length
    //     : false;

    //   isExist =
    //     isExistKeyword ||
    //     isExistDepartmentId ||
    //     isExistUserId ||
    //     isExistProductId ||
    //     isExistPosition ||
    //     isExistPaymentType ||
    //     isExistCreatedAt;

    //   return isExist;
    // },

    // async exportExcel() {
    //   if (!this.checkEmptyFilterForm()) {
    //     this.$swal({
    //       type: "error",
    //       title: "Lỗi !",
    //       text: "Vui lòng chọn ít nhất 1 điều kiện tìm kiếm",
    //     });
    //     return;
    //   }
    //   const loader = this.$loading.show();
    //   let user = await TokenService.getUser();
    //   OrderRepository.exportExcelContact({
    //     ...this.filterForm,
    //     username: user.username,
    //   });
    //   loader.hide();
    //   this.$swal({
    //     title: "Dữ liệu đang được xử lý, sẽ thông báo sau khi kết thúc!",
    //   });
    // },

    // async exportExcelDoiSoat() {
    //   if (!this.checkEmptyFilterForm()) {
    //     this.$swal({
    //       type: "error",
    //       title: "Lỗi !",
    //       text: "Vui lòng chọn ít nhất 1 điều kiện tìm kiếm",
    //     });
    //     return;
    //   }
    //   const loader = this.$loading.show();
    //   let user = await TokenService.getUser();
    //   OrderRepository.exportExcelContactDoiSoat({
    //     ...this.filterForm,
    //     username: user.username,
    //   });
    //   loader.hide();
    //   this.$swal({
    //     title: "Dữ liệu đang được xử lý, sẽ thông báo sau khi kết thúc!",
    //   });
    // },
  },
};
</script>
