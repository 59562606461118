<template>
  <v-expansion-panels
          class="mb-4"
  >
    <v-expansion-panel>
      <v-expansion-panel-header> Tìm kiếm kho</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card
                class="ma-0"
                outlined
        >
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0">
              <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                  <v-text-field
                          v-model="filterForm.keyword"
                          label="Tìm kiếm theo tên kho"
                          placeholder="Kho..."
                          clearable
                          @keyup.enter="searchStore"
                  />
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn
                        class="primary mx-0"
                        @click="searchStore"
                >
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>

            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
  export default {
    name: 'FilterForm',
    props: {
      filterForm: {
        default: () => ({}),
      },
    },
    data () {
      return {
      }
    },
    created () {
    },
    methods: {
      searchStore () {
        Bus.$emit('store-list-filter', this.filterForm)
      },
    },
  }
</script>
