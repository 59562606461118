<template>
  <div>
    <div class="text-sm-right mb-3">
      <v-btn v-if="checkAuthorization('admin-search_read')"
        v-show="(selected.length ? true : false)"
        color="default"
        dark
        class="mb-2 mr-2"
        @click="deleteMultipleItem"
      >
        Xóa tất cả
      </v-btn>
      <v-btn
        v-if="checkAuthorization('admin-search_read')"
        color="primary"
        dark
        class="mb-2"
        @click="addNewStore"
      >
        <v-icon>mdi-plus</v-icon>Thêm Mới
      </v-btn>
    </div>

<!--    <v-tabs-->
<!--      class="mt-0 pl-3"-->
<!--      @change="changeTab"-->
<!--      v-model="tabIndex"-->
<!--      color="primary"-->
<!--      style="background-color: #FFFFFF; padding: 5px 0px 10px 5px;"-->
<!--    >-->
<!--      <v-tab :key="0">Tất Cả</v-tab>-->
<!--      <v-tab v-for="(tab, i) in productStatusOptions" :key="i+1">{{ tab.text }}</v-tab>-->
<!--    </v-tabs>-->

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="storeList"
      :single-select="singleSelect"
      item-key="id"
      :show-select="checkAuthorization('admin-search_read')"
      class="table-layout pa-5"
      hide-default-footer
    >
      <template v-slot:top />
      <template v-slot:no-data>
        <common-no-result />
      </template>

<!--      <template v-slot:item.thumnail="{item}">-->
<!--        <div>-->
<!--          <v-img-->
<!--            :src="getThumbnailUrl(item.images)"-->
<!--            max-width="150"-->
<!--          />-->
<!--        </div>-->
<!--      </template>-->
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom v-if="checkAuthorization('admin-search_read')">
          <template v-slot:activator="{ on }">
            <v-btn class="mr-2 ml-2" @click="editStore(item)" icon small color="primary"  v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
          <span>Chỉnh sửa thông tin kho</span>
        </v-tooltip>
        <v-tooltip bottom v-if="checkAuthorization('admin-search_read')">
          <template v-slot:activator="{ on }">
            <v-btn @click="onClickDelete(item)" icon small color="default"  v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <span>Xóa kho</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <store-form/>
  </div>
</template>
<script>
  import StoreForm from './StoreForm.vue'
  import RepositoryFactory from '../../../repositories/RepositoryFactory'

  const StoreRepository = RepositoryFactory.get('store')
  export default {
    components: { StoreForm },
    props: {
      storeList: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        selectedStore: {},
        errorMessage: '',
        headers: [
          {
            text: '',
            value: 'index',
            width: "20px",
            align: 'center'
          },
          { text: 'Tên kho', value: 'name', align: 'center' },
          { text: 'Mã kho', value: 'code', align: 'center' },
          // { text: 'Kho', value: 'warehouse', align: 'center' },
        ],
        singleSelect: false,
        selected: [],
        productStatusOptions:[],
        tabIndex: 0,
        filterForm: {}
      }
    },
    created () {
      if ( this.checkAuthorization('admin-search_read')) {
        this.headers.push({ text: 'Thao tác', value: 'action', align: 'center', sortable: false });
      }
    },
    methods: {
      async convertStatus (id, status) {
        const statusStore = {
          status,
        }
        this.$swal({
          title: 'Chuyển đổi trạng thái',
          text: 'Bạn có thực sự chắc chắn ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const response = await StoreRepository.updateStatus(id, statusStore)
            loader.hide()
            if (response.success) {
              this.$swal({
                        title: 'Chuyển đổi thành công !',
                        confirmButtonText: 'Đồng ý',
                      },
              ).then(() => {
                Bus.$emit('store-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(respond, 'message', ''),
              })
            }
          }
        })
      },
      addNewStore () {
        this.selectedStore = {}
        Bus.$emit('store-show-form', this.selectedStore)
      },
      editStore (store) {
        this.selectedStore = store
        Bus.$emit('store-show-form', this.selectedStore)
      },
      async onClickDelete (store) {
        this.$swal({
          title: 'Xóa kho',
          text: 'Bạn có thực sự muốn xóa ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const respond = await StoreRepository.deleteOneItem(store.id)
            loader.hide()
            if (_.get(respond, 'success', false)) {
              this.$swal({
                title: 'Đã xóa thành công !',
                confirmButtonText: 'Đồng ý',
              },
              ).then(() => {
                Bus.$emit('store-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(respond, 'message', ''),
              })
            }
          }
        })
      },
      async deleteMultipleItem () {
        this.$swal({
          title: 'Xóa toàn bộ kho',
          text: 'Bạn có thực sự muốn xoá các kho này?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const response = await StoreRepository.deleteManyItems(this.selected.map(s => s.id))
            loader.hide()
            if (response.success) {
              this.selected = []
              this.$swal({
                        title: 'Xoá thành công !',
                        confirmButtonText: 'Đồng ý',
                      },
              ).then(() => {
                Bus.$emit('store-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(response, 'message', ''),
              })
            }
          }
        })
      },
      changeTab() {
        if (this.tabIndex === 0) {
          this.filterForm.status = '';
        } else if (this.tabIndex === 1) {
          this.filterForm.status = 'draft';
        } else if (this.tabIndex === 2) {
          this.filterForm.status = 'active';
        } else if (this.tabIndex === 3) {
          this.filterForm.status = 'inactive';
        }
        Bus.$emit('store-list-filter', this.filterForm)
      },
    },
  }
</script>
