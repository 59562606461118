<template>
  <v-expansion-panels class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm khách hàng</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0" outlined>
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0">
              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-model="filterForm.keyword"
                    placeholder="Tên ..."
                    label="Tìm kiếm theo tên"
                    clearable
                    @keyup.enter="searchUser"
                  />
                </v-col>

                <v-col cols="12" sm="3">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    :rules="{
                      max: 11,
                    }"
                    name="Số điện thoại"
                  >
                    <v-text-field
                      v-model.trim="filterForm.mobile"
                      label="Tìm kiếm theo số điện thoại"
                      :error-messages="errors"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-autocomplete
                    v-model="filterForm.position"
                    :items="customerPositionOptions"
                    item-text="text"
                    item-value="value"
                    label="Tìm kiếm theo loại tài khoản"
                    placeholder="Loại tài khoản"
                    color="primary"
                    clearable
                  />
                </v-col>

                <v-col cols="12" sm="3">
                  <v-autocomplete
                    v-model="filterForm.departmentId"
                    :items="departments"
                    item-text="name"
                    item-value="id"
                    label="Tìm kiếm theo nhóm khách hàng"
                    placeholder="Nhóm khách hàng"
                    color="primary"
                    clearable
                  />
                </v-col>
        
                <!-- <v-col
                        cols="12"
                        sm="3"
                >
                  <v-autocomplete
                          v-model="filterForm.status"
                          :items="contactSourceStatusOptions"
                          item-text="text"
                          item-value="value"
                          label="Tìm kiếm theo trạng thái"
                          placeholder="Trạng thái"
                          color="primary"
                          clearable
                  />
                </v-col> -->

                <!-- <v-col cols="12" sm="4">
                  <validation-provider v-slot="{ errors }"
                                       name="Call center">
                    <v-autocomplete
                        v-model="filterForm.callcenterId"
                        :items="callCenters"
                        :error-messages="errors"
                        item-text="name"
                        item-value="id"
                        label="Call center *"
                        color="primary"
                        eager
                    />
                  </validation-provider>
                </v-col> -->
              </v-row>
              <div class="text-center mb-4">
                <v-btn class="primary mx-0" @click="searchUser">
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import {
  customerPositionOptions,
  // contactSourceStatusOptions,
  regex,
} from "../../../helpers/constants";
// const DepartmentRepository = RepositoryFactory.get("department");
export default {
  name: "FilterForm",
  props: {
    filterForm: {
      default: () => ({}),
    },
    departments: {
      default: () => [],
    },
  },
  data() {
    return {
      user: {},
      customerPositionOptions,
      // contactSourceStatusOptions,
      // callCenters: [
      //   {
      //     id: "",
      //     name: "Không chọn",
      //   },
      //   {
      //     id: 1,
      //     name: "ePacific",
      //   },
      //   {
      //     id: 2,
      //     name: "CareSoft",
      //   },
      //   {
      //     id: 3,
      //     name: "Minh Phúc",
      //   },
      // ],
      regex,
    };
  },
  methods: {
    searchUser() {
      Bus.$emit("customer-list-filter", this.filterForm);
    },
  },
};
</script>
