<template>
  <v-container id="edit-product" fluid tag="section">
    <v-card class="mx-auto pa-5" outlined>
      <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(update)">
          <h3 style="padding-bottom: 35px">Thông tin sản phẩm</h3>
          <v-alert
            v-show="alertMessageText"
            v-model="alert"
            :type="alertMessageType"
            dismissible
          >
            {{ alertMessageText }}
          </v-alert>

          <v-row>
            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }">
                <v-select
                  v-model="details.status"
                  label="Trạng thái sản phẩm"
                  no-data-text="Không có dữ liệu"
                  :items="serviceProductStatus"
                  item-text="text"
                  item-value="value"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Tên sản phẩm"
                rules="required|max:512"
              >
                <v-text-field
                  v-model="details.name"
                  label="Tên *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Mã sản phẩm"
                :rules="{
                  required: true,
                  regex: regex.isUsername,
                }"
              >
                <v-text-field
                  v-model="details.code"
                  label="Mã *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Đường dẫn sản phẩm"
                :rules="{
                  required: true,
                }"
              >
                <v-text-field
                  v-model="details.slug"
                  label="Đường dẫn sản phẩm *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Số lượng sản phẩm đã bán"
                :rules="{
                  required: true,
                }"
              >
                <v-text-field
                  v-model="details.sold"
                  label="Số lượng sản phẩm đã bán *"
                  placeholder="2.6k đã bán"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Giá bán lẻ đề xuất"
                :rules="{ required: true, max: 9, regex: /^[0-9]*$/ }"
              >
                <v-currency-field
                  label="Giá bán lẻ đề xuất *"
                  :error-messages="errors"
                  v-model="details.retailPrice"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Giá gốc"
                :rules="{ required: true, max: 9, regex: /^[0-9]*$/ }"
              >
                <v-currency-field
                  label="Giá gốc *"
                  :error-messages="errors"
                  v-model="details.cost"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Giảm giá"
                :rules="{ required: false, max: 9, regex: /^[0-9]*$/ }"
              >
                <v-currency-field
                  label="Giảm giá"
                  :error-messages="errors"
                  v-model="details.discountPrice"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Đơn vị">
                <v-select
                  v-model="details.unitId"
                  label="Đơn vị *"
                  no-data-text="Không có dữ liệu"
                  :items="serviceUnits"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Nhóm sản phẩm">
                <v-select
                  v-model="details.productFolderId"
                  label="Nhóm sản phẩm *"
                  no-data-text="Không có dữ liệu"
                  :items="serviceGroups"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Nhóm dược lý">
                <v-select
                  v-model="details.pathologyId"
                  label="Nhóm dược lý"
                  no-data-text="Không có dữ liệu"
                  :items="servicePathologies"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Nhóm công dụng">
                <v-select
                  v-model="details.utilityId"
                  label="Nhóm công dụng"
                  no-data-text="Không có dữ liệu"
                  :items="serviceUtilities"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Nhóm hỗ trợ điều trị"
              >
                <v-select
                  v-model="details.treatmentId"
                  label="Nhóm hỗ trợ điều trị"
                  no-data-text="Không có dữ liệu"
                  :items="serviceTreatments"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Nhà sản xuất">
                <v-select
                  v-model="details.manufactureId"
                  label="Nhà sản xuất *"
                  no-data-text="Không có dữ liệu"
                  :items="serviceManufactures"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="details.tags"
                :items="serviceTags"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                label="Gắn thẻ sản phẩm *"
                color="primary"
                multiple
                no-data-text="Không có dữ liệu"
              >
                <template #prepend-item>
                  <common-select-all
                    v-model="details.tags"
                    :item="details.tags"
                    :items="serviceTags.map((s) => s.id)"
                  />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Thứ tự hiển thị"
                :rules="{ min: 1, regex: /^[0-9]*$/ }"
              >
                <v-text-field
                  v-model="details.displayOrder"
                  label="Thứ tự hiển thị"
                  :error-messages="errors"
                  color="primary"
                  type="number"
                  :placeholder="'Mặc định: 1'"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12">
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%; margin-bottom: 10px"
                >Mô tả ngắn *</span
              >
              <validation-provider v-slot="{ errors }" name="Mô tả ngắn">
                <template>
                  <vue-editor
                    v-model="details.description"
                    :editor-toolbar="customToolbar"
                  />
                </template>
                <span
                  v-if="errors[0]"
                  class="errors-span-required-content"
                  color="primary"
                  >* {{ errors[0] }}</span
                >
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12">
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%; margin-bottom: 10px"
                >Thông tin chi tiết của sản phẩm *</span
              >
              <validation-provider
                v-slot="{ errors }"
                name="Thông tin chi tiết của sản phẩm"
              >
                <template>
                  <vue-editor
                    v-model="details.information"
                    :editor-toolbar="customToolbar"
                  />
                </template>
                <span
                  v-if="errors[0]"
                  class="errors-span-required-content"
                  color="primary"
                  >* {{ errors[0] }}</span
                >
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12">
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%; margin-bottom: 10px"
                >Ghi chú của người nhập về sản phẩm</span
              >
              <validation-provider name="Ghi chú">
                <template>
                  <vue-editor
                    v-model="details.note"
                    :editor-toolbar="customToolbar"
                  />
                </template>
              </validation-provider>
            </v-col>
          </v-row>

          <div class="service-form-pictures">
            <v-file-input
              show-size
              v-model="details.files"
              accept="image/*"
              label="Hình ảnh"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Ảnh sản phẩm"
              @change="changeFiles"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <validation-provider
              v-slot="{ errors }"
              name="Ảnh sản phẩm"
              class="thumbnail-validation"
            >
              <div>
                <v-text-field
                  v-model="hasImage"
                  label="Ảnh sản phẩm *"
                  :error-messages="errors"
                  color="primary"
                  class="clear-both"
                />
              </div>
            </validation-provider>

            <div
              class="group--product"
              v-show="displayImages.length ? true : false"
            >
              <div
                v-for="displayImage in displayImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon small @click="deleteImage(displayImage)">
                    mdi-delete
                  </v-icon>
                </div>
                <v-img
                  :src="_.get(displayImage, 'filePath')"
                  gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                  style="height: 200px; width: 100%"
                />
                <v-radio-group v-model="thumbnailId">
                  <v-radio
                    :key="_.get(displayImage, 'id')"
                    label="Chọn làm ảnh chính"
                    :value="_.get(displayImage, 'id')"
                  />
                </v-radio-group>
              </div>

              <validation-provider
                v-slot="{ errors }"
                name="Chọn ảnh chính"
                class="thumbnail-validation"
              >
                <div>
                  <v-text-field
                    v-model="thumbnailId"
                    label="Ảnh thumb"
                    :error-messages="errors"
                    color="primary"
                    class="clear-both"
                  />
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="mt-2 text-center">
            <v-btn
              class="mb-2"
              color="primary"
              @click.prevent="handleSubmit(update)"
            >
              {{ isNew ? "Tạo" : "Cập nhật" }}
            </v-btn>
            <v-btn class="mb-2 mr-2" color="default" @click="goToList">
              Hủy bỏ
            </v-btn>
          </div>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>
<script>
import {
  regex,
  MAX_SIZE_IMAGE,
  productFolderStatus,
  productStatusOptions,
  productStatus,
  PAGE_LIMIT,
  FIRST_PAGE,
  STATUS_ACTIVE,
} from "../../../../helpers/constants";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import * as moment from "moment";
import { VueEditor } from "vue2-editor";

const ProductRepository = RepositoryFactory.get("product");
const ProductFolderRepository = RepositoryFactory.get("product_folder");
const UnitRepository = RepositoryFactory.get("unit");
const UtilityRepository = RepositoryFactory.get("utility");
const TreatmentRepository = RepositoryFactory.get("treatment");
const ManufactureRepository = RepositoryFactory.get("manufacture");
const ImageRepository = RepositoryFactory.get("image");
const SiteRepository = RepositoryFactory.get("site");
const TagRepository = RepositoryFactory.get("tag");
const PathologyRepository = RepositoryFactory.get("pathology");

export default {
  components: { VueEditor },
  $_veeValidate: {
    validator: "new",
  },
  name: "EditProductForm",
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      regex,
      isNew: false,
      details: {
        id: 0,
        code: "",
        name: "",
        price: "",
        // producer: "",
        displayOrder: "",
        productFolderId: "",
        status: 2,
        files: [],
        siteId: "",
        endDate: "",
        typeEndDate: "",
        endMonth: "",
      },
      serviceGroups: [],
      serviceUtilities: [],
      serviceTreatments: [],
      servicePathologies: [],
      serviceManufactures: [],
      serviceUnits: [],
      serviceTags: [],
      serviceProductStatus: productStatusOptions,
      files: null,
      displayImages: [],
      deleteImages: [],
      thumbnailId: null,
      alertMessageText: null,
      alertMessageType: "success",
      alert: true,
      loading: false,
      sites: [],
      datePicker: false,
      listTypeEndDate: [
        {
          type: "day",
          title: "Chọn ngày",
        },
        {
          type: "month",
          title: "Chọn tháng",
        },
      ],
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        ["image", "code-block"],
      ],
    };
  },
  computed: {
    pageTitle() {
      return this.isNew ? "Tạo sản phẩm" : "Sửa sản phẩm";
    },
    hasImage() {
      return this.displayImages.length ? "1" : "";
    },
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    afterDate() {
      return moment().subtract(1, "days").format("YYYY-MM-DD");
    },
  },
  mounted() {
    let id = 0;
    if ("id" in this.$route.params) {
      id = parseInt(this.$route.params.id, 10);
    }
    this.details.id = id;
    this.isNew = !id;
    this.getDetails();
    this.getSites();
  },
  methods: {
    deleteImage(item) {
      this.deleteImages.push(item.id);
      const self = this;
      const newDisplayImages = [];
      _.forEach(this.displayImages, function (item) {
        if (self.deleteImages.indexOf(item.id) === -1) {
          newDisplayImages.push(item);
        }
      });
      this.displayImages = newDisplayImages;
    },
    async changeFiles() {
      if (this.details.files.length < 1 || this.details.files.length > 10) {
        if (this.details.files.length > 10) {
          this.setAlertMessage(
            true,
            "error",
            "Không thể tải lớn hơn 10 file đồng thời!"
          );
        }
        return;
      }
      let checkError = false;
      this.details.files.forEach((dataFile) => {
        if (dataFile.size > MAX_SIZE_IMAGE) {
          checkError = true;
        }
      });
      if (checkError) {
        this.setAlertMessage(
          true,
          "error",
          "Không thể tải file ảnh lớn hơn 2 MB !"
        );
        return;
      }

      this.loading = this.$loading.show();
      const response = await ImageRepository.uploads(this.details.files);
      this.loading.hide();
      if (response.error) {
        this.setAlertMessage(true, "error", "Tải file thất bại!");
      } else {
        const tmpItems = _.get(response, "data");
        tmpItems.forEach((item) => {
          this.displayImages.push(item);
        });
      }
    },
    async update() {
      const images = [];
      const self = this;
      let imageId = null;
      _.forEach(this.displayImages, function (displayImage) {
        imageId = _.get(displayImage, "id", 0);
        images.push({
          id: imageId,
          isThumbnail: self.thumbnailId === imageId,
        });
      });
      const details = {
        code: _.get(this.details, "code", ""),
        name: _.get(this.details, "name", ""),
        slug: _.get(this.details, "slug", ""),
        description: _.get(this.details, "description", ""),
        note: _.get(this.details, "note", ""),
        displayOrder: +_.get(this.details, "displayOrder", 1),
        information: _.get(this.details, "information", ""),
        // producer: _.get(this.details, "producer", ""),
        status: _.get(this.details, "status", productStatus.DRAFF),
        unitId: _.get(this.details, "unitId", null),
        utilityId: _.get(this.details, "utilityId", null),
        treatmentId: _.get(this.details, "treatmentId", null),
        pathologyId: _.get(this.details, "pathologyId", null),
        manufactureId: _.get(this.details, "manufactureId", null),
        productCategoryId: _.get(this.details, "productCategoryId", 0),
        productFolderId: _.get(this.details, "productFolderId", 0),
        images: images,
        sold: _.get(this.details, "sold", ""),
        tags: _.get(this.details, "tags", []),
        cost: _.get(this.details, "cost", null),
        retailPrice: _.get(this.details, "retailPrice", null),
        discountPrice: _.get(this.details, "discountPrice", null),
      };

      this.loading = this.$loading.show();
      if (this.isNew) {
        const response = await ProductRepository.create(details);
        this.loading.hide();
        if (response.error) {
          this.setAlertMessage(true, "error", _.get(response, "message"));
        } else {
          this.$swal({
            title: "Thêm sản phẩm thành công!",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.goToList();
          });
        }
      } else {
        const response = await ProductRepository.update(
          _.get(this.details, "id", 0),
          details
        );
        this.loading.hide();
        if (response.error) {
          this.setAlertMessage(true, "error", _.get(response, "message"));
        } else {
          this.$swal({
            title: "Cập nhật thành công!",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.goToList();
          });
        }
      }
    },
    setAlertMessage(alert, type, text) {
      this.alert = alert;
      this.alertMessageText = text;
      this.alertMessageType = type;
    },
    async getDetails() {
      this.loading = this.$loading.show();
      const listProductFolder = await ProductFolderRepository.list({
        status: productFolderStatus.ACTIVE,
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      const listUnit = await UnitRepository.list({
        status: STATUS_ACTIVE,
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      const listTreatment = await TreatmentRepository.list({
        status: STATUS_ACTIVE,
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      const listUtility = await UtilityRepository.list({
        status: STATUS_ACTIVE,
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      const listManufacture = await ManufactureRepository.list({
        status: STATUS_ACTIVE,
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      const listTag = await TagRepository.list({
        status: STATUS_ACTIVE,
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      const listPathologies = await PathologyRepository.list({
        status: STATUS_ACTIVE,
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      this.loading.hide();

      if (listProductFolder.error) {
        this.serviceGroups = [];
      } else {
        this.serviceGroups = _.get(listProductFolder, "data.list_folder", []);
      }

      if (listUnit.error) {
        this.serviceUnits = [];
      } else {
        this.serviceUnits = _.get(listUnit, "data.list_unit", []);
      }

      if (listPathologies.error) {
        this.servicePathologies = [];
      } else {
        this.servicePathologies = _.get(
          listPathologies,
          "data.list_pathology",
          []
        );
      }

      if (listTreatment.error) {
        this.serviceTreatments = [];
      } else {
        this.serviceTreatments = _.get(
          listTreatment,
          "data.list_treatment",
          []
        );
      }

      if (listUnit.error) {
        this.serviceUnits = [];
      } else {
        this.serviceUnits = _.get(listUnit, "data.list_unit", []);
      }

      if (listUtility.error) {
        this.serviceUtilities = [];
      } else {
        this.serviceUtilities = _.get(listUtility, "data.list_utility", []);
      }

      if (listManufacture.error) {
        this.serviceManufactures = [];
      } else {
        this.serviceManufactures = _.get(
          listManufacture,
          "data.list_manufacture",
          []
        );
      }

      if (listTag.error) {
        this.serviceTags = [];
      } else {
        this.serviceTags = _.get(listTag, "data.list_tag", []);
      }

      if (!this.isNew) {
        this.loading = this.$loading.show();
        const response = await ProductRepository.getDetails(
          _.get(this.details, "id", 0)
        );
        this.loading.hide();
        if (response.error) {
          this.setAlertMessage(
            true,
            "error",
            "Lấy thông tin chi tiết thất bại!"
          );
        } else {
          this.details = _.get(response, "data");
          this.details.tags = this.details.tags.map((o) => o.id);
          const images = _.get(this.details, "images", []);
          if (images.length) {
            const self = this;
            let check;
            _.forEach(images, function (item) {
              check = _.get(item, "ImageItem.isThumbnail", false);
              if (check) {
                self.thumbnailId = _.get(item, "id", 0);
              }
              self.displayImages.push(item);
            });
          }
          // xu ly typeEndDate
          if (this.details.endDate) {
            this.details.typeEndDate = "day";
          } else if (this.details.endMonth) {
            this.details.typeEndDate = "month";
          } else {
            this.details.typeEndDate = "";
          }
          // console.log("this typeEndDate: ", this.details.typeEndDate);
        }
      }
    },
    goToList() {
      this.$router.push({
        name: "ProductList",
      });
    },
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
    async getSites() {
      let res = await SiteRepository.list();
      if (res.success) {
        this.sites = _.get(res, "data", []);
      } else {
        this.sites = [];
      }
    },
    showModalAction(item) {
      console.log("item", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.service-form-pictures {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa !important;
  line-height: 1.4;
}
.display-image-block {
  position: relative;
  max-width: 230px;
  width: 100%;
  margin-right: 20px;
  border: 1px solid #999;
  padding: 5px;
  display: inline-block;
}
.delete-image-block {
  position: absolute;
  right: 5px;
  bottom: 32px;
  z-index: 9;
}
.v-card--material {
  overflow: hidden;
}
.clear-both {
  clear: both;
}
.display-none {
  display: none;
}
.thumbnail-validation {
  .v-text-field__slot {
    display: none !important;
  }
}
.v-input--radio-group__input .v-input--selection-controls__ripple {
  left: -12px;
}
.errors-span-required-content {
  display: inline-block;
  height: 20px;
  width: 100%;
  text-align: left;
  color: #ff5252;
  font-size: 12px;
}
.title-span-content {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  margin-bottom: 8px;
}
</style>
