<template>
  <v-card
    class="mx-auto pa-3 overflow-visible mb-7"
    outlined
  >

    <v-list-item>
      <v-list-item-content class="overflow-visible">
        <v-list-item-title class="headline mb-2">
          Theo nguồn contact
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <th>KH phụ trách</th>
            <th>Đơn hàng</th>
            <th>Doanh số</th>
            <th>ARPU</th>
            <th>Tỷ lệ chốt (%)</th>
            <th>Doanh số thành công</th>
            <th colspan="2">Cuộc gọi</th>
          </tr>
        </thead>
        <tbody
          v-if="dataReport.tableData.length"
        >
          <tr
            v-for="(item, index) in dataReport.tableData"
            :key="item.id"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.countContacts }}</td>
            <td>{{ +item.initCountOrders }}</td>
            <td>{{ formatPrice(+item.initTurnOver) }}</td>
            <td>{{ formatPrice(+item.initArpu) }}</td>
            <td>{{ +item.initLatch + '%' }}</td>
            <td>{{ formatPrice(+item.successTurnOver) }}</td>
            <td :rowspan="mapRowspan[index]" v-if="(index==0 || index==breakPoint) && index == 0">Thời gian gọi</td>
            <td :rowspan="mapRowspan[index]" v-if="(index==0 || index==breakPoint) && index == 0">{{ dataReport.countCallTimes }}</td>
            <td :rowspan="mapRowspan[index]" v-if="(index==0 || index==breakPoint) && index == breakPoint">TB cuộc gọi</td>
            <td :rowspan="mapRowspan[index]" v-if="(index==0 || index==breakPoint) && index == breakPoint">{{ +dataReport.avgCall }}</td>
          </tr>
        </tbody>
        <common-no-result
          v-if="!dataReport.tableData.length"
        />
      </template>
    </v-simple-table>

  </v-card>
</template>

<script>

import { formatPrice } from '@/helpers/utils'

export default {
  props: {
    dataReport: {
      default: () => ({}),
    },
  },
  data() {
    return {
      mapRowspan: {},
      breakPoint: 0,
    }
  },
  created() {
    const length = this.dataReport.tableData.length;
    const breakPoint = Math.floor(length / 2);
    this.breakPoint = breakPoint;
    this.mapRowspan = {
      0: breakPoint,
      [breakPoint]: length - breakPoint,
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      border-left: thin solid rgba(0, 0, 0, 0.12);
      &:last-child {
        border-right: thin solid rgba(0, 0, 0, 0.12);
      }
    }
    tr:first-child {
      td {
        font-weight: 500;
      }
      td:nth-child(4), td:nth-child(3) {
        font-weight: normal;
      }
    }
    th {
      border-left: thin solid rgba(0, 0, 0, 0.12);
      border-top: thin solid rgba(0, 0, 0, 0.12);
      background-color: #9e9e9e;
      color: #ffffff!important;
      font-weight: 400!important;
      &:last-child {
        border-right: thin solid rgba(0, 0, 0, 0.12);
      }
    }
  }
</style>
