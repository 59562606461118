<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới nhà sản xuất </template>
          <template v-else> Cập nhật thông tin nhà sản xuất </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên nhà sản xuất"
              >
                <v-text-field
                  v-model="manufacture.name"
                  label="Tên nhà sản xuất *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đường dẫn"
                rules="required|max:255"
              >
                <v-text-field
                  v-model="manufacture.slug"
                  label="Đường dẫn *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%"
                >Nội dung *</span
              >
              <validation-provider
                v-slot="{ errors }"
                name="Nội dung"
                rules="required"
              >
                <template>
                  <vue-editor
                    v-model="manufacture.description"
                    :editor-toolbar="customToolbar"
                  />
                </template>
                <span
                  v-if="errors[0]"
                  class="errors-span-required-content"
                  color="primary"
                  >* {{ errors[0] }}</span
                >
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveManufacture)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
import { VueEditor } from "vue2-editor";
const ManufactureRepository = RepositoryFactory.get("manufacture");
export default {
  components: { VueEditor },
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      manufacture: {
        name: "",
        description: "",
        type: "",
      },
      isCreate: true,
      manufactures: [],
      errorMessage: "",
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ],
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], 
        ["image", "code-block"],
      ],
    };
  },
  created() {
    Bus.$on("manufacture-show-form", (manufacture) => {
      this.showModal = true;
      this.manufacture = _.cloneDeep(manufacture);
      if (manufacture.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveManufacture() {
      if (this.isCreate) {
        const manufacture = {
          name: _.trim(this.manufacture.name),
          slug: _.trim(this.manufacture.slug),
          description: _.trim(this.manufacture.description),
        };
        const loader = this.$loading.show();
        const response = await ManufactureRepository.create(manufacture);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Thêm nhà sản xuất thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("manufacture-list-reload");
          });
        }
        loader.hide();
      } else {
        const manufacture = {
          name: _.trim(this.manufacture.name),
          slug: _.trim(this.manufacture.slug),
          description: _.trim(this.manufacture.description),
        };
        const loader = this.$loading.show();
        const response = await ManufactureRepository.update(
          this.manufacture.id,
          manufacture
        );
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật nhà sản xuất thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("manufacture-list-reload");
          });
        }
        loader.hide();
      }
    },
  },
};
</script>
