<template>
  <div>
    <div>
      <v-card-title>
        <v-row class="mb-2">
          Preview <v-icon class="ml-2">mdi-message-processing</v-icon>
        </v-row>
        <v-row class="wrapper-preview-sms">
          <v-col cols="2">
            <v-icon size="40px">mdi-account-circle</v-icon>
          </v-col>

          <v-col cols="10">
            <div class="form-sms-container">
              <div class="upload-img-sms upload-image--user">
                <img
                  v-if="hasImage"
                  width="100%"
                  :src="_.get(sms, 'image.content', '')"
                />
                <img
                  v-else
                  src="https://kangsblackbeltacademy.com/wp-content/uploads/2017/04/default-image.jpg"
                  style="width: 100%"
                />
              </div>
              <div class="sms-title px-2">
                {{ _.get(sms, 'title', '') }}
              </div>
              <div class="sms-detail px-2">
                {{ _.get(sms, 'quote', '') }}<br/>
                {{ _.get(sms, 'content.content', '') }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
    </div>

    <validation-observer v-slot="{ handleSubmit }">
      <v-row style="width: 100%; padding: 0px 20px; font-size: 18px" class="my-2">
        Settings <v-icon class="ml-2">mdi-cog</v-icon>
      </v-row>
      <v-row style="width: 100%; padding: 0px 20px">
        <validation-provider 
            v-slot="{ errors }" 
            rules="required" 
            name="Kênh gửi"
            style="width: 100%">
          <v-autocomplete
              v-model="formSendSms.channel"
              :items="codeSmsList"
              label=""
              placeholder="Chọn kênh gửi"
              prepend-icon="mdi-telegram"
              :error-messages="errors"
              readonly
          >
            <template v-slot:label>
              <label class="mt-2">Kênh gửi *</label>
            </template>
          </v-autocomplete>
        </validation-provider>
      </v-row>

      <v-row style="width: 100%; padding: 0px 20px">
        <validation-provider 
            v-slot="{ errors }" 
            rules="required" 
            name="Tệp khách hàng"
            style="width: 100%">
          <v-autocomplete
              v-model="formSendSms.contactFilterId"
              :items="contactFilters"
              item-text="title"
              item-value="id"
              label=""
              clearable
              @change="getListExcept(formSendSms.contactFilterId)"
              placeholder="Chọn tệp khách hàng"
              prepend-icon="mdi-contacts"
              :error-messages="errors"
          >
            <template v-slot:label>
              <label>Gửi đến tệp khách hàng *</label>
            </template>
          </v-autocomplete>
        </validation-provider>
      </v-row>

      <v-row style="width: 100%; padding: 0px 20px">
        <v-autocomplete
            label=""
            :items="exceptContactFilters"
            item-text="title"
            item-value="id"
            v-model="formSendSms.exceptContactFilterId"
            placeholder="Chọn trường hợp"
            prepend-icon="mdi-contacts"
            clearable
            :disabled="!formSendSms.contactFilterId"
            @change="getCountSms()"
        >
          <template v-slot:label>
            <label>Trừ các trường hợp trong tệp sau:</label>
          </template>
        </v-autocomplete>
      </v-row>

      <v-row style="width: 100%; padding: 0px 20px">
        <v-text-field
            label=""
            v-model="formSendSms.numberSend"
            readonly
            prepend-icon="mdi-message"
        >
          <template v-slot:label>
            <label>Số lượng tin nhắn gửi đi</label>
          </template>
        </v-text-field>
      </v-row>
      <div class="text-center mb-4">
        <v-btn class="primary mx-0" @click.prevent="handleSubmit(sendSms)">
          <v-icon class="icon-send-sms">mdi-telegram</v-icon>Gửi tin nhắn
        </v-btn>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import * as moment from "moment";
import RepositoryFactory from '@/repositories/RepositoryFactory';

const SmsRepository = RepositoryFactory.get('sms');
const CustomerFileRepository = RepositoryFactory.get('customer_file');

export default {
    props: {
      customerList: {
        type: Array,
        default: () => []
      },
    },
    data() {
        return {
          codeSmsList: ["zalo", "facebook", "email", "sms"],
          sms: {},
          hasImage: false,
          formSendSms: {
            channel: '',
            contactFilterId: '',
            exceptContactFilterId: '',
            numberSend: 0,
          },
          customers: [],
          contactFilters: [],
          exceptContactFilters: []
        }
    },
    created() {
      this.getTypeSms();
      this.getListFilter()

      Bus.$on("show-preview-sms", sms => {
        this.sms = sms
        if (_.get(sms, 'image.content', '') && _.get(sms, 'image.detailStatus', 0)) {
          this.hasImage = true
        } else {
          this.hasImage = false
        }
        this.formSendSms.channel = this.sms.channel
      })
      
      Bus.$on("add-customer-file-to-send", id => {
        this.formSendSms.contactFilterId = id
        this.getListExcept(id)
      })
    },
  methods: {
      changeDateRange (dateRange) {
        this.formSendSms.dateRange = dateRange
      },
      
      getTypeSms() {

      },

      async sendSms() {
        if (_.every(this.sms, _.isEmpty)) {
          this.$swal({
            title: "Bạn chưa chọn tin nhắn."
          })
          return
        }

        const params = {
          title: this.sms.title,
          quote: this.sms.quote,
          type: this.sms.type,
          content: this.sms.content,
          image: this.sms.image,
          video: this.sms.video,
          button: this.sms.button,
          smsId: this.sms.id,
          smsCode: this.sms.code,
          ...this.formSendSms
        }

        SmsRepository.send(params)
        
        this.$swal({
          title: "Tin nhắn đang được gửi đi, sẽ thông báo ngay khi kết thúc."
        })
      },

      async getListContact(id) {
        const res = await CustomerFileRepository.listContact(id)
        if (res.success) {
          this.customers = _.get(res, 'data', [])
        } else {
          this.customers = []
        }
      },

      async getListFilter() {
        const res = await CustomerFileRepository.listFilter()
        if (res.success) {
          this.contactFilters = _.get(res, 'data', [])
        } else {
          this.contactFilters = []
        } 
      },

      getListExcept(id) {
        let index = this.contactFilters.findIndex(item => item.id == id)
        let contactFiltersTemp = _.cloneDeep(this.contactFilters) 
        if (index > -1) {
          this.formSendSms.numberSend = contactFiltersTemp[index].numberOfContact
          contactFiltersTemp.splice(index, 1)
        } else {
          this.formSendSms.numberSend = 0
        }

        this.exceptContactFilters = contactFiltersTemp
      },

      async getCountSms() {
        if (this.formSendSms.exceptContactFilterId) {
          const loader = this.$loading.show()
          const res = await SmsRepository.countSms({
            contactFilterId: this.formSendSms.contactFilterId,
            exceptContactFilterId: this.formSendSms.exceptContactFilterId
          })
          if (res.success) {
            this.formSendSms.numberSend = _.get(res, 'data', 0)
          } else {
            this.formSendSms.numberSend = 0
          }
          loader.hide()
        } else {
          let index = this.contactFilters.findIndex(item => item.id == this.formSendSms.contactFilterId)
          this.formSendSms.numberSend = this.contactFilters[index].numberOfContact
        }
      }
    }
}
</script>

<style lang="scss" scoped>
  .icon-form-send-msg {
    height: fit-content !important;
  }

  .icon-send-sms {
    margin-right: 4px;
  }

  .v-input {
    width: 100%;
  }

  .wrapper-preview-sms {
    border-radius: 5px;
    background-color: #DEDEDE;
  }

  .sms-title {
    font-size: 16px;
    font-weight: bold;
    word-break: break-word;
    text-align: justify;
  }

  .sms-detail {
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
    text-align: justify;
  }

  .form-sms-container {
    background-color: white;
    border-radius: 10px;
  }

  .upload-img-sms img {
    border-radius: 10px 10px 0px 0px;
  }
</style>