var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"edit-product","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"mx-auto pa-5",attrs:{"outlined":""}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.update)}}},[_c('h3',{staticStyle:{"padding-bottom":"35px"}},[_vm._v("Thông tin sản phẩm")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.alertMessageText),expression:"alertMessageText"}],attrs:{"type":_vm.alertMessageType,"dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessageText)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Trạng thái sản phẩm","no-data-text":"Không có dữ liệu","items":_vm.serviceProductStatus,"item-text":"text","item-value":"value","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)]}}],null,true),model:{value:(_vm.details.status),callback:function ($$v) {_vm.$set(_vm.details, "status", $$v)},expression:"details.status"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Tên sản phẩm","rules":"required|max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên *","error-messages":errors,"color":"primary"},model:{value:(_vm.details.name),callback:function ($$v) {_vm.$set(_vm.details, "name", $$v)},expression:"details.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Mã sản phẩm","rules":{
                required: true,
                regex: _vm.regex.isUsername,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mã *","error-messages":errors,"color":"primary"},model:{value:(_vm.details.code),callback:function ($$v) {_vm.$set(_vm.details, "code", $$v)},expression:"details.code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Đường dẫn sản phẩm","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Đường dẫn sản phẩm *","error-messages":errors,"color":"primary"},model:{value:(_vm.details.slug),callback:function ($$v) {_vm.$set(_vm.details, "slug", $$v)},expression:"details.slug"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Số lượng sản phẩm đã bán","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Số lượng sản phẩm đã bán *","placeholder":"2.6k đã bán","error-messages":errors,"color":"primary"},model:{value:(_vm.details.sold),callback:function ($$v) {_vm.$set(_vm.details, "sold", $$v)},expression:"details.sold"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Giá bán lẻ đề xuất","rules":{ required: true, max: 9, regex: /^[0-9]*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":"Giá bán lẻ đề xuất *","error-messages":errors},model:{value:(_vm.details.retailPrice),callback:function ($$v) {_vm.$set(_vm.details, "retailPrice", $$v)},expression:"details.retailPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Giá gốc","rules":{ required: true, max: 9, regex: /^[0-9]*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":"Giá gốc *","error-messages":errors},model:{value:(_vm.details.cost),callback:function ($$v) {_vm.$set(_vm.details, "cost", $$v)},expression:"details.cost"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Giảm giá","rules":{ required: false, max: 9, regex: /^[0-9]*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":"Giảm giá","error-messages":errors},model:{value:(_vm.details.discountPrice),callback:function ($$v) {_vm.$set(_vm.details, "discountPrice", $$v)},expression:"details.discountPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Đơn vị"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Đơn vị *","no-data-text":"Không có dữ liệu","items":_vm.serviceUnits,"item-text":"name","item-value":"id","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]}}],null,true),model:{value:(_vm.details.unitId),callback:function ($$v) {_vm.$set(_vm.details, "unitId", $$v)},expression:"details.unitId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nhóm sản phẩm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Nhóm sản phẩm *","no-data-text":"Không có dữ liệu","items":_vm.serviceGroups,"item-text":"name","item-value":"id","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]}}],null,true),model:{value:(_vm.details.productFolderId),callback:function ($$v) {_vm.$set(_vm.details, "productFolderId", $$v)},expression:"details.productFolderId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nhóm dược lý"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Nhóm dược lý","no-data-text":"Không có dữ liệu","items":_vm.servicePathologies,"item-text":"name","item-value":"id","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]}}],null,true),model:{value:(_vm.details.pathologyId),callback:function ($$v) {_vm.$set(_vm.details, "pathologyId", $$v)},expression:"details.pathologyId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nhóm công dụng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Nhóm công dụng","no-data-text":"Không có dữ liệu","items":_vm.serviceUtilities,"item-text":"name","item-value":"id","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]}}],null,true),model:{value:(_vm.details.utilityId),callback:function ($$v) {_vm.$set(_vm.details, "utilityId", $$v)},expression:"details.utilityId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nhóm hỗ trợ điều trị"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Nhóm hỗ trợ điều trị","no-data-text":"Không có dữ liệu","items":_vm.serviceTreatments,"item-text":"name","item-value":"id","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]}}],null,true),model:{value:(_vm.details.treatmentId),callback:function ($$v) {_vm.$set(_vm.details, "treatmentId", $$v)},expression:"details.treatmentId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nhà sản xuất"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Nhà sản xuất *","no-data-text":"Không có dữ liệu","items":_vm.serviceManufactures,"item-text":"name","item-value":"id","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]}}],null,true),model:{value:(_vm.details.manufactureId),callback:function ($$v) {_vm.$set(_vm.details, "manufactureId", $$v)},expression:"details.manufactureId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.serviceTags,"error-messages":_vm.errors,"item-text":"name","item-value":"id","label":"Gắn thẻ sản phẩm *","color":"primary","multiple":"","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('common-select-all',{attrs:{"item":_vm.details.tags,"items":_vm.serviceTags.map(function (s) { return s.id; })},model:{value:(_vm.details.tags),callback:function ($$v) {_vm.$set(_vm.details, "tags", $$v)},expression:"details.tags"}})]},proxy:true}],null,true),model:{value:(_vm.details.tags),callback:function ($$v) {_vm.$set(_vm.details, "tags", $$v)},expression:"details.tags"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Thứ tự hiển thị","rules":{ min: 1, regex: /^[0-9]*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Thứ tự hiển thị","error-messages":errors,"color":"primary","type":"number","placeholder":'Mặc định: 1'},model:{value:(_vm.details.displayOrder),callback:function ($$v) {_vm.$set(_vm.details, "displayOrder", $$v)},expression:"details.displayOrder"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"title-span-content text-left",staticStyle:{"display":"inline-block","width":"100%","margin-bottom":"10px"}},[_vm._v("Mô tả ngắn *")]),_c('validation-provider',{attrs:{"name":"Mô tả ngắn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [[_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.details.description),callback:function ($$v) {_vm.$set(_vm.details, "description", $$v)},expression:"details.description"}})],(errors[0])?_c('span',{staticClass:"errors-span-required-content",attrs:{"color":"primary"}},[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"title-span-content text-left",staticStyle:{"display":"inline-block","width":"100%","margin-bottom":"10px"}},[_vm._v("Thông tin chi tiết của sản phẩm *")]),_c('validation-provider',{attrs:{"name":"Thông tin chi tiết của sản phẩm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [[_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.details.information),callback:function ($$v) {_vm.$set(_vm.details, "information", $$v)},expression:"details.information"}})],(errors[0])?_c('span',{staticClass:"errors-span-required-content",attrs:{"color":"primary"}},[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"title-span-content text-left",staticStyle:{"display":"inline-block","width":"100%","margin-bottom":"10px"}},[_vm._v("Ghi chú của người nhập về sản phẩm")]),_c('validation-provider',{attrs:{"name":"Ghi chú"}},[[_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.details.note),callback:function ($$v) {_vm.$set(_vm.details, "note", $$v)},expression:"details.note"}})]],2)],1)],1),_c('div',{staticClass:"service-form-pictures"},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Hình ảnh","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Ảnh sản phẩm"},on:{"change":_vm.changeFiles},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.details.files),callback:function ($$v) {_vm.$set(_vm.details, "files", $$v)},expression:"details.files"}}),_c('validation-provider',{staticClass:"thumbnail-validation",attrs:{"name":"Ảnh sản phẩm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',[_c('v-text-field',{staticClass:"clear-both",attrs:{"label":"Ảnh sản phẩm *","error-messages":errors,"color":"primary"},model:{value:(_vm.hasImage),callback:function ($$v) {_vm.hasImage=$$v},expression:"hasImage"}})],1)]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayImages.length ? true : false),expression:"displayImages.length ? true : false"}],staticClass:"group--product"},[_vm._l((_vm.displayImages),function(displayImage){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteImage(displayImage)}}},[_vm._v(" mdi-delete ")])],1),_c('v-img',{staticStyle:{"height":"200px","width":"100%"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"gradient":"to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"}}),_c('v-radio-group',{model:{value:(_vm.thumbnailId),callback:function ($$v) {_vm.thumbnailId=$$v},expression:"thumbnailId"}},[_c('v-radio',{key:_vm._.get(displayImage, 'id'),attrs:{"label":"Chọn làm ảnh chính","value":_vm._.get(displayImage, 'id')}})],1)],1)}),_c('validation-provider',{staticClass:"thumbnail-validation",attrs:{"name":"Chọn ảnh chính"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',[_c('v-text-field',{staticClass:"clear-both",attrs:{"label":"Ảnh thumb","error-messages":errors,"color":"primary"},model:{value:(_vm.thumbnailId),callback:function ($$v) {_vm.thumbnailId=$$v},expression:"thumbnailId"}})],1)]}}],null,true)})],2)],1),_c('div',{staticClass:"mt-2 text-center"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.update)}}},[_vm._v(" "+_vm._s(_vm.isNew ? "Tạo" : "Cập nhật")+" ")]),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"default"},on:{"click":_vm.goToList}},[_vm._v(" Hủy bỏ ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }