<template>
  <v-container id="account-report" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <br />
    <div class="text-sm-right mb-2">
      <!-- <v-btn
                    v-if="checkAuthorization('account-report_download')"
                    class="primary mx-0"
                    @click="exportExcel"
            >
                <v-icon>mdi-download</v-icon>Download
            </v-btn> -->
    </div>
    <account-report-table
      :accountData="accountData"
      :totalRegularCustomer="totalRegularCustomer"
      :totalCompany="totalCompany"
      :totalPharmacy="totalPharmacy"
      :totalClinic="totalClinic"
    />
  </v-container>
</template>

<script>
import FilterForm from "./components/FilterForm.vue";
import AccountReportTable from "./components/AccountReportTable";
import moment from "moment";
import RepositoryFactory from "@/repositories/RepositoryFactory";
// import TokenService from "../../../helpers/token"

const ReportRepository = RepositoryFactory.get("report");

export default {
  components: { AccountReportTable, FilterForm },
  data() {
    return {
      filterForm: {
        dateRange: [],
      },
      accountData: [],
      totalRegularCustomer: 0,
      totalCompany: 0,
      totalPharmacy: 0,
      totalClinic: 0,
    };
  },
  created() {
    this.getAccountReport();
    Bus.$on("account-report-search-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getAccountReport();
    });
  },
  methods: {
    async getAccountReport() {
      const loading = this.$loading.show();
      const res = await ReportRepository.accountReport({ ...this.filterForm });
      if (res.success) {
        this.accountData = _.get(res, "data.account_statistics", []);
        this.totalRegularCustomer = _.get(res, "data.total_regular_customer", 0);
        this.totalCompany = _.get(res, "data.total_company", 0);
        this.totalPharmacy = _.get(res, "data.total_pharmacy", 0);
        this.totalClinic = _.get(res, "data.total_clinic", 0);
      } else {
        this.accountData = [];
      }
      loading.hide();
    },

    // async exportExcel() {
    //     const loader = this.$loading.show();
    //     let user = await TokenService.getUser()
    //     ReportRepository.exportExcelAccountReport({username: user.username})
    //     loader.hide();
    //     this.$swal({
    //         title: 'Dữ liệu đang được xử lý, sẽ thông báo sau khi kết thúc!'
    //     })
    // }
  },
};
</script>
