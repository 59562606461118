<template>
    <v-card class="mx-3" min-height="80%">
        <v-tabs color="primary" grow v-model="tab" @change="refreshTable()">
            <v-tab v-for="(item, i) in tabs" :key="i">
                {{ item }}
            </v-tab>

            <v-tab-item>
                <support-form />
            </v-tab-item>

            <v-tab-item>
                <filter-form />
                <support-table />
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import SupportForm from './components/SupportForm'
import SupportTable from './components/SupportTable'
import FilterForm from './components/FilterForm'

export default {
    components: {
        SupportForm,
        SupportTable,
        FilterForm
    },
    data() {
        return {
            tabs: ["Gửi hỗ trợ", "Danh sách"],
            tab: ''
        }
    },
    methods: {
        refreshTable() {
            if (this.tab == 1) {
                Bus.$emit('fetch-table-while-change-tab')
            }
        }
    }
}
</script>

<style scoped>

</style>