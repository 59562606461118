<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer 
        v-slot="{ handleSubmit }"
        ref="validator"
      >
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate">
            Thêm mới nhóm công dụng
          </template>
          <template v-else>
            Cập nhật thông tin nhóm công dụng
          </template>

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="hideModal"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên nhóm công dụng"
              >
                <v-text-field
                  v-model="utility.name"
                  label="Tên nhóm công dụng *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đường dẫn"
                rules="required|max:255"
              >
                <v-text-field
                  v-model="utility.slug"
                  label="Đường dẫn *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn
            color="success"
            @click.prevent="handleSubmit(saveUtility)"
          >
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn
            color="primary"
            @click="hideModal"
          >
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import RepositoryFactory from '../../../../repositories/RepositoryFactory'
  const UtilityRepository = RepositoryFactory.get('utility')
  export default {
    $_veeValidate: {
      validator: 'new',
    },
    props: {
      
    },
    data () {
      return {
        showModal: false,
        utility: {
          name: '',
          description: '',
          type: '',
        },
        isCreate: true,
        utilities: [],
        errorMessage: '',
      }
    },
    created () {
      Bus.$on('utility-show-form', (utility) => {
        this.showModal = true
        this.utility = _.cloneDeep(utility)
        if (utility.id) this.isCreate = false
        else this.isCreate = true
      })
    },
    methods: {
      hideModal: function () {
        this.showModal = false
        this.isCreate = true
        this.errorMessage = ''
        this.$refs.validator.reset()
      },
      async saveUtility () {
        if (this.isCreate) {
          const utility = {
            name: _.trim(this.utility.name),
            slug: _.trim(this.utility.slug),
          }
          const loader = this.$loading.show()
          const response = await UtilityRepository.create(utility)
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.showModal = false
            this.errorMessage = ''
            this.$swal({
                      title: 'Thêm thành công',
                      confirmButtonText: 'Đồng ý',
                    },
            ).then(() => {
              Bus.$emit('utility-list-reload')
            })
          }
          loader.hide()
        } else {
          const utility = {
            name: _.trim(this.utility.name),
            slug: _.trim(this.utility.slug),
          }
          const loader = this.$loading.show()
          const response = await UtilityRepository.update(this.utility.id, utility)
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.showModal = false
            this.errorMessage = ''
            this.$swal({
                      title: 'Cập nhật thành công',
                      confirmButtonText: 'Đồng ý',
                    },
            ).then(() => {
              Bus.$emit('utility-list-reload')
            })
          }
          loader.hide()
        }
      },
    },
  }
</script>
