<template>
  <div>
    <v-dialog v-model="showModal" max-width="1000" persistent v-if="showModal">
      <v-card class="text-center">
        <template v-if="errorMessage">
          <v-alert type="error">
            {{ errorMessage }}
          </v-alert>
        </template>
        <validation-observer v-slot="{ handleSubmit }" slim>
          <v-card-title class="text-center font-weight-bold">
            <template v-if="isCreate"> Tạo thông báo nội bộ </template>
            <template v-else> Thông tin thông báo </template>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Thời gian ngày bắt đầu"
                  rules="required"
                >
                  <v-menu
                    ref="menu"
                    v-model="start.menuStartTime"
                    :close-on-content-click="false"
                    :nudge-right="30"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="start.startTime"
                        label="Bắt đầu từ"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="start.menuStartTime"
                      v-model="start.startTime"
                      full-width
                      @click:minute="$refs.menu.save(start.startTime)"
                      :error-messages="errors"
                    ></v-time-picker>
                  </v-menu>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Ngày bắt đầu"
                  rules="required"
                >
                  <div class="style-datePicker">
                    <label class="style-datePicker_title">Ngày bắt đầu</label>
                    <date-picker
                      format="YYYY-MM-DD"
                      v-model="start.startDate"
                      type="date"
                      placeholder="Chọn ngày"
                      :error-messages="errors"
                    ></date-picker>
                  </div>
                </validation-provider>
              </v-col>

              <v-col cols="12" sm="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Thời gian ngày kết thúc"
                  rules="required"
                >
                  <v-menu
                    ref="menu"
                    v-model="end.menuEndTime"
                    :close-on-content-click="false"
                    :nudge-right="30"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="end.endTime"
                        label="Kết thúc vào"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="end.menuEndTime"
                      v-model="end.endTime"
                      full-width
                      @click:minute="$refs.menu.save(end.endTime)"
                      :error-messages="errors"
                    ></v-time-picker>
                  </v-menu>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Ngày kết thúc"
                  rules="required"
                >
                  <div class="style-datePicker">
                    <label class="style-datePicker_title">Ngày kết thúc</label>
                    <date-picker
                      format="YYYY-MM-DD"
                      v-model="end.endDate"
                      type="date"
                      placeholder="Chọn ngày"
                      :error-messages="errors"
                    ></date-picker>
                  </div>
                </validation-provider>
              </v-col>

              <v-col cols="12" sm="12">
                <validation-provider v-slot="{ errors }" name="Đường dẫn">
                  <v-text-field
                    v-model="announcement.link"
                    label="Đường dẫn"
                    :error-messages="errors"
                    color="primary"
                    placeholder="Nhập đường dẫn"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" sm="12">
                <validation-provider v-slot="{ errors }" name="Nhóm khách hàng">
                  <v-autocomplete
                    v-model="announcement.departmentIds"
                    :items="departments"
                    item-text="name"
                    item-value="id"
                    label="Nhóm khách hàng"
                    placeholder="Chọn Nhóm khách hàng"
                    multiple
                    class="selectAll-group-product"
                    clearable
                    :error-messages="errors"
                    eager
                  >
                    <template #prepend-item>
                      <common-select-all
                        v-model="announcement.departmentIds"
                        :item="departments"
                        :items="departments.map((s) => s.id)"
                      ></common-select-all>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
              <!--                        <v-col cols="12" sm="12">-->
              <!--                            <validation-provider v-slot="{ errors }" name="Nội dung" rules="required">-->
              <!--                                <v-textarea v-model="announcement.content" label="Nội dung *" :error-messages="errors" color="primary" placeholder="Nhập nội dung" />-->
              <!--                            </validation-provider>-->
              <!--                        </v-col>-->

              <!-- Add Vue2 Editor -->
              <v-col cols="12" sm="12">
                <span
                  class="title-span-content text-left"
                  style="display: inline-block; width: 100%"
                  >Nội dung *</span
                >
                <validation-provider
                  v-slot="{ errors }"
                  name="Nội dung"
                  rules="required"
                >
                  <template>
                    <vue-editor
                      v-model="announcement.content"
                      :editor-toolbar="customToolbar"
                    />
                  </template>
                  <span
                    v-if="errors[0]"
                    class="errors-span-required-content"
                    color="primary"
                    >* {{ errors[0] }}</span
                  >
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="group-btn--form pt-3">
            <v-row>
              <v-col cols="6" class="pa-0">
                <v-btn
                  color="success"
                  @click.prevent="handleSubmit(saveAnnouncement)"
                  class="reduce-height-btn"
                  style="margin-right: -10px"
                >
                  Lưu lại
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-btn
                  color="primary"
                  @click="hideModal()"
                  class="reduce-height-btn"
                >
                  Đóng
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { VueEditor } from "vue2-editor";
const AnnouncementRepository = RepositoryFactory.get("announcement");

export default {
  components: { VueEditor },
  props: {
    departments: {
      type: Array,
      default: () => {
        [];
      },
    },
  },
  data() {
    return {
      errorMessage: "",
      start: {
        menuStartTime: false,
        startTime: "",
        startDate: "",
      },
      end: {
        endTime: "",
        endDate: "",
        menuEndTime: false,
      },
      announcement: {
        startTime: "",
        endTime: "",
        departmentIds: [],
        content: "",
        link: "",
      },
      isCreate: false,
      showModal: false,
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        ["image", "code-block"],
      ],
    };
  },
  created() {
    Bus.$on("create-announcement-form", () => {
      this.isCreate = true;
      this.start = {
        menuStartTime: false,
        startTime: "",
        startDate: "",
      };
      this.end = {
        endTime: "",
        endDate: "",
        menuEndTime: false,
      };
      this.announcement = {
        startTime: "",
        endTime: "",
        code: "",
        content: "",
        link: "",
      };
      this.dateRange = [];
      this.showModal = true;
    });

    Bus.$on("update-announcement-form", (announcement) => {
      this.announcement = announcement;
      this.start.startDate = new Date(
        moment(announcement.startTime).format("YYYY-MM-DD")
      );
      this.start.startTime = moment(announcement.startTime).format("HH:mm");
      this.end.endDate = new Date(
        moment(announcement.endTime).format("YYYY-MM-DD")
      );
      this.end.endTime = moment(announcement.endTime).format("HH:mm");

      console.log(this.end);
      this.dateRange = [
        new Date(announcement.startTime),
        new Date(announcement.endTime),
      ];
      this.isCreate = false;
      this.showModal = true;
    });
  },
  methods: {
    async saveAnnouncement() {
      const data = {
        departmentIds: this.announcement.departmentIds,
        content: this.announcement.content,
        link: this.announcement.link,
        start_time: `${moment(this.start.startDate).format("YYYY-MM-DD")} ${
          this.start.startTime
        }:00`,
        end_time: `${moment(this.end.endDate).format("YYYY-MM-DD")} ${
          this.end.endTime
        }:00`,
      };

      if (this.isCreate) {
        const res = await AnnouncementRepository.create(data);
        if (res.success) {
          this.showModal = false;
          Bus.$emit("create-new-announcement");
        } else {
          this.errorMessage = _.get(res, "message", "");
        }
      } else {
        const res = await AnnouncementRepository.update(
          this.announcement.id,
          data
        );
        if (res.success) {
          this.showModal = false;
          Bus.$emit("update-announcement");
        } else {
          this.errorMessage = _.get(res, "message", "");
        }
      }
    },
    hideModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped lang="css">
.errors-span-required-content {
  display: inline-block;
  height: 20px;
  width: 100%;
  text-align: left;
  color: red;
}
.title-span-content {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  margin-bottom: 8px;
}
</style>
