<template>
  <v-container id="staff-report" fluid tag="section">
    <FilterForm :filterForm="filterForm" />

    <PersonalRevenue 
      :staff="staff" 
      :reportStaff="reportStaff" 
      :duration="filterForm.dateRange.duration"
    />

    <PersonalChart 
      v-if="dataChart.labels.length" 
      :dataChart="dataChart"
    />
    
    <PersonalStatusReport
      :typeHeaderContactStatus="typeHeaderContactStatus"
      :contactStatusList="contactStatusList"
      :contactStatusTotal="contactStatusTotal"
    />

    <PersonalContactSource
      v-if="dataReport.avgCall"
      :dataReport="dataReport"
    />
  </v-container>
</template>

<script>
  import FilterForm from './components/FilterForm'
  import PersonalChart from '../report/personal-report/components/PersonalChart'
  import PersonalContactSource from '../report/personal-report/components/PersonalContactSource'
  import PersonalRevenue from '../report/personal-report/components/PersonalRevenue'
  import PersonalStatusReport from '../report/personal-report/components/PersonalStatusReport'

  import RepositoryFactory from "@/repositories/RepositoryFactory";
  const AuthRepository = RepositoryFactory.get('auth')
  const UserRepository = RepositoryFactory.get('user');
  const ReportRepository = RepositoryFactory.get('report');
  import * as moment from 'moment'
  import TokenService from '@/helpers/token';
export default {
  components: { FilterForm, PersonalRevenue, PersonalChart, PersonalStatusReport, PersonalContactSource },
  data () {
    return {
      filterForm: {
        dateRange: {
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
          duration: 'today',
        },
        shift: [],
        userId: _.get(TokenService.getUser(), 'id', '')
      },
      staff: {},
      reportStaff: {},
      reportStaffChart: [],
      reportByContact: {},
      dataChart: {
        labels: [],
        series: [],
        successTurnOver: [],
        initTurnOver: [],
        userInitLatchRatio: [],
      },
      typeHeaderContactStatus: [],
      contactStatusList: [],
      contactStatusTotal: [],
      dataReport: {},
    }
  },
  created() {
    this.getUserProfile()
    this.getReportStaff()
    this.getReportStaffChart()
    this.getDataStatusReport()
    this.getDataContactSource()
    Bus.$on('profile-report-filter-form', (filterForm) => {
      this.getUserProfile()
      this.getReportStaff()
      this.getReportStaffChart()
      this.getDataStatusReport()
      this.getDataContactSource()
    })
  },
  beforeDestroy () {
    Bus.$off('profile-report-filter-form')
  },
  methods: {
    getUserProfile () {
      this.staff = TokenService.getUser()
    },
    async getReportStaff() {
      const loader = this.$loading.show()
      const params = {
        startDate: moment(this.filterForm.dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.filterForm.dateRange.endDate).format('YYYY-MM-DD'),
        userId: this.filterForm.userId,
        duration: this.filterForm.dateRange.duration,
        shift: this.filterForm.shift,
      };
      const response = await ReportRepository.personalReport(params)

      loader.hide()
      if (response.success) {
        this.reportStaff = _.get(response, 'data', {})
      }
    },
    async getReportStaffChart() {
      const loader = this.$loading.show()
      const params = {
        startDate: moment(this.filterForm.dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.filterForm.dateRange.endDate).format('YYYY-MM-DD'),
        userId: this.filterForm.userId,
        duration: this.filterForm.dateRange.duration,
        shift: this.filterForm.shift,
      };
      const response = await ReportRepository.personalChart(params)
      loader.hide()
      if (response.success) {
        this.reportStaffChart = _.get(response, 'data', [])
        this.getMultiLineData();
        this.getDataStatusReport()
        this.getDataContactSource()
      }
    },
    getMultiLineData() {
      const getDataLabel = this.reportStaffChart.map(v => Object.keys(v)[0])
      const dataLabel = getDataLabel.map((c, index) => {
        return c;
      })
      this.dataChart.labels = dataLabel
      const data = this.reportStaffChart.map(data => data[Object.keys(data)[0]])
      this.dataChart.successTurnOver = data.map(a => parseInt(a.successTurnOver, 10) || 0)
      this.dataChart.initLatchRatio = data.map(c => parseInt(c.initLatchRatio, 10) || 0)
      this.dataChart.initTurnOver = data.map(b => parseInt(b.initTurnOver, 10) || 0)

      this.dataChart.series = [
        this.dataChart.successTurnOver.map(d => ({ meta: 'Doanh thu thành công :', value: d })),
        this.dataChart.initTurnOver.map(d => ({ meta: 'Doanh thu khởi tạo :', value: d })),
        this.dataChart.initLatchRatio.map(d => ({ meta: 'Tỉ lệ chốt :', value: d })),
      ]
      Bus.$emit('staff-data-chart')
    },

    async getDataStatusReport() {
      const loading = this.$loading.show();
      const response = await ReportRepository.personalByContactStatus({
        userId: this.filterForm.userId,
        startDateTime: moment(this.filterForm.dateRange.startDate).format('YYYY-MM-DD 00:00:00'),
        endDateTime: moment(this.filterForm.dateRange.endDate).format('YYYY-MM-DD 23:59:59'),
        shift: this.filterForm.shift,
      });
      loading.hide();
      if (response.success) {
        const typeHeaderContactStatus = [];
        const contactStatusList = [];
        const contactStatusTotal = [];
        _.get(response, 'data', []).forEach(contactStatus => {
          if(contactStatus.type !== 'T0' && contactStatus.type !== 'C0'){
            typeHeaderContactStatus.push({
              name: contactStatus.type,
              colspan: _.get(contactStatus, 'data', []).length || 1
            });

            let percentageTotal = 0
            let countItemsTotal = 0

            _.get(contactStatus, 'data', []).forEach(data => {
              if(contactStatus.type !== 'T0' && contactStatus.type !== 'C0') {
                contactStatusList.push({
                  type: contactStatus.type,
                  id: _.get(data, 'id', 0),
                  countItems: _.get(data, 'countItems', 0),
                  name: _.get(data, 'name', 0),
                  percentage: _.get(data, 'percentage', 0)
                });
                percentageTotal += +data.percentage
                countItemsTotal += +data.countItems
              }
            });
            contactStatusTotal.push({
              type: contactStatus.type,
              percentage: percentageTotal,
              countItems: countItemsTotal
            })
          }
        });

        this.typeHeaderContactStatus = typeHeaderContactStatus;
        this.contactStatusList = contactStatusList;
        this.contactStatusTotal = contactStatusTotal
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
      }
    },

    async getDataContactSource() {
      const loading = this.$loading.show();
      const response = await ReportRepository.reportByContact({
        startDate: moment(this.filterForm.dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.filterForm.dateRange.endDate).format('YYYY-MM-DD'),
        userId: this.filterForm.userId,
        duration: this.filterForm.dateRange.duration,
        shift: this.filterForm.shift,
      });
      loading.hide();
      if (response.success) {
        this.dataReport = _.get(response, 'data')
      }
    }
  },
}
</script>
