<template>
  <v-card
      class="mx-auto overflow-visible"
      width="400px"
      style="margin-top: 0px; margin-bottom: 0px;"
      outlined
  >
    <v-list-item three-line>
          <validation-observer v-slot="{ handleSubmit }">
              <v-list-item-content class="overflow-visible">
                  <v-row style="width: 100%">
                    <v-col>
                      <validation-provider
                          name="Chọn ngày"
                          v-slot="{ errors }"
                      >
                        <common-range-picker
                            v-model="filterForm.createdAt"
                            label="Ngày tạo"
                            :clearable="true"
                            :aLight="'left'"
                            :defaultRange="defaultRange"
                            @change-date-range="changeDateRange"
                        />
                        <div class="v-text-field__details mt-1">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                {{ errors[0] }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row style="width: 100%">
                    <v-col>
                      <v-text-field
                          v-model="filterForm.codeSms"
                          label="Mã tin nhắn"
                          placeholder="Nhập mã tin nhắn"
                          clearable
                          @keyup.enter.native="handleSubmit(searchUser)"
                      />
                    </v-col>
                  </v-row>
                  <v-row style="width: 100%">
                    <v-col>
                      <v-autocomplete
                          v-model="filterForm.typeSms"
                          :items="typeSmsList"
                          label="Loại tin nhắn"
                          placeholder="Chọn loại tin nhắn"
                          clearable
                          @click:clear="clearAssignContact()"
                          @keyup.enter.native="handleSubmit(searchUser)"
                      />
                    </v-col>
                  </v-row>
                  <v-row style="width: 100%">
                    <v-col>
                      <v-autocomplete
                          label="Người tạo"
                          item-text="fullName"
                          item-value="id"
                          :items="users"
                          v-model="filterForm.userId"
                          @keyup.enter.native="handleSubmit(searchUser)"
                          placeholder="Chọn người tạo"
                          clearable
                      />
                    </v-col>
                  </v-row>
                  <v-row style="width: 100%">
                    <v-col>
                      <v-textarea
                          label="Nội dung"
                          placeholder="Tìm kiếm nội dung"
                          v-model="filterForm.content"
                          rows="2"
                      />
                    </v-col>
                  </v-row>

                <div class="text-center mb-4">
                      <v-btn class="primary mx-0" @click.prevent="handleSubmit(searchSms)">
                          <v-icon>mdi-magnify</v-icon>Tìm kiếm
                      </v-btn>
                  </div>
              </v-list-item-content>
          </validation-observer>
    </v-list-item>
  </v-card>
</template>

<script>
import * as moment from "moment";

export default {
    props: {
        filterForm: {
            type: Object,
            default: () => {}
        },
        users: {
          type: Array,
          default: () => []
        }
    },
    data() {
      return {
        defaultRange: {
          startDate: '',
          endDate: ''
        },
        typeSmsList: ["default", "MKT"],
      }
    },
    methods: {
        searchSms() {
            Bus.$emit('search-template-sms', this.filterForm)
        },
        clearAssignContact() {
           this.filterForm.clearRecently = true
        },
      changeDateRange (dateRange) {
        this.filterForm.dateRange = dateRange
      },
    }
}
</script>

<style lang="css" scoped>
</style>