var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto mt-0 s-layout-small",staticStyle:{"border-color":"#fff !important","padding-top":"20px"},attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('p',{staticClass:"mb-0"},[_vm._v("Địa chỉ nhận hàng")])]),_c('div',{staticClass:"mt-4"},[_c('validation-provider',{attrs:{"name":"Tên người nhận","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.isReadOnly,"label":"Tên người nhận","error-messages":errors,"color":"primary"},model:{value:(_vm.shippingAddress.receiver),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "receiver", $$v)},expression:"shippingAddress.receiver"}})]}}])}),_c('div',{staticClass:"d-flex align-center"},[_c('validation-provider',{staticStyle:{"width":"100%"},attrs:{"name":"Số Điện thoại","rules":{ regex: _vm.regex.isVNPhoneMobile, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.isReadOnly,"label":"Số Điện thoại","error-messages":errors,"color":"primary"},model:{value:(_vm.shippingAddress.phone),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "phone", $$v)},expression:"shippingAddress.phone"}})]}}])}),(_vm.showChat)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"size":"16","width":"20","height":"20","icon":"","small":"","color":"info"}},on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-facebook-messenger")])],1)]}}],null,false,1232039261)},[_c('span',[_vm._v("Nhắn tin")])]):_vm._e()],1),_c('validation-provider',{attrs:{"name":"Email","rules":{ max: 255, email: true, regex: _vm.regex.isEmail }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.isReadOnly,"label":"Email","error-messages":errors,"color":"primary"},model:{value:(_vm.shippingAddress.email),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "email", $$v)},expression:"shippingAddress.email"}})]}}])}),_c('span',[_c('label',[_vm._v("Điạ chỉ")]),_c('v-row',{staticStyle:{"margin-top":"-10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Tỉnh","rules":_vm.contentOrder.paymentType == 'COD' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.isReadOnly,"items":_vm.provinces,"item-text":"name","item-value":"id","label":"Tỉnh","error-messages":errors},on:{"change":_vm.onChangeProvince},model:{value:(_vm.shippingAddress.provinceId),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "provinceId", $$v)},expression:"shippingAddress.provinceId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Quận/Huyện","rules":_vm.contentOrder.paymentType == 'COD' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.isReadOnly,"items":_vm.districts,"item-text":"name","item-value":"id","label":"Quận/Huyện","error-messages":errors},on:{"change":_vm.onChangeDistrict},model:{value:(_vm.shippingAddress.districtId),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "districtId", $$v)},expression:"shippingAddress.districtId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Phường/Xã","rules":_vm.contentOrder.paymentType == 'COD' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.isReadOnly,"label":"Phường/Xã","items":_vm.wards,"item-text":"name","item-value":"id","error-messages":errors},on:{"change":_vm.onChangeWard},model:{value:(_vm.shippingAddress.wardId),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "wardId", $$v)},expression:"shippingAddress.wardId"}})]}}])})],1)],1)],1),_c('validation-provider',{attrs:{"name":"Địa chỉ chi tiết","rules":_vm.contentOrder.paymentType == 'COD' ? 'required|max:255' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Địa chỉ chi tiết (số nhà, đường, thôn...)","rows":"3","error-messages":errors,"readonly":_vm.isReadOnly},model:{value:(_vm.shippingAddress.detailAddress),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "detailAddress", $$v)},expression:"shippingAddress.detailAddress"}})]}}])})],1),_c('div',{staticClass:"mt-4",staticStyle:{"border":"1px solid #000000","border-radius":"5px"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ul',{staticClass:"table-info"},[_c('li',{staticClass:"mb-2"},[_c('strong',[_vm._v("Địa chỉ người nhận")])]),_c('li',{staticClass:"mb-1"},[_c('p',{staticClass:"ma-0"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Số điện thoại :")]),_vm._v(" "+_vm._s(_vm.fullNamePhone)+" ")])]),_c('li',{staticClass:"mb-1"},[_c('p',{staticClass:"ma-0"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Địa chỉ :")]),_vm._v(" "+_vm._s(_vm._.get(_vm.shippingAddress, "detailAddress", ""))+" ")])]),_c('li',{staticClass:"mb-1"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.provinceAddress))])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }