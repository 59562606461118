<template>
    <v-card
        class="mx-auto overflow-visible"
        width="400px"
        style="margin-top: 0px; margin-bottom: 0px;"
        outlined
    >
        <v-list-item three-line>
            <validation-observer v-slot="{ handleSubmit }">
                <v-list-item-content class="overflow-visible">
                    <v-row style="width: 100%; padding: 0px 20px">
                        <v-autocomplete
                            v-model="filterForm.store"
                            :items="stores"
                            item-text="name"
                            item-value="value"
                            label="Chọn kho"
                            placeholder="Chọn kho"
                            clearable
                            @keyup.enter.native="handleSubmit(searchUser)"
                        />
                    </v-row>
                    <v-row style="width: 100%; padding: 0px 20px">
                        <v-text-field
                            type="number"
                            min="0"
                            step="1"
                            label="Số lượng contact được phân bổ"
                            placeholder="Nhập số lượng contact được phân bổ"
                            v-model="filterForm.number_of_contact"
                            clearable
                            @keyup.enter.native="handleSubmit(searchUser)">
                        </v-text-field>
                    </v-row>
                    <v-row style="width: 100%; padding: 0px 20px">
                        <v-text-field
                            label="Nhân viên"
                            placeholder="Nhập tên nhân viên"
                            v-model="filterForm.name"
                            @keyup.enter.native="handleSubmit(searchUser)">
                        </v-text-field>
                    </v-row>
                  <v-row style="width: 100%; padding: 0px 20px">
<!--                    <v-text-field-->
<!--                        label="Nhóm khách hàng"-->
<!--                        placeholder="Nhập tên Nhóm khách hàng"-->
<!--                        v-model="filterForm.departmentIds"-->
<!--                        @keyup.enter.native="handleSubmit(searchUser)">-->
<!--                    </v-text-field>-->


                    <v-autocomplete
                        v-model="filterForm.departmentIds"
                        label="Nhóm khách hàng"
                        placeholder="Chọn Nhóm khách hàng"
                        no-data-text="Không có dữ liệu"
                        :items="departmentList"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        color="primary"
                        multiple
                    >
                      <template #prepend-item>
                        <common-select-all
                            v-model="filterForm.departmentIds"
                            :item="filterForm.departmentIds"
                            :items="departmentList.map(s => s.id)"
                        ></common-select-all>
                      </template>
                    </v-autocomplete>

                  </v-row>
                    <div class="text-center mb-4">
                        <v-btn class="primary mx-0" @click.prevent="handleSubmit(searchUser)">
                            <v-icon>mdi-magnify</v-icon>Tìm kiếm
                        </v-btn>
                    </div>
                </v-list-item-content>
            </validation-observer>
        </v-list-item>
    </v-card>
</template>

<script>
import TokenService from "@/helpers/token";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const DepartmentRepository = RepositoryFactory.get('department')

export default {
    props: {
        filterForm: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            stores: [
                {
                    name: 'Kho T',
                    value: 'T'
                },
                {
                    name: 'Kho C',
                    value: 'C'
                },
                {
                    name: 'Kho G',
                    value: 'G'
                },
              {
                name: 'Kho M',
                value: 'M'
              }
            ],
            departmentList: [],
            errors: []
        }
    },
    async mounted() {
      await this.fetchDepartmentList();
    },
    methods: {
        searchUser() {
            Bus.$emit('search-setting-contact-v1', this.filterForm)
        },
        clearAssignContact() {
           this.filterForm.clearRecently = true
        },
        async fetchDepartmentList () {
        this.loading = this.$loading.show();

        const response = await DepartmentRepository.listUsers({
          keyword: '',
          status: 'active',
          departmentType: 'retail'
        })
        if (response.error) {
        } else {
          let departmentList = _.get(response, 'data', []).filter(d => _.get(d, 'users', []).length > 0);
          if (!this.isAdmin() && !this.checkAuthorization('schedule_view_all')) {
            const dataUser = TokenService.getUser() || {};
            departmentList = departmentList.filter(d => d.id == dataUser.departmentId  || dataUser.listDepartmentIds.includes(parseInt(d.id)));
          }
          this.departmentList = departmentList;
        }
        this.loading.hide()
      },
    }
}
</script>