<template>
  <v-card class="ma-0">
    <div>
    </div>
    <div>
      <order-account-table-mobile :orderAccounts="orderAccounts" />
    </div>

    <v-row class="ma-0 pagination-container">
      <v-col cols="12" class="py-1">
        <v-pagination
            v-if="totalItem > limit"
            v-model="currentPage"
            :length="getTotalItem(totalItem, limit)"
            class="justify-sm-end"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>

    <v-row class="bottom-tabs-container ma-0"
           color="primary">
      <div class="tabs-slider"></div>
    </v-row>
  </v-card>
</template>

<script>
import RepositoryFactory from '../../repositories/RepositoryFactory'
import OrderAccountTableMobile from "@/pages/order/components/OrderAccountTableMobile";
const OrderRepository = RepositoryFactory.get('order')

export default {
  components: {OrderAccountTableMobile },
  data() {
    return {
      filterForm: {},
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      orderAccounts: [],
      showCreate: false
    }
  },
  created() {
    this.showCreate = this.checkAuthorization('order-ctv-create_read')
    this.getOrderAccountList();
  },
  watch: {
    currentPage: {
      handler() {
        this.getOrderAccountList();
      }
    }
  },
  methods: {

    async getOrderAccountList() {
      const params = {
        limit: this.limit,
        page: this.currentPage - 1,
      };

      params['orderId'] =  this.$route.params.id;

      this.loading = true;
      const loader = this.$loading.show();
      const response = await OrderRepository.getListOrderAccount(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.orderAccounts = _.get(response, 'data.accountList', []);
        this.totalItem = _.get(response, 'data.totalItem', 0);
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
        this.orderAccounts = [];
        this.totalItem = 0;
      }
    }
}
}
</script>

<style scoped>
.v-card {
  min-height: calc(100vh - 45px);
}

.pagination-btn-left, .pagination-btn-right {
  max-width: 30px !important;
  min-width: 30px !important;
  background-color: #ffffff !important;
}

.pagination-btn-left i, .pagination-btn-right i {
  color: rgba(0,0,0,.54) !important
}

.pagination-btn-left {
  margin: 0px 5px 2px 10px !important;
}

.pagination-btn-right {
  margin: 0px 10px 2px 5px !important;
}

.pagination-container {
  position: fixed;
  background-color: white;
  z-index: 10;
  bottom: 0px;
  text-align: center;
  width: 100%;
}
</style>