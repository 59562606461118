<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('product_unit_create')"
        class="primary mx-0"
        @click="addNewUnit"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên đơn vị</th>
            <th>Đường dẫn</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(unit, index) in units">
            <tr :key="unit.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(unit, "name") }}</td>
              <td>{{ _.get(unit, "slug") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('product_unit_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editUnit(unit)"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin đơn vị</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('product_unit_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(unit)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa đơn vị</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <unit-form />
  </div>
</template>
<script>
import UnitForm from "./UnitForm.vue";
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const UnitRepository = RepositoryFactory.get("unit");
export default {
  components: { UnitForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedUnit: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("product_unit_update") ||
        this.checkAuthorization("product_unit_delete")
      );
    },
  },
  methods: {
    addNewUnit() {
      this.selectedUnit = {};
      Bus.$emit("unit-show-form", this.selectedUnit);
    },
    editUnit(unit) {
      this.selectedUnit = unit;
      Bus.$emit("unit-show-form", this.selectedUnit);
    },
    async onClickDelete(unit) {
      this.$swal({
        title: "Xóa đơn vị",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await UnitRepository.delete(unit.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("unit-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
