<template>
  <v-container fluid>
    <filter-form :dataForm="dataForm" :filterForm="filterForm" />

    <v-card>
      <setting-table v-if="users.length" :users="users" :dataForm="dataForm"/>

      <common-no-result
        v-if="!loading && totalItem === 0"
      />

      <v-row>
        <v-col
          cols="12"
          sm="6"
        />
        <v-col
          cols="12"
          sm="6"
        >
          <v-pagination
            v-if="totalItem > limit"
            v-model="currentPage"
            class="justify-sm-end"
            :length="getTotalItem(totalItem)"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import FilterForm from './components/FilterForm'
import SettingTable from './components/SettingTable'
import {userPositions} from '@/helpers/constants'
import RepositoryFactory from '../../repositories/RepositoryFactory';
const SettingRepository = RepositoryFactory.get('setting');
const UserRepository = RepositoryFactory.get('user');

export default {
  components: {
    FilterForm,
    SettingTable
  },
  data() {
    return {
      dataForm: {
        assignContact: '',
        contactForTelesales: '',
        contactForSaleLeader: '',
      },
      filterForm: {
        store: '',
        number_of_contact: '',
        assignContact: '',
        name: '',
        clearRecently: false,
        openMenu: false
      },
      users: [],
      totalItem: 0,
      currentPage: 1,
      limit: 1000,
      loading: false,
      userPositions
    };
  },
  async created() {
    await this.getAutoAssignContact();
    this.getUserList()
    Bus.$on('save-contact-setting', async (dataForm) => {
      this.dataForm = dataForm
      await this.saveSetting()
      this.getUserList()
    })
    Bus.$on('update-user-setting-contact', (user) => {
      this.updateUser(user)
    })
    Bus.$on('search-setting-contact', (filterForm) => {
      this.filterForm = filterForm
      this.currentPage = 1
      this.getUserList()
    })
  },
  watch: {
    currentPage: {
      handler () {
        this.getUserList()
      },
    },
  },
  methods: {
    async getAutoAssignContact() {
      const loading = this.$loading.show();
      const response = await SettingRepository.detail();
      loading.hide();
      this.dataForm = {
        assignContact: _.get(response, 'data.value', '') == 'on' ? true : false,
        contactForTelesales: _.get(response, 'data.maxContactForTelesales', 0),
        contactForSaleLeader: _.get(response, 'data.maxContactForSaleLeader', 0),
      };
    },
    async saveSetting() {
      const loading = this.$loading.show();
      const params = {
        value: this.dataForm.assignContact ? 'on' : 'off',
        maxContactForTelesales: +this.dataForm.contactForTelesales,
        maxContactForSaleLeader: +this.dataForm.contactForSaleLeader,
      };
      const response = await SettingRepository.update(params);
      loading.hide();
      if (response.success) {
        this.$swal({
          title: 'Cập nhật thành công !',
          confirmButtonText: 'Đồng ý',
        },);
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
      }
    },
    async getUserList() {
      const loading = this.$loading.show()
      this.loading = true
      let params = {
        limit: this.limit,
        page: this.currentPage - 1,
        store: this.filterForm.store,
        offAssignContact: this.filterForm.assignContact == '' ? '' : (this.filterForm.assignContact == 'true' ? false : true)
      }
      if (this.filterForm.clearRecently) {
        params.offAssignContact = ''
        this.filterForm.clearRecently = false
      }
      const response = await SettingRepository.list(params)
      loading.hide()
      this.loading = false

      if (response.success) {
        let users = _.get(response.data, 'rows', []).map(u => ({
          ...u,
          assignContact: !u.offAssignContact,
          fullName: u.lastName + ' ' + u.firstName,
          real_contacts: u.offAssignContact ? 0 : (u.number_of_contact == null ? (this.dataForm.assignContact ? (u.position == 'telesales' ? this.dataForm.contactForTelesales : this.dataForm.contactForSaleLeader) : 0) : u.number_of_contact),
          showEdit: {
            number_of_contact: false,
            store: false,
          }
        }))
        let totalItem = response.data.totalItem

        if (this.filterForm.name != '') {
          users = users.filter(user => user.fullName.toLowerCase().indexOf(this.filterForm.name.toLowerCase()) > -1) 
          totalItem = users.length
        }
        if (this.filterForm.number_of_contact != '') {
          users = users.filter(user => user.real_contacts == this.filterForm.number_of_contact)
          totalItem = users.length
        }

        this.users = users
        this.totalItem = totalItem
      } else {
        this.users = []
        this.totalItem = 0
      }
    },
    async updateUser(user) {
      let params = {
        number_of_contact: user.number_of_contact == '' ? null : user.number_of_contact,
        store: user.store == '' ? 'C' : user.store,
        offAssignContact: !user.assignContact
      }
      // if (typeof(user.number_of_contact) != "number") {
      //   if (params.offAssignContact) {
      //     params.number_of_contact = 0
      //   } else {
      //     if (user.position === userPositions.TELESALES) {
      //       params.number_of_contact = this.dataForm.contactForTelesales
      //     } else if (user.position === userPositions.SALE_LEADER) {
      //       params.number_of_contact = this.dataForm.contactForSaleLeader
      //     }
      //   } 
      // }
      const response = await SettingRepository.updateUser(user.id, params)
      if (response.success) {
        this.$swal({
          title: 'Cập nhật thành công !',
          confirmButtonText: 'Đồng ý',
        },);
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
      }
      this.getUserList()
    }
  }
};
</script>