var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.errorMessage))])]:_vm._e(),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[(_vm.isCreate)?[_vm._v("Thêm mới khách hàng")]:[_vm._v("Cập nhật thông tin khách hàng")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.cancel}},[_vm._v("mdi-close")])],2),_c('v-card-text',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"upload-image upload-image--user"},[(_vm._.get(_vm.user, 'avatar.id', null))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"delete-img",attrs:{"icon":"","small":"","color":"default"},on:{"click":_vm.deleteImage}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Xóa ảnh đại diện")])]):_vm._e(),(_vm._.get(_vm.user, 'avatar.id', null))?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm._.get(_vm.user, 'avatar.filePath')},on:{"click":_vm.chooseImage}}):_c('v-img',{attrs:{"src":require("@/assets/default-avatar.jpg")},on:{"click":_vm.chooseImage}}),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"input":_vm.onSelectFile}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":{
                  required: true,
                  max: 50,
                  /* regex: regex.isUsername, */
                },"name":"Tên tài khoản"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên tài khoản *","error-messages":errors,"color":"primary","readonly":!_vm.isCreate},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Số điện thoại","rules":{ regex: _vm.regex.isVNPhoneMobile, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Số điện thoại *","error-messages":errors,"color":"primary","type":"number"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)})],1),(_vm.isCreate)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":{
                  required: true,
                  min: 6,
                  regex: _vm.regex.isPassword,
                  max: 20,
                },"name":"Mật khẩu","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mật khẩu *","error-messages":errors,"color":"primary","append-icon":_vm.visiblePassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.visiblePassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.visiblePassword = !_vm.visiblePassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1):_vm._e(),(_vm.isCreate)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|confirmed:password","name":"Nhập lại mật khẩu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Xác nhận lại mật khẩu *","error-messages":errors,"color":"primary","append-icon":_vm.visibleConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.visibleConfirmPassword ? 'password' : 'text'},on:{"click:append":function () { return (_vm.visibleConfirmPassword = !_vm.visibleConfirmPassword); }},model:{value:(_vm.user.confirm),callback:function ($$v) {_vm.$set(_vm.user, "confirm", $$v)},expression:"user.confirm"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Giới tính"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"attach":"","items":_vm.userGenderOptions,"error-messages":errors,"item-text":"text","item-value":"value","label":"Giới tính *","color":"primary"},model:{value:(_vm.user.gender),callback:function ($$v) {_vm.$set(_vm.user, "gender", $$v)},expression:"user.gender"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"datePicker",attrs:{"close-on-content-click":false,"return-value":_vm.user.birthday,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.user, "birthday", $event)},"update:return-value":function($event){return _vm.$set(_vm.user, "birthday", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"color":"primary","label":"Ngày sinh","prepend-icon":"mdi-calendar-outline","readonly":""},model:{value:(_vm.user.birthday),callback:function ($$v) {_vm.$set(_vm.user, "birthday", $$v)},expression:"user.birthday"}},on))]}}],null,true),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"color":"secondary","scrollable":"","max":_vm.currentDate},on:{"change":function($event){_vm.$refs.datePicker.save(_vm.user.birthday), (_vm.datePicker = false)}},model:{value:(_vm.user.birthday),callback:function ($$v) {_vm.$set(_vm.user, "birthday", $$v)},expression:"user.birthday"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Email","rules":{
              max: 255,
              email: true,
              regex: _vm.regex.isEmail,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"color":"primary","type":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Địa chỉ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Địa chỉ","error-messages":errors,"color":"primary"},model:{value:(_vm.user.address),callback:function ($$v) {_vm.$set(_vm.user, "address", $$v)},expression:"user.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Nhóm khách hàng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.departments,"error-messages":errors,"item-text":"name","item-value":"id","label":"Nhóm khách hàng *","color":"primary"},model:{value:(_vm.user.departmentId),callback:function ($$v) {_vm.$set(_vm.user, "departmentId", $$v)},expression:"user.departmentId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Loại tài khoản","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.customerTypeList,"error-messages":errors,"item-text":"name","item-value":"code","label":"Loại tài khoản *","color":"primary","clearable":"","eager":""},on:{"change":function($event){return _vm.changeCustomerType()}},model:{value:(_vm.user.position),callback:function ($$v) {_vm.$set(_vm.user, "position", $$v)},expression:"user.position"}})]}}],null,true)})],1),(_vm.user.position == _vm.customerTypeListObj.CONG_TY)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Tên công ty","rules":{
              max: 255,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên công ty","error-messages":errors,"color":"primary"},model:{value:(_vm.user.customer_profile.companyName),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "companyName", $$v)},expression:"user.customer_profile.companyName"}})]}}],null,true)})],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.CONG_TY)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Người đại diện công ty","rules":{
              max: 255,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Người đại diện công ty","error-messages":errors,"color":"primary"},model:{value:(_vm.user.customer_profile.representativeName),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "representativeName", $$v)},expression:"user.customer_profile.representativeName"}})]}}],null,true)})],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.NHA_THUOC)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Tên nhà thuốc","rules":{
              max: 255,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên nhà thuốc","error-messages":errors,"color":"primary"},model:{value:(_vm.user.customer_profile.nameOfPharmacy),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "nameOfPharmacy", $$v)},expression:"user.customer_profile.nameOfPharmacy"}})]}}],null,true)})],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.NHA_THUOC)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Dược sỹ phụ trách","rules":{
              max: 255,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Dược sỹ phụ trách","error-messages":errors,"color":"primary"},model:{value:(_vm.user.customer_profile.pharmacistInCharge),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "pharmacistInCharge", $$v)},expression:"user.customer_profile.pharmacistInCharge"}})]}}],null,true)})],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.PHONG_KHAM)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Tên phòng khám","rules":{
              max: 255,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên phòng khám","error-messages":errors,"color":"primary"},model:{value:(_vm.user.customer_profile.clinicName),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "clinicName", $$v)},expression:"user.customer_profile.clinicName"}})]}}],null,true)})],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.PHONG_KHAM)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Bác sĩ chuyên môn","rules":{
              max: 255,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Bác sĩ chuyên môn","error-messages":errors,"color":"primary"},model:{value:(_vm.user.customer_profile.specialistDoctor),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "specialistDoctor", $$v)},expression:"user.customer_profile.specialistDoctor"}})]}}],null,true)})],1):_vm._e(),(
            _vm.user.position == _vm.customerTypeListObj.PHONG_KHAM ||
            _vm.user.position == _vm.customerTypeListObj.CONG_TY ||
            _vm.user.position == _vm.customerTypeListObj.NHA_THUOC
          )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Mã số thuế","rules":{
              max: 255,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mã số thuế","error-messages":errors,"color":"primary"},model:{value:(_vm.user.customer_profile.tax),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "tax", $$v)},expression:"user.customer_profile.tax"}})]}}],null,true)})],1):_vm._e(),(
            _vm.user.position == _vm.customerTypeListObj.PHONG_KHAM ||
            _vm.user.position == _vm.customerTypeListObj.CONG_TY ||
            _vm.user.position == _vm.customerTypeListObj.NHA_THUOC
          )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Giấy phép kinh doanh","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Giấy phép kinh doanh"},on:{"change":function($event){return _vm.changeFiles('businessLicenseImagesFiles')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.user.customer_profile.businessLicenseImagesFiles),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "businessLicenseImagesFiles", $$v)},expression:"user.customer_profile.businessLicenseImagesFiles"}}),(
              _vm.user.customer_profile.businessLicenseImages &&
              _vm.user.customer_profile.businessLicenseImages.length
            )?_c('div',{staticClass:"group--product"},_vm._l((_vm.user.customer_profile
                .businessLicenseImages),function(displayImage,index){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{staticClass:"delete-image-block-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteImageItem('businessLicenseImages', index)}}},[_vm._v(" mdi-close ")])],1),_c('a',{attrs:{"href":_vm._.get(displayImage, 'filePath'),"target":"_blank"}},[_c('v-img',{staticStyle:{"border":"1px solid black","border-radius":"5px"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"width":"50px","height":"50px"}})],1)])}),0):_vm._e()],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.CONG_TY)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Giấy phép khác","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Giấy phép khác"},on:{"change":function($event){return _vm.changeFiles('otherLicenseImagesFiles')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.user.customer_profile.otherLicenseImagesFiles),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "otherLicenseImagesFiles", $$v)},expression:"user.customer_profile.otherLicenseImagesFiles"}}),(
              _vm.user.customer_profile.otherLicenseImages &&
              _vm.user.customer_profile.otherLicenseImages.length
            )?_c('div',{staticClass:"group--product"},_vm._l((_vm.user.customer_profile
                .otherLicenseImages),function(displayImage,index){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{staticClass:"delete-image-block-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteImageItem('otherLicenseImages', index)}}},[_vm._v(" mdi-close ")])],1),_c('a',{attrs:{"href":_vm._.get(displayImage, 'filePath'),"target":"_blank"}},[_c('v-img',{staticStyle:{"border":"1px solid black","border-radius":"5px"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"width":"50px","height":"50px"}})],1)])}),0):_vm._e()],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.NHA_THUOC)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Giấy chứng nhận đủ điều kiện kinh doanh dược","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Giấy chứng nhận đủ điều kiện kinh doanh dược"},on:{"change":function($event){return _vm.changeFiles('certificateOfEligibilityForPharmacyBusinessImagesFiles')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(
              _vm.user.customer_profile.certificateOfEligibilityForPharmacyBusinessImagesFiles
            ),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "certificateOfEligibilityForPharmacyBusinessImagesFiles", $$v)},expression:"\n              user.customer_profile.certificateOfEligibilityForPharmacyBusinessImagesFiles\n            "}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.customer_profile
                .certificateOfEligibilityForPharmacyBusinessImages &&
              _vm.user.customer_profile
                .certificateOfEligibilityForPharmacyBusinessImages.length
                ? true
                : false
            ),expression:"\n              user.customer_profile\n                .certificateOfEligibilityForPharmacyBusinessImages &&\n              user.customer_profile\n                .certificateOfEligibilityForPharmacyBusinessImages.length\n                ? true\n                : false\n            "}],staticClass:"group--product"},_vm._l((_vm.user.customer_profile
                .certificateOfEligibilityForPharmacyBusinessImages),function(displayImage,index){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{staticClass:"delete-image-block-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteImageItem(
                      'certificateOfEligibilityForPharmacyBusinessImages',
                      index
                    )}}},[_vm._v(" mdi-close ")])],1),_c('a',{attrs:{"href":_vm._.get(displayImage, 'filePath'),"target":"_blank"}},[_c('v-img',{staticStyle:{"border":"1px solid black","border-radius":"5px"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"width":"50px","height":"50px"}})],1)])}),0)],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.NHA_THUOC)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Giấy chứng nhận đạt thực hành tốt cơ sở bán lẻ thuốc","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Giấy chứng nhận đạt thực hành tốt cơ sở bán lẻ thuốc"},on:{"change":function($event){return _vm.changeFiles(
                'certificateOfGoodPracticeForADrugRetailerImagesFiles'
              )}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(
              _vm.user.customer_profile
                .certificateOfGoodPracticeForADrugRetailerImagesFiles
            ),callback:function ($$v) {_vm.$set(_vm.user.customer_profile
                , "certificateOfGoodPracticeForADrugRetailerImagesFiles", $$v)},expression:"\n              user.customer_profile\n                .certificateOfGoodPracticeForADrugRetailerImagesFiles\n            "}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.customer_profile
                .certificateOfGoodPracticeForADrugRetailerImages &&
              _vm.user.customer_profile
                .certificateOfGoodPracticeForADrugRetailerImages.length
                ? true
                : false
            ),expression:"\n              user.customer_profile\n                .certificateOfGoodPracticeForADrugRetailerImages &&\n              user.customer_profile\n                .certificateOfGoodPracticeForADrugRetailerImages.length\n                ? true\n                : false\n            "}],staticClass:"group--product"},_vm._l((_vm.user.customer_profile
                .certificateOfGoodPracticeForADrugRetailerImages),function(displayImage,index){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{staticClass:"delete-image-block-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteImageItem(
                      'certificateOfGoodPracticeForADrugRetailerImages',
                      index
                    )}}},[_vm._v(" mdi-close ")])],1),_c('a',{attrs:{"href":_vm._.get(displayImage, 'filePath'),"target":"_blank"}},[_c('v-img',{staticStyle:{"border":"1px solid black","border-radius":"5px"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"width":"50px","height":"50px"}})],1)])}),0)],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.PHONG_KHAM)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Giấy phép hoạt động cơ sở khám chữa bệnh","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Giấy phép hoạt động cơ sở khám chữa bệnh"},on:{"change":function($event){return _vm.changeFiles(
                'licenseToOperateMedicalExaminationAndTreatmentFacilityImagesFiles'
              )}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(
              _vm.user.customer_profile
                .licenseToOperateMedicalExaminationAndTreatmentFacilityImagesFiles
            ),callback:function ($$v) {_vm.$set(_vm.user.customer_profile
                , "licenseToOperateMedicalExaminationAndTreatmentFacilityImagesFiles", $$v)},expression:"\n              user.customer_profile\n                .licenseToOperateMedicalExaminationAndTreatmentFacilityImagesFiles\n            "}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.customer_profile
                .licenseToOperateMedicalExaminationAndTreatmentFacilityImages &&
              _vm.user.customer_profile
                .licenseToOperateMedicalExaminationAndTreatmentFacilityImages
                .length
                ? true
                : false
            ),expression:"\n              user.customer_profile\n                .licenseToOperateMedicalExaminationAndTreatmentFacilityImages &&\n              user.customer_profile\n                .licenseToOperateMedicalExaminationAndTreatmentFacilityImages\n                .length\n                ? true\n                : false\n            "}],staticClass:"group--product"},_vm._l((_vm.user.customer_profile
                .licenseToOperateMedicalExaminationAndTreatmentFacilityImages),function(displayImage,index){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{staticClass:"delete-image-block-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteImageItem(
                      'licenseToOperateMedicalExaminationAndTreatmentFacilityImages',
                      index
                    )}}},[_vm._v(" mdi-close ")])],1),_c('a',{attrs:{"href":_vm._.get(displayImage, 'filePath'),"target":"_blank"}},[_c('v-img',{staticStyle:{"border":"1px solid black","border-radius":"5px"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"width":"50px","height":"50px"}})],1)])}),0)],1):_vm._e(),(
            _vm.user.position == _vm.customerTypeListObj.NHA_THUOC ||
            _vm.user.position == _vm.customerTypeListObj.PHONG_KHAM
          )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Giấy chứng nhận hành nghề","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Giấy chứng nhận hành nghề"},on:{"change":function($event){return _vm.changeFiles('practicingCertificateImagesFiles')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.user.customer_profile.practicingCertificateImagesFiles),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "practicingCertificateImagesFiles", $$v)},expression:"user.customer_profile.practicingCertificateImagesFiles"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.customer_profile.practicingCertificateImages &&
              _vm.user.customer_profile.practicingCertificateImages.length
                ? true
                : false
            ),expression:"\n              user.customer_profile.practicingCertificateImages &&\n              user.customer_profile.practicingCertificateImages.length\n                ? true\n                : false\n            "}],staticClass:"group--product"},_vm._l((_vm.user.customer_profile
                .practicingCertificateImages),function(displayImage,index){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{staticClass:"delete-image-block-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteImageItem('practicingCertificateImages', index)}}},[_vm._v(" mdi-close ")])],1),_c('a',{attrs:{"href":_vm._.get(displayImage, 'filePath'),"target":"_blank"}},[_c('v-img',{staticStyle:{"border":"1px solid black","border-radius":"5px"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"width":"50px","height":"50px"}})],1)])}),0)],1):_vm._e(),(_vm.user.position == _vm.customerTypeListObj.PHONG_KHAM)?_c('v-col',[_c('v-checkbox',{attrs:{"label":"Thông tin nhà thuốc tại phòng khám"},model:{value:(_vm.user.customer_profile.isClinic),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "isClinic", $$v)},expression:"user.customer_profile.isClinic"}})],1):_vm._e(),(
            _vm.user.position == _vm.customerTypeListObj.PHONG_KHAM &&
            _vm.user.customer_profile.isClinic
          )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Tên nhà thuốc","rules":{
              max: 255,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên nhà thuốc","error-messages":errors,"color":"primary"},model:{value:(_vm.user.customer_profile.nameOfPharmacy),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "nameOfPharmacy", $$v)},expression:"user.customer_profile.nameOfPharmacy"}})]}}],null,true)})],1):_vm._e(),(
            _vm.user.position == _vm.customerTypeListObj.PHONG_KHAM &&
            _vm.user.customer_profile.isClinic
          )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Giấy phép kinh doanh","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Giấy phép kinh doanh"},on:{"change":function($event){return _vm.changeFiles('businessLicenseClinicImagesFiles')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.user.customer_profile.businessLicenseClinicImagesFiles),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "businessLicenseClinicImagesFiles", $$v)},expression:"user.customer_profile.businessLicenseClinicImagesFiles"}}),(
              _vm.user.customer_profile.businessLicenseClinicImages &&
              _vm.user.customer_profile.businessLicenseClinicImages.length
            )?_c('div',{staticClass:"group--product"},_vm._l((_vm.user.customer_profile
                .businessLicenseClinicImages),function(displayImage,index){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{staticClass:"delete-image-block-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteImageItem('businessLicenseClinicImages', index)}}},[_vm._v(" mdi-close ")])],1),_c('a',{attrs:{"href":_vm._.get(displayImage, 'filePath'),"target":"_blank"}},[_c('v-img',{staticStyle:{"border":"1px solid black","border-radius":"5px"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"width":"50px","height":"50px"}})],1)])}),0):_vm._e()],1):_vm._e(),(
            _vm.user.position == _vm.customerTypeListObj.PHONG_KHAM &&
            _vm.user.customer_profile.isClinic
          )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Giấy chứng nhận đạt thực hành tốt cơ sở bán lẻ thuốc","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Giấy chứng nhận đạt thực hành tốt cơ sở bán lẻ thuốc"},on:{"change":function($event){return _vm.changeFiles('licenseToOperateRetailClinicImagesFiles')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(
              _vm.user.customer_profile.licenseToOperateRetailClinicImagesFiles
            ),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "licenseToOperateRetailClinicImagesFiles", $$v)},expression:"\n              user.customer_profile.licenseToOperateRetailClinicImagesFiles\n            "}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.customer_profile.licenseToOperateRetailClinicImages &&
              _vm.user.customer_profile.licenseToOperateRetailClinicImages.length
                ? true
                : false
            ),expression:"\n              user.customer_profile.licenseToOperateRetailClinicImages &&\n              user.customer_profile.licenseToOperateRetailClinicImages.length\n                ? true\n                : false\n            "}],staticClass:"group--product"},_vm._l((_vm.user.customer_profile
                .licenseToOperateRetailClinicImages),function(displayImage,index){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{staticClass:"delete-image-block-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteImageItem(
                      'licenseToOperateRetailClinicImages',
                      index
                    )}}},[_vm._v(" mdi-close ")])],1),_c('a',{attrs:{"href":_vm._.get(displayImage, 'filePath'),"target":"_blank"}},[_c('v-img',{staticStyle:{"border":"1px solid black","border-radius":"5px"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"width":"50px","height":"50px"}})],1)])}),0)],1):_vm._e(),(
            _vm.user.position == _vm.customerTypeListObj.PHONG_KHAM &&
            _vm.user.customer_profile.isClinic
          )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Giấy chứng nhận hành nghề","multiple":"","prepend-icon":"mdi-paperclip","placeholder":"Giấy chứng nhận hành nghề"},on:{"change":function($event){return _vm.changeFiles('certificateOfPracticeClinicImagesFiles')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(
              _vm.user.customer_profile.certificateOfPracticeClinicImagesFiles
            ),callback:function ($$v) {_vm.$set(_vm.user.customer_profile, "certificateOfPracticeClinicImagesFiles", $$v)},expression:"\n              user.customer_profile.certificateOfPracticeClinicImagesFiles\n            "}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.customer_profile.certificateOfPracticeClinicImages &&
              _vm.user.customer_profile.certificateOfPracticeClinicImages.length
                ? true
                : false
            ),expression:"\n              user.customer_profile.certificateOfPracticeClinicImages &&\n              user.customer_profile.certificateOfPracticeClinicImages.length\n                ? true\n                : false\n            "}],staticClass:"group--product"},_vm._l((_vm.user.customer_profile
                .certificateOfPracticeClinicImages),function(displayImage,index){return _c('div',{key:displayImage.id,staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{staticClass:"delete-image-block-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteImageItem(
                      'certificateOfPracticeClinicImages',
                      index
                    )}}},[_vm._v(" mdi-close ")])],1),_c('a',{attrs:{"href":_vm._.get(displayImage, 'filePath'),"target":"_blank"}},[_c('v-img',{staticStyle:{"border":"1px solid black","border-radius":"5px"},attrs:{"src":_vm._.get(displayImage, 'filePath'),"width":"50px","height":"50px"}})],1)])}),0)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveCustomer)}}},[_vm._v("Lưu lại")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.cancel}},[_vm._v("Hủy bỏ")])],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }