<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('filter_price_create')"
        class="primary mx-0"
        @click="addNewPrice"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên biến cấu hình</th>
            <th>Giá trị</th>
            
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(price, index) in prices">
            <tr :key="price.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(price, "text") }}</td>
              <td>{{ _.get(price, "value") }}</td>
              <td>{{ getTextStatus(_.get(price, "status", null)) }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('filter_price_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editPrice(price)"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('filter_price_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(price)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <price-form />
  </div>
</template>
<script>
import PriceForm from "./PriceForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { configStatusOptions } from "../../../helpers/constants";

const SiteConfigRepository = RepositoryFactory.get("site_config");
export default {
  components: { PriceForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    prices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedPrice: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("filter_price_update") ||
        this.checkAuthorization("filter_price_delete")
      );
    },
  },
  methods: {
    addNewPrice() {
      this.selectedPrice = {};
      Bus.$emit("price-show-form", this.selectedPrice);
    },
    editPrice(price) {
      this.selectedPrice = price;
      Bus.$emit("price-show-form", this.selectedPrice);
    },
    async onClickDelete(price) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await SiteConfigRepository.delete(price.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("price-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
    getTextStatus(status) {
      const option = configStatusOptions.find((o) => o.value == status);
      return option ? option.text : "";
    },
  },
};
</script>
