<template>
  <v-card
    class="mx-auto pa-3 overflow-visible mb-7"
    outlined
  >
   <v-list-item>
    <v-list-item-content class="overflow-visible">
      <v-list-item-title class="headline mb-2">
        Theo kết quả
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>

  <chartist
    :data="multipleLine.data"
    :options="multipleLine.options"
    type="Line"
    style="max-height: 300px;"
    class="mt-5 chart-multi chart--personal chart-fix"
  />
    <div class="chart d-flex justify-center mt-3 mb-3">
      <div class="chart-content">
        <div class="chart-item">
          <span class="font-weight-bold">Doanh thu thành công:</span>
          <span class="chart-bg c-successTurnOver"></span>
        </div>
        <div class="chart-item">
          <span class="font-weight-bold">Doanh thu khởi tạo:</span>
          <span class="chart-bg c-initTurnOver"></span>
        </div>
        <div class="chart-item">
          <span class="font-weight-bold">Tỉ lệ chốt:</span>
          <span class="chart-bg c-userInitLatchRatio"></span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
  import * as ChartistTooltips from 'chartist-plugin-tooltips';
  import { formatPrice } from '@/helpers/utils'
  export default {
    props: {
      dataChart: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        multipleLine: {
          data: {
            labels: this.dataChart.labels,
            series: this.dataChart.series,
          },
          options: {
            low: 0,
            axisY: {
              onlyInteger: true,
              labelInterpolationFnc: (value) => {
                return formatPrice(value)
              },
            },
            fullWidth: true,
            chartPadding: {
              top: 0,
              right: 40,
              bottom: 0,
              left: 40,
            },
            plugins: [this.$chartist.plugins.tooltip(
              {
                currency: ' ',
              }
            )]
          },
        },
      }
    },
    created() {
    },
    mounted() {
      Bus.$on('staff-data-chart', () => {
        this.multipleLine.data.labels = this.dataChart.labels
        this.multipleLine.data.series = this.dataChart.series
      })
    }
  }
</script>

<style lang="sass" scoped>
  .chart-bg
    &.c-userInitLatchRatio
      background: #f4c63d
    &.c-successTurnOver
      background: #00cae3
    &.c-initTurnOver
      background: #f05b4f
</style>
