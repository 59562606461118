<template>
  <div>
    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Mã đơn hàng</th>
            <th>Thời điểm</th>
            <th>Hành động</th>
            <th>Người cập nhật</th>
            <th>Khách hàng</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(orderHistory, index) in orderHistories">
            <tr :key="orderHistory.id">
              <td>{{ index + 1 }}</td>
              <td>
                <v-tooltip bottom v-if="checkAuthorization('order_read')">
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                      @click="clickDetailOrder(orderHistory.orderId)"
                      class="btn-detail"
                    >
                      {{ _.get(orderHistory, "order.code") }}
                    </span>
                  </template>
                  <span>Xem chi tiết thông tin đơn hàng</span>
                </v-tooltip>
              </td>
              <td>{{ _.get(orderHistory, "createdAt") }}</td>
              <td>{{ _.get(orderHistory, "action") }}</td>
              <td>{{ _.get(orderHistory, "user.username") }}</td>
              <td>{{ _.get(orderHistory, "order.customer.username") }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    orderHistories: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clickDetailOrder(orderId) {
      let route = this.$router.resolve({
        name: "OrderDetail",
        params: {
          id: orderId,
        },
        query: {
          orderList: true,
        },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style lang="css" scoped>
.btn-detail {
  font-weight: 500;
  cursor: pointer;
}
</style>