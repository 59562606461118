var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{ref:"validation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[(_vm.isCreate)?[_vm._v(" Thêm mới combo ")]:[_vm._v(" Cập nhật combo ")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v(" mdi-close ")])],2),_c('v-card-text',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:255","name":"Tên combo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên combo *","error-messages":errors,"color":"primary"},model:{value:(_vm.combo.name),callback:function ($$v) {_vm.$set(_vm.combo, "name", $$v)},expression:"combo.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Mã combo","rules":{ required: true, max:255, regex: _vm.regex.isUsername }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mã combo *","error-messages":errors,"color":"primary"},model:{value:(_vm.combo.code),callback:function ($$v) {_vm.$set(_vm.combo, "code", $$v)},expression:"combo.code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Công ty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.sites,"error-messages":errors,"item-text":"name","item-value":"id","label":"Công ty","color":"primary","clearable":""},model:{value:(_vm.combo.siteId),callback:function ($$v) {_vm.$set(_vm.combo, "siteId", $$v)},expression:"combo.siteId"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"group-input mb-4"},[_c('ul',[_c('li',{staticClass:"title"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h3',{staticClass:"text-left"},[_vm._v(" Combo sản phẩm ")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h3',{staticClass:"text-left"},[_vm._v(" Giá sản phẩm ưu đãi từng sản phẩm ")])])],1)],1),_vm._l((_vm.combo.products),function(product,index){return [_c('li',{key:product.id,staticClass:"list-input"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required","name":"Chọn sản phẩm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.getProductOptions(product),"error-messages":errors,"item-text":"name","item-value":"id","label":"Chọn sản phẩm *","color":"primary","readonly":!_vm.updateCombo},on:{"change":function($event){return _vm.selectProduct(product)}},model:{value:(product.productId),callback:function ($$v) {_vm.$set(product, "productId", $$v)},expression:"product.productId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-currency-field',{attrs:{"label":"Giá sản phẩm *","color":"primary","readonly":true},model:{value:(product.price),callback:function ($$v) {_vm.$set(product, "price", $$v)},expression:"product.price"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":("required|numeric|max_value:" + (product.price)),"name":"Giá ưu đãi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":"Giá ưu đãi *","error-messages":errors,"color":"primary"},model:{value:(product.discountPrice),callback:function ($$v) {_vm.$set(product, "discountPrice", $$v)},expression:"product.discountPrice"}})]}}],null,true)})],1)],1),(index>0 && _vm.updateCombo)?_c('v-icon',{staticClass:"group-input-close",on:{"click":function($event){return _vm.deleteRow(product.id)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]})],2),(_vm.updateCombo)?_c('v-btn',{staticClass:"group-input-add m--icon-add",attrs:{"color":"primary"},on:{"click":_vm.addRow}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Thêm lựa chọn ")],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-currency-field',{attrs:{"label":"Tổng giá gốc combo *","value":_vm.totalPrice,"color":"primary","readonly":true}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}}),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-currency-field',{attrs:{"label":"Giá combo ưu đãi *","value":_vm.discountPrice,"color":"primary","readonly":true}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}})],1)],1),_c('v-card-actions',{staticClass:"px-0 group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveCombo)}}},[_vm._v(" Lưu lại ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy bỏ ")])],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }