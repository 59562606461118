var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[(_vm.isCreate)?[_vm._v(" Thêm mới ")]:[_vm._v(" Cập nhật thông tin ")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v(" mdi-close ")])],2),_c('v-card-text',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:255","name":"Tên biến cấu hình"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên biến cấu hình *","error-messages":errors,"color":"primary"},model:{value:(_vm.price.text),callback:function ($$v) {_vm.$set(_vm.price, "text", $$v)},expression:"price.text"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Giá trị","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Giá trị *","error-messages":errors,"color":"primary"},model:{value:(_vm.price.value),callback:function ($$v) {_vm.$set(_vm.price, "value", $$v)},expression:"price.value"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Thứ tự","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Thứ tự *","error-messages":errors,"color":"primary"},model:{value:(_vm.price.displayOrder),callback:function ($$v) {_vm.$set(_vm.price, "displayOrder", $$v)},expression:"price.displayOrder"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Trạng thái","no-data-text":"Không có dữ liệu","items":_vm.configStatusOptions,"item-text":"text","item-value":"value","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)]}}],null,true),model:{value:(_vm.price.status),callback:function ($$v) {_vm.$set(_vm.price, "status", $$v)},expression:"price.status"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveConfig)}}},[_vm._v(" Lưu lại ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy bỏ ")])],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }