<template>
    <div class="support-list">
        <v-row class="ma-0" v-for="(item, index) in requestList" :key="index">
            <v-col cols="12" class="px-2">
                <div>
                    <span><b>{{_.get(item, 'mobile', '')}}</b></span>
                    <span class="float-right">{{formatDate(item.createdAt)}}</span>
                </div>
                <div>
                    <span>
                        <span class="status-order" :style="getStatusStyle(item.status)">
                            {{getStatus(item.status)}}
                        </span>
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-row class="ma-0" v-if="checkEmpty()">
            <v-col cols="12" class="px-2">
                <common-no-result />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TokenService from '@/helpers/token'
import moment from 'moment'
import RepositoryFactory from '../../../repositories/RepositoryFactory'
const SupportRepository = RepositoryFactory.get('support')

export default {
    props: {
        requestList: {
            type: Array,
            default: () => {[]}
        }
    },
    data() {
        return {
            statusList: [],
        } 
    },
    async created() {
        this.getStatusList()
        
    },
    methods: {
        formatDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY H:mm')
            } else {
                return ''
            }
        },

        getStatusStyle(status) {
            let style = 'text-align: center; font-weight: 500; background-color: '

            switch (status) {
                case 'DONE':
                    style += '#67c23a; color: white; padding: 4px';
                    break;
                case 'CANCELLED':
                    style += '#f56c6c; color: white; padding: 4px';
                    break;
                case 'NHANYEUCAU':
                    style += '#409eff; color: white; padding: 4px';
                    break;
                case 'DANGXULY':
                    style += '#e6a23c; color: white; padding: 4px';
                    break;
                case 'PENDING': 
                    style += '#909399; color: white; padding: 4px';
                    break;
                default:
                    style += 'transparent; color: #000000DE';
            }

            return style
        },

        async getStatusList() {
            const loading = this.$loading.show()
            let res = await SupportRepository.statusList()
            if (res.success) {
                this.statusList = _.get(res, 'data', [])
            }
            loading.hide()
        },

        getStatus(status) {
            let index = this.statusList.findIndex(item => item.code == status)

            if (index > -1) {
                return this.statusList[index].requests
            } else {
                return 'Không có trạng thái'
            }
        },

        checkEmpty() {
            return _.isEmpty(this.requestList)
        }
    }
}
</script>

<style scoped>
.support-list {
    height: calc(100vh - 215px);
    overflow-y: auto;
}
</style>