<template>

  <v-card
    class="mx-auto pa-2 overflow-visible"
    outlined
  >
    <v-list-item>
      <v-list-item-content class="overflow-visible">
        <v-list-item-title class="headline mb-2">
          Theo trạng thái contact
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-simple-table class="table-layout border-table mb-10">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="font-weight-regular" rowspan="2" style="border-bottom: thin solid rgba(0, 0, 0, 0.12)"></th>
            <th
              class="font-weight-regular"
              v-for="(type, index) in typeHeaderContactStatus"
              :key="'total-' + index"
            >
              {{type.name}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="font-weight-bold" style="min-width: 10rem;">Số lượng</td>
            <td
              v-for="(item, index) in contactStatusTotal"
              :key="'countItemsTotal-' + index"
            >
              {{formatPrice(item.countItems)}}
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">%</td>
            <td
              v-for="(item, index) in contactStatusTotal"
              :key="'percentageTotal-' + index"
            >
              {{+item.percentage.toFixed(2)}}%
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table class="table-layout border-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th rowspan="2" style="border-bottom: thin solid rgba(0, 0, 0, 0.12)"></th>
            <th
              class="font-weight-regular"
              v-for="(type, index) in typeHeaderContactStatus"
              :key="'type-' + index"
              :colspan="type.colspan"
            >
              {{type.name}}
            </th>
          </tr>
          <tr>
            <th
              style="border-top: thin solid rgba(0, 0, 0, 0.12);"
              v-for="(item, index) in contactStatusList"
              :key="'name-type-' + index"
            >
              {{item.name}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="font-weight-bold" style="min-width: 10rem;">Số lượng</td>
            <td
              v-for="(item, index) in contactStatusList"
              :key="'countItems-' + index"
            >
              {{formatPrice(item.countItems)}}
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">%</td>
            <td
              v-for="(item, index) in contactStatusList"
              :key="'percentage-' + index"
            >
              {{+item.percentage}}%
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
export default {
  props: {
    typeHeaderContactStatus: {
      type: Array,
      default: () => [],
    },
    contactStatusList: {
      type: Array,
      default: () => [],
    },
    contactStatusTotal: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  table {
    thead {
      tr:first-child th {
        background-color: #03657a;
      }
      tr:last-child th {
        background-color: #19848e;
        font-weight: 300!important;
        padding: 5px;
      }
      tr th {
        color: #ffffff!important;
      }
    }
    
  }
</style>