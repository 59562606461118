<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
    :retain-focus="false"
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Tạo thông báo </template>
          <template v-else> Cập nhật thông báo </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <validation-provider class="mb-2" name="Icon">
            <v-select
              v-model="notification.iconId"
              label="Loại thông báo"
              color="primary"
              :items="icons"
              item-text="title"
              item-value="id"
            />
          </validation-provider>

          <validation-provider
            v-if="isCreate"
            v-slot="{ errors }"
            name="Nhóm khách hàng"
          >
            <v-autocomplete
              v-model="groupCustomers"
              :items="listGroupCustomer"
              :error-messages="errors"
              item-text="departmentName"
              item-value="departmentId"
              label="Nhóm khách hàng"
              color="primary"
              multiple
              no-data-text="Không có dữ liệu"
              @change="changeGroupCustomer()"
            >
              <template #prepend-item>
                <common-select-all
                  v-model="groupCustomers"
                  :item="groupCustomers"
                  :items="listGroupCustomer.map((s) => s.departmentId)"
                  @change="changeGroupCustomer()"
                />
              </template>
            </v-autocomplete>
          </validation-provider>

          <validation-provider
            v-if="isCreate"
            v-slot="{ errors }"
            rules="required"
            name="Khách hàng *"
          >
            <v-autocomplete
              v-model="notification.listCustomer"
              :items="listCustomerForSelect"
              :error-messages="errors"
              item-text="username"
              item-value="id"
              label="Khách hàng *"
              color="primary"
              multiple
              no-data-text="Không có dữ liệu"
            >
              <template #prepend-item>
                <common-select-all
                  v-model="notification.listCustomer"
                  :item="notification.listCustomer"
                  :items="listCustomerForSelect.map((s) => s.id)"
                  @change="changeGroupCustomer()"
                />
              </template>
            </v-autocomplete>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Tiêu đề"
          >
            <v-text-field
              v-model="notification.title"
              label="Tiêu đề *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Liên kết"
          >
            <v-text-field
              v-model="notification.url"
              label="Liên kết *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            name="Mô tả"
            rules="max: 255"
          >
            <v-textarea
              v-model="notification.description"
              label="Mô tả"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <span
            class="title-span-content text-left"
            style="display: inline-block; width: 100%"
            >Nội dung *</span
          >
          <validation-provider
            v-slot="{ errors }"
            name="Nội dung"
            rules="required"
          >
            <template>
              <vue-editor
                v-model="notification.content"
                :editor-toolbar="customToolbar"
              />
            </template>
            <span
              v-if="errors[0]"
              class="errors-span-required-content"
              color="primary"
              >* {{ errors[0] }}</span
            >
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn
            color="success"
            @click.prevent="handleSubmit(saveNotification)"
          >
            Lưu lại
          </v-btn>
          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  notificationStatusOptions,
  PAGE_LIMIT,
} from "../../../helpers/constants";
import { VueEditor } from "vue2-editor";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { filterItemValidArray } from "../../../helpers/utils";

const NotificationRepository = RepositoryFactory.get("notification");
const IconRepository = RepositoryFactory.get("icon");
const CustomerRepository = RepositoryFactory.get("customer");

export default {
  components: { VueEditor },
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      notification: {},
      isCreate: true,
      errorMessage: "",
      statusOptions: notificationStatusOptions,
      inputFile: [],
      icons: [],
      customers: [],
      listCustomer: [],
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        ["image", "code-block"],
      ],
      groupCustomers: [],
      listGroupCustomer: [],
      listCustomerForSelect: [],
    };
  },
  async created() {
    this.getIconList();
    // this.getListCustomer();
    this.listCustomerGroup();
    Bus.$on("notification-customer-show-form", (notification) => {
      this.showModal = true;
      this.notification = _.cloneDeep(notification);
      if (notification.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  computed: {},
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveNotification() {
      const notification = {
        title: _.trim(this.notification.title),
        url: _.trim(this.notification.url),
        description: _.trim(this.notification.description),
        content: _.trim(this.notification.content),
        iconId: this.notification.iconId,
        listCustomer: filterItemValidArray(this.notification.listCustomer),
        customerId: this.notification.customerId,
      };
      const loader = this.$loading.show();
      let response = {};
      if (this.isCreate) {
        response = await NotificationRepository.create(notification);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("notification-customer-reload");
          });
        }
      } else {
        response = await NotificationRepository.update(
          this.notification.id,
          notification
        );
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("notification-customer-reload");
          });
        }
      }
    },
    async getIconList() {
      this.loading = true;
      const loader = this.$loading.show();
      const response = await IconRepository.list({
        limit: PAGE_LIMIT,
      });
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.icons = _.get(response, "data.list_icon", []);
      }
    },

    // async getListCustomer() {
    //   let res = await CustomerRepository.list({
    //     limit: PAGE_LIMIT,
    //   });
    //   if (res.success) {
    //     this.customers = _.get(res, "data", []);
    //   }
    // },

    async listCustomerGroup() {
      let res = await CustomerRepository.listCustomerGroup();
      if (res.success) {
        let customerList = [];
        _.get(res, "data.list_department", []).forEach(
          (groupCustomer, index) => {
            this.listGroupCustomer.push(groupCustomer);
            const { listCustomer = [] } = groupCustomer;
            customerList.push({
              header: groupCustomer.departmentName,
              name: groupCustomer.departmentName,
              departmentId: groupCustomer.departmentId,
            });
            listCustomer.forEach((cus) => {
              customerList.push({
                ...cus,
                departmentId: groupCustomer.departmentId,
              });
            });
          }
        );
        this.customers = _.cloneDeep(customerList);
        this.listCustomerForSelect = _.cloneDeep(customerList);
      }
    },

    changeGroupCustomer() {
      this.listCustomerForSelect = this.customers.filter((item) =>
        this.groupCustomers.includes(item.departmentId)
      );
    },
  },
};
</script>
