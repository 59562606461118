<template>
    <div>
        <v-simple-table class="table-layout pa-5">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Nhân viên</th>
                        <th>Kho phụ trách</th>
                        <th>Hạn mức riêng</th>
                        <th>Số contact thực tế nhận</th>
                        <th>Quản lý phân bổ</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(user) in users">
                        <tr
                            :key="user.id"
                        >
                            <td> {{user.fullName}} </td>
                            <td @dblclick="beforeEdit(user, 'store')"> 
                                <div v-if="user.showEdit.store">
                                    <v-autocomplete
                                        v-model="user.store"
                                        :items="stores"
                                        item-text="name"
                                        item-value="value"
                                        @change="updateUser(user)"
                                    />
                                </div>
                                <div v-else>
                                    {{user.store | storeFilter}}
                                </div>
                            </td>
                            <td @dblclick="beforeEdit(user, 'number_of_contact')"> 
                                <div v-if="user.showEdit.number_of_contact">
                                     <v-text-field
                                        v-model="user.number_of_contact"
                                        color="primary"
                                        type="number"
                                        min="0"
                                        step="1"
                                        @change="updateUser(user)"
                                    />
                                </div>
                                <div v-else>
                                    {{user.number_of_contact}}
                                </div> 
                            </td>
                            <td>
                                {{user.real_contacts}}
                            </td>
                            <td>
                                <div style="width: 85px; margin: 0 auto">
                                    <v-switch
                                        v-model="user.assignContact"
                                        inset
                                        @change="updateUser(user)"
                                    /> 
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    props: {
        users: {
            type: Array,
            default: () => {[]}
        },
        dataForm: {
            type: Object,
            default: () => {{}}
        }
    },
    filters: {
        storeFilter(value) {
            if (value == 'T') {
                return 'Kho T'
            } else {
                return 'Kho C'
            }
        }
    },
    data() {
        return {
            stores: [
                {
                    name: 'Kho T',
                    value: 'T'
                },
                {
                    name: 'Kho C',
                    value: 'C'
                }
            ],
        }
    },
    methods: {
        updateUser(user) {
            Bus.$emit('update-user-setting-contact', user)
        },
        beforeEdit(user, param) {
            user.showEdit[param] = !user.showEdit[param]
        }
    }
}
</script>

<style scoped>
    /deep/.v-input--switch--inset .v-input--switch__thumb {
        transform: translate(0px, 0px) !important;
    }
    /deep/.v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
        transform: translate(50px, 0px) !important;
    }
    /deep/.v-input--switch--inset .v-input--selection-controls__ripple {
        transform: translate(0px, 0px) !important;
    }
    /deep/.v-input--switch--inset.v-input--is-dirty .v-input--selection-controls__ripple {
        transform: translate(50px, 0px) !important;
    }
    /deep/.v-input--switch--inset .v-input--switch__track {
        width: 80px !important;
    }
</style>