var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"edit-product","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"mx-auto pa-5",attrs:{"outlined":""}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.update)}}},[_c('h3',{staticStyle:{"padding-bottom":"35px"}},[_vm._v("Thông chương trình khuyến mãi")]),(_vm.errorMessage)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Tên chương trình khuyến mãi","rules":"required|max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên *","error-messages":errors,"color":"primary"},on:{"change":function($event){return _vm.convertToSlug()}},model:{value:(_vm.details.title),callback:function ($$v) {_vm.$set(_vm.details, "title", $$v)},expression:"details.title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Đường dẫn","rules":{
                required: true,
                regex: _vm.regex.isSlug,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Đường dẫn *","error-messages":errors,"color":"primary"},model:{value:(_vm.details.slug),callback:function ($$v) {_vm.$set(_vm.details, "slug", $$v)},expression:"details.slug"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Alt","rules":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Thẻ alt","error-messages":errors,"color":"primary"},model:{value:(_vm.details.alt),callback:function ($$v) {_vm.$set(_vm.details, "alt", $$v)},expression:"details.alt"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nhà tài trợ","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nhà tài trợ","error-messages":errors,"color":"primary"},model:{value:(_vm.details.sponsor),callback:function ($$v) {_vm.$set(_vm.details, "sponsor", $$v)},expression:"details.sponsor"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Alt","rules":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Liên kết","error-messages":errors,"color":"primary"},model:{value:(_vm.details.link),callback:function ($$v) {_vm.$set(_vm.details, "link", $$v)},expression:"details.link"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Alt","rules":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mô tả","error-messages":errors,"color":"primary"},model:{value:(_vm.details.description),callback:function ($$v) {_vm.$set(_vm.details, "description", $$v)},expression:"details.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Thời gian ngày bắt đầu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"max-width":"300","close-on-content-click":false,"nudge-right":30,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bắt đầu từ","readonly":""},model:{value:(_vm.start.startTime),callback:function ($$v) {_vm.$set(_vm.start, "startTime", $$v)},expression:"start.startTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.start.menuStartTime),callback:function ($$v) {_vm.$set(_vm.start, "menuStartTime", $$v)},expression:"start.menuStartTime"}},[(_vm.start.menuStartTime)?_c('v-time-picker',{attrs:{"full-width":"","error-messages":errors},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.start.startTime)}},model:{value:(_vm.start.startTime),callback:function ($$v) {_vm.$set(_vm.start, "startTime", $$v)},expression:"start.startTime"}}):_vm._e()],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Ngày bắt đầu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"style-datePicker"},[_c('label',{staticClass:"style-datePicker_title"},[_vm._v("Ngày bắt đầu")]),_c('date-picker',{attrs:{"format":"YYYY-MM-DD","type":"date","placeholder":"Chọn ngày","error-messages":errors},model:{value:(_vm.start.startDate),callback:function ($$v) {_vm.$set(_vm.start, "startDate", $$v)},expression:"start.startDate"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Thời gian ngày kết thúc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"max-width":"300","close-on-content-click":false,"nudge-right":30,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Kết thúc vào","readonly":""},model:{value:(_vm.end.endTime),callback:function ($$v) {_vm.$set(_vm.end, "endTime", $$v)},expression:"end.endTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.end.menuEndTime),callback:function ($$v) {_vm.$set(_vm.end, "menuEndTime", $$v)},expression:"end.menuEndTime"}},[(_vm.end.menuEndTime)?_c('v-time-picker',{attrs:{"full-width":"","error-messages":errors},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.end.endTime)}},model:{value:(_vm.end.endTime),callback:function ($$v) {_vm.$set(_vm.end, "endTime", $$v)},expression:"end.endTime"}}):_vm._e()],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Ngày kết thúc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"style-datePicker"},[_c('label',{staticClass:"style-datePicker_title"},[_vm._v("Ngày kết thúc")]),_c('date-picker',{attrs:{"format":"YYYY-MM-DD","type":"date","placeholder":"Chọn ngày","error-messages":errors},model:{value:(_vm.end.endDate),callback:function ($$v) {_vm.$set(_vm.end, "endDate", $$v)},expression:"end.endDate"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Sản phẩm *"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.products,"error-messages":errors,"item-text":"name","item-value":"id","label":"Sản phẩm *","color":"primary","multiple":"","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('common-select-all',{attrs:{"item":_vm.details.products,"items":_vm.products.map(function (s) { return s.id; })},model:{value:(_vm.details.products),callback:function ($$v) {_vm.$set(_vm.details, "products", $$v)},expression:"details.products"}})]},proxy:true}],null,true),model:{value:(_vm.details.products),callback:function ($$v) {_vm.$set(_vm.details, "products", $$v)},expression:"details.products"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Nhóm khách hàng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listGroupCustomer,"error-messages":errors,"item-text":"departmentName","item-value":"departmentId","label":"Nhóm khách hàng","color":"primary","multiple":"","no-data-text":"Không có dữ liệu"},on:{"change":function($event){return _vm.changeGroupCustomer()}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('common-select-all',{attrs:{"item":_vm.details.groupCustomers,"items":_vm.listGroupCustomer.map(function (s) { return s.departmentId; })},on:{"change":function($event){return _vm.changeGroupCustomer()}},model:{value:(_vm.details.groupCustomers),callback:function ($$v) {_vm.$set(_vm.details, "groupCustomers", $$v)},expression:"details.groupCustomers"}})]},proxy:true}],null,true),model:{value:(_vm.details.groupCustomers),callback:function ($$v) {_vm.$set(_vm.details, "groupCustomers", $$v)},expression:"details.groupCustomers"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Khách hàng *"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listCustomerForSelect,"error-messages":errors,"item-text":"username","item-value":"id","label":"Khách hàng *","color":"primary","multiple":"","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('common-select-all',{attrs:{"item":_vm.details.customers,"items":_vm.listCustomerForSelect.map(function (s) { return s.id; })},model:{value:(_vm.details.customers),callback:function ($$v) {_vm.$set(_vm.details, "customers", $$v)},expression:"details.customers"}})]},proxy:true}],null,true),model:{value:(_vm.details.customers),callback:function ($$v) {_vm.$set(_vm.details, "customers", $$v)},expression:"details.customers"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12","align":"center"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"image/*","label":"Hình ảnh quảng cáo","prepend-icon":"mdi-paperclip","placeholder":"Ảnh","rules":_vm.ruleImg},on:{"change":_vm.changeFiles},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.details.files),callback:function ($$v) {_vm.$set(_vm.details, "files", $$v)},expression:"details.files"}}),(_vm.details.imageId)?_c('div',{staticClass:"display-image-block"},[_c('div',{staticClass:"delete-image-block"},[_c('v-icon',{attrs:{"color":"white","title":"Xóa ảnh"},on:{"click":function($event){return _vm.deleteImage()}}},[_vm._v(" mdi-delete ")])],1),_c('v-img',{attrs:{"src":_vm._.get(_vm.details, 'image.filePath', '')}})],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-actions',{staticClass:"px-0 group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.update)}}},[_vm._v(" "+_vm._s(_vm.isNew ? "Tạo" : "Cập nhật")+" ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary"},on:{"click":_vm.goToList}},[_vm._v(" Hủy bỏ ")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }