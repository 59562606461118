<template>
  <v-card class="my-0">
    <v-simple-table fixed-header class="table-layout">
      <template v-slot:default>
        <thead>
          <!-- <tr class="text-left">
            <td colspan="1000" class="">
              <h3 class="">SỔ CHI TIẾT BÁN HÀNG</h3>
            </td>
          </tr> -->
          <tr>
            <th>Ngày hạch toán</th>
            <th>Mã đơn hàng</th>
            <th>Tên khách hàng</th>
            <th>Phân nhóm khách hàng</th>
            <th>Địa chỉ</th>
            <th>Tên sản phẩm</th>
            <th>Đơn vị tính</th>
            <th>Tổng số lượng bán</th>
            <th>Đơn giá</th>
            <th>Doanh số bán</th>
            <th>Chiết khấu</th>
            <th>Phần trăm khi thanh toán ngay</th>
            <th>Tổng thanh toán</th>
            <th>Trạng thái đơn hàng</th>
            <th>Tên nhân viên bán hàng</th>
          </tr>
        </thead>
        <tbody v-if="_.isArray(salesData) && salesData.length">
          <template v-for="(item, index) in salesData">
            <tr :key="index">
              <td>{{ item.createdAt }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.customerName }}</td>
              <td>{{ item.departmentName }}</td>
              <td>{{ item.address }}</td>
              <td>{{ _.get(item, "product.name", "") }}</td>
              <td>{{ _.get(item, "product.productUnit.name", "") }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ formatPrice(item.oldPrice) }}</td>
              <td>{{ formatPrice(item.totalPrice) }}</td>
              <td>{{ formatPrice(item.percentDiscount || 0) }}</td>
              <td>{{ item.percentOnInstantPayment || 0 }}</td>
              <td>
                {{
                  formatPrice(
                    item.totalPrice -
                      (item.totalPrice * item.percentDiscount) / 100 -
                      ((item.totalPrice -
                        (item.totalPrice * item.percentDiscount) / 100) *
                        item.percentOnInstantPayment) /
                        100
                  )
                }}
              </td>
              <td>{{ item.status }}</td>
              <td>{{ item.managerName }}</td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100">
              <common-no-result />
            </td>
          </tr>
        </tbody>
        <tfoot style="position: sticky; bottom: 0">
          <tr class="foot-line">
            <td>Ngày hạch toán</td>
            <td>Mã đơn hàng</td>
            <td>Tên khách hàng</td>
            <td>Phân nhóm khách hàng</td>
            <td>Địa chỉ</td>
            <td>Tên sản phẩm</td>
            <td>Đơn vị tính</td>
            <td>Tổng số lượng bán</td>
            <td>Đơn giá</td>
            <td>Doanh số bán</td>
            <td>Chiết khấu</td>
            <td>Phần trăm khi thanh toán ngay</td>
            <td>Tổng thanh toán</td>
            <td>Trạng thái đơn hàng</td>
            <td>Tên nhân viên bán hàng</td>
          </tr>
          <tr>
            <td>
              <h3 class="text-center">TỔNG CỘNG:</h3>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <h3 class="text-center">{{ totalQuantity }}</h3>
            </td>
            <td></td>
            <td>
              <h3 class="text-center">{{ formatPrice(totalSales) }}</h3>
            </td>
            <td></td>
            <td></td>
            <td>
              <h3 class="text-center">{{ formatPrice(totalPayment) }}</h3>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  props: {
    salesData: {
      type: Array,
      default: () => {
        [];
      },
    },
    totalQuantity: {
      type: Number,
      default: 0,
    },
    totalSales: {
      type: Number,
      default: 0,
    },
    totalPercentDiscount: {
      type: Number,
      default: 0,
    },
    totalPayment: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.foot-line td {
  border-top: 2.5px solid rgb(0, 0, 0);
}
</style>
