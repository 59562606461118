<template>
  <div>
    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên khách hàng</th>
            <th>Số điện thoại</th>
            <th>Email</th>
            <th>Nội dung</th>
            <th>Ghi chú</th>
            <th>Trạng thái</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in contactWorks">
            <tr :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(item, "name") }}</td>
              <td>{{ _.get(item, "phone") }}</td>
              <td>{{ _.get(item, "email") }}</td>
              <td>{{ _.get(item, "content") }}</td>
              <td>{{ _.get(item, "note") }}</td>
              <td>{{ getTextStatus(_.get(item, "status", null)) }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('contact_work_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editContactWork(item)"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật liên hệ</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('contact_work_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(item)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa liên hệ</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <contact-work-form />
  </div>
</template>
<script>
import ContactWorkForm from "./ContactWorkForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const ContactWorkRepository = RepositoryFactory.get("contact_work");
import { contactWorkStatusOptions } from "../../../helpers/constants";
export default {
  components: { ContactWorkForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    contactWorks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contactWork: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("contact_work_update") ||
        this.checkAuthorization("contact_work_delete")
      );
    },
  },
  methods: {
    editContactWork(item) {
      Bus.$emit("contact-us-show-form", item);
    },
    async onClickDelete(item) {
      this.$swal({
        title: "Xóa liên hệ",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await ContactWorkRepository.delete(item.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("contact-us-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
    getTextStatus(status) {
      const option = contactWorkStatusOptions.find((o) => o.value == status);
      return option ? option.text : "";
    },
  },
};
</script>
