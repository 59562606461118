<template>
  <v-expansion-panels class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0" outlined>
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="filterForm.keyword"
                    label="Tìm kiếm theo tiêu đề, mô tả, nội dung thông báo..."
                    clearable
                    @keyup.enter="searchNotification"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.listUser"
                    :items="users"
                    item-text="username"
                    item-value="id"
                    label="Tìm kiếm theo thành viên"
                    color="primary"
                    clearable
                    multiple
                  />
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn class="primary mx-0" @click="searchNotification">
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { notificationStatusOptions } from "../../../helpers/constants";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user");

export default {
  name: "FilterForm",
  props: {
    filterForm: {
      default: () => ({}),
    },
  },
  data() {
    return {
      notificationStatusOptions,
      users: [],
    };
  },
  created() {
    this.getListUser();
  },
  methods: {
    searchNotification() {
      Bus.$emit("notification-list-filter", this.filterForm);
    },
    async getListUser() {
      let res = await UserRepository.list();
      if (res.success) {
        this.users = _.get(res, "data", []);
      }
    },
  },
};
</script>
