<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('product_treatment_create')"
        class="primary mx-0"
        @click="addNewTreatment"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên nhóm hỗ trợ điều trị</th>
            <th>Đường dẫn</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(treatment, index) in treatments">
            <tr :key="treatment.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(treatment, "name") }}</td>
              <td>{{ _.get(treatment, "slug") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('product_treatment_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editTreatment(treatment)"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin nhóm hỗ trợ điều trị</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('product_treatment_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(treatment)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa nhóm hỗ trợ điều trị</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <treatment-form />
  </div>
</template>
<script>
import TreatmentForm from "./TreatmentForm.vue";
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const TreatmentRepository = RepositoryFactory.get("treatment");
export default {
  components: { TreatmentForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    treatments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTreatment: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("product_treatment_update") ||
        this.checkAuthorization("product_treatment_delete")
      );
    },
  },
  methods: {
    addNewTreatment() {
      this.selectedTreatment = {};
      Bus.$emit("treatment-show-form", this.selectedTreatment);
    },
    editTreatment(treatment) {
      this.selectedTreatment = treatment;
      Bus.$emit("treatment-show-form", this.selectedTreatment);
    },
    async onClickDelete(treatment) {
      this.$swal({
        title: "Xóa nhóm hỗ trợ điều trị",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await TreatmentRepository.delete(treatment.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("treatment-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
