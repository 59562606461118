var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-text',{staticClass:"px-6",style:(_vm.mobileStyle)},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('validation-provider',{staticClass:"mb-3",attrs:{"rules":{ regex: _vm.regex.isVNPhoneMobile, required: true },"name":"SĐT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Số điện thoại *","error-messages":errors,"color":"primary","placeholder":"Nhập số điện thoại...","clearable":""},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}},[_c('template',{slot:"label"},[_c('div',[_vm._v("Số điện thoại "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])])],2)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('validation-provider',{staticClass:"mb-3",attrs:{"name":"Tùy chọn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"attach":"","items":_vm.requestActions,"error-messages":errors,"label":"Tùy chọn hỗ trợ *","color":"primary","placeholder":"Chọn tùy chọn hỗ trợ...","item-text":"requests","item-value":"code","clearable":""},model:{value:(_vm.request),callback:function ($$v) {_vm.request=$$v},expression:"request"}},[_c('template',{slot:"label"},[_c('div',[_vm._v("Ticket thường")])])],2)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('validation-provider',{staticClass:"mb-3",attrs:{"name":"Tùy chọn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"attach":"","items":_vm.urgentList,"error-messages":errors,"label":"Tùy chọn hỗ trợ (gấp)","color":"primary","placeholder":"Chọn tùy chọn hỗ trợ...","clearable":""},model:{value:(_vm.urgentRequest),callback:function ($$v) {_vm.urgentRequest=$$v},expression:"urgentRequest"}},[_c('template',{slot:"label"},[_c('div',[_vm._v("Ticket gấp")])])],2)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":{ required: true },"name":"Chi tiết"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Chi tiết yêu cầu *","color":"primary","placeholder":"Nhập chi tiết yêu cầu...","rows":"5","auto-grow":"","clearable":""},model:{value:(_vm.descriptionRequest),callback:function ($$v) {_vm.descriptionRequest=$$v},expression:"descriptionRequest"}},[_c('template',{slot:"label"},[_c('div',[_vm._v("Chi tiết yêu cầu "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])])],2)]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"group-btn--form create-support-btn-container"},[_c('v-btn',{staticClass:"create-support-btn",attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.createRequest)}}},[_vm._v("Gửi")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }