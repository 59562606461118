var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{ref:"validation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[[_vm._v(" Cài đặt chiết khấu ")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v(" mdi-close ")])],2),_c('v-card-text',{staticClass:"px-6",staticStyle:{"padding-top":"30px"}},[_c('div',{staticClass:"group-input mb-4"},[_c('ul',[_c('li',{staticClass:"title"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"text-left"},[_vm._v("Sản phẩm")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h3',{staticClass:"text-left"},[_vm._v("Giá gốc")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h3',{staticClass:"text-left"},[_vm._v("Phần trăm chiết khấu")])])],1)],1),_vm._l((_vm.discount.products),function(product,index){return [_c('li',{key:product.id,staticClass:"list-input"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required","name":"Chọn sản phẩm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.getProductOptions(product),"error-messages":errors,"item-text":"name","item-value":"id","label":"Chọn sản phẩm *","color":"primary"},on:{"change":function($event){return _vm.selectProduct(product)}},model:{value:(product.productId),callback:function ($$v) {_vm.$set(product, "productId", $$v)},expression:"product.productId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-currency-field',{attrs:{"label":"Giá sản phẩm *","color":"primary","readonly":true},model:{value:(product.cost),callback:function ($$v) {_vm.$set(product, "cost", $$v)},expression:"product.cost"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|numeric|max_value:100","name":"Phần trăm chiết khấu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":"Phần trăm chiết khấu *","error-messages":errors,"color":"primary"},model:{value:(product.percentDiscount),callback:function ($$v) {_vm.$set(product, "percentDiscount", $$v)},expression:"product.percentDiscount"}})]}}],null,true)})],1)],1),(index > 0)?_c('v-icon',{staticClass:"group-input-close",on:{"click":function($event){return _vm.deleteRow(product.id)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]})],2),_c('v-btn',{staticClass:"group-input-add m--icon-add",attrs:{"color":"primary"},on:{"click":_vm.addRow}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Thêm cấu hình ")],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Nhóm khách hàng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listGroupCustomer,"error-messages":errors,"item-text":"departmentName","item-value":"departmentId","label":"Nhóm khách hàng","color":"primary","multiple":"","no-data-text":"Không có dữ liệu"},on:{"change":function($event){return _vm.changeGroupCustomer()}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('common-select-all',{attrs:{"item":_vm.groupCustomers,"items":_vm.listGroupCustomer.map(function (s) { return s.departmentId; })},on:{"change":function($event){return _vm.changeGroupCustomer()}},model:{value:(_vm.groupCustomers),callback:function ($$v) {_vm.groupCustomers=$$v},expression:"groupCustomers"}})]},proxy:true}],null,true),model:{value:(_vm.groupCustomers),callback:function ($$v) {_vm.groupCustomers=$$v},expression:"groupCustomers"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Khách hàng *"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listCustomerForSelect,"error-messages":errors,"item-text":"username","item-value":"id","label":"Khách hàng *","color":"primary","multiple":"","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('common-select-all',{attrs:{"item":_vm.listCustomer,"items":_vm.listCustomerForSelect.map(function (s) { return s.id; })},on:{"change":function($event){return _vm.changeGroupCustomer()}},model:{value:(_vm.listCustomer),callback:function ($$v) {_vm.listCustomer=$$v},expression:"listCustomer"}})]},proxy:true}],null,true),model:{value:(_vm.listCustomer),callback:function ($$v) {_vm.listCustomer=$$v},expression:"listCustomer"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Thời gian ngày bắt đầu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":30,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bắt đầu từ","readonly":""},model:{value:(_vm.start.startTime),callback:function ($$v) {_vm.$set(_vm.start, "startTime", $$v)},expression:"start.startTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.start.menuStartTime),callback:function ($$v) {_vm.$set(_vm.start, "menuStartTime", $$v)},expression:"start.menuStartTime"}},[(_vm.start.menuStartTime)?_c('v-time-picker',{attrs:{"full-width":"","error-messages":errors},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.start.startTime)}},model:{value:(_vm.start.startTime),callback:function ($$v) {_vm.$set(_vm.start, "startTime", $$v)},expression:"start.startTime"}}):_vm._e()],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Ngày bắt đầu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"style-datePicker"},[_c('label',{staticClass:"style-datePicker_title"},[_vm._v("Ngày bắt đầu")]),_c('date-picker',{attrs:{"format":"YYYY-MM-DD","type":"date","placeholder":"Chọn ngày","error-messages":errors},model:{value:(_vm.start.startDate),callback:function ($$v) {_vm.$set(_vm.start, "startDate", $$v)},expression:"start.startDate"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Thời gian ngày kết thúc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":30,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Kết thúc vào","readonly":""},model:{value:(_vm.end.endTime),callback:function ($$v) {_vm.$set(_vm.end, "endTime", $$v)},expression:"end.endTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.end.menuEndTime),callback:function ($$v) {_vm.$set(_vm.end, "menuEndTime", $$v)},expression:"end.menuEndTime"}},[(_vm.end.menuEndTime)?_c('v-time-picker',{attrs:{"full-width":"","error-messages":errors},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.end.endTime)}},model:{value:(_vm.end.endTime),callback:function ($$v) {_vm.$set(_vm.end, "endTime", $$v)},expression:"end.endTime"}}):_vm._e()],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Ngày kết thúc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"style-datePicker"},[_c('label',{staticClass:"style-datePicker_title"},[_vm._v("Ngày kết thúc")]),_c('date-picker',{attrs:{"format":"YYYY-MM-DD","type":"date","placeholder":"Chọn ngày","error-messages":errors},model:{value:(_vm.end.endDate),callback:function ($$v) {_vm.$set(_vm.end, "endDate", $$v)},expression:"end.endDate"}})],1)]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveDiscount)}}},[_vm._v(" Thêm mới ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy bỏ ")])],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }