<template>
  <v-simple-table class="table-layout pa-5">
        <thead>
          <tr>
            <th>STT</th>
            <th>Tài khoản</th>
            <th>Mật khẩu</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(account, index) in orderAccounts" :key="account.id">
            <td>{{index + 1}}</td>
            <td>{{account.username}}</td>
            <td>{{account.password}}</td>
            <td>{{account.email}}</td>
          </tr>
        </tbody>
  </v-simple-table>
</template>
<script>
export default {
  props: {
    orderAccounts: {
      type: Array,
      default: () => []
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>

</style>