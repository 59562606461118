<template>
  <v-container id="contact-detail" fluid tag="section">
    <base-material-card
      v-if="loadOrderDetailSuccess"
      outlined
      icon="mdi-cash-multiple"
      class="overflow-visible pb-7"
    >
      <template v-slot:after-heading>
        <div class="header mobile-hidden">
          <div class="font-weight-light card-title mt-2">
            Thông tin đơn hàng
          </div>
        </div>
      </template>
      <span @click="clickCancel" class="mobile-show span-icon-custom"
        ><v-icon>mdi-arrow-left</v-icon></span
      >
      <!-- <div class="mobile-show header-mobile">
        <span class="font-weight-light card-title mt-2 span-custom"
          >Tạo đơn hàng</span
        >
      </div> -->
      <validation-observer>
        <div style="position: relative" class="mobile-hidden">
          <div class="header-group-btn">
            <v-btn
              color="primary"
              class="mb-2"
              @click="confirmPayment()"
              v-if="
                checkAuthorization('order-payment_read') &&
                (contactStatus.type == 'T4' || contactStatus.type == 'C4')
              "
            >
              Xác nhận thanh toán
            </v-btn>

            <!-- <v-btn
              color="primary"
              class="mb-2"
              @click="showAccount()"
              v-if="
                checkAuthorization('order-contact_read') &&
                (contactStatus.type == 'T5' || contactStatus.type == 'C5')
              "
            >
              Xem tài khoản
            </v-btn> -->

            <!-- <v-btn
              v-if="
                checkAuthorization('contact_read') &&
                checkAuthorization('order_update')
              "
              color="warning"
              class="mb-2"
              @click="clickDetailContact"
            >
              Chi tiết khách hàng
            </v-btn> -->

            <v-btn
              v-if="checkAuthorization('order_update')"
              color="info"
              class="mb-2"
              @click="clickUpdateOrder"
            >
              Sửa đơn
            </v-btn>

            <v-btn
              v-if="checkAuthorization('order_delete')"
              color="secondary"
              class="mb-2"
              @click="clickCancelOrder"
            >
              Hủy đơn
            </v-btn>

            <v-btn color="success" class="mb-2" @click="clickCancel"
              >Thoát</v-btn
            >
          </div>
        </div>
        <v-row>
          <v-col cols="12" sm="6">
            <order-card
              :contactStatus="contactStatus"
              :shippingAddress="shippingAddress"
              :contentOrder="contentOrder"
              :products="products"
              :stepOrder="stepOrder"
              :isReadOnly="isReadOnly"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <address-card
              :shippingAddress="shippingAddress"
              :provinces="provinces"
              :contentOrder="contentOrder"
              :isReadOnly="isReadOnly"
            />
          </v-col>
        </v-row>
      </validation-observer>
      <!-- <common-voice-call :mobile="mobile" v-if="mobile ? true : false" /> -->
    </base-material-card>
    <v-dialog v-model="showModalCall" max-width="500" persistent></v-dialog>
  </v-container>
</template>

<script>
import {
  orderStatus,
  // contactStatus,
  idsOrderContactStatusFinish,
} from "../../helpers/constants";
import AddressCard from "./components/AddressCard";
import OrderCard from "./components/OrderCard";
import RepositoryFactory from "../../repositories/RepositoryFactory";
const AddressRepository = RepositoryFactory.get("address");
const OrderRepository = RepositoryFactory.get("order");

export default {
  components: { AddressCard, OrderCard },
  data() {
    return {
      products: [],
      mainCustomer: {},
      stepOrder: 2,
      provinces: [],
      contactStatus: {},
      orderStatus,
      shippingAddress: {
        wardName: "",
        provinceName: "",
        districtName: "",
        receiver: "",
        phone: "",
        email: "",
        provinceId: 0,
        wardId: 0,
        districtId: 0,
        detailAddress: "",
      },
      contentOrder: {
        paidAmount: 0,
        code: "",
        products: [],
        description: "",
        note: "",
        shift: "",
        statusId: "",
        paymentType: "",
        isDetail: true,
        totalPrice: 0,
        isVatInvoice: 0,
        storeName: "",
        storeTax: "",
        storeAddress: "",
      },
      idsOrderContactStatusFinish: idsOrderContactStatusFinish,
      loadOrderDetailSuccess: false,
      loadContactDetailSuccess: false,
      showModalCall: false,
      mobile: "",
      dataUpdate: {},
      dataDialog: {},
      isReadOnly: false,
      updateDialog: false,
      allowUpdate: false,
      checkEndDate: true,
    };
  },
  async created() {
    // if (this.idsOrderContactStatusFinish.includes(this.contactStatus.id)) {
    //   this.stepOrder = 3;
    // }
    // if (this.$route.query.mobile) {
    //   this.isReadOnly = true;
    //   if (typeof this.$route.query.allowUpdate == "boolean") {
    //     this.allowUpdate = this.$route.query.allowUpdate;
    //   } else {
    //     this.allowUpdate =
    //       this.$route.query.allowUpdate == "true" ? true : false;
    //   }
    // } else {
    //   this.isReadOnly = !this.checkAuthorization("order_update");
    // }

    this.isReadOnly = !this.checkAuthorization("order_update");

    await this.getProductList();
    await this.getOrderDetail();
    await this.getProvinces();

    // Bus.$on("close-voice-call-form", () => {
    //   this.showModalCall = false;
    //   this.mobile = "";
    //   this.appointmentId = 0;
    // });
  },
  beforeDestroy() {},
  methods: {
    // clickDetailContact() {
    //   this.$router.push({
    //     name: "ContactDetail",
    //     params: {
    //       id: this.contactId || 0,
    //     },
    //   });
    // },

    async clickCancelOrder() {
      this.$swal({
        title: "Hủy đơn hàng",
        text: "Bạn có thực sự muốn hủy đơn hàng ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (!result.value) {
          return;
        }
        const loader = this.$loading.show();
        const respond = await OrderRepository.changeStatus(
          this.$route.params.id,
          { status: orderStatus.CANCELLED }
        );
        loader.hide();
        if (_.get(respond, "success", false)) {
          this.$swal({
            title: "Hủy đơn hàng thành công !",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.clickCancel();
          });
        } else {
          this.$swal({
            type: "error",
            title: "Lỗi !",
            text: _.get(respond, "message", ""),
          });
        }
      });
    },

    async getOrderDetail() {
      this.loadOrderDetailSuccess = false;
      const loader = this.$loading.show();
      const respond = await OrderRepository.detail(this.$route.params.id);
      loader.hide();
      if (_.get(respond, "success", false)) {
        this.loadOrderDetailSuccess = true;
        const dataOrderDetail = _.get(respond, "data.order", {});

        Object.assign(this.shippingAddress, {
          receiver: dataOrderDetail.name,
          phone: dataOrderDetail.phone,
          email: dataOrderDetail.email,
          provinceId: _.get(dataOrderDetail, "provinces.id", null),
          wardId: _.get(dataOrderDetail, "wards.id", null),
          districtId: _.get(dataOrderDetail, "districts.id", null),
          detailAddress: dataOrderDetail.address,
        });

        const products = [];
        // _.get(dataOrderDetail, "combos", []).forEach((dataCombo) => {
        //   products.push({
        //     productId: `combo-${dataCombo.comboId}`,
        //     quantity: dataCombo.quantity,
        //     price: dataCombo.price,
        //     customerId: dataCombo.customerId,
        //   });
        // });

        _.get(respond, "data.order.products", []).forEach((dataProduct) => {
          products.push({
            productId: `product-${dataProduct.productId}`,
            quantity: dataProduct.quantity,
            price: dataProduct.price,
            customerId: dataProduct.customerId,
            ...dataProduct.product,
          });
        });

        Object.assign(this.contentOrder, {
          code: dataOrderDetail.code || "",
          paymentType: dataOrderDetail.paymentType || "",
          statusId: dataOrderDetail.status,
          description: dataOrderDetail.description,
          note: dataOrderDetail.note,
          shift: dataOrderDetail.shift,
          paidAmount: dataOrderDetail.paidAmount,
          isVatInvoice: dataOrderDetail.isVatInvoice || 0,
          storeName: dataOrderDetail.storeName || "",
          storeTax: dataOrderDetail.storeTax || "",
          storeAddress: dataOrderDetail.storeAddress || "",
          products: products,
        });
      } else {
        this.loadOrderDetailSuccess = false;
      }
    },

    async getProvinces() {
      const respond = await AddressRepository.provinces({ limit: 100 });
      if (_.get(respond, "success", false)) {
        this.provinces = _.get(respond, "data.list_province", []).filter(
          (data) => data.id != 0
        );
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(respond, "message", ""),
        });
      }
    },

    async getProductList() {
      const respond = await OrderRepository.fillProduct(this.$route.params.id);
      if (_.get(respond, "success", false)) {
        const products = _.get(respond, "data.list_product", []).map((obj) => {
          const productTemp = {
            id: obj.id,
            name: obj.name,
            cost: obj.cost ? obj.cost : obj.price,
            retailPrice: obj.retailPrice,
            price: obj.price,
          };
          productTemp.idTemp = `product-${obj.id}`;
          return productTemp;
        });

        // const combos = _.get(respond, "data.combos", []).map((combo) => {
        //   const comboTemp = combo;
        //   comboTemp.idTemp = `combo-${combo.id}`;
        //   comboTemp.price = _.get(comboTemp, "products", []).reduce(
        //     (accumulator, product) => {
        //       return accumulator + _.get(product, "price", 0);
        //     },
        //     0
        //   );
        //   return comboTemp;
        // });

        this.products = [
          { header: "Chọn sản phẩm" },
          ...products,
          // { divider: true },
          // { header: "Combo" },
          // ...combos,
        ];
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(respond, "message", ""),
        });
      }
    },

    clickCancel() {
      if (this.$route.query.orderList) {
        return this.$router.push({
          name: "OrderList",
        });
      }
    },

    clickStep() {
      this.contentOrder.paidAmount = this.contentOrder.paidAmount << 0;
      if (this.stepOrder === 2) {
        // Combine the same products and customers
        let objectCheckProducts = {};
        const productsTemp = [];
        let indexProductsTemp = 0;
        _.get(this.contentOrder, "products", []).forEach((product) => {
          const idTemp = product.productId + "_" + product.customerId;
          const indexTemp = objectCheckProducts[idTemp];
          if (indexTemp == null) {
            objectCheckProducts[idTemp] = indexProductsTemp;
            indexProductsTemp++;
            productsTemp.push({ ...product });
          } else {
            productsTemp[indexTemp].quantity += +product.quantity;
          }
        });
        // End Combine the same products and customers
        this.contentOrder.products = productsTemp;
      }
      this.stepOrder = this.stepOrder + 1;
    },

    async clickUpdateOrder() {
      const contentOrderUpdate = {
        description: _.get(this.contentOrder, "description", ""),
        note: _.get(this.contentOrder, "note", ""),
        shift: _.get(this.contentOrder, "shift", ""),
        statusId: this.contentOrder.statusId,
        isVatInvoice: this.contentOrder.isVatInvoice,
        storeName: this.contentOrder.storeName || "",
        storeTax: this.contentOrder.storeTax || "",
        storeAddress: this.contentOrder.storeAddress || "",
        paymentType: this.contentOrder.paymentType,
        paidAmount: !this.contentOrder.paidAmount
          ? 0
          : Number(this.contentOrder.paidAmount),
        totalPrice: !this.contentOrder.totalPrice
          ? 0
          : Number(this.contentOrder.totalPrice),
      };

      contentOrderUpdate.products = this.contentOrder.products.map(
        (product) => {
          const productTemp = { ...product };
          const productArray = productTemp.productId.split("-");
          return {
            productId: +productArray[1],
            productType: productArray[0],
            quantity: +product.quantity,
            customerId: +product.customerId,
            price: product.price,
            oldPrice: product.cost,
          };
        }
      );

      let totalPrice =
        contentOrderUpdate.products.reduce((accumulator, product) => {
          return (
            accumulator +
            _.get(product, "price", 0) * _.get(product, "quantity", 0)
          );
        }, 0) || 0;

      const shippingAddressUpdate = {
        receiver: _.get(this.shippingAddress, "receiver", ""),
        phone: _.get(this.shippingAddress, "phone", ""),
        email: _.get(this.shippingAddress, "email", ""),
        provinceId: _.get(this.shippingAddress, "provinceId", ""),
        districtId: _.get(this.shippingAddress, "districtId", ""),
        wardId: _.get(this.shippingAddress, "wardId", ""),
        detailAddress: _.get(this.shippingAddress, "detailAddress", ""),
      };

      const dataUpdate = {
        statusId: +_.get(contentOrderUpdate, "statusId", 0),
        paymentType: _.get(contentOrderUpdate, "paymentType", "BANK"),
        description: _.get(contentOrderUpdate, "description", ""),
        note: _.get(contentOrderUpdate, "note", ""),
        shift: _.get(contentOrderUpdate, "shift", ""),
        isVatInvoice: _.get(contentOrderUpdate, "isVatInvoice", 0),
        storeName: _.get(contentOrderUpdate, "storeName", ''),
        storeTax: _.get(contentOrderUpdate, "storeTax", ''),
        storeAddress: _.get(contentOrderUpdate, "storeAddress", ''),
        paidAmount: +_.get(contentOrderUpdate, "paidAmount", 0),
        products: _.get(contentOrderUpdate, "products", []),
        shippingAddress: shippingAddressUpdate,
        totalPrice: totalPrice,
      };

      this.loading = this.$loading.show();
      delete this.dataUpdate.listEndDate;
      const response = await OrderRepository.update(
        this.$route.params.id,
        dataUpdate
      );

      this.loading.hide();

      if (response.error) {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      } else {
        this.$swal({
          title: "Cập nhật đơn hàng thành công !",
          confirmButtonText: "Đồng ý",
        }).then(() => {
          if (this.$route.query.orderList) {
            return this.$router.push({
              name: "OrderList",
            });
          }
        });
      }
    },

    closeModal() {
      this.updateDialog = false;
    },

    confirmPayment() {
      let me = this;
      this.$swal({
        title: "Bạn có chắc chắc đã nhận được tiền của đơn hàng này không?",
        confirmButtonText: "Xác nhận",
        showCancelButton: true,
        cancelButtonText: "Hủy",
      }).then(async (response) => {
        if (response.value) {
          let res = await OrderRepository.confirmPayment({
            id: me.$route.params.id,
          });
          if (res.success) {
            await me.getOrderDetail();
          }
        }
      });
    },

    // showAccount() {
    //   this.$router.push(`/orders/${this.$route.params.id}/account`);
    // },

    checkAuthCtvUpdate(type) {
      if (this.checkAuthorization("order-ctv-create_read")) {
        if (!["T5", "C5"].includes(type)) {
          return true;
        }
      }
      return !this.checkIncludesT4NT5(type);
    },

    async beforeUpdate() {
      const contentOrderUpdate = {
        description: _.get(this.contentOrder, "description", ""),
        statusId: this.contentOrder.statusId,
        paymentType: this.contentOrder.paymentType,
        paidAmount: !this.contentOrder.paidAmount
          ? 0
          : Number(this.contentOrder.paidAmount),
        totalPrice: !this.contentOrder.totalPrice
          ? 0
          : Number(this.contentOrder.totalPrice),
      };
      contentOrderUpdate.products = this.contentOrder.products.map(
        (product) => {
          const productTemp = { ...product };
          const productArray = productTemp.productId.split("-");
          return {
            productId: +productArray[1],
            productType: productArray[0],
            quantity: +product.quantity,
            customerId: +product.customerId,
            price: product.price,
          };
        }
      );
      let totalPrice =
        contentOrderUpdate.products.reduce((accumulator, product) => {
          return (
            accumulator +
            _.get(product, "price", 0) * _.get(product, "quantity", 0)
          );
        }, 0) || 0;
      const shippingAddressUpdate = {
        receiver: _.get(this.shippingAddress, "receiver", ""),
        phone: _.get(this.shippingAddress, "phone", ""),
        email: _.get(this.shippingAddress, "email", ""),
        provinceId: _.get(this.shippingAddress, "provinceId", ""),
        districtId: _.get(this.shippingAddress, "districtId", ""),
        wardId: _.get(this.shippingAddress, "wardId", ""),
        detailAddress: _.get(this.shippingAddress, "detailAddress", ""),
      };
      const dataUpdate = {
        statusId: +_.get(contentOrderUpdate, "statusId", 0),
        paymentType: _.get(contentOrderUpdate, "paymentType", "BANK"),
        description: _.get(contentOrderUpdate, "description", ""),
        paidAmount: +_.get(contentOrderUpdate, "paidAmount", 0),
        products: _.get(contentOrderUpdate, "products", []),
        shippingAddress: shippingAddressUpdate,
        totalPrice: totalPrice,
      };
      this.dataUpdate = dataUpdate;
      this.loading = this.$loading.show();
      dataUpdate.listEndDate = {};
      let response = await OrderRepository.getEndDate(dataUpdate);
      if (response.success) {
        this.checkEndDate = false;
        dataUpdate.products.forEach((item, key) => {
          if (typeof dataUpdate.listEndDate[item.customerId] == "undefined") {
            dataUpdate.listEndDate[item.customerId] = {};
          }

          let endDateObject = moment(
            response.data[item.customerId][item.productId],
            "DD/MM/YYYY"
          );
          if (
            !endDateObject.isValid() &&
            response.data[item.customerId][item.productId] !== "Trọn đời"
          ) {
            this.checkEndDate = false;
            // return;
          }
          if (item.productId) {
            dataUpdate.listEndDate[item.customerId][item.productId] = {
              end_date: response.data[item.customerId][item.productId],
            };
          }
        });
        this.dataDialog = {
          ...dataUpdate,
          // products: _.get(response, 'data', []),
          shippingAddress: {
            ...this.shippingAddress,
          },
        };
        this.updateDialog = true;
        this.loading.hide();
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
  },
};
</script>

<style scoped>
span.span-icon-custom {
  position: absolute;
  top: 15px;
  left: 20px;
}

.header-mobile {
  width: 100%;
  text-align: center;
}
</style>
