<template>
  <v-card outlined class="mx-auto px-0 ma-0 s-layout-small customer-info-wrapper">
    <v-card-title class="pa-0">
      <p>Thông Tin Khách Hàng</p>
    </v-card-title>

      <validation-provider
        v-slot="{ errors }"
        name="Tên người liên hệ"
        :rules="{ required: true, max:255 }"
      >
        <v-text-field
          v-model="dataForm.customers[0].fullName"
          label="Tên người liên hệ"
          :error-messages="errors"
          color="primary"
          class="ma-0 pa-0"
        >
          <template slot="label">
            <div>Tên người liên hệ <span style="color: red">*</span></div>
          </template>
        </v-text-field>
      </validation-provider>
        <label>Đối tượng liên hệ <span style="color: red">*</span></label>
        <v-radio-group
          v-model="dataForm.customers[0].position"
          row
          @change="changePosition"
          class="ma-0 pb-0 mt-1 pb-2"
        >
          <v-radio label="Con" value="child" />
          <v-radio label="Bố" value="father" />
          <v-radio label="Mẹ" value="mother" />
          <v-radio label="Khác" value="other" />
        </v-radio-group>
      <template v-for="(customer, indexCustomer) in childCustomers">
        <div v-if="showChild" :key="'childInfo-' + indexCustomer">
          <div v-if="indexCustomer > 0" style="text-align: right; margin-right: 10px;">
            <v-btn
              style="margin-right: -20px;"
              @click="onClickRemoveCustomer(customer.customerId)"
              text
              small
            >
              <v-icon>mdi-close-box</v-icon>
            </v-btn>
          </div>
          <validation-provider
            :key="'childName-' + indexCustomer"
            v-slot="{ errors }"
            :vid="`Tên con ${indexCustomer +1}`"
            name="Tên Con"
            rules="required|max:255"
          >
            <v-text-field
              class="ma-0 pb-2 pt-0"
              :label="`Tên con ${indexCustomer +1}`"
              v-model="customer.fullName"
              color="primary"
              :error-messages="errors"
            >
              <template slot="label">
                <div>Tên con {{indexCustomer +1}} <span style="color: red">*</span></div>
              </template>
            </v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :key="'childBirthday-' + indexCustomer"
            name="Năm sinh của con"
            :vid="`Năm sinh của con ${indexCustomer +1}`"
            rules="required|min_value:1900"
          >
            <common-select-year
              v-model="customer.birthday"
              class="birthday-child mobile-hidden"
              :label="`Năm sinh của con ${indexCustomer +1}`"
              :error-messages="errors"
              :required="true"
            />
            <v-select
              v-model="customer.birthday"
              :items="selectYears"
              class="birthday-child mobile-show"
              :label="`Năm sinh của con ${indexCustomer +1}`"
              :error-messages="errors"
            >
              <template slot="label">
                <div>Năm sinh của con {{indexCustomer +1}} <span style="color: red">*</span></div>
              </template>
            </v-select>
          </validation-provider>
        </div>
      </template>

      <div v-show="showButtonAddChild" style="text-align: right;">
        <v-btn class="ma-0 add-child-btn" small @click="onClickAddChild">
          <v-icon>mdi-plus</v-icon><span class="add-child-text">Thêm con</span>
        </v-btn>
      </div>
      <div class="d-flex align-center">
        <validation-provider
          v-slot="{ errors }"
          name="Số Điện thoại"
          :rules="{ regex: regex.isVNPhoneMobile, required:true }"
          style="width: 100%"
        >
          <v-text-field
            v-model="dataForm.customers[0].mobile"
            label="Số Điện thoại"
            class="ma-0 pb-2 pt-0"
            :error-messages="errors"
            color="primary"
          >
            <template slot="label">
              <div>Số Điện thoại <span style="color: red">*</span></div>
            </template>
          </v-text-field>
        </validation-provider>
        <v-tooltip v-if="showChat" bottom>
          <template v-slot:activator="{ on }">
              <v-btn class="icon-fb" icon small color="info" v-on="on"><v-icon>mdi-facebook-messenger</v-icon></v-btn>
          </template>
          <span>Nhắn tin</span>
        </v-tooltip>
      </div>
      <validation-provider
        v-slot="{ errors }"
        name="Số Điện thoại khác"
        :rules="{ regex: regex.isVNPhoneMobile, }"
      >
        <v-text-field
          label="Số điện thoại khác"
          color="primary"
          class="ma-0 pa-0"
          v-model="dataForm.customers[0].phones[0]"
          :error-messages="errors"
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Email"
        :rules="{ max:255, email: true, regex: regex.isEmail }"
      >
        <v-text-field
          v-model="dataForm.customers[0].email"
          label="Email"
          class="ma-0 pt-0"
          :error-messages="errors"
          color="primary"
        />
      </validation-provider>
        <label>Điạ chỉ</label>
        <v-row class="mt-4">
          <v-col cols="12" sm="12" class="py-0">
            <validation-provider v-slot="{ errors }" name="Tỉnh">
              <v-autocomplete
                v-model="dataForm.customers[0].provinceId"
                :items="provinces"
                item-text="ten"
                item-value="id"
                class="ma-0 pb-2 pt-0"
                label="Tỉnh"
                @change="onChangeProvince"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <validation-provider
              v-slot="{ errors }"
              name="Quận/Huyện"
              :rules="{required: districts.length > 0}"
            >
              <v-autocomplete
                :readonly="stepOrder === 3"
                v-model="dataForm.customers[0].districtId"
                :items="districts"
                item-text="ten"
                item-value="id"
                class="ma-0 pb-2 pt-0"
                label="Quận/Huyện"
                @change="onChangeDistrict"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <validation-provider
              v-slot="{ errors }"
              name="Phường/Xã"
              :rules="{required: wards.length > 0}"
            >
              <v-autocomplete
                v-model="dataForm.customers[0].wardId"
                label="Phường/Xã"
                :items="wards"
                class="ma-0 pb-2 pt-0"
                item-text="ten"
                item-value="id"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
      <validation-provider
        v-slot="{ errors }"
        name="Địa chỉ chi tiết(số nhà, đường, thôn...)"
        rules="max:255"
      >
        <v-textarea
          label="Địa chỉ chi tiết(số nhà, đường, thôn...)"
          rows="3"
          class="ma-0 pa-0"
          :error-messages="errors"
          v-model="dataForm.customers[0].detailAddress"
        />
      </validation-provider>
  </v-card>
</template>

<script>
import { regex, contactDetail } from '../../../helpers/constants';
import RepositoryFactory from '../../../repositories/RepositoryFactory';
const AddressRepository = RepositoryFactory.get('address');
const ContactRepository = RepositoryFactory.get('contact');

export default {
  props: {
    stepOrder: {
      type: Number,
      default: -1
    },
    dataForm: {
      type: Object,
      default: () => {}
    },
    provinces: {
      type: Array,
      default: () => []
    },
    childCustomers: {
      type: Array,
      default: () => []
    },
    showChat: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      regex,
      districts: [],
      wards: [],
      showChild: true,
      selectYears: []
    };
  },
  computed: {
    showButtonAddChild() {
      return this.childCustomers.length  < contactDetail.MAX_CONTACT;
    }
  },
  created() {
    if (this.dataForm.customers[0].provinceId == 0) {
      this.dataForm.customers[0].provinceId = '';
    }
    if (this.dataForm.customers[0].provinceId) {
      this.getDistricts();
    }
    if (this.dataForm.customers[0].districtId) {
      this.getWards();
    }
    let selectYears = []
    const yearNow = new Date().getFullYear();
    for (let i = yearNow; i >= 1900; i--) {
      selectYears.push(i);
    }
    this.selectYears = selectYears;
  },
  methods: {
    changePosition() {
      // render child again
      this.showChild = false;
      if (_.get(this.dataForm, 'customers[0].position', '') === 'child') {
        const indexChildTemp = this.dataForm.customers.findIndex(dataCustomer => dataCustomer.isDataTemp);
        if (indexChildTemp !== -1) {
          const dataChildTemp = this.dataForm.customers[indexChildTemp];
          if (_.get(dataChildTemp, 'birthday') === 0 && !_.get(dataChildTemp, 'fullName', '')) {
            this.dataForm.customers.splice(indexChildTemp, 1);
          }
        }
      } else {
        if (this.dataForm.customers.length == 1) {
          this.onClickAddChild()
        }
      }
      setTimeout(() => {
        this.showChild = true;
      }, 100);
    },
    onClickAddChild() {
      if (this.childCustomers.length === contactDetail.MAX_CONTACT) {
        return this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: `Bạn chỉ có thể tạo tối đa ${contactDetail.MAX_CONTACT} thông tin con`
        });
      }
      this.dataForm.customers.push({
        customerId: new Date().getTime(),
        isMainCustomer: false,
        position: 'child',
        courseRegister: []
      });
    },
    onChangeDistrict() {
      if (this.dataForm.customers[0].districtId) {
        this.dataForm.customers[0].wardId = '';
        this.wards = [];
        this.getWards();
      }
    },
    onChangeProvince() {
      // if (this.dataForm.customers[0].provinceId) {
      this.dataForm.customers[0].districtId = '';
      this.dataForm.customers[0].wardId = '';
      this.districts = [];
      this.wards = [];
      this.getDistricts();
      // }
      if (this.dataForm.customers[0].provinceId == 0) {
        this.dataForm.customers[0].districtId = 0;
        this.dataForm.customers[0].wardId = 0;
      }
    },
    async getDistricts() {
      const loader = this.$loading.show();
      const respond = await AddressRepository.districts(
        { limit: 100 },
        this.dataForm.customers[0].provinceId
      );
      loader.hide();
      if (_.get(respond, 'success', false)) {
        this.districts = _.get(respond, 'data', []);
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(respond, 'message', '')
        });
      }
    },
    async getWards() {
      const loader = this.$loading.show();
      const respond = await AddressRepository.wards(
        { limit: 100 },
        this.dataForm.customers[0].provinceId,
        this.dataForm.customers[0].districtId
      );
      loader.hide();
      if (_.get(respond, 'success', false)) {
        this.wards = _.get(respond, 'data', []);
        if (this.wards.length === 0) {
          this.dataForm.customers[0].wardId = 0;
        }
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(respond, 'message', '')
        });
      }
    },
    onClickRemoveCustomer(customerId) {
      this.$swal({
        title: 'Xóa thông tin của Con',
        text: 'Bạn có thực sự muốn xoá ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ'
      }).then(async result => {
        if (result.value) {
          const index = this.dataForm.customers.findIndex(
            customer => customer.customerId === customerId
          );
          if (index === -1) {
            return;
          }
          const customerTemp = this.dataForm.customers[index];
          customerTemp.isDeleted = true;
          customerTemp.courseRegister = [];
          this.dataForm.customers.splice(index, 1);
          if (this.$route.params.id && !_.get(customerTemp, 'isNew', true)) {
            this.dataForm.customers.push(customerTemp);
          }
        }
      });
    }
  }
};
</script>
<style scoped>
  /deep/.birthday-child .v-text-field{
    margin: 0;
    padding: 0;
  }

  .add-child-btn {
    background-color: #2196F3 !important;
    border-color: #2196F3 !important;
  }

  @media screen and (max-width: 480px) {
    .add-child-btn {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #2196F3 !important;
      box-shadow: unset;
      font-weight: bold;
    }

    .add-child-btn .add-child-text {
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  @media screen and (orientation:landscape) and (max-height: 480px) {
    .add-child-btn {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #2196F3 !important;
      box-shadow: unset;
      font-weight: bold;
    }

    .add-child-btn .add-child-text {
      text-decoration: underline;
      text-underline-position: under;
    }
  }
</style>