<template>
  <v-container id="treatment" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <pathology-table :pathologies="pathologies" />
    <common-no-result v-if="!loading && totalItem === 0" />
    <v-row class="mt-4">
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FilterForm from "./components/FilterForm.vue";
import PathologyTable from "./components/PathologyTable.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const PathologyRepository = RepositoryFactory.get("pathology");
export default {
  components: { FilterForm, PathologyTable },
  data() {
    return {
      filterForm: {
        keyword: "",
      },
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      pathologies: [],
      loading: false,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getPathologyList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getPathologyList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  async created() {
    this.getPathologyList();
    Bus.$on("pathology-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getPathologyList();
    });
    Bus.$on("pathology-list-reload", () => {
      this.getPathologyList();
    });
  },
  beforeDestroy() {
    Bus.$off("pathology-list-filter");
  },
  methods: {
    async getPathologyList() {
      this.loading = true;
      const params = {
        limit: this.limit,
        page: this.currentPage,
        keyword: this.filterForm.keyword,
      };
      const loader = this.$loading.show();
      const response = await PathologyRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.pathologies = _.get(response, "data.list_pathology", []);
        this.totalItem = _.get(response, "data.total_item", 0);
      }
    },
  },
};
</script>
