var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"product-list","fluid":"","tag":"section"}},[_c('div',{staticClass:"md-layout"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('FilterForm',{attrs:{"filterForm":_vm.filterForm},on:{"product-filter":_vm.search}}),_c('div',{staticClass:"text-sm-right mb-3"},[(_vm.checkAuthorization('product_delete'))?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length ? true : false),expression:"selected.length ? true : false"}],staticClass:"mb-2 mr-2",attrs:{"color":"default","dark":""},on:{"click":_vm.deleteMultipleItem}},[_vm._v(" Xóa tất cả ")]):_vm._e(),(_vm.checkAuthorization('product_create'))?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.gotoNewPage}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Thêm Mới ")],1):_vm._e()],1),_c('v-tabs',{staticClass:"mt-0 pl-3",staticStyle:{"background-color":"#ffffff","padding":"5px 0px 10px 5px"},attrs:{"color":"primary"},on:{"change":_vm.changeTab},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('v-tab',{key:0},[_vm._v("Tất Cả")]),_vm._l((_vm.productStatusOptions),function(tab,i){return _c('v-tab',{key:i + 1},[_vm._v(_vm._s(tab.text))])})],2),_c('v-data-table',{staticClass:"table-layout pa-5",attrs:{"headers":_vm.headers,"items":_vm.tableData,"single-select":_vm.singleSelect,"item-key":"id","show-select":_vm.checkAuthorization('product_delete'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.thumnail",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-img',{attrs:{"src":_vm.getThumbnailUrl(item.images),"max-width":"120"}})],1)]}},{key:"no-data",fn:function(){return [_c('common-no-result')]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status === 2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"size":"22","icon":"","small":"","dark":"","color":"orange"},on:{"click":function($event){return _vm.convertStatus(item.id, _vm.productStatus.INACTIVE)}}},on),[_c('v-icon',[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Chuyển sang trạng thái ngừng hoạt động")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"size":"22","icon":"","small":"","dark":"","color":"green"},on:{"click":function($event){return _vm.convertStatus(item.id, _vm.productStatus.ACTIVE)}}},on),[_c('v-icon',[_vm._v("mdi-check-bold")])],1)]}}],null,true)},[_c('span',[_vm._v("Chuyển sang trạng thái hoạt động")])]),(_vm.checkAuthorization('product_update'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Cập nhật thông tin sản phẩm")])]):_vm._e(),(_vm.checkAuthorization('product_delete'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"size":"22","icon":"","small":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-window-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Xóa sản phẩm")])]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-row',{staticStyle:{"padding":"20px 20px"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}}),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.totalItem > _vm.limit)?_c('v-pagination',{staticClass:"justify-sm-end",attrs:{"length":_vm.getTotalItem(_vm.totalItem, _vm.limit),"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }