<template>
  <v-card
    outlined
    class="mx-auto s-layout-small mt-0"
    style="border-color: #fff !important; padding-top: 20px"
  >
    <v-card-title class="pa-0" style="justify-content: space-between">
      <p class="ma-0">Mã đơn hàng: {{ contentOrder.code }}</p>
      <!-- <p
        class="ma-0"
        v-if="
          contentOrder.isDetail &&
          checkAuthorization('order-ctv-create_read') &&
          !checkAuthorization('contact_read')
        "
      >
      </p> -->
      <p class="ma-0">{{ dataStatus }}</p>
    </v-card-title>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        class="order-products-wrapper"
        style="overflow: auto"
      >
        <v-simple-table class="table-layout table-small-screen mobile-hidden">
          <template v-slot:default>
            <thead>
              <tr>
                <td class="text-left">Tên sản phẩm</td>
                <td class="text-left">Đơn giá</td>
                <td class="text-left">Đơn giá đã giảm</td>
                <td class="text-left">Số lượng</td>
                <td class="text-left">Thành tiền</td>
                <td v-if="stepOrder !== 3"></td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(product, index) in contentOrder.products"
                :key="index"
              >
                <td class="text-center">
                  <validation-provider
                    v-slot="{ errors }"
                    :vid="'Tên sản phẩm ' + index"
                    name="Tên sản phẩm"
                    rules="required"
                  >
                    <v-autocomplete
                      :disabled="isReadOnly || stepOrder === 3"
                      v-model="product.productId"
                      :items="products"
                      item-text="name"
                      item-value="idTemp"
                      label
                      class="ma-0 pa-0"
                      :error-messages="errors"
                      @change="changeProduct(product)"
                    />
                  </validation-provider>
                </td>
                <td class="text-center">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Đơn giá"
                    :vid="'Đơn giá' + index"
                    :rules="{
                      required: true,
                      numeric: true,
                      regex: /^[0-9]+$/,
                      min_value: 0,
                    }"
                  >
                    <v-text-field
                      :readonly="
                        isReadOnly ||
                        stepOrder === 3 ||
                        (checkAuthorization('order-ctv-create_read') &&
                          !checkAuthorization('contact_read'))
                      "
                      step="5000"
                      type="number"
                      v-model="product.cost"
                      label
                      class="ma-0 pa-0"
                      :error-messages="errors"
                      color="primary"
                    />
                  </validation-provider>
                </td>
                <td class="text-center">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Đơn giá đã giảm"
                    :vid="'Đơn giá đã giảm' + index"
                    :rules="{
                      required: true,
                      numeric: true,
                      regex: /^[0-9]+$/,
                      min_value: 0,
                    }"
                  >
                    <v-text-field
                      :readonly="
                        isReadOnly ||
                        stepOrder === 3 ||
                        (checkAuthorization('order-ctv-create_read') &&
                          !checkAuthorization('contact_read'))
                      "
                      step="5000"
                      type="number"
                      v-model="product.price"
                      label
                      class="ma-0 pa-0"
                      :error-messages="errors"
                      color="primary"
                    />
                  </validation-provider>
                </td>
                <td class="text-center">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Số lượng"
                    :vid="'Số lượng' + index"
                    :rules="{
                      required: true,
                      numeric: true,
                      regex: /^[0-9]+$/,
                      min_value: 1,
                      max: 4,
                    }"
                  >
                    <v-text-field
                      :readonly="isReadOnly || stepOrder === 3"
                      type="number"
                      v-model="product.quantity"
                      label
                      class="ma-0 pa-0"
                      :error-messages="errors"
                      color="primary"
                    />
                  </validation-provider>
                </td>
                <td class="text-center">
                  <v-currency-field
                    class="ma-0 pa-0"
                    readonly
                    :value="product.price * product.quantity"
                  />
                </td>
                <td
                  v-if="stepOrder !== 3"
                  v-show="index > 0 && !isReadOnly"
                  class="text-center"
                  style="position: relative"
                >
                  <div class="border-table-order"></div>
                  <v-btn
                    color="red"
                    text
                    style="
                      text-transform: inherit;
                      font-weight: bold;
                      font-size: 12px;
                      margin-top: -15px;
                    "
                    @click="onClickRemoteProduct(index)"
                    >Xóa</v-btn
                  >
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <v-btn
                    small
                    v-if="
                      ![orderStatus.SUCCESS, orderStatus.CANCELLED].includes(
                        contentOrder.statusId
                      )
                    "
                    color="blue"
                    @click="onClickAddProduct"
                  >
                    <v-icon>mdi-plus</v-icon>Thêm sản phẩm
                  </v-btn>
                </td>
                <td colspan="2" style="font-weight: bold; text-align: right">
                  Giá trị đơn hàng (Chưa trừ):
                </td>
                <td style="font-weight: bold; text-align: left">
                  {{ formatPrice(totalPrice) }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="12" md="12">
        <validation-provider
          v-show="stepOrder >= 2"
          v-slot="{ errors }"
          name="Đã thanh toán"
          :rules="{
            required: true,
            min_value: 0,
            max_value: totalPrice,
          }"
        >
          <v-currency-field
            label="Đã thanh toán"
            :error-messages="errors"
            class="ma-0 pa-0"
            v-model="contentOrder.paidAmount"
          />
        </validation-provider>
      </v-col>

      <v-col cols="12" md="12">
        <v-currency-field
          readonly
          label="Giảm trừ khi thanh toán ngay"
          :value="restDiscountPrice"
        />
      </v-col>

      <v-col cols="12" md="12">
        <v-currency-field readonly label="Còn lại" :value="restPrice" />
      </v-col>

      <v-col cols="12" md="12">
        <validation-provider
          v-slot="{ errors }"
          name="Hình thức thanh toán"
          rules="required"
        >
          <v-autocomplete
            :disabled="isReadOnly || stepOrder === 3"
            v-model="contentOrder.paymentType"
            :items="orderPaymentTypes"
            label="Hình thức thanh toán *"
            class="ma-0 pa-0"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>

      <v-col cols="12" md="12">
        <validation-provider
          v-slot="{ errors }"
          name="Thời gian giao hàng mong muốn"
          rules="max:255"
        >
          <v-textarea
            :readonly="true"
            label="Thời gian giao hàng mong muốn"
            rows="3"
            class="ma-0 pa-0"
            v-model="contentOrder.shift"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>

      <v-col cols="12" md="12">
        <validation-provider
          v-slot="{ errors }"
          name="Ghi chú từ khách hàng"
          rules="max:255"
        >
          <v-textarea
            :readonly="true"
            label="Ghi chú từ khách hàng"
            rows="3"
            class="ma-0 pa-0"
            v-model="contentOrder.description"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>

      <v-col cols="12" md="12">
        <validation-provider v-slot="{ errors }">
          <v-select
            v-model="contentOrder.statusId"
            label="Trạng thái đơn hàng"
            no-data-text="Không có dữ liệu"
            :items="orderStatusOptions"
            item-text="text"
            item-value="value"
            :error-messages="errors"
            color="primary"
          >
            <template v-slot:item="{ attrs, item, on }">
              <v-list-item
                v-bind="attrs"
                active-class="secondary elevation-4 white--text"
                class="mx-3 mb-3 v-sheet"
                elevation="0"
                v-on="on"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </validation-provider>
      </v-col>

      <v-col cols="12" md="12">
        <validation-provider v-slot="{ errors }">
          <v-select
            v-model="contentOrder.isVatInvoice"
            label="Hóa đơn"
            no-data-text="Không có dữ liệu"
            :items="vatStatusOptions"
            item-text="text"
            item-value="value"
            :error-messages="errors"
            color="primary"
          >
            <template v-slot:item="{ attrs, item, on }">
              <v-list-item
                v-bind="attrs"
                active-class="secondary elevation-4 white--text"
                class="mx-3 mb-3 v-sheet"
                elevation="0"
                v-on="on"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </validation-provider>
      </v-col>

      <v-col cols="12" md="12" v-if="contentOrder.isVatInvoice">
        <validation-provider
          v-slot="{ errors }"
          class="mb-2"
          name="Tên đơn vị"
          :rules="{
            max: 255,
          }"
        >
          <v-text-field
            v-model="contentOrder.storeName"
            label="Tên đơn vị"
            :error-messages="errors"
            color="primary"
          />
        </validation-provider>
      </v-col>

      <v-col cols="12" md="12" v-if="contentOrder.isVatInvoice">
        <validation-provider
          v-slot="{ errors }"
          class="mb-2"
          name="Mã số thuế"
          :rules="{
            max: 255,
          }"
        >
          <v-text-field
            v-model="contentOrder.storeTax"
            label="Mã số thuế"
            :error-messages="errors"
            color="primary"
          />
        </validation-provider>
      </v-col>

      <v-col cols="12" md="12" v-if="contentOrder.isVatInvoice">
        <validation-provider
          v-slot="{ errors }"
          class="mb-2"
          name="Địa chỉ đơn vị"
          :rules="{
            max: 255,
          }"
        >
          <v-text-field
            v-model="contentOrder.storeAddress"
            label="Địa chỉ"
            :error-messages="errors"
            color="primary"
          />
        </validation-provider>
      </v-col>

      <v-col cols="12" md="12">
        <validation-provider
          v-slot="{ errors }"
          name="Ghi chú từ nhân viên"
          rules="max:255"
        >
          <v-textarea
            label="Ghi chú từ nhân viên"
            rows="3"
            class="ma-0 pa-0"
            v-model="contentOrder.note"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  orderStatus,
  vatStatusOptions,
  orderPaymentTypes,
  orderStatusOptions,
  thresholdPercentDiscount,
  paymentTypes,
} from "../../../helpers/constants";

export default {
  props: {
    dataForm: {
      type: Object,
      default: () => {},
    },
    contentOrder: {
      type: Object,
      default: () => {},
    },
    shippingAddress: {
      type: Object,
      default: () => {},
    },
    products: {
      type: Array,
      default: () => [],
    },
    stepOrder: {
      type: Number,
      default: -1,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderStatus,
      vatStatusOptions,
      orderPaymentTypes,
      orderStatusOptions,
      panel: [0],
    };
  },
  computed: {
    dataStatus() {
      const orderStatus = orderStatusOptions.find(
        (o) => o.value == this.contentOrder.statusId
      );
      return `Trạng thái: (${orderStatus ? orderStatus.text : ""})`;
    },

    restDiscountPrice() {
      return this.contentOrder.paymentType == paymentTypes.BANK
        ? Number((this.totalPrice * thresholdPercentDiscount) / 100)
        : 0;
    },

    restPrice() {
      return (
        this.totalPrice -
        Number(this.contentOrder.paidAmount) -
        (this.contentOrder.paymentType == paymentTypes.BANK
          ? Number((this.totalPrice * thresholdPercentDiscount) / 100)
          : 0)
      );
    },

    totalPrice() {
      return (
        _.get(this.contentOrder, "products", []).reduce(
          (accumulator, product) => {
            return (
              accumulator +
              _.get(product, "price", 0) * _.get(product, "quantity", 0)
            );
          },
          0
        ) || 0
      );
    },
  },
  methods: {
    onClickRemoteProduct(index) {
      this.$swal({
        title: "Xóa sản phẩm",
        text: "Bạn có thực sự muốn xoá ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          this.contentOrder.products.splice(index, 1);
        }
      });
    },

    onClickAddProduct() {
      this.contentOrder.products.push({
        productId: "",
        quantity: 1,
        cost: 0,
        price: 0,
        customerId: "",
      });
    },

    changeProduct(product) {
      const productTemp = product;
      const data = this.products.find(
        (obj) => obj.idTemp === productTemp.productId
      );
      productTemp.customerId = "";
      productTemp.cost = data.cost;
      productTemp.price = data.price;
    },
  },
};
</script>

<style scoped>
/deep/ .v-subheader.theme--light {
  font-size: 10px;
  font-weight: bold;
}

.add-product-btn {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #2196f3 !important;
  box-shadow: unset;
  text-underline-position: under;
  font-weight: bold;
}

.add-product-btn .add-product-text {
  text-decoration: underline;
  text-underline-position: under;
}

.delete-product-btn {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #f44336 !important;
  box-shadow: unset;
  text-underline-position: under;
  font-weight: bold;
  position: absolute;
  right: 20px;
}

.delete-product-btn .delete-product-text {
  text-decoration: underline;
  text-underline-position: under;
}
</style>
