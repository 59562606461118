var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","persistent":"","retain-focus":false},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[(_vm.isCreate)?[_vm._v(" Tạo thông báo ")]:[_vm._v(" Cập nhật thông báo ")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v(" mdi-close ")])],2),_c('v-card-text',{staticClass:"px-6"},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Icon"}},[_c('v-select',{attrs:{"label":"Loại thông báo","color":"primary","items":_vm.icons,"item-text":"title","item-value":"id"},model:{value:(_vm.notification.iconId),callback:function ($$v) {_vm.$set(_vm.notification, "iconId", $$v)},expression:"notification.iconId"}})],1),(_vm.isCreate)?_c('validation-provider',{attrs:{"name":"Nhóm khách hàng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listGroupCustomer,"error-messages":errors,"item-text":"departmentName","item-value":"departmentId","label":"Nhóm khách hàng","color":"primary","multiple":"","no-data-text":"Không có dữ liệu"},on:{"change":function($event){return _vm.changeGroupCustomer()}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('common-select-all',{attrs:{"item":_vm.groupCustomers,"items":_vm.listGroupCustomer.map(function (s) { return s.departmentId; })},on:{"change":function($event){return _vm.changeGroupCustomer()}},model:{value:(_vm.groupCustomers),callback:function ($$v) {_vm.groupCustomers=$$v},expression:"groupCustomers"}})]},proxy:true}],null,true),model:{value:(_vm.groupCustomers),callback:function ($$v) {_vm.groupCustomers=$$v},expression:"groupCustomers"}})]}}],null,true)}):_vm._e(),(_vm.isCreate)?_c('validation-provider',{attrs:{"rules":"required","name":"Khách hàng *"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listCustomerForSelect,"error-messages":errors,"item-text":"username","item-value":"id","label":"Khách hàng *","color":"primary","multiple":"","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('common-select-all',{attrs:{"item":_vm.notification.listCustomer,"items":_vm.listCustomerForSelect.map(function (s) { return s.id; })},on:{"change":function($event){return _vm.changeGroupCustomer()}},model:{value:(_vm.notification.listCustomer),callback:function ($$v) {_vm.$set(_vm.notification, "listCustomer", $$v)},expression:"notification.listCustomer"}})]},proxy:true}],null,true),model:{value:(_vm.notification.listCustomer),callback:function ($$v) {_vm.$set(_vm.notification, "listCustomer", $$v)},expression:"notification.listCustomer"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:255","name":"Tiêu đề"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tiêu đề *","error-messages":errors,"color":"primary"},model:{value:(_vm.notification.title),callback:function ($$v) {_vm.$set(_vm.notification, "title", $$v)},expression:"notification.title"}})]}}],null,true)}),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:255","name":"Liên kết"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Liên kết *","error-messages":errors,"color":"primary"},model:{value:(_vm.notification.url),callback:function ($$v) {_vm.$set(_vm.notification, "url", $$v)},expression:"notification.url"}})]}}],null,true)}),_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Mô tả","rules":"max: 255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Mô tả","error-messages":errors,"color":"primary"},model:{value:(_vm.notification.description),callback:function ($$v) {_vm.$set(_vm.notification, "description", $$v)},expression:"notification.description"}})]}}],null,true)}),_c('span',{staticClass:"title-span-content text-left",staticStyle:{"display":"inline-block","width":"100%"}},[_vm._v("Nội dung *")]),_c('validation-provider',{attrs:{"name":"Nội dung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.notification.content),callback:function ($$v) {_vm.$set(_vm.notification, "content", $$v)},expression:"notification.content"}})],(errors[0])?_c('span',{staticClass:"errors-span-required-content",attrs:{"color":"primary"}},[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('v-card-actions',{staticClass:"px-0 group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveNotification)}}},[_vm._v(" Lưu lại ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy bỏ ")])],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }