<template>
  <v-card
    outlined
    class="mx-auto mt-0 s-layout-small"
    style="border-color: #fff !important; padding-top: 20px"
  >
    <v-card-title class="pa-0">
      <p class="mb-0">Địa chỉ nhận hàng</p>
    </v-card-title>
    <div class="mt-4" style="">
      <validation-provider
        v-slot="{ errors }"
        name="Tên người nhận"
        rules="required|max:255"
      >
        <v-text-field
          v-model="shippingAddress.receiver"
          :readonly="isReadOnly"
          label="Tên người nhận"
          :error-messages="errors"
          color="primary"
        />
      </validation-provider>
      <div class="d-flex align-center">
        <validation-provider
          v-slot="{ errors }"
          name="Số Điện thoại"
          :rules="{ regex: regex.isVNPhoneMobile, required: true }"
          style="width: 100%"
        >
          <v-text-field
            v-model="shippingAddress.phone"
            :readonly="isReadOnly"
            label="Số Điện thoại"
            :error-messages="errors"
            color="primary"
          />
        </validation-provider>
        <v-tooltip v-if="showChat" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              size="16"
              width="20"
              height="20"
              class="mr-1"
              icon
              small
              color="info"
              v-on="on"
            >
              <v-icon size="16">mdi-facebook-messenger</v-icon>
            </v-btn>
          </template>
          <span>Nhắn tin</span>
        </v-tooltip>
      </div>
      <validation-provider
        v-slot="{ errors }"
        name="Email"
        :rules="{ max: 255, email: true, regex: regex.isEmail }"
      >
        <v-text-field
          v-model="shippingAddress.email"
          :readonly="isReadOnly"
          label="Email"
          :error-messages="errors"
          color="primary"
        />
      </validation-provider>
      <span>
        <label>Điạ chỉ</label>
        <v-row style="margin-top: -10px">
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Tỉnh"
              :rules="contentOrder.paymentType == 'COD' ? 'required' : ''"
            >
              <v-autocomplete
                v-model="shippingAddress.provinceId"
                :disabled="isReadOnly"
                :items="provinces"
                item-text="name"
                item-value="id"
                label="Tỉnh"
                @change="onChangeProvince"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Quận/Huyện"
              :rules="contentOrder.paymentType == 'COD' ? 'required' : ''"
            >
              <v-autocomplete
                v-model="shippingAddress.districtId"
                :disabled="isReadOnly"
                :items="districts"
                item-text="name"
                item-value="id"
                label="Quận/Huyện"
                @change="onChangeDistrict"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Phường/Xã"
              :rules="contentOrder.paymentType == 'COD' ? 'required' : ''"
            >
              <v-autocomplete
                @change="onChangeWard"
                v-model="shippingAddress.wardId"
                :disabled="isReadOnly"
                label="Phường/Xã"
                :items="wards"
                item-text="name"
                item-value="id"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </span>
      <validation-provider
        v-slot="{ errors }"
        name="Địa chỉ chi tiết"
        :rules="contentOrder.paymentType == 'COD' ? 'required|max:255' : ''"
      >
        <v-textarea
          label="Địa chỉ chi tiết (số nhà, đường, thôn...)"
          rows="3"
          :error-messages="errors"
          v-model="shippingAddress.detailAddress"
          :readonly="isReadOnly"
        />
      </validation-provider>
    </div>
    <div class="mt-4" style="border: 1px solid #000000; border-radius: 5px">
      <v-col cols="12" sm="12">
        <ul class="table-info">
          <li class="mb-2">
            <strong>Địa chỉ người nhận</strong>
          </li>
          <li class="mb-1">
            <p class="ma-0">
              <span class="font-weight-medium">Số điện thoại :</span>
              {{ fullNamePhone }}
            </p>
          </li>
          <li class="mb-1">
            <p class="ma-0">
              <span class="font-weight-medium">Địa chỉ :</span>
              {{ _.get(shippingAddress, "detailAddress", "") }}
            </p>
          </li>
          <li class="mb-1">
            <p class="ma-0">{{ provinceAddress }}</p>
          </li>
        </ul>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import { regex } from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const AddressRepository = RepositoryFactory.get("address");
export default {
  props: {
    shippingAddress: {
      type: Object,
      default: () => {},
    },
    provinces: {
      type: Array,
      default: () => [],
    },
    showChat: {
      type: Boolean,
      default: false,
    },
    contentOrder: {
      type: Object,
      default: () => {},
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      regex,
      districts: [],
      wards: [],
    };
  },
  created() {
    if (this.shippingAddress.provinceId == 0) {
      this.shippingAddress.provinceId = "";
    }
    if (this.shippingAddress.provinceId) {
      const dataTemp = this.provinces.find(
        (data) => data.id === this.shippingAddress.provinceId
      );
      this.shippingAddress.provinceName = _.get(dataTemp, "name", "");
      this.getDistricts();
    }
    if (this.shippingAddress.districtId) {
      this.getWards();
    }
  },
  computed: {
    fullNamePhone() {
      return `${_.get(this.shippingAddress, "receiver", "")} - ${_.get(
        this.shippingAddress,
        "phone",
        ""
      )}`;
    },

    provinceAddress() {
      return `${_.get(this.shippingAddress, "wardName", "")} ${_.get(
        this.shippingAddress,
        "districtName",
        ""
      )} ${_.get(this.shippingAddress, "provinceName", "")}`;
    },
  },
  methods: {
    onChangeWard() {
      const dataTemp = this.wards.find(
        (data) => data.id === this.shippingAddress.wardId
      );
      this.shippingAddress.wardName = _.get(dataTemp, "name", "") + ", ";
    },

    onChangeDistrict() {
      if (this.shippingAddress.districtId) {
        this.shippingAddress.wardId = "";
        this.wards = [];
        this.getWards();
        const dataTemp = this.districts.find(
          (data) => data.id === this.shippingAddress.districtId
        );
        this.shippingAddress.districtName = _.get(dataTemp, "name", "") + ", ";
        this.shippingAddress.wardName = "";
      }
    },

    onChangeProvince() {
      this.shippingAddress.districtId = "";
      this.shippingAddress.wardId = "";
      this.districts = [];
      this.wards = [];
      this.getDistricts();
      const dataTemp = this.provinces.find(
        (data) => data.id === this.shippingAddress.provinceId
      );
      this.shippingAddress.provinceName = _.get(dataTemp, "name", "");
      this.shippingAddress.districtName = "";
      this.shippingAddress.wardName = "";
      if (this.shippingAddress.provinceId == 0) {
        this.shippingAddress.districtId = 0;
        this.shippingAddress.wardId = 0;
      }
    },

    async getDistricts() {
      const loader = this.$loading.show();
      const respond = await AddressRepository.districts(
        { limit: 100 },
        this.shippingAddress.provinceId
      );
      loader.hide();
      if (_.get(respond, "success", false)) {
        this.districts = _.get(respond, "data.list_district", []);
        if (this.shippingAddress.districtId) {
          const dataTemp = this.districts.find(
            (data) => data.id === this.shippingAddress.districtId
          );
          this.shippingAddress.districtName =
            _.get(dataTemp, "name", "") + ", ";
        }
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(respond, "message", ""),
        });
      }
    },

    async getWards() {
      const loader = this.$loading.show();
      const respond = await AddressRepository.wards(
        { limit: 100 },
        this.shippingAddress.provinceId,
        this.shippingAddress.districtId
      );
      loader.hide();
      if (_.get(respond, "success", false)) {
        this.wards = _.get(respond, "data.list_ward", []);
        if (this.shippingAddress.wardId) {
          const dataTemp = this.wards.find(
            (data) => data.id === this.shippingAddress.wardId
          );
          this.shippingAddress.wardName = _.get(dataTemp, "name", "") + ", ";
        }
        if (this.wards.length === 0) {
          this.shippingAddress.wardId = 0;
        }
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(respond, "message", ""),
        });
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  font-size: 2.2em;
}
</style>