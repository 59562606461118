<template>
  <v-card class="text-center">
    <template v-if="errorMessage">
      <v-alert type="error">{{ errorMessage }}</v-alert>
    </template>
    <validation-observer ref="validator" v-slot="{ handleSubmit }">
      <v-card-title class="text-sm-left font-weight-bold">
        <template v-if="isCreate">Thêm mới khách hàng</template>
        <template v-else>Cập nhật thông tin khách hàng</template>

        <v-spacer />

        <v-icon aria-label="Close" @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="px-6">
        <v-row>
          <v-col cols="12" md="4">
            <div class="upload-image upload-image--user">
              <v-tooltip bottom v-if="_.get(user, 'avatar.id', null)">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="delete-img"
                    @click="deleteImage"
                    icon
                    small
                    color="default"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
                <span>Xóa ảnh đại diện</span>
              </v-tooltip>
              <img
                v-if="_.get(user, 'avatar.id', null)"
                :src="_.get(user, 'avatar.filePath')"
                @click="chooseImage"
                style="width: 100%"
              />
              <v-img
                v-else
                src="@/assets/default-avatar.jpg"
                @click="chooseImage"
              />
              <input
                ref="fileInput"
                style="display: none"
                type="file"
                accept="image/*"
                @input="onSelectFile"
              />
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  :rules="{
                    required: true,
                    max: 50,
                    /* regex: regex.isUsername, */
                  }"
                  name="Tên tài khoản"
                >
                  <v-text-field
                    v-model="user.username"
                    label="Tên tài khoản *"
                    :error-messages="errors"
                    color="primary"
                    :readonly="!isCreate"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Số điện thoại"
                  :rules="{ regex: regex.isVNPhoneMobile, required: true }"
                >
                  <v-text-field
                    v-model="user.phone"
                    label="Số điện thoại *"
                    :error-messages="errors"
                    color="primary"
                    type="number"
                  />
                </validation-provider>
              </v-col>

              <!-- <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  rules="required|max:255"
                  name="Họ và tên đệm"
                >
                  <v-text-field
                    v-model="user.lastName"
                    label="Họ và tên đệm *"
                    :error-messages="errors"
                    color="primary"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  rules="required|max:50"
                  name="Tên"
                >
                  <v-text-field
                    v-model="user.firstName"
                    label="Tên *"
                    :error-messages="errors"
                    color="primary"
                  />
                </validation-provider>
              </v-col> -->

              <v-col cols="12" md="6" v-if="isCreate">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  :rules="{
                    required: true,
                    min: 6,
                    regex: regex.isPassword,
                    max: 20,
                  }"
                  name="Mật khẩu"
                  vid="password"
                >
                  <v-text-field
                    v-model="user.password"
                    label="Mật khẩu *"
                    :error-messages="errors"
                    color="primary"
                    :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="visiblePassword = !visiblePassword"
                    :type="visiblePassword ? 'text' : 'password'"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" md="6" v-if="isCreate">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  rules="required|confirmed:password"
                  name="Nhập lại mật khẩu"
                >
                  <v-text-field
                    v-model="user.confirm"
                    label="Xác nhận lại mật khẩu *"
                    :error-messages="errors"
                    color="primary"
                    :append-icon="
                      visibleConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="
                      () => (visibleConfirmPassword = !visibleConfirmPassword)
                    "
                    :type="visibleConfirmPassword ? 'password' : 'text'"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Giới tính"
                >
                  <v-autocomplete
                    v-model="user.gender"
                    attach
                    :items="userGenderOptions"
                    :error-messages="errors"
                    item-text="text"
                    item-value="value"
                    label="Giới tính *"
                    color="primary"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :return-value.sync="user.birthday"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="user.birthday"
                      color="primary"
                      label="Ngày sinh"
                      prepend-icon="mdi-calendar-outline"
                      readonly
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="user.birthday"
                    color="secondary"
                    scrollable
                    @change="
                      $refs.datePicker.save(user.birthday), (datePicker = false)
                    "
                    :max="currentDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Email"
              :rules="{
                max: 255,
                email: true,
                regex: regex.isEmail,
              }"
            >
              <v-text-field
                v-model="user.email"
                label="Email"
                :error-messages="errors"
                color="primary"
                type="email"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="3">
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Địa chỉ"
              rules="required"
            >
              <v-text-field
                v-model="user.address"
                label="Địa chỉ"
                :error-messages="errors"
                color="primary"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="3">
            <validation-provider v-slot="{ errors }" name="Nhóm khách hàng">
              <v-autocomplete
                v-model="user.departmentId"
                :items="departments"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                label="Nhóm khách hàng *"
                color="primary"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="3">
            <validation-provider
              v-slot="{ errors }"
              name="Loại tài khoản"
              class="mb-2"
              :rules="{
                required: true,
              }"
            >
              <v-autocomplete
                v-model="user.position"
                :items="customerTypeList"
                :error-messages="errors"
                item-text="name"
                item-value="code"
                label="Loại tài khoản *"
                color="primary"
                class=""
                clearable
                eager
                @change="changeCustomerType()"
              >
              </v-autocomplete>
            </validation-provider>
          </v-col>

          <!-- Cong ty -->
          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.CONG_TY"
          >
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Tên công ty"
              :rules="{
                max: 255,
              }"
            >
              <v-text-field
                v-model="user.customer_profile.companyName"
                label="Tên công ty"
                :error-messages="errors"
                color="primary"
              />
            </validation-provider>
          </v-col>

          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.CONG_TY"
          >
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Người đại diện công ty"
              :rules="{
                max: 255,
              }"
            >
              <v-text-field
                v-model="user.customer_profile.representativeName"
                label="Người đại diện công ty"
                :error-messages="errors"
                color="primary"
              />
            </validation-provider>
          </v-col>

          <!-- Nha thuoc -->
          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.NHA_THUOC"
          >
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Tên nhà thuốc"
              :rules="{
                max: 255,
              }"
            >
              <v-text-field
                v-model="user.customer_profile.nameOfPharmacy"
                label="Tên nhà thuốc"
                :error-messages="errors"
                color="primary"
              />
            </validation-provider>
          </v-col>

          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.NHA_THUOC"
          >
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Dược sỹ phụ trách"
              :rules="{
                max: 255,
              }"
            >
              <v-text-field
                v-model="user.customer_profile.pharmacistInCharge"
                label="Dược sỹ phụ trách"
                :error-messages="errors"
                color="primary"
              />
            </validation-provider>
          </v-col>

          <!-- Phong kham -->
          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.PHONG_KHAM"
          >
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Tên phòng khám"
              :rules="{
                max: 255,
              }"
            >
              <v-text-field
                v-model="user.customer_profile.clinicName"
                label="Tên phòng khám"
                :error-messages="errors"
                color="primary"
              />
            </validation-provider>
          </v-col>

          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.PHONG_KHAM"
          >
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Bác sĩ chuyên môn"
              :rules="{
                max: 255,
              }"
            >
              <v-text-field
                v-model="user.customer_profile.specialistDoctor"
                label="Bác sĩ chuyên môn"
                :error-messages="errors"
                color="primary"
              />
            </validation-provider>
          </v-col>

          <!-- Cong ty, Phong kham, Nha thuoc -->
          <v-col
            cols="12"
            md="3"
            v-if="
              user.position == customerTypeListObj.PHONG_KHAM ||
              user.position == customerTypeListObj.CONG_TY ||
              user.position == customerTypeListObj.NHA_THUOC
            "
          >
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Mã số thuế"
              :rules="{
                max: 255,
              }"
            >
              <v-text-field
                v-model="user.customer_profile.tax"
                label="Mã số thuế"
                :error-messages="errors"
                color="primary"
              />
            </validation-provider>
          </v-col>

          <v-col
            cols="12"
            md="3"
            v-if="
              user.position == customerTypeListObj.PHONG_KHAM ||
              user.position == customerTypeListObj.CONG_TY ||
              user.position == customerTypeListObj.NHA_THUOC
            "
          >
            <v-file-input
              show-size
              v-model="user.customer_profile.businessLicenseImagesFiles"
              accept="image/*"
              label="Giấy phép kinh doanh"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Giấy phép kinh doanh"
              @change="changeFiles('businessLicenseImagesFiles')"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <div
              class="group--product"
              v-if="
                user.customer_profile.businessLicenseImages &&
                user.customer_profile.businessLicenseImages.length
              "
            >
              <div
                v-for="(displayImage, index) in user.customer_profile
                  .businessLicenseImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon
                    class="delete-image-block-icon"
                    small
                    @click="deleteImageItem('businessLicenseImages', index)"
                  >
                    mdi-close
                  </v-icon>
                </div>
                <a :href="_.get(displayImage, 'filePath')" target="_blank">
                  <v-img
                    :src="_.get(displayImage, 'filePath')"
                    width="50px"
                    height="50px"
                    style="border: 1px solid black; border-radius: 5px"
                  />
                </a>
              </div>
            </div>
          </v-col>

          <!--Cong ty-->
          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.CONG_TY"
          >
            <v-file-input
              show-size
              v-model="user.customer_profile.otherLicenseImagesFiles"
              accept="image/*"
              label="Giấy phép khác"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Giấy phép khác"
              @change="changeFiles('otherLicenseImagesFiles')"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <div
              class="group--product"
              v-if="
                user.customer_profile.otherLicenseImages &&
                user.customer_profile.otherLicenseImages.length
              "
            >
              <div
                v-for="(displayImage, index) in user.customer_profile
                  .otherLicenseImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon
                    class="delete-image-block-icon"
                    small
                    @click="deleteImageItem('otherLicenseImages', index)"
                  >
                    mdi-close
                  </v-icon>
                </div>
                <a :href="_.get(displayImage, 'filePath')" target="_blank">
                  <v-img
                    :src="_.get(displayImage, 'filePath')"
                    width="50px"
                    height="50px"
                    style="border: 1px solid black; border-radius: 5px"
                  />
                </a>
              </div>
            </div>
          </v-col>

          <!-- Nha thuoc -->
          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.NHA_THUOC"
          >
            <v-file-input
              show-size
              v-model="
                user.customer_profile.certificateOfEligibilityForPharmacyBusinessImagesFiles
              "
              accept="image/*"
              label="Giấy chứng nhận đủ điều kiện kinh doanh dược"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Giấy chứng nhận đủ điều kiện kinh doanh dược"
              @change="changeFiles('certificateOfEligibilityForPharmacyBusinessImagesFiles')"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <div
              class="group--product"
              v-show="
                user.customer_profile
                  .certificateOfEligibilityForPharmacyBusinessImages &&
                user.customer_profile
                  .certificateOfEligibilityForPharmacyBusinessImages.length
                  ? true
                  : false
              "
            >
              <div
                v-for="(displayImage, index) in user.customer_profile
                  .certificateOfEligibilityForPharmacyBusinessImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon
                    class="delete-image-block-icon"
                    small
                    @click="
                      deleteImageItem(
                        'certificateOfEligibilityForPharmacyBusinessImages',
                        index
                      )
                    "
                  >
                    mdi-close
                  </v-icon>
                </div>
                <a :href="_.get(displayImage, 'filePath')" target="_blank">
                  <v-img
                    :src="_.get(displayImage, 'filePath')"
                    width="50px"
                    height="50px"
                    style="border: 1px solid black; border-radius: 5px"
                  />
                </a>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.NHA_THUOC"
          >
            <v-file-input
              show-size
              v-model="
                user.customer_profile
                  .certificateOfGoodPracticeForADrugRetailerImagesFiles
              "
              accept="image/*"
              label="Giấy chứng nhận đạt thực hành tốt cơ sở bán lẻ thuốc"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Giấy chứng nhận đạt thực hành tốt cơ sở bán lẻ thuốc"
              @change="
                changeFiles(
                  'certificateOfGoodPracticeForADrugRetailerImagesFiles'
                )
              "
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <div
              class="group--product"
              v-show="
                user.customer_profile
                  .certificateOfGoodPracticeForADrugRetailerImages &&
                user.customer_profile
                  .certificateOfGoodPracticeForADrugRetailerImages.length
                  ? true
                  : false
              "
            >
              <div
                v-for="(displayImage, index) in user.customer_profile
                  .certificateOfGoodPracticeForADrugRetailerImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon
                    class="delete-image-block-icon"
                    small
                    @click="
                      deleteImageItem(
                        'certificateOfGoodPracticeForADrugRetailerImages',
                        index
                      )
                    "
                  >
                    mdi-close
                  </v-icon>
                </div>
                <a :href="_.get(displayImage, 'filePath')" target="_blank">
                  <v-img
                    :src="_.get(displayImage, 'filePath')"
                    width="50px"
                    height="50px"
                    style="border: 1px solid black; border-radius: 5px"
                  />
                </a>
              </div>
            </div>
          </v-col>
          <!-- Phong kham -->
          <v-col
            cols="12"
            md="3"
            v-if="user.position == customerTypeListObj.PHONG_KHAM"
          >
            <v-file-input
              show-size
              v-model="
                user.customer_profile
                  .licenseToOperateMedicalExaminationAndTreatmentFacilityImagesFiles
              "
              accept="image/*"
              label="Giấy phép hoạt động cơ sở khám chữa bệnh"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Giấy phép hoạt động cơ sở khám chữa bệnh"
              @change="
                changeFiles(
                  'licenseToOperateMedicalExaminationAndTreatmentFacilityImagesFiles'
                )
              "
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <div
              class="group--product"
              v-show="
                user.customer_profile
                  .licenseToOperateMedicalExaminationAndTreatmentFacilityImages &&
                user.customer_profile
                  .licenseToOperateMedicalExaminationAndTreatmentFacilityImages
                  .length
                  ? true
                  : false
              "
            >
              <div
                v-for="(displayImage, index) in user.customer_profile
                  .licenseToOperateMedicalExaminationAndTreatmentFacilityImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon
                    class="delete-image-block-icon"
                    small
                    @click="
                      deleteImageItem(
                        'licenseToOperateMedicalExaminationAndTreatmentFacilityImages',
                        index
                      )
                    "
                  >
                    mdi-close
                  </v-icon>
                </div>
                <a :href="_.get(displayImage, 'filePath')" target="_blank">
                  <v-img
                    :src="_.get(displayImage, 'filePath')"
                    width="50px"
                    height="50px"
                    style="border: 1px solid black; border-radius: 5px"
                  />
                </a>
              </div>
            </div>
          </v-col>

          <!-- Nha thuoc, Phong kham -->
          <v-col
            cols="12"
            md="3"
            v-if="
              user.position == customerTypeListObj.NHA_THUOC ||
              user.position == customerTypeListObj.PHONG_KHAM
            "
          >
            <v-file-input
              show-size
              v-model="user.customer_profile.practicingCertificateImagesFiles"
              accept="image/*"
              label="Giấy chứng nhận hành nghề"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Giấy chứng nhận hành nghề"
              @change="changeFiles('practicingCertificateImagesFiles')"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <div
              class="group--product"
              v-show="
                user.customer_profile.practicingCertificateImages &&
                user.customer_profile.practicingCertificateImages.length
                  ? true
                  : false
              "
            >
              <div
                v-for="(displayImage, index) in user.customer_profile
                  .practicingCertificateImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon
                    class="delete-image-block-icon"
                    small
                    @click="
                      deleteImageItem('practicingCertificateImages', index)
                    "
                  >
                    mdi-close
                  </v-icon>
                </div>
                <a :href="_.get(displayImage, 'filePath')" target="_blank">
                  <v-img
                    :src="_.get(displayImage, 'filePath')"
                    width="50px"
                    height="50px"
                    style="border: 1px solid black; border-radius: 5px"
                  />
                </a>
              </div>
            </div>
          </v-col>

          <v-col v-if="user.position == customerTypeListObj.PHONG_KHAM">
            <v-checkbox
              v-model="user.customer_profile.isClinic"
              label="Thông tin nhà thuốc tại phòng khám"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
            v-if="
              user.position == customerTypeListObj.PHONG_KHAM &&
              user.customer_profile.isClinic
            "
          >
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              name="Tên nhà thuốc"
              :rules="{
                max: 255,
              }"
            >
              <v-text-field
                v-model="user.customer_profile.nameOfPharmacy"
                label="Tên nhà thuốc"
                :error-messages="errors"
                color="primary"
              />
            </validation-provider>
          </v-col>

          <v-col
            cols="12"
            md="3"
            v-if="
              user.position == customerTypeListObj.PHONG_KHAM &&
              user.customer_profile.isClinic
            "
          >
            <v-file-input
              show-size
              v-model="user.customer_profile.businessLicenseClinicImagesFiles"
              accept="image/*"
              label="Giấy phép kinh doanh"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Giấy phép kinh doanh"
              @change="changeFiles('businessLicenseClinicImagesFiles')"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <div
              class="group--product"
              v-if="
                user.customer_profile.businessLicenseClinicImages &&
                user.customer_profile.businessLicenseClinicImages.length
              "
            >
              <div
                v-for="(displayImage, index) in user.customer_profile
                  .businessLicenseClinicImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon
                    class="delete-image-block-icon"
                    small
                    @click="
                      deleteImageItem('businessLicenseClinicImages', index)
                    "
                  >
                    mdi-close
                  </v-icon>
                </div>
                <a :href="_.get(displayImage, 'filePath')" target="_blank">
                  <v-img
                    :src="_.get(displayImage, 'filePath')"
                    width="50px"
                    height="50px"
                    style="border: 1px solid black; border-radius: 5px"
                  />
                </a>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
            v-if="
              user.position == customerTypeListObj.PHONG_KHAM &&
              user.customer_profile.isClinic
            "
          >
            <v-file-input
              show-size
              v-model="
                user.customer_profile.licenseToOperateRetailClinicImagesFiles
              "
              accept="image/*"
              label="Giấy chứng nhận đạt thực hành tốt cơ sở bán lẻ thuốc"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Giấy chứng nhận đạt thực hành tốt cơ sở bán lẻ thuốc"
              @change="changeFiles('licenseToOperateRetailClinicImagesFiles')"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <div
              class="group--product"
              v-show="
                user.customer_profile.licenseToOperateRetailClinicImages &&
                user.customer_profile.licenseToOperateRetailClinicImages.length
                  ? true
                  : false
              "
            >
              <div
                v-for="(displayImage, index) in user.customer_profile
                  .licenseToOperateRetailClinicImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon
                    class="delete-image-block-icon"
                    small
                    @click="
                      deleteImageItem(
                        'licenseToOperateRetailClinicImages',
                        index
                      )
                    "
                  >
                    mdi-close
                  </v-icon>
                </div>
                <a :href="_.get(displayImage, 'filePath')" target="_blank">
                  <v-img
                    :src="_.get(displayImage, 'filePath')"
                    width="50px"
                    height="50px"
                    style="border: 1px solid black; border-radius: 5px"
                  />
                </a>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
            v-if="
              user.position == customerTypeListObj.PHONG_KHAM &&
              user.customer_profile.isClinic
            "
          >
            <v-file-input
              show-size
              v-model="
                user.customer_profile.certificateOfPracticeClinicImagesFiles
              "
              accept="image/*"
              label="Giấy chứng nhận hành nghề"
              multiple
              prepend-icon="mdi-paperclip"
              placeholder="Giấy chứng nhận hành nghề"
              @change="changeFiles('certificateOfPracticeClinicImagesFiles')"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <div
              class="group--product"
              v-show="
                user.customer_profile.certificateOfPracticeClinicImages &&
                user.customer_profile.certificateOfPracticeClinicImages.length
                  ? true
                  : false
              "
            >
              <div
                v-for="(displayImage, index) in user.customer_profile
                  .certificateOfPracticeClinicImages"
                :key="displayImage.id"
                class="display-image-block"
              >
                <div class="delete-image-block">
                  <v-icon
                    class="delete-image-block-icon"
                    small
                    @click="
                      deleteImageItem(
                        'certificateOfPracticeClinicImages',
                        index
                      )
                    "
                  >
                    mdi-close
                  </v-icon>
                </div>
                <a :href="_.get(displayImage, 'filePath')" target="_blank">
                  <v-img
                    :src="_.get(displayImage, 'filePath')"
                    width="50px"
                    height="50px"
                    style="border: 1px solid black; border-radius: 5px"
                  />
                </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="group-btn--form">
        <v-btn color="success" @click.prevent="handleSubmit(saveCustomer)"
          >Lưu lại</v-btn
        >
        <v-spacer />

        <v-btn color="primary" @click="cancel">Hủy bỏ</v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { formatDate } from "../../../helpers/utils";
import {
  regex,
  userPositionOptions,
  userGenderOptions,
  userPositions,
  MAX_SIZE_IMAGE,
  PAGE_LIMIT,
} from "../../../helpers/constants";
import {
  customerTypeList,
  customerTypeListObj,
} from "@/pages/customer/components/customerContant";
import TokenService from "../../../helpers/token";

const ImageRepository = RepositoryFactory.get("image");
const CustomerRepository = RepositoryFactory.get("customer");
const DepartmentRepository = RepositoryFactory.get("department");

export default {
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      datePicker: false,
      regex: regex,
      user: {
        customer_profile: {},
      },
      isManager: false,
      isCreate: true,
      roles: [],
      productGroups: [],
      userPositionOptions,
      userPositions,
      userGenderOptions,
      errorMessage: "",
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Kích thước hình đại diện phải nhỏ hơn 2 MB!",
      ],
      visibleExtPassword: true,
      visibleConfirmPassword: true,
      visiblePassword: true,
      customerTypeList: customerTypeList,
      departmentsForSaleLeader: [],
      customerTypeListObj,
      departments: [],
    };
  },
  created() {
    this.getDepartmentList();
    if (this.$route.params.id) {
      this.isCreate = false;
      this.getCustomerDetail();
    }
    delete this.user.firstName;
    delete this.user.password;
  },
  computed: {
    currentDate() {
      const d = new Date();
      return d.toISOString().slice(0, 10);
    },
  },
  watch: {},
  methods: {
    async getDepartmentList() {
      const response = await DepartmentRepository.list({ limit: PAGE_LIMIT });
      if (_.get(response, "success", false)) {
        this.departments = _.get(response, "data.list_department", []);
      }
    },

    async getCustomerDetail() {
      this.loading = this.$loading.show();

      const response = await CustomerRepository.getDetail(
        this.$route.params.id
      );

      this.loading.hide();

      if (response.success) {
        this.user = _.get(response, "data", {});
        this.user.birthday = formatDate(this.user.birthday);
        if (_.isEmpty(this.user.customer_profile)) {
          let customerProfile = {
            businessLicenseClinicImageIds: [],
            businessLicenseClinicImages: [],
            businessLicenseImageIds: [],
            businessLicenseImages: [],
            certificateOfEligibilityForPharmacyBusinessImageIds: [],
            certificateOfEligibilityForPharmacyBusinessImages: [],
            certificateOfGoodPracticeForADrugRetailerImageIds: [],
            certificateOfGoodPracticeForADrugRetailerImages: [],
            certificateOfPracticeClinicImageIds: [],
            certificateOfPracticeClinicImages: [],
            licenseToOperateMedicalExaminationAndTreatmentFacilityImageIds: [],
            licenseToOperateMedicalExaminationAndTreatmentFacilityImages: [],
            licenseToOperateRetailClinicImageIds: [],
            licenseToOperateRetailClinicImages: [],
          };
          this.user["customer_profile"] = customerProfile;
        }
      }
    },

    deleteImage() {
      this.$set(this.user, "avatar", null);
      this.$set(this.user, "avatarId", null);
    },

    chooseImage() {
      this.$refs.fileInput.click();
    },

    async onSelectFile() {
      const loader = this.$loading.show();
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files.length) {
        await this.uploadOneFile(files[0]);
      }
      loader.hide();
    },

    async uploadOneFile(file) {
      const uploadResponse = await ImageRepository.upload(file);
      if (_.get(uploadResponse, "success", false)) {
        const newImage = _.get(uploadResponse, "data", {});
        const avatar = {
          id: _.get(newImage, "id", null),
          filePath: _.get(newImage, "filePath", ""),
        };
        this.$set(this.user, "avatar", avatar);
        this.$set(this.user, "avatarId", avatar.id);
      } else {
        this.$swal("Upload ảnh đại diện không thành công");
      }
    },

    async saveCustomer() {
      let profile = this.user.customer_profile;
      let customerProfile = {
        companyName: profile.companyName,
        nameOfTheMedicalFacility: profile.nameOfTheMedicalFacility,
        representativeName: profile.representativeName,
        nameOfPharmacy: profile.nameOfPharmacy,
        tax: profile.tax,
        specialistDoctor: profile.specialistDoctor,
        clinicName: profile.clinicName,
        isClinic: profile.isClinic,
        businessLicenseImageIds:
          _.isArray(profile.businessLicenseImages) &&
          profile.businessLicenseImages.length
            ? profile.businessLicenseImages.map((e) => e.id).toString()
            : "",
        // Giấy phép kinh doanh của nhà thuốc từ phòng khám
        businessLicenseClinicImageIds:
          _.isArray(profile.businessLicenseClinicImages) &&
          profile.businessLicenseClinicImages.length
            ? profile.businessLicenseClinicImages.map((e) => e.id).toString()
            : "",
        // Giấy chứng nhận đạt thực hành tốt cơ sở bán lẻ thuốc của nhà thuốc từ phòng khám 
        licenseToOperateRetailClinicImageIds:
          _.isArray(profile.licenseToOperateRetailClinicImages) &&
          profile.licenseToOperateRetailClinicImages.length
            ? profile.licenseToOperateRetailClinicImages.map((e) => e.id).toString()
            : "",
        // Giấy chứng nhận hành nghề của nhà thuốc từ phòng khám
        certificateOfPracticeClinicImageIds:
          _.isArray(profile.certificateOfPracticeClinicImages) &&
          profile.certificateOfPracticeClinicImages.length
            ? profile.certificateOfPracticeClinicImages.map((e) => e.id).toString()
            : "",
        otherLicenseImageIds:
          _.isArray(profile.otherLicenseImages) &&
          profile.otherLicenseImages.length
            ? profile.otherLicenseImages.map((e) => e.id).toString()
            : "",
        certificateOfEligibilityForPharmacyBusinessImageIds:
          _.isArray(
            profile.certificateOfEligibilityForPharmacyBusinessImages
          ) && profile.certificateOfEligibilityForPharmacyBusinessImages.length
            ? profile.certificateOfEligibilityForPharmacyBusinessImages
                .map((e) => e.id)
                .toString()
            : "",
        certificateOfGoodPracticeForADrugRetailerImageIds:
          _.isArray(profile.certificateOfGoodPracticeForADrugRetailerImages) &&
          profile.certificateOfGoodPracticeForADrugRetailerImages.length
            ? profile.certificateOfGoodPracticeForADrugRetailerImages
                .map((e) => e.id)
                .toString()
            : "",
        practicingCertificateImageIds:
          _.isArray(profile.practicingCertificateImages) &&
          profile.practicingCertificateImages.length
            ? profile.practicingCertificateImages
                .map((e) => e.id)
                .toString()
            : "",
        certificateOfGoodPracticeForADrugRetailerImageIds:
          _.isArray(profile.certificateOfGoodPracticeForADrugRetailerImages) &&
          profile.certificateOfGoodPracticeForADrugRetailerImages.length
            ? profile.certificateOfGoodPracticeForADrugRetailerImages
                .map((e) => e.id)
                .toString()
            : "",
        licenseToOperateMedicalExaminationAndTreatmentFacilityImageIds:
          _.isArray(
            profile.licenseToOperateMedicalExaminationAndTreatmentFacilityImages
          ) &&
          profile.licenseToOperateMedicalExaminationAndTreatmentFacilityImages
            .length
            ? profile.licenseToOperateMedicalExaminationAndTreatmentFacilityImages
                .map((e) => e.id)
                .toString()
            : "",
      };

      if (this.isCreate) {
        let customer = {
          username: _.trim(this.user.username),
          password: this.user.password,
          firstName: _.trim(this.user.firstName),
          lastName: _.trim(this.user.lastName),
          position: this.user.position,
          email: this.user.email,
          phone: this.user.phone,
          address: this.user.address,
          departmentId: this.user.departmentId,
          gender: this.user.gender,
          birthday: this.user.birthday,
          avatarId: this.user.avatarId,
          listDepartment: this.user.listdepartmentId,
          customerProfile,
        };
        const loader = this.$loading.show();
        const response = await CustomerRepository.create(customer);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.$swal({
            title: "Thêm khách hàng thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.$router.push({
              name: "CustomerList",
            });
          });
        }
      } else {
        const customer = {
          firstName: _.trim(this.user.firstName),
          lastName: _.trim(this.user.lastName),
          position: this.user.position,
          email: this.user.email,
          phone: this.user.phone,
          address: this.user.address,
          departmentId: this.user.departmentId,
          gender: this.user.gender,
          birthday: this.user.birthday,
          avatarId: this.user.avatarId,
          listDepartment: this.user.listdepartmentId,
        };
        const loader = this.$loading.show();
        const response = await CustomerRepository.update(this.user.id, {
          customer,
          customerProfile,
        });
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật khách hàng thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.$router.push({
              name: "CustomerList",
            });
          });
        }
      }
    },

    deleteImageItem(field, index) {
      this.user["customer_profile"][field].splice(index, 1);
    },

    async changeFiles(field) {
      if (
        this.user["customer_profile"][field].length < 1 ||
        this.user["customer_profile"][field].length > 10
      ) {
        if (this.user["customer_profile"][field].length > 10) {
          this.setAlertMessage(
            true,
            "error",
            "Không thể tải lớn hơn 10 file đồng thời!"
          );
        }
        return;
      }
      let checkError = false;
      this.user["customer_profile"][field].forEach((dataFile) => {
        if (dataFile.size > MAX_SIZE_IMAGE) {
          checkError = true;
        }
      });
      if (checkError) {
        this.setAlertMessage(
          true,
          "error",
          "Không thể tải file ảnh lớn hơn 2 MB !"
        );
        return;
      }

      this.loading = this.$loading.show();
      const response = await ImageRepository.uploads(
        this.user["customer_profile"][field]
      );
      this.loading.hide();
      if (response.error) {
        this.setAlertMessage(true, "error", "Tải file thất bại!");
      } else {
        const tmpItems = _.get(response, "data");
        let fieldImg = field.replace("Files", "");
        if (
          this.user["customer_profile"][fieldImg] &&
          _.isArray(this.user["customer_profile"][fieldImg])
        ) {
          this.user["customer_profile"][fieldImg] = [
            ...this.user["customer_profile"][fieldImg],
            ...tmpItems,
          ];
        } else {
          this.user["customer_profile"][fieldImg] = tmpItems;
        }
      }
    },
    cancel() {
      this.$router.push({
        name: "CustomerList",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.service-form-pictures {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa !important;
  line-height: 1.4;
}
.group--product {
  display: flex;
  flex-wrap: wrap;
}
.display-image-block {
  position: relative;
  width: fit-content;
  margin-right: 15px;
  margin-bottom: 15px;
}
.delete-image-block {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 9;
}
.delete-image-block-icon {
  border-radius: 50%;
  background: #c7c7c7;
}
.v-card--material {
  overflow: hidden;
}
.clear-both {
  clear: both;
}
.display-none {
  display: none;
}
.thumbnail-validation {
  .v-text-field__slot {
    display: none !important;
  }
}
.v-input--radio-group__input .v-input--selection-controls__ripple {
  left: -12px;
}
.errors-span-required-content {
  display: inline-block;
  height: 20px;
  width: 100%;
  text-align: left;
  color: #ff5252;
  font-size: 12px;
}
.title-span-content {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  margin-bottom: 8px;
}
</style>