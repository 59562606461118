var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto px-0 ma-0 s-layout-small customer-info-wrapper",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('p',[_vm._v("Thông Tin Khách Hàng")])]),_c('validation-provider',{attrs:{"name":"Tên người liên hệ","rules":{ required: true, max:255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":"Tên người liên hệ","error-messages":errors,"color":"primary"},model:{value:(_vm.dataForm.customers[0].fullName),callback:function ($$v) {_vm.$set(_vm.dataForm.customers[0], "fullName", $$v)},expression:"dataForm.customers[0].fullName"}},[_c('template',{slot:"label"},[_c('div',[_vm._v("Tên người liên hệ "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])])],2)]}}])}),_c('label',[_vm._v("Đối tượng liên hệ "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('v-radio-group',{staticClass:"ma-0 pb-0 mt-1 pb-2",attrs:{"row":""},on:{"change":_vm.changePosition},model:{value:(_vm.dataForm.customers[0].position),callback:function ($$v) {_vm.$set(_vm.dataForm.customers[0], "position", $$v)},expression:"dataForm.customers[0].position"}},[_c('v-radio',{attrs:{"label":"Con","value":"child"}}),_c('v-radio',{attrs:{"label":"Bố","value":"father"}}),_c('v-radio',{attrs:{"label":"Mẹ","value":"mother"}}),_c('v-radio',{attrs:{"label":"Khác","value":"other"}})],1),_vm._l((_vm.childCustomers),function(customer,indexCustomer){return [(_vm.showChild)?_c('div',{key:'childInfo-' + indexCustomer},[(indexCustomer > 0)?_c('div',{staticStyle:{"text-align":"right","margin-right":"10px"}},[_c('v-btn',{staticStyle:{"margin-right":"-20px"},attrs:{"text":"","small":""},on:{"click":function($event){return _vm.onClickRemoveCustomer(customer.customerId)}}},[_c('v-icon',[_vm._v("mdi-close-box")])],1)],1):_vm._e(),_c('validation-provider',{key:'childName-' + indexCustomer,attrs:{"vid":("Tên con " + (indexCustomer +1)),"name":"Tên Con","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0 pb-2 pt-0",attrs:{"label":("Tên con " + (indexCustomer +1)),"color":"primary","error-messages":errors},model:{value:(customer.fullName),callback:function ($$v) {_vm.$set(customer, "fullName", $$v)},expression:"customer.fullName"}},[_c('template',{slot:"label"},[_c('div',[_vm._v("Tên con "+_vm._s(indexCustomer +1)+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])])],2)]}}],null,true)}),_c('validation-provider',{key:'childBirthday-' + indexCustomer,attrs:{"name":"Năm sinh của con","vid":("Năm sinh của con " + (indexCustomer +1)),"rules":"required|min_value:1900"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-select-year',{staticClass:"birthday-child mobile-hidden",attrs:{"label":("Năm sinh của con " + (indexCustomer +1)),"error-messages":errors,"required":true},model:{value:(customer.birthday),callback:function ($$v) {_vm.$set(customer, "birthday", $$v)},expression:"customer.birthday"}}),_c('v-select',{staticClass:"birthday-child mobile-show",attrs:{"items":_vm.selectYears,"label":("Năm sinh của con " + (indexCustomer +1)),"error-messages":errors},model:{value:(customer.birthday),callback:function ($$v) {_vm.$set(customer, "birthday", $$v)},expression:"customer.birthday"}},[_c('template',{slot:"label"},[_c('div',[_vm._v("Năm sinh của con "+_vm._s(indexCustomer +1)+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])])],2)]}}],null,true)})],1):_vm._e()]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButtonAddChild),expression:"showButtonAddChild"}],staticStyle:{"text-align":"right"}},[_c('v-btn',{staticClass:"ma-0 add-child-btn",attrs:{"small":""},on:{"click":_vm.onClickAddChild}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"add-child-text"},[_vm._v("Thêm con")])],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('validation-provider',{staticStyle:{"width":"100%"},attrs:{"name":"Số Điện thoại","rules":{ regex: _vm.regex.isVNPhoneMobile, required:true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0 pb-2 pt-0",attrs:{"label":"Số Điện thoại","error-messages":errors,"color":"primary"},model:{value:(_vm.dataForm.customers[0].mobile),callback:function ($$v) {_vm.$set(_vm.dataForm.customers[0], "mobile", $$v)},expression:"dataForm.customers[0].mobile"}},[_c('template',{slot:"label"},[_c('div',[_vm._v("Số Điện thoại "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])])],2)]}}])}),(_vm.showChat)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"icon-fb",attrs:{"icon":"","small":"","color":"info"}},on),[_c('v-icon',[_vm._v("mdi-facebook-messenger")])],1)]}}],null,false,2896601151)},[_c('span',[_vm._v("Nhắn tin")])]):_vm._e()],1),_c('validation-provider',{attrs:{"name":"Số Điện thoại khác","rules":{ regex: _vm.regex.isVNPhoneMobile, }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":"Số điện thoại khác","color":"primary","error-messages":errors},model:{value:(_vm.dataForm.customers[0].phones[0]),callback:function ($$v) {_vm.$set(_vm.dataForm.customers[0].phones, 0, $$v)},expression:"dataForm.customers[0].phones[0]"}})]}}])}),_c('validation-provider',{attrs:{"name":"Email","rules":{ max:255, email: true, regex: _vm.regex.isEmail }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0 pt-0",attrs:{"label":"Email","error-messages":errors,"color":"primary"},model:{value:(_vm.dataForm.customers[0].email),callback:function ($$v) {_vm.$set(_vm.dataForm.customers[0], "email", $$v)},expression:"dataForm.customers[0].email"}})]}}])}),_c('label',[_vm._v("Điạ chỉ")]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Tỉnh"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ma-0 pb-2 pt-0",attrs:{"items":_vm.provinces,"item-text":"ten","item-value":"id","label":"Tỉnh","error-messages":errors},on:{"change":_vm.onChangeProvince},model:{value:(_vm.dataForm.customers[0].provinceId),callback:function ($$v) {_vm.$set(_vm.dataForm.customers[0], "provinceId", $$v)},expression:"dataForm.customers[0].provinceId"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Quận/Huyện","rules":{required: _vm.districts.length > 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ma-0 pb-2 pt-0",attrs:{"readonly":_vm.stepOrder === 3,"items":_vm.districts,"item-text":"ten","item-value":"id","label":"Quận/Huyện","error-messages":errors},on:{"change":_vm.onChangeDistrict},model:{value:(_vm.dataForm.customers[0].districtId),callback:function ($$v) {_vm.$set(_vm.dataForm.customers[0], "districtId", $$v)},expression:"dataForm.customers[0].districtId"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Phường/Xã","rules":{required: _vm.wards.length > 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ma-0 pb-2 pt-0",attrs:{"label":"Phường/Xã","items":_vm.wards,"item-text":"ten","item-value":"id","error-messages":errors},model:{value:(_vm.dataForm.customers[0].wardId),callback:function ($$v) {_vm.$set(_vm.dataForm.customers[0], "wardId", $$v)},expression:"dataForm.customers[0].wardId"}})]}}])})],1)],1),_c('validation-provider',{attrs:{"name":"Địa chỉ chi tiết(số nhà, đường, thôn...)","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"ma-0 pa-0",attrs:{"label":"Địa chỉ chi tiết(số nhà, đường, thôn...)","rows":"3","error-messages":errors},model:{value:(_vm.dataForm.customers[0].detailAddress),callback:function ($$v) {_vm.$set(_vm.dataForm.customers[0], "detailAddress", $$v)},expression:"dataForm.customers[0].detailAddress"}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }