<template>
  <v-container id="product-list" fluid tag="section">
    <div class="md-layout">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>

      <FilterForm :filterForm="filterForm" @product-filter="search" />

      <div class="text-sm-right mb-3">
        <!-- <v-btn
          v-if="checkAuthorization('product_update')"
          v-show="selected.length ? true : false"
          color="blue"
          dark
          class="mb-2 mr-2"
          @click="changeEndDateMultipleItem"
        >
          Chọn thời gian sử dụng
        </v-btn> -->
        <v-btn
          v-if="checkAuthorization('product_delete')"
          v-show="selected.length ? true : false"
          color="default"
          dark
          class="mb-2 mr-2"
          @click="deleteMultipleItem"
        >
          Xóa tất cả
        </v-btn>
        <v-btn
          v-if="checkAuthorization('product_create')"
          color="primary"
          dark
          class="mb-2"
          @click="gotoNewPage"
        >
          <v-icon>mdi-plus</v-icon>Thêm Mới
        </v-btn>
      </div>

      <v-tabs
        class="mt-0 pl-3"
        @change="changeTab"
        v-model="tabIndex"
        color="primary"
        style="background-color: #ffffff; padding: 5px 0px 10px 5px"
      >
        <v-tab :key="0">Tất Cả</v-tab>
        <v-tab v-for="(tab, i) in productStatusOptions" :key="i + 1">{{
          tab.text
        }}</v-tab>
      </v-tabs>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="tableData"
        :single-select="singleSelect"
        item-key="id"
        :show-select="checkAuthorization('product_delete')"
        class="table-layout pa-5"
        hide-default-footer
      >
        <template v-slot:top />
        <template v-slot:[`item.thumnail`]="{ item }">
          <div>
            <v-img :src="getThumbnailUrl(item.images)" max-width="120" />
          </div>
        </template>

        <template v-slot:no-data>
          <common-no-result />
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom v-if="item.status === 2">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="convertStatus(item.id, productStatus.INACTIVE)"
                size="22"
                icon
                small
                dark
                color="orange"
                v-on="on"
                ><v-icon>mdi-minus-circle</v-icon></v-btn
              >
            </template>
            <span>Chuyển sang trạng thái ngừng hoạt động</span>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="convertStatus(item.id, productStatus.ACTIVE)"
                size="22"
                icon
                small
                dark
                color="green"
                v-on="on"
                ><v-icon>mdi-check-bold</v-icon></v-btn
              >
            </template>
            <span>Chuyển sang trạng thái hoạt động</span>
          </v-tooltip>

          <v-tooltip bottom v-if="checkAuthorization('product_update')">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mr-2"
                @click="editItem(item)"
                icon
                small
                color="primary"
                dark
                v-on="on"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>

            <span>Cập nhật thông tin sản phẩm</span>
          </v-tooltip>
          <v-tooltip bottom v-if="checkAuthorization('product_delete')">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="deleteItem(item)"
                size="22"
                icon
                small
                dark
                color="red"
                v-on="on"
                ><v-icon>mdi-window-close</v-icon></v-btn
              >
            </template>
            <span>Xóa sản phẩm</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- Pagination -->

      <v-row style="padding: 20px 20px">
        <v-col cols="12" sm="6" />
        <v-col cols="12" sm="6">
          <v-pagination
            v-if="totalItem > limit"
            v-model="currentPage"
            :length="getTotalItem(totalItem, limit)"
            class="justify-sm-end"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          />
        </v-col>
      </v-row>
    </div>
    <!-- <modal-form-end-date
      :selected="selected"
      :listTypeEndDate="listTypeEndDate"
    /> -->
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import FilterForm from "./components/FilterForm";
import {
  productStatusOptions,
  productStatus,
} from "../../../helpers/constants";
import { formatPrice } from "../../../helpers/utils";
import ModalFormEndDate from "@/pages/products/product/components/ModalFormEndDate";

const ProductRepository = RepositoryFactory.get("product");

export default {
  components: { ModalFormEndDate, FilterForm },
  data() {
    return {
      singleSelect: false,
      selected: [],
      tableData: [],
      headers: [
        {
          text: "Mã sản phẩm",
          align: "center",
          sortable: false,
          value: "code",
        },
        { text: "Tên sản phẩm", value: "name", align: "center" },
        { text: "Hình ảnh", value: "thumnail", align: "center" },
        { text: "Giá bán lẻ (VND)", value: "retailPrice", align: "center" },
        { text: "Giá gốc (VND)", value: "cost", align: "center" },
        { text: "Giảm giá (VND)", value: "discountPrice", align: "center" },
        { text: "Nhóm sản phẩm", value: "productFolder.name", align: "center" },
        { text: "Đơn vị", value: "productUnit.name", align: "center" },
        {
          text: "Nhà sản xuất",
          value: "productManufacture.name",
          align: "center",
        },
      ],
      errorMessage: "",
      loading: false,
      filterForm: {},
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      productStatusOptions,
      productStatus,
      productFolderId: null,
      unitId: null,
      tabIndex: 0,
      // listTypeEndDate: [
      //   {
      //     type: "day",
      //     title: "Chọn ngày",
      //   },
      //   {
      //     type: "month",
      //     title: "Chọn tháng",
      //   },
      // ],
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.fetchTableData();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.fetchTableData();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  mounted() {
    this.fetchTableData();
  },
  created() {
    Bus.$on("product-list-reload", () => {
      this.fetchTableData();
    });
    if (
      this.checkAuthorization("product_update") ||
      this.checkAuthorization("product_create")
    ) {
      this.headers.push({
        text: "Thao tác",
        value: "action",
        align: "center",
        sortable: false,
      });
    }
  },
  methods: {
    changeTab() {
      if (this.tabIndex === 0) {
        this.filterForm.status = null;
      } else if (this.tabIndex === 1) {
        this.filterForm.status = productStatus.DRAFF;
      } else if (this.tabIndex === 2) {
        this.filterForm.status = productStatus.ACTIVE;
      } else if (this.tabIndex === 3) {
        this.filterForm.status = productStatus.INACTIVE;
      }
      this.currentPage = 1;
      this.fetchTableData();
    },
    search(filterForm) {
      if (filterForm) {
        this.filterForm = filterForm;
      }
      this.fetchTableData();
    },

    async convertStatus(id, status) {
      const statusProduct = { status: status };
      this.$swal({
        title: "Chuyển đổi trạng thái",
        text: "Bạn có thực sự chắc chắn ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const response = await ProductRepository.updateStatus(
            id,
            statusProduct
          );
          if (response.success) {
            this.$swal({
              title: "Chuyển đổi thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              // window.location.reload()
              Bus.$emit("product-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(response, "message", ""),
            });
          }
        }
      });
    },

    setAlertMessage(alert, type, text) {
      this.alert = alert;
      this.alertMessageText = text;
      this.alertMessageType = type;
    },

    getThumbnailUrl(images) {
      if (_.isArray(images) && images.length) {
        let isThumbnail;
        let url = "";
        _.forEach(images, function (image) {
          isThumbnail = _.get(image, "ImageItem.isThumbnail", false);
          if (isThumbnail) {
            url = _.get(image, "filePath", "");
          }
        });
        return url;
      }
      return "";
    },

    gotoNewPage() {
      this.$router.push({
        name: "CreateProduct",
      });
    },

    editItem(item) {
      this.$router.push({
        name: "EditProduct",
        params: {
          id: _.get(item, "id", 0),
        },
      });
    },

    async deleteItem(item) {
      this.$swal({
        title: "Xóa sản phẩm",
        text: "Bạn có thực sự muốn xoá sản phẩm này?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const response = await ProductRepository.deleteOneItem(
            _.get(item, "id", 0)
          );
          loader.hide();
          if (response.success) {
            this.$swal({
              title: "Xoá thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("product-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(response, "message", ""),
            });
          }
        }
      });
    },

    async deleteMultipleItem() {
      this.$swal({
        title: "Xóa toàn bộ sản phẩm",
        text: "Bạn có thực sự muốn xoá các sản phẩm này?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const response = await ProductRepository.deleteManyItems(
            this.selected.map((s) => s.id)
          );
          loader.hide();
          if (response.success) {
            this.selected = [];
            this.$swal({
              title: "Xoá thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("product-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(response, "message", ""),
            });
          }
        }
      });
    },

    async fetchTableData() {
      this.loading = this.$loading.show();
      const response = await ProductRepository.list({
        limit: this.limit,
        page: this.currentPage,
        keyword: this.filterForm.keyword,
        status: this.filterForm.status,
        productFolderId: this.filterForm.productFolderId,
        unitId: this.filterForm.unitId,
        manufactureId: this.filterForm.manufactureId,
        utilityId: this.filterForm.utilityId,
        treatmentId: this.filterForm.treatmentId,
        pathologyId: this.filterForm.pathologyId,
        siteIds: this.filterForm.siteId,
      });

      if (response.success) {
        this.errorMessage = "";
        this.tableData = _.get(response, "data.list_product");
        this.totalItem = _.get(response, "data.total_item", 0);
      } else {
        this.errorMessage = _.get(response, "message", "");
      }
      this.loading.hide();
    },

    changeEndDateMultipleItem() {
      Bus.$emit("open-form-end-date");
      this.openFormEndDate = true;
    },
  },
};
</script>
