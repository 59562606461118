<template>
  <v-expansion-panels class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm theo báo cáo thành tích cá nhân</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0 overflow-visible" outlined>
          <validation-observer v-slot="{ handleSubmit }">
            <v-list-item class="pa-0" three-line>
              <v-list-item-content class="overflow-visible pa-0">
                <v-row>
                 
                  <v-col cols="12" sm="6">
                    <validation-provider name="Chọn ngày" v-slot="{ errors }" rules="required">
                      <common-range-picker
                        v-model="selectRange"
                        :aLight="'center'"
                        :clearable="true"
                        :defaultRange="defaultRange"
                        @change-date-range="changeDateRange"
                      />
                      <div class="v-text-field__details mt-1">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </div>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="filterForm.shift"
                      :items="shiftListReport"
                      label="Tìm kiếm theo ca làm việc"
                      placeholder="Ca làm việc"
                      item-text="text"
                      item-value="value"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <div class="text-center mb-4">
                  <v-btn class="primary mx-0" @click.prevent="handleSubmit(searchReport);">
                    <v-icon>mdi-magnify</v-icon>Tìm kiếm
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </validation-observer>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import TokenService from '@/helpers/token';
import RepositoryFactory from '@/repositories/RepositoryFactory';
import { shiftListReport } from '@/helpers/constants';
import * as moment from 'moment';
import { userPositions } from '@/helpers/constants';
const DepartmentRepository = RepositoryFactory.get('department');
export default {
  name: 'FilterForm',
  props: {
    filterForm: {
      default: () => ({})
    }
  },
  data() {
    return {
      shiftListReport,
      defaultRange: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      },
      selectRange: [
        {
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD')
        }
      ]
    };
  },
  created() {
  },
  methods: {
    changeDateRange(dateRange) {
      this.filterForm.dateRange = dateRange;
      this.selectRange = dateRange;
    },
    searchReport() {
      Bus.$emit('profile-report-filter-form', this.filterForm);
    },
  }
};
</script>
