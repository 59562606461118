export const customerTypeList = [
    {
        code: 1,
        name: 'Khách hàng thường'
    },
    {
        code: 2,
        name: 'Công ty'
    },
    {
        code: 3,
        name: 'Nhà thuốc'
    },
    {
        code: 4,
        name: 'Phòng khám'
    }
]

export const customerTypeListObj = {
    KHACH_HANG_THUONG: '1',
    CONG_TY: '2',
    NHA_THUOC: '3',
    PHONG_KHAM: '4'
}