var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showModal)?_c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-center font-weight-bold"},[(_vm.isCreate)?[_vm._v(" Tạo thông báo nội bộ ")]:[_vm._v(" Thông tin thông báo ")]],2),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"Thời gian ngày bắt đầu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":30,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bắt đầu từ","readonly":""},model:{value:(_vm.start.startTime),callback:function ($$v) {_vm.$set(_vm.start, "startTime", $$v)},expression:"start.startTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.start.menuStartTime),callback:function ($$v) {_vm.$set(_vm.start, "menuStartTime", $$v)},expression:"start.menuStartTime"}},[(_vm.start.menuStartTime)?_c('v-time-picker',{attrs:{"full-width":"","error-messages":errors},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.start.startTime)}},model:{value:(_vm.start.startTime),callback:function ($$v) {_vm.$set(_vm.start, "startTime", $$v)},expression:"start.startTime"}}):_vm._e()],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"Ngày bắt đầu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"style-datePicker"},[_c('label',{staticClass:"style-datePicker_title"},[_vm._v("Ngày bắt đầu")]),_c('date-picker',{attrs:{"format":"YYYY-MM-DD","type":"date","placeholder":"Chọn ngày","error-messages":errors},model:{value:(_vm.start.startDate),callback:function ($$v) {_vm.$set(_vm.start, "startDate", $$v)},expression:"start.startDate"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"Thời gian ngày kết thúc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":30,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Kết thúc vào","readonly":""},model:{value:(_vm.end.endTime),callback:function ($$v) {_vm.$set(_vm.end, "endTime", $$v)},expression:"end.endTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.end.menuEndTime),callback:function ($$v) {_vm.$set(_vm.end, "menuEndTime", $$v)},expression:"end.menuEndTime"}},[(_vm.end.menuEndTime)?_c('v-time-picker',{attrs:{"full-width":"","error-messages":errors},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.end.endTime)}},model:{value:(_vm.end.endTime),callback:function ($$v) {_vm.$set(_vm.end, "endTime", $$v)},expression:"end.endTime"}}):_vm._e()],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"Ngày kết thúc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"style-datePicker"},[_c('label',{staticClass:"style-datePicker_title"},[_vm._v("Ngày kết thúc")]),_c('date-picker',{attrs:{"format":"YYYY-MM-DD","type":"date","placeholder":"Chọn ngày","error-messages":errors},model:{value:(_vm.end.endDate),callback:function ($$v) {_vm.$set(_vm.end, "endDate", $$v)},expression:"end.endDate"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Đường dẫn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Đường dẫn","error-messages":errors,"color":"primary","placeholder":"Nhập đường dẫn"},model:{value:(_vm.announcement.link),callback:function ($$v) {_vm.$set(_vm.announcement, "link", $$v)},expression:"announcement.link"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nhóm khách hàng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"selectAll-group-product",attrs:{"items":_vm.departments,"item-text":"name","item-value":"id","label":"Nhóm khách hàng","placeholder":"Chọn Nhóm khách hàng","multiple":"","clearable":"","error-messages":errors,"eager":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('common-select-all',{attrs:{"item":_vm.departments,"items":_vm.departments.map(function (s) { return s.id; })},model:{value:(_vm.announcement.departmentIds),callback:function ($$v) {_vm.$set(_vm.announcement, "departmentIds", $$v)},expression:"announcement.departmentIds"}})]},proxy:true}],null,true),model:{value:(_vm.announcement.departmentIds),callback:function ($$v) {_vm.$set(_vm.announcement, "departmentIds", $$v)},expression:"announcement.departmentIds"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"title-span-content text-left",staticStyle:{"display":"inline-block","width":"100%"}},[_vm._v("Nội dung *")]),_c('validation-provider',{attrs:{"name":"Nội dung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.announcement.content),callback:function ($$v) {_vm.$set(_vm.announcement, "content", $$v)},expression:"announcement.content"}})],(errors[0])?_c('span',{staticClass:"errors-span-required-content",attrs:{"color":"primary"}},[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"group-btn--form pt-3"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"reduce-height-btn",staticStyle:{"margin-right":"-10px"},attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveAnnouncement)}}},[_vm._v(" Lưu lại ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"reduce-height-btn",attrs:{"color":"primary"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" Đóng ")])],1)],1)],1)]}}],null,false,1799576980)})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }