<template>
    <v-card
        class="mx-auto pa-3"
        outlined
        v-if="_.get(staff, 'id', null)"
    >
       <v-list-item>
            <v-list-item-content class="overflow-visible">
                <v-list-item-title class="headline mb-2">
                    Xếp hạng
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item one-line>
            <v-list-item-content>
                <v-row>
                    <v-col cols="12" md="6">
                        <div class="staff-profile">
                            <div class="image-profile">
                                <img
                                    v-if="_.get(staff, 'avatar.id', false)"
                                    :src="_.get(staff, 'avatar.filePath', '')"
                                />
                                <img
                                    v-else
                                    src="@/assets/default-avatar.jpg"
                                />
                            </div>
                            <div class="staff-profile--info ml-7">
                                <h4 class="display-2 font-weight-medium">{{_.get(staff, 'fullName', '')}}</h4>
                                <h4 class="display-2 font-weight-medium">{{`${_.get(staff, 'lastName', '')} ${_.get(staff, 'firstName', '')}`}}</h4>
                                <p class="display-1">{{_.get(staff, 'group', '')}}</p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h4 class="display-2 font-weight-medium mb-7">Hoàn thành KPI</h4>
                        <v-progress-linear
                            v-if="this.reportStaff.kpi !== ''"
                            :value="parseInt(_.get(this.reportStaff, 'kpi', '')  || 0)"
                            color="success"
                            height="25"
                        >
                            <template v-slot="{ value }">
                                <strong>{{ Math.round(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table class="table-layout pt-5 pb-5">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Đơn hàng</th>
                                        <th>Doanh thu thành công</th>
                                        <th>Tỉ lệ chốt thành công</th>
                                        <th>Thứ hạng trong team sales</th>
                                        <th>Thứ hạng trong công ty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td>{{ reportStaff.successCountOrders }} / {{ reportStaff.initCountOrders }}</td>
                                    <td>{{ formatPrice(parseInt(_.get(reportStaff, 'successTurnOver', ''))  || 0) }} / {{ formatPrice(parseInt(_.get(reportStaff, 'initTurnOver', '')) || 0) }}</td>
                                    <td>{{ +(_.get(reportStaff, 'successLatchRatio', '')  || 0) }}% / {{ +(_.get(reportStaff, 'initLatchRatio', '') || 0) }}%</td>
                                    <td>
                                        <v-avatar size="50" :class="getRankTeam()">
                                            <span> {{ parseInt(_.get(reportStaff, 'rankInTeam', '')  || 0) }}</span>
                                        </v-avatar>
                                    </td>
                                    <td>
                                        <v-avatar size="50" :class="getRankCompany()">
                                            <span> {{ parseInt(_.get(reportStaff, 'rankInCompany', '')  || 0) }}</span>
                                        </v-avatar>
                                    </td>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>
<script>
    import { formatPrice } from '@/helpers/utils'
    export default {
        props: {
            staff: {
                type: Object,
                default: () => ({})
            },
            reportStaff: {
                type: Object,
                default: () => ({})
            },
            duration: {
                type: String,
                default: () => ''
            }
        },
        data() {
          return {
          }
        },
        methods: {
            getRankTeam() {
                // if (this.duration !== 'today') return
                if (_.get(this.reportStaff, 'rankInTeam', 0) == 0 || _.get(this.reportStaff, 'preRankInTeam', 0) == 0) return
                if(parseInt(_.get(this.reportStaff, 'preRankInTeam', '')  || 0) > parseInt(_.get(this.reportStaff, 'rankInTeam', '')  || 0)) {
                    return 'border-success'
                } else if (parseInt(_.get(this.reportStaff, 'preRankInTeam', '')  || 0) === parseInt(_.get(this.reportStaff, 'rankInTeam', '')  || 0)) {
                    return 'border-warning'
                } else if(parseInt(_.get(this.reportStaff, 'preRankInTeam', '')  || 0) < parseInt(_.get(this.reportStaff, 'rankInTeam', '')  || 0)) {
                    return 'border-primary'
                }
            },
            getRankCompany() {
                // if (this.duration !== 'today') return
                if (_.get(this.reportStaff, 'rankInCompany', 0) == 0 || _.get(this.reportStaff, 'preRankInCompany', 0) == 0) return
                if(parseInt(_.get(this.reportStaff, 'preRankInCompany', '')  || 0) > parseInt(_.get(this.reportStaff, 'rankInCompany', '')  || 0)) {
                    return 'border-success'
                } else if (parseInt(_.get(this.reportStaff, 'preRankInCompany', '')  || 0) === parseInt(_.get(this.reportStaff, 'rankInCompany', '')  || 0)) {
                    return 'border-warning'
                } else if(parseInt(_.get(this.reportStaff, 'preRankInCompany', '')  || 0) < parseInt(_.get(this.reportStaff, 'rankInCompany', '')  || 0)) {
                    return 'border-primary'
                }
            }
        }
    }
</script>
<style lang="sass" scoped>
    .border-success
        border: 2px solid #4caf50
    .border-warning
        border: 2px solid #fb8c00
    .border-primary
        border: 2px solid #e91e63
    .image-profile img
        display: inline-block
        width: 200px
        height: 200px
        max-width: 200px
        max-height: 200px
        margin-right: 12px
</style>
