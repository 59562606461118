<template>
  <div>
    <v-simple-table class="table-layout table-small-screen">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Thời điểm</th>
            <th>Tên tài khoản</th>
            <th>Số điện thoại</th>
            <th>Hành động</th>
            <th>Dữ liệu</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(log, index) in logs">
            <tr :key="log.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(log, "createdAt") }}</td>
              <td>{{ _.get(log, "customer.username") }}</td>
              <td>{{ _.get(log, "customer.phone") }}</td>
              <td>{{ _.get(log, "action") }}</td>
              <td>{{ _.get(log, "data") }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    logs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clickDetailOrder(orderId) {
      let route = this.$router.resolve({
        name: "OrderDetail",
        params: {
          id: orderId,
        },
        query: {
          orderList: true,
        },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style lang="css" scoped>
.btn-detail {
  font-weight: 500;
  cursor: pointer;
}
</style>