var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","sm":"6"}},[(_vm.checkAuthorization('mkt-sms-customer_create'))?_c('v-btn',{staticClass:"primary mb-0 mx-2",staticStyle:{"padding":"0px 25px !important"},attrs:{"height":"30px"},on:{"click":_vm.addNewCustomer}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Tạo tệp mới ")],1):_vm._e()],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"internal-activator":true,"content-class":"menu__customer--filter no-contain overflow-visible","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"padding":"0px 25px !important","margin-right":"10px"},attrs:{"color":"primary","dark":"","height":"30px"}},on),[_c('span',[_vm._v("Tìm kiếm")]),_c('v-icon',{staticStyle:{"margin-left":"10px","margin-right":"-20px"}},[_vm._v("mdi-chevron-down")])],1)]}}]),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[_c('filter-form-customer',{attrs:{"customerList":_vm.customerList,"users":_vm.users}})],1)],1)],1),_c('v-simple-table',{staticClass:"table-layout pa-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Ngày tạo")]),_c('th',[_vm._v("Người tạo")]),_c('th',[_vm._v("Tệp")]),_c('th',[_vm._v("Điều kiện")]),(_vm.showAction)?_c('th',[_vm._v("Thao tác")]):_vm._e()])]),(!_vm.loadingContact && _vm.totalItemCustomerFile > 0)?_c('tbody',[_vm._l((_vm.customerList),function(item){return [_c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(_vm._.get(item, 'createdAt')))]),_c('td',[_vm._v(_vm._s(_vm._.get(item, 'createdUser')))]),_c('td',[_vm._v(_vm._s(_vm._.get(item, 'title')))]),_c('td',_vm._l((_vm._.get(item, 'condition')),function(value,key){return _c('span',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(key)+" "+_vm._s(value))])}),0),(_vm.showAction)?_c('td',[_c('div',{staticClass:"action"},[(_vm.checkAuthorization('mkt-sms-customer_update'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.addToSend(item)}}},on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Chọn tệp khách hàng để gửi")])]):_vm._e(),(_vm.checkAuthorization('mkt-sms-customer_update'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.editCustomerFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Chi tiết tệp khách hàng")])]):_vm._e(),(_vm.checkAuthorization('mkt-sms-customer_delete'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"default"},on:{"click":function($event){return _vm.onClickDelete(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Xóa tệp khách hàng")])]):_vm._e()],1)]):_vm._e()])]})],2):_vm._e(),(!_vm.loadingContact && _vm.totalItemCustomerFile === 0)?_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"1000"}},[_c('common-no-result')],1)])]):_vm._e()]},proxy:true}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}}),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.totalItemCustomerFile > _vm.limitCustomerFile)?_c('v-pagination',{staticClass:"justify-sm-end",attrs:{"length":_vm.getTotalItem(_vm.totalItemCustomerFile, _vm.limitCustomerFile),"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},model:{value:(_vm.currentPageCustomerFile),callback:function ($$v) {_vm.currentPageCustomerFile=$$v},expression:"currentPageCustomerFile"}}):_vm._e()],1)],1),_c('FormCreateDetailCustomerFile',{attrs:{"users":_vm.users,"productGroups":_vm.productGroups,"contactStatusList":_vm.contactStatusList,"contactSources":_vm.contactSources,"products":_vm.products}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }