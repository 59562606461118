<template>
    <v-container fluid tag="section">
        <filter-form :filterForm="filterForm" :users="users" />

        <v-card class="mt-0">
            <sms-history-table 
                    :smsHistoryList="smsHistoryList" 
                    :loading="loading" 
                    :users="users" 
                    @showDetailHistory="showDetailHistory"/>

            <v-row>
                <v-col cols="12" sm="6" />
                <v-col cols="12" sm="6">
                    <v-pagination
                        v-if="totalItem > limit"
                        v-model="currentPage"
                        :length="getTotalItem(totalItem, limit)"
                        class="justify-sm-end"
                        prev-icon="mdi-menu-left"
                        next-icon="mdi-menu-right"
                    />
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import SmsHistoryTable from "./components/SmsHistoryTable"
import FilterForm from "./components/FilterForm"
import RepositoryFactory from "@/repositories/RepositoryFactory"
const SmsRepository = RepositoryFactory.get("sms")
const DepartmentRepository = RepositoryFactory.get("department")
const CustomerFileRepository = RepositoryFactory.get("customer_file")

export default {
    components: { SmsHistoryTable, FilterForm },
    data() {
        return {
            smsHistoryList: [],
            loading: false,
            currentPage: 1,
            totalItem: 0,
            limit: 10,
            filterForm: {
                customerName: '',
                createdAt: [],
                smsCode: '',
                createdBy: [],
                channel: []
            },
            users: []
        }
    },
    watch: {
        currentPage: {
            deep: true,
            handler() {
                this.getSmsHistory()
            }
        }
    },
    async created() {
        await this.getUsers()
        this.getSmsHistory()
        Bus.$on("search-sms-history", (filterForm) => {
            this.filterForm = filterForm
            this.currentPage = 1
            this.getSmsHistory()
        })
    },
    methods: {
        async getSmsHistory() {
            const loader = this.$loading.show()
            this.loading = true
            const params = {
                customerName: this.filterForm.customerName,
                createdAt: this.filterForm.createdAt,
                smsCode: this.filterForm.smsCode,
                createdBy: this.filterForm.createdBy,
                channel: this.filterForm.channel,
                page: this.currentPage - 1,
                limit: this.limit
            }

            const res = await SmsRepository.listHistory(params)
            if (res.success) {
                this.smsHistoryList = _.get(res, 'data', [])
                this.totalItem = _.get(res, 'totalItem', 0)
            } else {
                this.smsHistoryList = []
                this.totalItem = 0
            }

            loader.hide()
            this.loading = false
        },

        async getUsers() {
            const loader = this.$loading.show();
            const respond = await DepartmentRepository.listUsers({
                keyword: ''
            });
            loader.hide();
            if (_.get(respond, 'success', false)) {
                const departmentList = _.get(respond, 'data', []).filter(
                    d => _.get(d, 'users', []).length > 0
                );
                const groupUsers = [];
                departmentList.forEach(department => {
                    const { users = [] } = department;
                    if (users.length) {
                        groupUsers.push({
                            header: department.name,
                            name: _.get(department, 'name'),
                            departmentId: department.id,
                        });
                        users.forEach(user => {
                            groupUsers.push({
                                fullName: _.get(user, 'fullName'),
                                group: _.get(department, 'name'),
                                id: user.id,
                                avatar: _.get(user, 'avatar.filePath'),
                                departmentId: department.id,
                            });
                        });
                    }
                });
                this.users = groupUsers;
            } else {
                this.$swal({
                    type: 'error',
                    title: 'Lỗi !',
                    text: _.get(respond, 'message', '')
                });
            }
        },

        async showDetailHistory(history) {
            const res = await CustomerFileRepository.listResult(history.id)
            if (res.success) {
                Bus.$emit("show-sms-send-result", {
                    ...history,
                    ..._.get(res, 'data', {})
                })
            } else {
                this.$swal({
                    title: "Lỗi!",
                    text: res.message
                })
            }
        }
    }
}
</script>

<style scoped>

</style>