var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[(_vm.isCreate)?[_vm._v(" Thêm mới nhóm sản phẩm ")]:[_vm._v(" Cập nhật thông tin nhóm sản phẩm ")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v(" mdi-close ")])],2),_c('v-card-text',{staticClass:"px-6"},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:255","name":"Tên nhóm sản phẩm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên nhóm sản phẩm *","error-messages":errors,"color":"primary"},model:{value:(_vm.productGroup.name),callback:function ($$v) {_vm.$set(_vm.productGroup, "name", $$v)},expression:"productGroup.name"}})]}}],null,true)}),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:10|alpha_num","name":"Mã nhóm sản phẩm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mã nhóm sản phẩm *","error-messages":errors,"color":"primary"},model:{value:(_vm.productGroup.code),callback:function ($$v) {_vm.$set(_vm.productGroup, "code", $$v)},expression:"productGroup.code"}})]}}],null,true)}),_c('div',{staticClass:"group-input"},[_c('ul',[_c('li',{staticClass:"title"},[_c('h3',{staticClass:"text-left"},[_vm._v(" Link Url: ")])]),_vm._l((_vm.productGroup.urls),function(url,index){return [_c('li',{key:url.id,staticClass:"list-input"},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":{
                    regex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                    max: 255,
                  },"name":"Url","vid":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Url","color":"primary","error-messages":errors,"clearable":""},model:{value:(url.inputUrl),callback:function ($$v) {_vm.$set(url, "inputUrl", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"url.inputUrl"}})]}}],null,true)}),(index>0)?_c('v-icon',{staticClass:"group-input-close",on:{"click":function($event){return _vm.deleteRow(url.id)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]})],2),_c('v-icon',{staticClass:"group-input-add",on:{"click":_vm.addRow}},[_vm._v(" mdi-plus ")])],1),_c('v-autocomplete',{attrs:{"attach":"","items":_vm.schoolLevelOptions,"item-text":"text","item-value":"value","label":"Cấp học","color":"primary"},model:{value:(_vm.productGroup.schoolLevel),callback:function ($$v) {_vm.$set(_vm.productGroup, "schoolLevel", $$v)},expression:"productGroup.schoolLevel"}}),_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Nhóm ngành","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.schoolLevels,"item-text":"name","item-value":"id","label":"Nhóm ngành *","error-messages":errors,"color":"primary"},model:{value:(_vm.productGroup.schoolLevelId),callback:function ($$v) {_vm.$set(_vm.productGroup, "schoolLevelId", $$v)},expression:"productGroup.schoolLevelId"}})]}}],null,true)}),_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Mô tả sản phẩm","rules":"max: 255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Mô tả sản phẩm","error-messages":errors,"color":"primary"},model:{value:(_vm.productGroup.description),callback:function ($$v) {_vm.$set(_vm.productGroup, "description", $$v)},expression:"productGroup.description"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"px-0 group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveProductGroup)}}},[_vm._v(" Lưu lại ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy bỏ ")])],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }