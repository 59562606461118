<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới </template>
          <template v-else> Cập nhật thông tin </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên biến cấu hình"
              >
                <v-text-field
                  v-model="price.text"
                  label="Tên biến cấu hình *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Giá trị"
                rules="required|max:255"
              >
                <v-text-field
                  v-model="price.value"
                  label="Giá trị *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Thứ tự"
                rules="required|max:255"
              >
                <v-text-field
                  v-model="price.displayOrder"
                  label="Thứ tự *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }">
                <v-select
                  v-model="price.status"
                  label="Trạng thái"
                  no-data-text="Không có dữ liệu"
                  :items="configStatusOptions"
                  item-text="text"
                  item-value="value"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveConfig)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { typeConfig, configStatusOptions } from "../../../helpers/constants";
const SiteConfigRepository = RepositoryFactory.get("site_config");
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      price: {
        text: "",
        value: "",
        displayOrder: "",
        status: "",
        type: typeConfig.SEARCH_PRICE,
      },
      configStatusOptions,
      isCreate: true,
      prices: [],
      errorMessage: "",
    };
  },
  created() {
    Bus.$on("price-show-form", (price) => {
      this.showModal = true;
      this.price = _.cloneDeep(price);
      if (price.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveConfig() {
      if (this.isCreate) {
        const price = {
          text: _.trim(this.price.text),
          value: _.trim(this.price.value),
          displayOrder: _.trim(this.price.displayOrder),
          status: _.trim(this.price.status),
          type: typeConfig.SEARCH_PRICE,
        };
        const loader = this.$loading.show();
        const response = await SiteConfigRepository.create(price);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("price-list-reload");
          });
        }
        loader.hide();
      } else {
        const price = {
          text: _.trim(this.price.text),
          value: _.trim(this.price.value),
          displayOrder: _.trim(this.price.displayOrder),
          status: _.trim(this.price.status),
          type: typeConfig.SEARCH_PRICE,
        };
        const loader = this.$loading.show();
        const response = await SiteConfigRepository.update(
          this.price.id,
          price
        );
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("price-list-reload");
          });
        }
        loader.hide();
      }
    },
  },
};
</script>
