<template>
    <v-card
        class="mx-auto overflow-visible"
        width="400px"
        style="margin-top: 0px; margin-bottom: 0px;"
        outlined
    >
        <v-list-item three-line>
<!--            <validation-observer v-slot="{ handleSubmit }">-->
                <v-list-item-content class="overflow-visible">
                    <v-row>
                      <v-col>
                        <validation-provider
                            name="Chọn ngày"
                            v-slot="{ errors }"
                        >
                          <common-range-picker
                              v-model="selectRange"
                              :clearable="true"
                              :aLight="'left'"
                              :defaultRange="defaultRange"
                              @change-date-range="changeDateRange"
                              label="Ngày tạo"
                          />
                          <div class="v-text-field__details mt-1">
                            <div class="v-messages theme--light error--text" role="alert">
                              <div class="v-messages__wrapper">
                                <div class="v-messages__message">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row style="width: 100%; padding: 0px 20px">
                        <v-text-field
                            v-model="filterForm.title"
                            label="Tên tệp"
                            placeholder="Chọn tên tệp"
                            clearable
                            @keyup.enter.native="(searchCustomerFile)"
                        />
                    </v-row>

                    <v-row style="width: 100%; padding: 0px 20px">
                        <v-autocomplete
                            label="Người tạo"
                            :items="users"
                            item-text="fullName"
                            item-value="id"
                            v-model="filterForm.userId"
                            @keyup.enter.native="(searchCustomerFile)"
                        />
                    </v-row>

                  <div class="text-center mb-4">
                        <v-btn class="primary mx-0" @click="searchCustomerFile">
                            <v-icon>mdi-magnify</v-icon>Tìm kiếm
                        </v-btn>
                    </div>
                </v-list-item-content>
<!--            </validation-observer>-->
        </v-list-item>
    </v-card>
</template>

<script>
import * as moment from "moment";

export default {
    props: {
        customerList: {
          type: Array,
          default: () => []
        },
        users: {
            type: Array,
          default: () => []
        }
    },
    data() {
        return {
          defaultRange: {
            startDate: '',
            endDate: ''
          },
          selectRange: [{
            startDate: '',
            endDate: '',
          }],
          filterForm: {
            title: '',
            userId: '',
            code: '',
            dateRange: {
              startDate: '',
              endDate: ''
            }
          },
          customerFileNameArr: []
        }
    },
  created() {
    this.customerList.forEach(item => {
      this.customerFileNameArr.push(item.title)
    })
  },
  methods: {
        searchCustomerFile() {
            Bus.$emit('search-customer-file', this.filterForm)
        },
        changeDateRange (dateRange) {
          this.filterForm.dateRange = dateRange
        },
    }
}
</script>

<style>

</style>