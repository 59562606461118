<template>
  <!-- <HandyScroll
    class="handscrolls v-data-table table-layout pa-4 pt-0 theme--light"
    ref="handscrolls"
  > -->
  <v-card class="my-0">
    <v-simple-table class="table-layout pa-4 pt-0">
    <template v-slot:default>
    <thead>
      <tr>
        <th>STT</th>
        <th>Ngày đặt</th>
        <th>Mã ĐH</th>
        <th>Trạng thái</th>
        <th>Tên khách hàng</th>
        <th>Số điện thoại</th>
        <th>Địa chỉ</th>
        <th>Phường(Xã)</th>
        <th>Quận(Huyện)</th>
        <th>Tỉnh(TP)</th>
        <th>Giá trị ĐH</th>
        <th>Giảm trừ khi thanh toán ngay</th>
        <th>Đã thanh toán</th>
        <th>Còn lại</th>
        <th>Doanh thu</th>
        <th>Người cập nhật cuối</th>
        <th v-if="checkAuthorization('order_update')">Thao tác</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(order, index) in orders" :key="order.id">
        <td>{{ index + 1 }}</td>
        <td>{{ formatDate(order.createdAt) }}</td>
        <td>
          <v-tooltip bottom v-if="checkAuthorization('order_read')">
            <template v-slot:activator="{ on }">
              <span
                v-on="on"
                @click="clickDetailOrder(order.id)"
                class="btn-detail"
              >
                {{ order.code }}
              </span>
            </template>
            <span>Xem chi tiết thông tin đơn hàng</span>
          </v-tooltip>
          <span v-else>{{ _.get(order, "receiver", "") }}</span>
        </td>
        <td>
          <v-btn
            small
            rounded
            v-bind:color="orderStatusMappingColor(order.status)"
          >
            {{ dataStatus(_.get(order, "status", null)) }}
          </v-btn>
        </td>
        <td>
          <v-tooltip bottom v-if="checkAuthorization('order_update')">
            <template v-slot:activator="{ on }">
              <span
                v-on="on"
                @click="clickDetailCustomer(order.customerId)"
                class="btn-detail"
              >
                {{ _.get(order, "name", "") }}
              </span>
            </template>
            <span>Xem chi tiết thông tin khách hàng</span>
          </v-tooltip>
          <span v-else>{{ _.get(order, "customer.fullName", "") }}</span>
        </td>
        <td>{{ order.phone }}</td>
        <td>{{ order.address }}</td>
        <td>{{ _.get(order, "wards.name", "") }}</td>
        <td>{{ _.get(order, "districts.name", "") }}</td>
        <td>{{ _.get(order, "provinces.name", "") }}</td>
        <td>{{ formatPrice(order.totalPrice) }}</td>
        <td v-if="order.paymentType == paymentTypes.BANK">
          {{ formatPrice((order.totalPrice * thresholdPercentDiscount) / 100) }}
        </td>
        <td v-else>
          {{ formatPrice(0) }}
        </td>
        <td>{{ formatPrice(_.get(order, "paidAmount", 0)) }}</td>

        <td v-if="order.paymentType == paymentTypes.BANK">
          {{
            formatPrice(
              order.totalPrice -
                (order.totalPrice * thresholdPercentDiscount) / 100 -
                _.get(order, "paidAmount", 0)
            )
          }}
        </td>
        <td v-else>
          {{ formatPrice(order.totalPrice - _.get(order, "paidAmount", 0)) }}
        </td>

        <td v-if="order.paymentType == paymentTypes.BANK">
          {{
            formatPrice(
              order.totalPrice -
                (order.totalPrice * thresholdPercentDiscount) / 100
            )
          }}
        </td>
        <td v-else>
          {{ formatPrice(order.totalPrice) }}
        </td>

        <td>{{ _.get(order, "user.fullName", "") }}</td>
        <td v-if="checkAuthorization('order_update')">
          <div class="action">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-2"
                  @click="clickDetailOrder(order.id)"
                  size="22"
                  outlined
                  small
                  color="green"
                  v-on="on"
                  ><v-icon>mdi-format-list-bulleted-square</v-icon></v-btn
                >
              </template>
              <span>Xem chi tiết thông tin đơn hàng</span>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </tbody>
    </template>
    </v-simple-table>
  </v-card>
  <!-- </HandyScroll> -->
</template>
<script>
import HandyScroll, { EventBus } from "vue-handy-scroll";
import {
  orderStatusOptions,
  paymentTypes,
  thresholdPercentDiscount,
} from "../../../helpers/constants";

export default {
  components: {
    // HandyScroll,
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      paymentTypes,
      thresholdPercentDiscount,
    };
  },
  mounted() {
    EventBus.$emit("update", this.$refs.handscrolls.$el);
  },
  updated() {
    EventBus.$emit("update", this.$refs.handscrolls.$el);
  },
  methods: {
    clickDetailOrder(orderId) {
      // this.$router.push({
      let route = this.$router.resolve({
        name: "OrderDetail",
        params: {
          id: orderId,
        },
        query: {
          orderList: true,
        },
      });
      window.open(route.href, "_blank");
    },
    clickDetailCustomer(CustomerId) {
      let route = this.$router.resolve({
        path: `/users/edit-customer/${CustomerId}`,
        name: "CustomerDetail",
        params: {
          id: CustomerId,
        },
      });
      window.open(route.href, "_blank");
    },
    dataStatus(status) {
      const orderStatus = orderStatusOptions.find((o) => o.value == status);
      return orderStatus ? orderStatus.text : "";
    },
    orderStatusMappingColor(status) {
      const orderStatus = orderStatusOptions.find((o) => o.value == status);
      return orderStatus ? orderStatus.color : "gray";
    },
  },
};
</script>

<style lang="css" scoped>
.btn-detail {
  font-weight: 500;
  cursor: pointer;
}
</style>