<template>
  <div>
    <div class="text-sm-right mb-3">
      <v-btn v-if="checkAuthorization('product_delete')"
        v-show="(selected.length ? true : false)"
        color="default"
        dark
        class="mb-2 mr-2"
        @click="deleteMultipleItem"
      >
        Xóa tất cả
      </v-btn>
      <v-btn
        v-if="checkAuthorization('product_create')"
        color="primary"
        dark
        class="mb-2"
        @click="addNewProductGroup"
      >
        <v-icon>mdi-plus</v-icon>Thêm Mới
      </v-btn>
    </div>

    <v-tabs
      class="mt-0 pl-3"
      @change="changeTab"
      v-model="tabIndex"
      color="primary"
      style="background-color: #FFFFFF; padding: 5px 0px 10px 5px;"
    >
      <v-tab :key="0">Tất Cả</v-tab>
      <v-tab v-for="(tab, i) in productStatusOptions" :key="i+1">{{ tab.text }}</v-tab>
    </v-tabs>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="productGroups"
      :single-select="singleSelect"
      item-key="id"
      :show-select="checkAuthorization('product_delete')"
      class="table-layout pa-5"
      hide-default-footer
    >
      <template v-slot:top />
      <template v-slot:no-data>
        <common-no-result />
      </template>

      <template v-slot:item.thumnail="{item}">
        <div>
          <v-img
            :src="getThumbnailUrl(item.images)"
            max-width="150"
          />
        </div>
      </template>
      <template v-slot:item.schoolLevelId="{item}">
        <div>
          {{ getSchoolLevel(item.schoolLevelId) }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip v-if="item.status === 'active'" bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" @click="convertStatus(item.id, 'inactive')" color="info" icon v-on="on">mdi-block-helper</v-icon>
          </template>
          <span>Chuyển sang trạng thái ngừng hoạt động</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="24" @click="convertStatus(item.id, 'active')" color="success" icon v-on="on">mdi-checkbox-marked-circle</v-icon>
          </template>
          <span>Chuyển sang trạng thái hoạt động</span>
        </v-tooltip>
        <v-tooltip bottom v-if="checkAuthorization('product_update')">
          <template v-slot:activator="{ on }">
            <v-btn class="mr-2 ml-2" @click="editProductGroup(item)" icon small color="primary"  v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
          <span>Chỉnh sửa thông tin nhóm sản phẩm</span>
        </v-tooltip>
        <v-tooltip bottom v-if="checkAuthorization('product_delete')">
          <template v-slot:activator="{ on }">
            <v-btn @click="onClickDelete(item)" icon small color="default"  v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <span>Xóa nhóm sản phẩm</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <product-group-form :schoolLevels="schoolLevels" />
  </div>
</template>
<script>
  import ProductGroupForm from './ProductGroupForm.vue'
  import RepositoryFactory from '../../../../repositories/RepositoryFactory'
  import { productStatusOptions } from '../../../../helpers/constants'

  const ProductGroupRepository = RepositoryFactory.get('product_group')
  export default {
    components: { ProductGroupForm },
    props: {
      productGroups: {
        type: Array,
        default: () => [],
      },
      schoolLevels: {
        type: Array,
        default: () => {[]}
      }
    },
    data () {
      return {
        selectedProductGroup: {},
        errorMessage: '',
        headers: [
          {
            text: '',
            value: 'index',
            width: "20px",
            align: 'center'
          },
          { text: 'Tên nhóm', value: 'name', align: 'center' },
          { text: 'Mã nhóm', value: 'code', align: 'center' },
          { text: 'Nhóm ngành', value: 'schoolLevelId', align: 'center'},
          { text: 'Mô tả', value: 'description', align: 'center' },
          { text: 'Trạng thái', value: 'statusText', align: 'center' },
        ],
        singleSelect: false,
        selected: [],
        productStatusOptions,
        tabIndex: 0,
        filterForm: {}
      }
    },
    created () {
      if ( this.checkAuthorization('product_update') || this.checkAuthorization('product_create')) {
        this.headers.push({ text: 'Thao tác', value: 'action', align: 'center', sortable: false });
      }
    },
    methods: {
      async convertStatus (id, status) {
        const statusProductGroup = {
          status,
        }
        this.$swal({
          title: 'Chuyển đổi trạng thái',
          text: 'Bạn có thực sự chắc chắn ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const response = await ProductGroupRepository.updateStatus(id, statusProductGroup)
            loader.hide()
            if (response.success) {
              this.$swal({
                        title: 'Chuyển đổi thành công !',
                        confirmButtonText: 'Đồng ý',
                      },
              ).then(() => {
                Bus.$emit('product-group-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(respond, 'message', ''),
              })
            }
          }
        })
      },
      addNewProductGroup () {
        this.selectedProductGroup = {}
        Bus.$emit('product-group-show-form', this.selectedProductGroup)
      },
      editProductGroup (productGroup) {
        this.selectedProductGroup = productGroup
        Bus.$emit('product-group-show-form', this.selectedProductGroup)
      },
      async onClickDelete (productGroup) {
        this.$swal({
          title: 'Xóa nhóm sản phẩm',
          text: 'Bạn có thực sự muốn xóa ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const respond = await ProductGroupRepository.deleteOneItem(productGroup.id)
            loader.hide()
            if (_.get(respond, 'success', false)) {
              this.$swal({
                title: 'Đã xóa thành công !',
                confirmButtonText: 'Đồng ý',
              },
              ).then(() => {
                Bus.$emit('product-group-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(respond, 'message', ''),
              })
            }
          }
        })
      },
      async deleteMultipleItem () {
        this.$swal({
          title: 'Xóa toàn bộ nhóm sản phẩm',
          text: 'Bạn có thực sự muốn xoá các nhóm sản phẩm này?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const response = await ProductGroupRepository.deleteManyItems(this.selected.map(s => s.id))
            loader.hide()
            if (response.success) {
              this.selected = []
              this.$swal({
                        title: 'Xoá thành công !',
                        confirmButtonText: 'Đồng ý',
                      },
              ).then(() => {
                Bus.$emit('product-group-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(response, 'message', ''),
              })
            }
          }
        })
      },
      changeTab() {
        if (this.tabIndex === 0) {
          this.filterForm.status = '';
        } else if (this.tabIndex === 1) {
          this.filterForm.status = 'draft';
        } else if (this.tabIndex === 2) {
          this.filterForm.status = 'active';
        } else if (this.tabIndex === 3) {
          this.filterForm.status = 'inactive';
        }
        Bus.$emit('product-group-list-filter', this.filterForm)
      },
      getSchoolLevel(id) {
        if (!_.isEmpty(this.schoolLevels)) {
          let index = this.schoolLevels.findIndex(item => item.id == id)
          
          if(index > -1) {
            return this.schoolLevels[index].name
          }
        }

        return ''
      }
    },
  }
</script>
