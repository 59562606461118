<template>
  <v-container id="banner" fluid tag="section">
    <filter-form
      :filter-form="filterForm"
      :types="types"
    />
    <banner-table
      :filter-form="filterForm"
      :banners="banners"
      :types="types"
    />
    <v-row class="mt-4">
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem, limit)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { bannerStatusOptions } from "../../helpers/constants";
import FilterForm from "./components/FilterForm.vue";
import BannerTable from "./components/BannerTable.vue";
import RepositoryFactory from "../../repositories/RepositoryFactory";
const BannerRepository = RepositoryFactory.get("banner");

export default {
  components: { FilterForm, BannerTable },
  data() {
    return {
      filterForm: {
        keyword: "",
        type: "",
        status: undefined,
      },
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      banners: [],
      types: [],
      loading: true,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getBannerList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getBannerList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  async created() {
    this.getBannerList();
    Bus.$on("banner-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getBannerList();
    });
    Bus.$on("banner-reload", () => {
      this.getBannerList();
    });
  },
  beforeDestroy() {
    Bus.$off("banner-list-filter");
  },
  methods: {
    async getBannerList() {
      this.loading = true;
      const params = {
        limit: this.limit,
        page: this.currentPage,
        keyword: this.filterForm.keyword,
        type: this.filterForm.type,
        status: this.filterForm.status,
      };

      const loader = this.$loading.show();
      const response = await BannerRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.types = _.get(response, "data.list_type_banner", []);
        this.banners = _.get(response, "data.list_banner", []).map((b) => ({
          ...b,
          typeText: _.get(
            this.types.find((s) => s.value == b.type),
            "text",
            "Trang chủ"
          ),
          statusText: _.get(
            bannerStatusOptions.find((s) => s.value == b.status),
            "text"
          ),
        }));
        this.totalItem = _.get(response, "data.total_item", 0);
      }
    },
  },
};
</script>
