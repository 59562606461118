<template>
  <base-material-card outlined icon="mdi-account-outline" class="mt-0">
    <template v-slot:after-heading>
      <span @click="goBack" class="mobile-show span-icon-custom mt-2">
        <v-icon size="25">mdi-arrow-left</v-icon>
      </span>
      <div class="font-weight-medium card-title mt-2 text-center mobile-hidden">
        Thông tin tài khoản
        <!-- <span class="body-1">— Complete your profile</span> -->
      </div>
    </template>
    <div class="mobile-show font-weight-medium card-title mt-2 text-center header-title-mobile">
      Thông tin tài khoản
      <!-- <span class="body-1">— Complete your profile</span> -->
    </div>
    <div class="edit-profile-user text-center">
      <v-btn
        color="primary"
        rounded
        class="mr-2 mt-4"
        @click="goToEditProfile"
      >
        chỉnh sửa
      </v-btn>
      <v-btn
        color="default"
        rounded
        class="mr-0 mt-4"
        @click="goToChangePassword"
      >
        Đổi mật khẩu
      </v-btn>
    </div>
    <v-form class="profile-user">
      <v-container class="py-0">
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <div class="profile-avatar text-center" style="max-width: 150px; margin: 0 auto;">
              <img
                v-if="_.get(profile, 'avatar.id', null)"
                :src="_.get(profile, 'avatar.filePath')"
                style="width: 100%; "
              />
              <v-img
                v-else
                src="../../../assets/default-avatar.jpg"
              />
              <span class="full-name">{{ profile.lastName }} {{ profile.firstName }}</span>
              <span class="position">{{ _.get(profile, 'role.name', '') }}</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <div class="profile-info">
              <div class="info-detail">
                <span class="label">Họ và tên: </span>
                {{ profile.lastName }} {{ profile.firstName }}
              </div>
              <div class="info-detail">
                <span class="label">Tên đăng nhập: </span>
                {{ profile.username }}
              </div>
              <div class="info-detail">
                <span class="label">Chức danh: </span>
                {{ _.get(userPositionOptions.find(position => position.value === profile.position), 'text', '') }}
              </div>
              <div class="info-detail">
                <span class="label">Điện thoại: </span>
                {{ profile.phone }}
              </div>
              <div class="info-detail">
                <span class="label">Ngày sinh: </span>
                {{ formatDate(profile.birthday) }}
              </div>
              <div class="info-detail">
                <span class="label">Giới tính: </span>
                {{ gender }}
              </div>
              <div class="info-detail">
                <span class="label">Số điện thoại: </span>
                {{ profile.phone }}
              </div>
              <div class="info-detail">
                <span class="label">Nhóm quyền: </span>
                {{ _.get(profile, 'role.name', '') }}
              </div>
              <div class="info-detail">
                <span class="label">Email: </span>
                {{ profile.email }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-material-card>
</template>
<script>
  import { userPositionOptions, userGenderOptions } from '../../../helpers/constants'
  import RepositoryFactory from '../../../repositories/RepositoryFactory'
  const AuthRepository = RepositoryFactory.get('auth')

  export default {
    name: 'ViewProfile',
    data () {
      return {
        errorMessage: '',
        profile: {},
        loading: false,
        userPositionOptions,
        userGenderOptions,
        gender: ''
      }
    },
    created () {
      this.getUserProfile()
    },
    methods: {
      async getUserProfile () {
        const loader = this.$loading.show()
        const profile = await AuthRepository.profile()
        loader.hide()
        if (_.get(profile, 'success', false)) {
          this.profile = _.get(profile, 'data')
        } else {
          this.errorMessage = _.get(profile, 'message', '')
        }
        userGenderOptions.forEach(e => {
          if(e.value == this.profile.gender) {
            return this.gender = e.text
          }
        })
      },
      goToEditProfile () {
        Bus.$emit('profile-show-edit-profile', this.profile)
      },
      goToChangePassword () {
        Bus.$emit('profile-show-change-password')
      },
      goBack () {
        this.$router.go(-1);
      },
    },
  }
</script>
<style lang="sass" scope>
.v-card--custom
  .v-card--material
    overflow: visible

.edit-profile-user
  position: absolute
  right: 12px
  top: 0
  @media(max-width: 767px)
    position: static

.profile-user
  .profile-avatar

    .v-image
      max-width: 100%
      height: auto
      border-radius: 50%
      width: 150px
      //height: 150px
      margin: auto
      -webkit-box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12)
      box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12)
      margin-bottom: 10px

    span
      display: block

    full-name
      text-transform: uppercase
      font-weight: 500

.profile-info
  .info-detail
    margin-bottom: 10px

    span.label
      font-weight: 500
      max-width: 110px
      width: 100%
      display: inline-block

span.span-icon-custom
  position: absolute
  top: 12px
  left: 12px

.header-title-mobile
  width: 100%
  margin-left: 0 !important
</style>
