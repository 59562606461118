<template>
  <v-dialog v-model="showModal" max-width="1000" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validation" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template> Cài đặt chiết khấu </template>
          <v-spacer />
          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6" style="padding-top: 30px">
          <div class="group-input mb-4">
            <ul>
              <li class="title">
                <v-row>
                  <v-col cols="12" md="6">
                    <h3 class="text-left">Sản phẩm</h3>
                  </v-col>
                  <v-col cols="12" md="3">
                    <h3 class="text-left">Giá gốc</h3>
                  </v-col>
                  <v-col cols="12" md="3">
                    <h3 class="text-left">Phần trăm chiết khấu</h3>
                  </v-col>
                </v-row>
              </li>
              <template v-for="(product, index) in discount.products">
                <li :key="product.id" class="list-input">
                  <v-row>
                    <v-col cols="12" md="6">
                      <validation-provider
                        v-slot="{ errors }"
                        class="mb-2"
                        rules="required"
                        name="Chọn sản phẩm"
                      >
                        <v-autocomplete
                          v-model="product.productId"
                          :items="getProductOptions(product)"
                          :error-messages="errors"
                          item-text="name"
                          item-value="id"
                          label="Chọn sản phẩm *"
                          color="primary"
                          @change="selectProduct(product)"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-currency-field
                        label="Giá sản phẩm *"
                        v-model="product.cost"
                        color="primary"
                        :readonly="true"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        class="mb-2"
                        :rules="`required|numeric|max_value:100`"
                        name="Phần trăm chiết khấu"
                      >
                        <v-currency-field
                          label="Phần trăm chiết khấu *"
                          :error-messages="errors"
                          v-model="product.percentDiscount"
                          color="primary"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-icon
                    v-if="index > 0"
                    class="group-input-close"
                    @click="deleteRow(product.id)"
                  >
                    mdi-delete
                  </v-icon>
                </li>
              </template>
            </ul>
            <v-btn
              class="group-input-add m--icon-add"
              color="primary"
              @click="addRow"
            >
              <v-icon>mdi-plus</v-icon>Thêm cấu hình
            </v-btn>
          </div>
          <v-row>
            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }" name="Nhóm khách hàng">
                <v-autocomplete
                  v-model="groupCustomers"
                  :items="listGroupCustomer"
                  :error-messages="errors"
                  item-text="departmentName"
                  item-value="departmentId"
                  label="Nhóm khách hàng"
                  color="primary"
                  multiple
                  no-data-text="Không có dữ liệu"
                  @change="changeGroupCustomer()"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="groupCustomers"
                      :item="groupCustomers"
                      :items="listGroupCustomer.map((s) => s.departmentId)"
                      @change="changeGroupCustomer()"
                    />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Khách hàng *"
              >
                <v-autocomplete
                  v-model="listCustomer"
                  :items="listCustomerForSelect"
                  :error-messages="errors"
                  item-text="username"
                  item-value="id"
                  label="Khách hàng *"
                  color="primary"
                  multiple
                  no-data-text="Không có dữ liệu"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="listCustomer"
                      :item="listCustomer"
                      :items="listCustomerForSelect.map((s) => s.id)"
                      @change="changeGroupCustomer()"
                    />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="Thời gian ngày bắt đầu"
                rules="required"
              >
                <v-menu
                  ref="menu"
                  v-model="start.menuStartTime"
                  :close-on-content-click="false"
                  :nudge-right="30"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start.startTime"
                      label="Bắt đầu từ"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="start.menuStartTime"
                    v-model="start.startTime"
                    full-width
                    @click:minute="$refs.menu.save(start.startTime)"
                    :error-messages="errors"
                  ></v-time-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="Ngày bắt đầu"
                rules="required"
              >
                <div class="style-datePicker">
                  <label class="style-datePicker_title">Ngày bắt đầu</label>
                  <date-picker
                    format="YYYY-MM-DD"
                    v-model="start.startDate"
                    type="date"
                    placeholder="Chọn ngày"
                    :error-messages="errors"
                  ></date-picker>
                </div>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="Thời gian ngày kết thúc"
                rules="required"
              >
                <v-menu
                  ref="menu"
                  v-model="end.menuEndTime"
                  :close-on-content-click="false"
                  :nudge-right="30"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end.endTime"
                      label="Kết thúc vào"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="end.menuEndTime"
                    v-model="end.endTime"
                    full-width
                    @click:minute="$refs.menu.save(end.endTime)"
                    :error-messages="errors"
                  ></v-time-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="Ngày kết thúc"
                rules="required"
              >
                <div class="style-datePicker">
                  <label class="style-datePicker_title">Ngày kết thúc</label>
                  <date-picker
                    format="YYYY-MM-DD"
                    v-model="end.endDate"
                    type="date"
                    placeholder="Chọn ngày"
                    :error-messages="errors"
                  ></date-picker>
                </div>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveDiscount)">
            Thêm mới
          </v-btn>

          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { regex, productStatus, PAGE_LIMIT } from "../../../helpers/constants";
import { filterItemValidArray } from "../../../helpers/utils";

const ProductRepository = RepositoryFactory.get("product");
const DepartmentRepository = RepositoryFactory.get("department");
const DiscountRepository = RepositoryFactory.get("discount");
const CustomerRepository = RepositoryFactory.get("customer");
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      regex,
      showModal: false,
      discount: {
        products: [],
      },
      listCustomer: [],
      isCreate: true,
      products: [],
      departments: [],
      customers: [],
      errorMessage: "",
      start: {
        menuStartTime: false,
        startTime: "",
        startDate: "",
      },
      end: {
        endTime: "",
        endDate: "",
        menuEndTime: false,
      },
      listCustomerForSelect: [],
      groupCustomers: [],
      listGroupCustomer: [],
    };
  },
  computed: {},
  created() {
    this.getProductList();
    this.getDepartmentList();
    this.getCustomerList();

    Bus.$on("discount-show-form", (discount) => {
      this.showModal = true;
      const { products = [] } = _.cloneDeep(discount);
      if (products.length === 0) {
        discount.products = [
          {
            id: `${products.length}_${new Date().getTime()}`,
            productId: "",
            cost: "",
            percentDiscount: "",
          },
        ];
      }
      this.discount = _.cloneDeep(discount);
      this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validation.reset();
    },
    getProductOptions(product) {
      const productIds = this.discount.products.map((p) => p.productId);
      return this.products.filter(
        (p) => !productIds.includes(p.id) || p.id === product.productId
      );
    },
    selectProduct(product) {
      // find in products
      const foundIndex = this.discount.products.findIndex(
        (p) => p.id === product.id
      );
      if (foundIndex > -1) {
        const foundProduct = this.products.find(
          (p) => p.id === product.productId
        );
        const discount = _.cloneDeep(this.discount);
        discount.products[foundIndex] = {
          id: product.id,
          productId: product.productId,
          cost: foundProduct.cost,
          percentDiscount: "",
        };
        this.$set(this, "discount", discount);
      }
    },
    async getProductList() {
      this.loading = true;
      const loader = this.$loading.show();
      const response = await ProductRepository.list({
        limit: PAGE_LIMIT,
        statusArray: [productStatus.DRAFF, productStatus.ACTIVE],
      });
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.products = _.get(response, "data.list_product", []);
      }
    },
    async getDepartmentList() {
      this.loading = true;
      const loader = this.$loading.show();
      const response = await DepartmentRepository.list({
        limit: 100,
      });
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.departments = _.get(response, "data.list_department", []);
      }
    },
    async getCustomerList() {
      this.loading = true;
      const loader = this.$loading.show();
      let res = await CustomerRepository.listCustomerGroup();
      loader.hide();
      this.loading = false;
      if (res.success) {
        let customerList = [];
        _.get(res, "data.list_department", []).forEach(
          (groupCustomer, index) => {
            this.listGroupCustomer.push(groupCustomer);
            const { listCustomer = [] } = groupCustomer;
            customerList.push({
              header: groupCustomer.departmentName,
              name: groupCustomer.departmentName,
              departmentId: groupCustomer.departmentId,
            });
            listCustomer.forEach((cus) => {
              customerList.push({
                ...cus,
                departmentId: groupCustomer.departmentId,
              });
            });
          }
        );
        this.customers = _.cloneDeep(customerList);
        this.listCustomerForSelect = _.cloneDeep(customerList);
      }
    },
    addRow() {
      const discount = _.cloneDeep(this.discount);
      const { products = [] } = discount;
      products.push({
        id: `${products.length}_${new Date().getTime()}`,
        productId: "",
        cost: "",
        percentDiscount: "",
      });
      this.$set(this, "discount", discount);
    },
    deleteRow(id) {
      const index = this.discount.products.findIndex((u) => u.id === id);
      if (index > -1) this.discount.products.splice(index, 1);
    },
    async saveDiscount() {
      if (this.isCreate) {
        const data = {
          listCustomer: filterItemValidArray(this.listCustomer),
          products: this.discount.products.map((p) => ({
            productId: p.productId,
            percentDiscount: p.percentDiscount,
          })),
          startTime: `${moment(this.start.startDate).format("YYYY-MM-DD")} ${
            this.start.startTime
          }:00`,
          endTime: `${moment(this.end.endDate).format("YYYY-MM-DD")} ${
            this.end.endTime
          }:00`,
        };
        const loader = this.$loading.show();
        const response = await DiscountRepository.create(data);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("discount-list-reload");
          });
        }
      }
    },
    changeGroupCustomer() {
      this.listCustomerForSelect = this.customers.filter((item) =>
        this.groupCustomers.includes(item.departmentId)
      );
    },
  },
};
</script>
