<template>
  <v-dialog :value="openForm" max-width="1000" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">{{ errorMessage }}</v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          Chọn ngày hết hạn
          <v-spacer />
          <v-icon aria-label="Close" @click="hideModal">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <validation-provider
                  v-slot="{ errors }"
                  name="Chọn loại thời hạn sử dụng"
              >
                <v-select
                    v-model="typeEndDate"
                    label="Loại thời hạn sử dụng"
                    no-data-text="Không có dữ liệu"
                    :items="listTypeEndDate"
                    item-text="title"
                    item-value="type"
                    :error-messages="errors"
                    color="primary"
                    clearable
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                        v-bind="attrs"
                        active-class="secondary elevation-4 white--text"
                        class="mx-3 mb-3 v-sheet"
                        elevation="0"
                        v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>

            <v-col v-if="typeEndDate === 'day'" cols="12" md="6">
              <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <validation-provider
                      v-slot="{ errors }"
                      name="Chọn ngày"
                      :rules="{required: false, after: afterDate,}"
                      :immediate="false"
                  >
                    <v-text-field
                        style="margin-left: -4px"
                        v-model="endDate"
                        color="primary"
                        label="Ngày hết hạn sử dụng"
                        :error-messages="errors"
                        prepend-icon="mdi-calendar-outline"
                        readonly
                        v-on="on"
                    />
                  </validation-provider>
                </template>

                <v-date-picker
                    v-model="endDate"
                    color="secondary"
                    scrollable
                    @change="$refs.datePicker.save(endDate), datePicker = false"
                    :min="currentDate"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col
                v-if="typeEndDate === 'month'"
                cols="12"
                md="6"
            >
              <validation-provider
                  v-slot="{ errors }"
                  name="Nhập số tháng"
                  :rules="{ required: false }"
              >
                <v-currency-field
                    label="Nhập số tháng"
                    :error-messages="errors"
                    v-model="endMonth"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveChange)">Lưu lại</v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal">Hủy bỏ</v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import * as moment from "moment";
import RepositoryFactory from '@/repositories/RepositoryFactory'
const ProductRepository = RepositoryFactory.get('product')

export default {
  name: "ModalFormEndDate",
  comments: {},
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    listTypeEndDate: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      openForm: false,
      errorMessage: '',
      endDate: '',
      datePicker: '',
      endMonth: '',
      typeEndDate: ''
    }
  },
  created() {
    Bus.$on('open-form-end-date', () => {
      this.openForm = true;
    })
  },
  computed: {
    currentDate () {
      return moment().format('YYYY-MM-DD')
    },
    afterDate () {
      return moment().subtract(1, "days").format('YYYY-MM-DD')
    }
  },
  methods: {
    hideModal() {
      this.endDate = '';
      this.openForm = false;
    },
    async saveChange() {
      this.$swal({
        title: 'Chọn thời hạn sử dụng sản phẩm',
        text: 'Bạn có thực sự muốn thay đổi end date các sản phẩm này?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show()
          const dataChange = {
            ids: this.selected.map(s => s.id),
          }
          if(this.typeEndDate === 'day') {
            dataChange.endDate = this.endDate ? this.endDate : null;
            dataChange.endMonth = null
          } else if(this.typeEndDate === 'month') {
            dataChange.endMonth = this.endMonth ? this.endMonth : null;
            dataChange.endDate = null
          }
          const response = await ProductRepository.changeEndDateManyItems(dataChange)
          loader.hide()
          if (response.success) {
            this.$swal({
                  title: 'Thay đổi hạn sản phẩm thành công !',
                  // confirmButtonText: 'Đồng ý',
                  timer: 800,
                  showConfirmButton: false
                },
            ).then(() => {
              Bus.$emit('product-list-reload')
            })
            this.hideModal();
          } else {
            this.$swal({
              type: 'error',
              title: 'Lỗi !',
              text: _.get(response, 'message', ''),
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>