<template>
  <v-container
    id="combo-list"
    fluid
    tag="section"
  >
    <filter-form :filter-form="filterForm" />
    <combo-table
      :filter-form="filterForm"
      :combos="combos"
    />
    <v-row class="mt-4">
      <v-col
        cols="12"
        sm="6"
      />
      <v-col
        cols="12"
        sm="6"
      >
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem, limit)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import FilterForm from './components/FilterForm.vue'
  import ComboTable from './components/ComboTable'
  import RepositoryFactory from '../../../repositories/RepositoryFactory'
  import { productStatusOptions } from '../../../helpers/constants'

  const ComboRepository = RepositoryFactory.get('combo')
  export default {
    components: { ComboTable, FilterForm },
    data () {
      return {
        filterForm: {},
        productStatusOptions,
        currentPage: 1,
        limit: 10,
        totalItem: 0,
        combos: [],
        loading: false,
      }
    },
    watch: {
      currentPage: {
        handler () {
          this.getComboList()
        },
      },
      limit: {
        handler () {
          if (this.currentPage === 1) {
            this.getComboList()
          } else {
            this.currentPage = 1
          }
        },
      },
    },
    created () {
      this.getComboList()
      Bus.$on('combo-list-filter', (filterForm) => {
        this.filterForm = filterForm
        this.currentPage = 1
        this.getComboList()
      })
      Bus.$on('combo-list-reload', () => {
        this.getComboList()
      })
    },
    beforeDestroy () {
      Bus.$off('combo-list-filter')
    },
    methods: {
      async getComboList () {
        this.loading = true
        const params = {
          limit: this.limit,
          page: this.currentPage - 1,
          keyword: this.filterForm.keyword,
          status: this.filterForm.status,
          siteIds: this.filterForm.siteId
        }
        const loader = this.$loading.show()
        const response = await ComboRepository.list(params)
        loader.hide()
        this.loading = false
        if (response.success) {
          this.combos = _.get(response, 'data', []).map(c => ({
            ...c,
            products: _.get(c, 'products', []).map((product, index) => ({
              productId: product.id,
              price: product.price,
              name: product.name,
              discountPrice: _.get(product, 'ProductCombo.price', 0),
            })),
            statusText: _.get(productStatusOptions.find(s => s.value === c.status ), 'text', '')
          }))
          this.totalItem = _.get(response, "data.total_item", 0);
        }
      },
    },
  }
</script>
