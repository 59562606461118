<template>
    <div>
        <v-simple-table class="table-layout pa-5">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th rowspan="2">Nội dung</th>
                        <th rowspan="2">Nhóm khách hàng</th>
                        <th rowspan="2">Link</th>
                        <th colspan="2">Thời gian</th>
                        <th rowspan="2">Thao tác</th>
                    </tr>
                    <tr>
                        <th>Bắt đầu</th>
                        <th>Kết thúc</th>
                    </tr>
                </thead>
                <tbody v-for="(item, index) in announcementList" :key="index">
                    <tr>
                        <td class="td_content text-left" v-html="item.content"></td>
                        <td>{{ getDepartment(item.departmentIds) }}</td>
                        <td class="td_link">{{ item.link }}</td>
                        <td>{{ formatDate(item.startTime) }}</td>
                        <td>{{ formatDate(item.endTime) }}</td>
                        <td>
                            <v-icon 
                                    color="info" 
                                    style="cursor: pointer" 
                                    title="Cập nhật"
                                    @click="updateAnnouncement(item)"
                                    v-if="checkAuthorization('announcement_update')">
                                mdi-pencil
                            </v-icon>
                            <v-icon 
                                    color="error" 
                                    style="cursor: pointer" 
                                    title="Xóa"
                                    @click="deleteAnnouncement(item.id)"
                                    v-if="checkAuthorization('announcement_delete')">
                                mdi-delete
                            </v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
import moment from "moment"
export default {
    props: {
        announcementList: {
            type: Array,
            default: () => {[]}
        },
        departments: {
            type: Array,
            default: () => {[]}
        }
    },
    methods: {
        updateAnnouncement(announcement) {
            this.$emit("updateAnnouncement", announcement)
        },
        deleteAnnouncement(id) {
            let me = this

            this.$swal({
                title: "Bạn có chắc muốn xóa thông báo này?",
                width: "500px",
                showCancelButton: true,
                confirmButtonText: "Đồng ý",
                cancelButtonText: "Hủy"
            }).then(res => {
                if (res.value) {
                    me.$emit("deleteAnnouncement", id)
                }
            })
        },
        formatDate(value) {
            if (value) {
                return moment(value).format("YYYY-MM-DD")
            }
            return ""
        },
        getDepartment(departmentIds) {
            if (departmentIds.length) {
                if (_.isArray(this.departments) && this.departments.length) {
                    if (departmentIds.length == this.departments.length) {
                        return "Tất cả"
                    } else {
                        let temp = this.departments.filter(item => departmentIds.includes(item.id))
                        temp = temp.map(item => item.name)
                        return temp.join(", ")
                    }
                }
            }
            return "Tất cả"
        }
    }
}
</script>

<style scoped>
/deep/ table {
    border-collapse: collapse;
}

/deep/ table tr td {
    border-bottom: 1px solid #DDDDDD;
}

/deep/ table tr th {
    border-bottom: 1px solid #DDDDDD;
    height: 35px !important;
}

/deep/ tbody:hover td[rowspan], tr:hover td {
   background: #DDDDDD; 
}

/deep/ table tbody tr {
    height: 50px;
}

.td_content {
    width: 35% !important;
}

.td_link {
    width: 20% !important;
}

.v-icon {
    outline: unset;
}
</style>

