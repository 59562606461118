<template>
  <v-expansion-panels class="report-register-filter" style="z-index: 5">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0 overflow-visible" outlined>
          <validation-observer v-slot="{ handleSubmit }">
            <v-list-item class="pa-0" three-line>
              <v-list-item-content class="overflow-visible pa-0">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filterForm.listCustomer"
                      :items="listCustomer"
                      item-text="username"
                      item-value="id"
                      label="Tìm kiếm theo khách hàng"
                      placeholder="Khách hàng"
                      color="primary"
                      clearable
                      multiple
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filterForm.listProduct"
                      :items="listProduct"
                      item-text="name"
                      item-value="id"
                      label="Tìm kiếm theo sản phẩm"
                      placeholder="Sản phẩm"
                      color="primary"
                      clearable
                      multiple
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filterForm.listUser"
                      :items="listUser"
                      item-text="username"
                      item-value="id"
                      label="Tìm kiếm theo quản lý"
                      placeholder="Quản lý"
                      color="primary"
                      clearable
                      multiple
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider name="Chọn ngày" v-slot="{ errors }">
                      <common-range-picker
                        v-model="selectRange"
                        :clearable="true"
                        :aLight="'left'"
                        :defaultRange="defaultRange"
                        @change-date-range="changeDateRange"
                      />
                      <div class="v-text-field__details mt-1">
                        <div
                          class="v-messages theme--light error--text"
                          role="alert"
                        >
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="text-center mb-4">
                  <v-btn
                    class="primary mx-0"
                    @click.prevent="handleSubmit(searchSalesReport)"
                  >
                    <v-icon>mdi-magnify</v-icon>Tìm kiếm
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </validation-observer>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import * as moment from "moment";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const ReportRepository = RepositoryFactory.get("report");

export default {
  name: "FilterForm",
  props: {
    filterForm: {
      default: () => ({}),
    },
  },
  data() {
    return {
      start: {
        menuStartTime: false,
        startTime: "",
        startDate: "",
      },
      end: {
        endTime: "",
        endDate: "",
        menuEndTime: false,
      },
      datePicker: false,
      defaultRange: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      selectRange: [
        {
          startDate: moment().format("YYYY-MM-DD 00:00:00"),
          endDate: moment().format("YYYY-MM-DD 23:59:59"),
        },
      ],
      listUser: [],
      listCustomer: [],
      listProduct: [],
    };
  },
  mounted() {
    this.customerFilterReport();
    this.productFilterReport();
    this.userFilterReport();
  },
  methods: {
    searchSalesReport() {
      Bus.$emit("sales-report-search-filter", this.filterForm);
    },

    changeDateRange(dateRange) {
      this.filterForm.dateRange = dateRange;
      this.selectRange = dateRange;
    },

    async customerFilterReport() {
      let res = await ReportRepository.customerFilterReport();
      if (res.success) {
        this.listCustomer = _.get(res, "data.customers", []);
      }
    },

    async productFilterReport() {
      let res = await ReportRepository.productFilterReport();
      if (res.success) {
        this.listProduct = _.get(res, "data.list_product", []);
      }
    },

    async userFilterReport() {
      let res = await ReportRepository.userFilterReport();
      if (res.success) {
        this.listUser = _.get(res, "data.list_user", []);
      }
    },
  },
};
</script>
