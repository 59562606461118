<template>
  <v-dialog
      :value="showModal"
      max-width="80%"
      persistent
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-card class="mx-auto overflow-visible " width="100%" style="margin-top: 0px; margin-bottom: 0px;" outlined>

    <v-list-item three-line>
      <v-list-item-content class="overflow-visible">
        <div class="mx-6">
          <v-row style="width: 100%">
            <v-col cols="10">
              <v-list-item-title v-if="isCreate" class="headline mb-2" style="font-size: 22px !important">Tạo tệp khách hàng</v-list-item-title>
              <v-list-item-title v-else class="headline mb-2" style="font-size: 22px !important">Chi tiết tệp khách hàng</v-list-item-title>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-icon aria-label="Close" @click="closeForm">mdi-close</v-icon>
            </v-col>
          </v-row>

          <v-row class="pr-10">

            <v-col cols="12" md="6">
              <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Tên tệp"
                  style="width: 100%; display: flex"
              >
                <v-icon class="mr-5 icon-form-create-customer">mdi-account</v-icon>
                <v-text-field
                    label="Tên tệp khách hàng *"
                    placeholder="Nhập tên tệp"
                    v-model="customerFileData.title"
                    :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <validation-provider
                  v-slot="{ errors }"
                  name="Ghi chú"
                  style="width: 100%; display: flex"
              >
                <v-icon class="mr-5 icon-form-create-customer">mdi-file</v-icon>
                <v-text-field
                    label="Ghi chú"
                    placeholder="Nhập ghi chú"
                    v-model="customerFileData.description"
                    :error-messages="errors"
                />
              </validation-provider>
            </v-col>

<!--            form edit-->
            <v-col v-if="!isCreate" cols="12" md="6" class="d-flex">
                <v-icon class="mr-5 icon-form-create-customer">mdi-account</v-icon>
                <v-text-field
                    label="Số khách hàng của tệp *"
                    v-model="customerFileData.numberOfContact"
                    readonly
                />
            </v-col>

            <v-col v-if="!isCreate" cols="12" md="6" class="d-flex">
              <v-icon class="mr-5 icon-form-create-customer">mdi-account</v-icon>
              <v-text-field
                  label="Ngày tạo *"
                  v-model="customerFileData.createdAt"
                  readonly
              />
            </v-col>

          </v-row>

          <v-row style="width: 100%;" class="pr-7 pb-3 justify-space-between">
            <span class="headline pt-2 mx-1" style="font-size: 18px !important">Với điều kiện sau</span>
            <v-btn class="success mx-0" height="30px" v-if="!isCreate" @click="viewContactList()">Xem danh sách contact</v-btn>
          </v-row>
        </div>

        <v-row class="border-table-custom mx-4">
          <v-col cols="12" md="3">
            <common-range-picker
                    :clearable="true"
                    :aLight="'right'"
                    label="Ngày được cấp contact"
                    v-model="customerFileData.operatorValue.assignedAt"
                    :readonly="isReadOnly"
                    :dateRangeValue="customerFileData.operatorValue.assignedAt"
            ></common-range-picker>
          </v-col>

          <v-col cols="12" md="3">
            <common-range-picker
                :clearable="true"
                :aLight="'right'"
                label="Ngày đăng ký gần nhất"
                v-model="customerFileData.operatorValue.importUpdatedAt"
                :readonly="isReadOnly"
                :dateRangeValue="customerFileData.operatorValue.importUpdatedAt"
            ></common-range-picker>
          </v-col>

          <v-col cols="12" md="3">
            <common-range-picker
                :clearable="true"
                :aLight="'center'"
                label="Ngày tạo đơn"
                v-model="customerFileData.operatorValue.orderCreatedAt"
                :readonly="isReadOnly"
                :dateRangeValue="customerFileData.operatorValue.orderCreatedAt"
            />
          </v-col>

          <v-col cols="12" md="3" class="pt-0">
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                    :items="typeSearch"
                    item-text="name"
                    item-value="code"
                    label="So sánh"
                    placeholder="Băng"
                    v-model="customerFileData.operatorType.orderTotalPrice"
                    :readonly="isReadOnly"
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                    type="number"
                    min="0"
                    step="1"
                    label="Tổng giá trị đơn"
                    placeholder="Tìm kiếm theo Tổng giá trị đơn"
                    v-model="customerFileData.operatorValue.orderTotalPrice"
                    :readonly="isReadOnly"
                />
              </v-col>
            </v-row>
          </v-col>
<!-- row 2-->
          <v-col cols="12" md="3">
            <common-select-user
                class="btn-style-all"
                v-model="customerFileData.operatorValue.userIds"
                :items="usersGroup"
                color="primary"
                item-text="fullName"
                item-value="id"
                multiple
                placeholder="Tìm kiếm theo người phụ trách"
                label="Người phụ trách"
                :readonly="isReadOnly"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-autocomplete
                v-model="customerFileData.operatorValue.contactSourceIds"
                :items="contactSources"
                item-text="name"
                item-value="id"
                label="Nguồn khách hàng"
                placeholder="Tìm kiếm theo nguồn khách hàng"
                multiple
                clearable
                eager
                :readonly="isReadOnly"
            />
          </v-col>

          <v-col cols="12" md="3">
            <common-range-picker
                :clearable="true"
                :aLight="'center'"
                label="Ngày hết hạn"
                v-model="customerFileData.operatorValue.expiredDate"
                :readonly="isReadOnly"
                :dateRangeValue="customerFileData.operatorValue.expiredDate"
            ></common-range-picker>
          </v-col>

          <v-col cols="12" md="3">
            <v-select
                v-model="customerFileData.operatorValue.customerResult"
                :items="customerResults"
                label="Kết quả học tập"
                placeholder="Tìm kiếm kết quả học tập"
                clearable
                :readonly="isReadOnly"
            ></v-select>
          </v-col>

<!--          row 3-->

          <v-col cols="12" md="3">
            <v-autocomplete
                v-model="customerFileData.operatorValue.statusId"
                :items="selectContactStatusList"
                item-text="name"
                item-value="id"
                label="Mối quan hệ"
                placeholder="Tìm kiếm theo mối quan hệ"
                clearable
                eager
                :readonly="isReadOnly"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-autocomplete
                v-model="customerFileData.operatorValue.productGroupIds"
                :items="productGroups"
                item-text="name"
                item-value="id"
                label="Nhóm sản phẩm"
                placeholder="Tìm kiếm theo nhóm sản phẩm"
                multiple
                clearable
                eager
                :readonly="isReadOnly"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-autocomplete
                v-model="customerFileData.operatorValue.boughtProduct"
                :items="products"
                item-text="name"
                item-value="id"
                label="Sản phẩm đã mua"
                placeholder="Sản phẩm đã mua"
                multiple
                clearable
                eager
                :readonly="isReadOnly"
            />
          </v-col>

          <v-col v-if="isCreate" cols="12" md="3">
            <common-select-year
                v-model="customerFileData.operatorValue.years"
                label="Năm sinh"
                placeholder="Tìm kiếm theo năm sinh"
                clearable
                :readonly="isReadOnly"
            />
          </v-col>

          <v-col v-else cols="12" md="3">
            <v-text-field
                v-model="customerFileData.operatorValue.years"
                label="Năm sinh"
                placeholder="Tìm kiếm theo năm sinh"
                :readonly="isReadOnly"
            />
          </v-col>

<!--          row 4-->

          <v-col cols="12" md="3" class="pt-0">
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                    :items="typeSearch"
                    item-text="name"
                    item-value="code"
                    label="So sánh"
                    placeholder="Bằng"
                    v-model="customerFileData.operatorType.numberOfCall"
                    :readonly="isReadOnly"
                ></v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                    type="number"
                    min="0"
                    step="1"
                    label="Số lần gọi"
                    placeholder="Tìm kiếm theo số lần gọi"
                    v-model="customerFileData.operatorValue.numberOfCall"
                    :readonly="isReadOnly"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="3">
            <common-range-picker
                :aLight="'right'"
                label="Ngày liên hệ gần nhất"
                v-model="customerFileData.operatorValue.callAt"
                :readonly="isReadOnly"
                :dateRangeValue="customerFileData.operatorValue.callAt"
            ></common-range-picker>
          </v-col>

          <v-col cols="12" md="3">
            <v-autocomplete
                v-model="customerFileData.operatorValue.unBoughtProduct"
                :items="products"
                item-text="name"
                item-value="id"
                label="Sản phẩm chưa mua"
                placeholder="Sản phẩm chưa mua"
                multiple
                clearable
                eager
                :readonly="isReadOnly"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              label="Tên con"
              placeholder="Tìm kiếm theo tên con"
              v-model="customerFileData.operatorValue.childName"
              :readonly="isReadOnly"
            />
          </v-col>

        </v-row>
        <div v-if="!isReadOnly" class="text-center my-4">
          <v-btn class="success mx-2" @click.prevent="handleSubmit(createCustomerFile)">Tạo
          </v-btn>
          <v-btn color="red" @click="closeForm">Hủy
          </v-btn>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
    </validation-observer>
  </v-dialog>
</template>
<script>
import RepositoryFactory from '../../../../repositories/RepositoryFactory';
import { downloadFile } from '../../../../helpers/utils';
const DepartmentRepository = RepositoryFactory.get('department');
const ContactRepository = RepositoryFactory.get('contact');
const CustomerFileRepository = RepositoryFactory.get('customer_file')

import TokenService from '../../../../helpers/token';
export default {
  name: 'ContactStatusFilterForm',
  props: {
    users: {
      type: Array,
      default: () => []
    },
    productGroups: {
      type: Array,
      default: () => []
    },
    contactStatusList: {
      type: Array,
      default: () => []
    },
    contactSources: {
      type: Array,
      default: () => []
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      currentPicker: null,
      showPopupDate: false,
      usersGroup: [],
      userPostion: TokenService.getPosition(),
      typeSearch: [
        {
          code: 'eq',
          name: 'Bằng'
        },
        {
          code: 'gt',
          name: 'Lớn hơn'
        },
        {
          code: 'lt',
          name: 'Nhỏ hơn'
        },
      ],
      showModal: false,
      isCreate : true,
      isReadOnly: false,
      errorMessage: '',
      filterForm: {
        totalValueOfOrder: '',
        openMenu: false,
        code: '',
        departmentId: '',
        userId: '',
        departmentIds: [],
        userIds: [],
        groupId: '',
        productId: '',
        contactSourceId: '',
        paymentType: '',
        createdAt: [],
        contactName: '',
        contactMobile: '',
        childBirthday: '',
        statusIds: [],
        callAt: '',
        productNotBuyYet: [],
        productBought: [],
        numberOfCustomFile: '',
        numberOfCall: '',
        typeNumberOfCall: "",
        typeNumberOfTotalValue: "",
        customerResult: ''
      },
      customerFileData: {
        title: '',
        description: '',
        operatorValue: {
          assignedAt: [],
          importUpdatedAt: [],
          orderCreatedAt: [],
          orderTotalPrice: '',

          userIds: [],
          contactSourceIds: [],
          expiredDate: [],
          customerResult: '',

          statusId: '',
          productGroupIds: [],
          boughtProduct: '',
          years: '',

          numberOfCall: '',
          callAt: [],
          unBoughtProduct: '',
          childName: ''
        },
        operatorType: {}
      },
      customerResults: [
          'Chăm chỉ', 'Bình thường', 'Lười', 'Không học'
      ]
    }
  },
  async created() {

    await Promise.all([
      this.getUsers()
  ]);

    // console.log(this.usersGroup)
    Bus.$on('customer-show-form-create-detail', async (item) => {
      this.customerFileData.operatorType = {}
      this.customerFileData =  _.cloneDeep(item);
      console.log('customerFileData', this.customerFileData)
      if(item.id) {
        this.isReadOnly = true;
        this.isCreate = false;
      } else {
        this.isReadOnly = false;
        this.isCreate = true;
      }
      this.showModal = true;
    })
  },
  computed: {
    selectContactStatusList() {
      const contactStatusList = [];
      const checkType = {};
      if (this.isStaff()) {
        _.remove(this.contactStatusList, function (e) {
          return e.type === 'T0';
        });
      }
      this.contactStatusList.forEach(contactStatus => {
        const typeContactStatus = contactStatus.type;
        if (!checkType[typeContactStatus]) {
          checkType[typeContactStatus] = true;
          if (contactStatusList.length > 0) {
            contactStatusList.push({ divider: true });
          }
          contactStatusList.push({ header: typeContactStatus });
        }
        contactStatusList.push(contactStatus);
      });
      return contactStatusList;
    }
  },
  methods: {
    closeForm() {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = '';
      this.customerFileData = {
        operatorValue: {},
        operatorType: {}
      };
      console.log(this.customerFileData)
    },
    onChangeDepartment() {
      const groupUsers = [];
      const filterFormTemp = this.filterForm;
      filterFormTemp.userId = '';
      const departmentList = this.departments.filter(
          d => {
            if ( _.isArray(filterFormTemp.departmentIds) && filterFormTemp.departmentIds.length ) {
              return  _.get(d, 'users', []).length > 0 && filterFormTemp.departmentIds.includes(parseInt(d.id))
            } else {
              return  _.get(d, 'users', []).length > 0
            }
          }
      );
      departmentList.forEach(department => {
        const { users = [] } = department;
        if (users.length) {
          groupUsers.push({
            header: department.name,
            name: _.get(department, 'name')
          });
          users.forEach(user => {
            groupUsers.push({
              fullName: _.get(user, 'fullName'),
              group: _.get(department, 'name'),
              id: user.id,
              avatar: _.get(user, 'avatar.filePath')
            });
          });
        }
      });
      this.usersGroup = groupUsers;
      this.filterForm.userIds = [];
    },
    async getUsers() {
      const respond = await DepartmentRepository.listUsers({
        keyword: ''
      });
      if (_.get(respond, 'success', false)) {
        let departments = _.get(respond, 'data', []);
        if (!this.checkAuthorization('order_view_all') && !this.isAdmin()) {
          const dataUser = TokenService.getUser() || {};
          departments = departments.filter( d => d.id == dataUser.departmentId || dataUser.listDepartmentIds.includes(parseInt(d.id)));
        }
        this.departments = departments;
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(respond, 'message', '')
        });
      }
      this.onChangeDepartment()
    },
    searchContact() {
      Bus.$emit('contact-list-filter', this.filterForm);
    },
    checkEmptyFilterForm() {
      let isExist = false;
      // check keyword
      const isExistKeyword = !!this.filterForm.keyword;

      // check appointmentAt
      const isExistAppointmentAt = _.isArray(this.filterForm.appointmentAt) ? !!this.filterForm.appointmentAt.length : false;

      // check assignedAt
      const isExistAssignedAt = _.isArray(this.filterForm.assignedAt) ? !!this.filterForm.assignedAt.length : false;

      // check callAt
      const isExistCallAt = _.isArray(this.filterForm.callAt) ? !!this.filterForm.callAt.length : false;

      // check years
      const isExistYears = _.isArray(this.filterForm.years) ? !!this.filterForm.years.length : false;

      // check statusIds
      const isExistStatusIds = _.isArray(this.filterForm.statusIds) ? !!this.filterForm.statusIds.length : false;

      // check userIds
      const isExistUserIds = _.isArray(this.filterForm.userIds) ? !!this.filterForm.userIds.length : false;

      // check contactSourceIds
      const isExistContactSourceIds = _.isArray(this.filterForm.contactSourceIds) ? !!this.filterForm.contactSourceIds.length : false;

      // check productGroupIds
      const isExistProductGroupIds = _.isArray(this.filterForm.productGroupIds) ? !!this.filterForm.productGroupIds.length : false;

      // check childName
      const isExistChildName = !!this.filterForm.childName;

      // check importUpdatedAt
      const isExistImportUpdatedAt = _.isArray(this.filterForm.importUpdatedAt) ? !!this.filterForm.importUpdatedAt.length : false;

      isExist = isExistKeyword || isExistAppointmentAt || isExistAssignedAt || isExistCallAt || isExistYears || isExistStatusIds || isExistUserIds || isExistContactSourceIds || isExistProductGroupIds || isExistChildName || isExistImportUpdatedAt;
      return isExist;
    },
    
    async createCustomerFile() {

      const dataCustomerFile = _.cloneDeep(this.customerFileData)
      Object.keys(dataCustomerFile.operatorValue).forEach(key => {
        if(key != 'orderTotalPrice' && key != 'numberOfCall') {
          dataCustomerFile.operatorType[key] = 'eq';
        }
      })

      if(this.isCreate) {
        const res = await CustomerFileRepository.create(dataCustomerFile)
        if(res.success) {
          this.showModal = false;
          this.$swal({
                title: 'Tệp khách hàng đã được tạo thành công với ' +  _.get(res, 'data.numberOfContact', 0) + ' contacts',
                confirmButtonText: 'Đồng ý',
                timer: 3000
              },
          ).then(() => {
            Bus.$emit('create-customer-file-reload')
          })
        } else {
          this.errorMessage = _.get(res, 'message', '')
        }
      } else {
        Object.keys(dataCustomerFile).forEach(key => {
          if(!['title', 'description', 'operatorValue', 'operatorType'].includes(key)) {
            delete dataCustomerFile[key]
          }
        })
        const loader = this.$loading.show();
        this.loading = true;
        const res = await CustomerFileRepository.update(this.customerFileData.id, dataCustomerFile)
        loader.hide();
        this.loading = false;
        if(res.success) {
          this.showModal = false;
          this.errorMessage = '';
          this.$swal({
                title: 'Tệp khách hàng đã được cập nhật thành công',
                confirmButtonText: 'Đồng ý',
                timer: 3000
              },
          ).then(() => {
            Bus.$emit('create-customer-file-reload')
          })
        } else {
          this.errorMessage = _.get(res, 'message', '')
        }
      }
    },
    async viewContactList() {
      let res = await CustomerFileRepository.listContact(this.customerFileData.id)
      Bus.$emit("show-contact-list-contact-filter", _.get(res, 'data', []))
    }
  }
};
</script>
<style scoped>

  /deep/.hide-date .daterangepicker.dropdown-menu{
    display: none
  }
  /deep/.show-date .daterangepicker.dropdown-menu{
    display: block;
  }

  /deep/ .icon-form-create-customer {
    height: fit-content !important;
  }

  /deep/ .border-table-custom {
    border: solid 1px #ccc;
    border-radius: 5px;
  }

</style>