<template>
    <v-card class="ma-0 support-card-mobile" >
        <v-tabs color="primary" grow v-model="tab" @change="refreshTable()">
            <v-tab v-for="(item, i) in tabs" :key="i">
                {{ item }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
            <v-tab-item >
                <support-form :mobileStyle="'overflow-y: auto; height: calc(100vh - 160px)'"/>
            </v-tab-item>
            <v-tab-item>
                <filter-form :isMobile="true"/>
                <support-table-mobile :requestList="requestList"/>
                <v-row class="ma-0 pagination-container">
                    <v-col cols="12" class="py-1">
                        <v-pagination
                            v-if="totalItem > limit"
                            v-model="currentPage"
                            :length="getTotalItem(totalItem, limit)"
                            class="justify-sm-end"
                            prev-icon="mdi-menu-left"
                            next-icon="mdi-menu-right"
                        />
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import SupportForm from './components/SupportForm'
import SupportTableMobile from './components/SupportTableMobile'
import FilterForm from './components/FilterForm'
import TokenService from '@/helpers/token'
import moment from 'moment'
import RepositoryFactory from '../../repositories/RepositoryFactory'
const SupportRepository = RepositoryFactory.get('support')

export default {
    components: {
        SupportForm,
        SupportTableMobile,
        FilterForm
    },
    data() {
        return {
            tabs: ["Gửi hỗ trợ", "Danh sách"],
            tab: '',
            currentPage: 1,
            limit: 30,
            totalItem: 0,
            requestList: [],
            filterForm: {
                mobile: '',
                startDateTime: moment().startOf('isoWeek').format('YYYY-MM-DD'),
                endDateTime: moment().endOf('isoWeek').format('YYYY-MM-DD')
            },
        }
    },
    created() {
        this.getRequestList()
        Bus.$on('search-request-support-list-mobile', filterForm => {
            this.filterForm = filterForm
            this.getRequestList()
        })
        Bus.$on('fetch-table-while-change-tab-mobile', () => {
            this.getRequestList()
        })
    },
    watch: {
        currentPage: {
            handler() {
                this.getRequestList();
            }
        }
    },
    methods: {
        refreshTable() {
            if (this.tab == 1) {
                Bus.$emit('fetch-table-while-change-tab-mobile')
            }
        },

        async getRequestList() {
            const loading = this.$loading.show()
            const userId = await TokenService.getId()
            const params = {
                userId,
                ...this.filterForm,
                startDateTime: this.filterForm.startDateTime == '' ? '' : this.filterForm.startDateTime + ' 00:00:00',
                endDateTime: this.filterForm.endDateTime == '' ? '' : this.filterForm.endDateTime + ' 23:59:59',
                limit: this.limit,
                page: this.currentPage,
                order: 'createdAt'
            }
            let res = await SupportRepository.list(params)
            loading.hide()
            if (res.success) {
                this.requestList = _.get(res, 'data', []).map(item => {
                    return {
                        ...item,
                        expanded: false
                    }
                }),
                this.totalItem = _.get(res, 'totalItem', [])
            } else {
                this.requestList = []
                this.totalItem = 0
            }
        },
    }
}
</script>

<style scoped>
.support-card-mobile {
    height: calc(100vh - 50px);
}
.pagination-container {
    position: fixed;
    background-color: white;
    z-index: 10;
    bottom: 5px;
    text-align: center;
    width: 100%;
}
</style>