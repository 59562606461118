<template>
  <v-expansion-panels class="report-register-filter" style="z-index: 5">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0 overflow-visible" outlined>
          <validation-observer v-slot="{ handleSubmit }">
            <v-list-item class="pa-0" three-line>
              <v-list-item-content class="overflow-visible pa-0">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filterForm.userId"
                      :items="users"
                      item-text="username"
                      item-value="id"
                      label="Tìm kiếm theo quản lý"
                      placeholder="Quản lý"
                      color="primary"
                      clearable
                      multiple
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filterForm.customerId"
                      :items="customers"
                      item-text="username"
                      item-value="id"
                      label="Tìm kiếm theo khách hàng"
                      placeholder="Khách hàng"
                      color="primary"
                      clearable
                      multiple
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-autocomplete
                      v-model="filterForm.productId"
                      :items="products"
                      item-text="name"
                      item-value="id"
                      label="Tìm kiếm theo sản phẩm"
                      placeholder="Sản phẩm"
                      color="primary"
                      clearable
                      multiple
                    />
                  </v-col>
                </v-row>
                <div class="text-center mb-4">
                  <v-btn
                    class="primary mx-0"
                    @click.prevent="handleSubmit(searchRecord)"
                  >
                    <v-icon>mdi-magnify</v-icon>Tìm kiếm
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </validation-observer>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {
  PAGE_LIMIT,
  userPositions,
} from "../../../helpers/constants";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const ProductRepository = RepositoryFactory.get("product");
const CustomerRepository = RepositoryFactory.get("customer");
const UserRepository = RepositoryFactory.get("user");

export default {
  name: "FilterForm",
  props: {
    filterForm: {
      default: () => ({}),
    },
  },
  data() {
    return {
      products: [],
      customers: [],
      users: [],
    };
  },
  created() {
    this.getProducts();
    this.getListCustomer();
    this.getListUser();
  },
  methods: {
    searchRecord() {
      console.log("this.filterForm = ", this.filterForm)
      Bus.$emit("management-list-filter", this.filterForm);
    },

    async getListUser() {
      let res = await UserRepository.list({
        limit: PAGE_LIMIT,
        position: userPositions.MANAGEMENT,
      });
      if (res.success) {
        this.users = _.get(res, "data", []);
      }
    },

    async getProducts() {
      let res = await ProductRepository.list();
      if (res.success) {
        this.products = _.get(res, "data.list_product", []);
      } else {
        this.products = [];
      }
    },
    
    async getListCustomer() {
      let res = await CustomerRepository.listCustomerGroup();
      if (res.success) {
        let customerList = [];
        _.get(res, "data.list_department", []).forEach(
          (groupCustomer, index) => {
            const { listCustomer = [] } = groupCustomer;
            customerList.push({
              header: groupCustomer.departmentName,
              name: groupCustomer.departmentName,
              departmentId: groupCustomer.departmentId,
            });
            listCustomer.forEach((cus) => {
              customerList.push(cus);
            });
          }
        );
        this.customers = customerList;
      }
    },
  },
};
</script>
