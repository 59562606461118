<template>
  <v-container id="add-question-answer" fluid tag="section">
    <v-card class="mx-auto pa-5" outlined>
      <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(update)">
          <v-alert
            v-show="alertMessageText"
            v-model="alert"
            :type="alertMessageType"
            dismissible
          >
            {{ alertMessageText }}
          </v-alert>

          <v-row>
            <v-col cols="12" sm="12">
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%; margin-bottom: 10px"
                >Câu hỏi</span
              >
              <validation-provider v-slot="{ errors }" name="Câu hỏi">
                <template>
                  <vue-editor
                    v-model="information.question"
                    :editor-toolbar="customToolbar"
                  />
                </template>
                <span
                  v-if="errors[0]"
                  class="errors-span-required-content"
                  color="primary"
                  >* {{ errors[0] }}</span
                >
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12">
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%; margin-bottom: 10px"
                >Câu trả lời</span
              >
              <validation-provider v-slot="{ errors }" name="Câu trả lời">
                <template>
                  <vue-editor
                    v-model="information.answer"
                    :editor-toolbar="customToolbar"
                  />
                </template>
                <span
                  v-if="errors[0]"
                  class="errors-span-required-content"
                  color="primary"
                  >* {{ errors[0] }}</span
                >
              </validation-provider>
            </v-col>
          </v-row>

          <div class="mt-2 text-center">
            <v-btn
              class="mb-2"
              color="green"
              @click.prevent="handleSubmit(update)"
            >
              Cập nhật
            </v-btn>
            <v-btn class="mb-2" color="red" @click="goToList()"> Hủy </v-btn>
          </div>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template> 
<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { VueEditor } from "vue2-editor";
const QuestionAnswerRepository = RepositoryFactory.get("question_answer");

export default {
  components: { VueEditor },
  $_veeValidate: {
    validator: "new",
  },
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      information: {
        id: 0,
        question: "",
        answer: "",
      },
      alertMessageText: null,
      alertMessageType: "success",
      alert: true,
      loading: false,
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        ["image", "code-block"],
      ],
    };
  },
  async created() {
    await this.getDetail();
  },
  methods: {
    async update() {
      this.loading = this.$loading.show();
      const response = await QuestionAnswerRepository.update(
        this.information.id,
        {
          question: _.get(this.information, "question", ""),
          answer: _.get(this.information, "answer", ""),
        }
      );
      this.loading.hide();
      if (response.error) {
        this.setAlertMessage(true, "error", _.get(response, "message"));
      } else {
        this.$swal({
          title: "Cập nhật thành công!",
          confirmButtonText: "Đồng ý",
        });
      }
    },
    setAlertMessage(alert, type, text) {
      this.alert = alert;
      this.alertMessageText = text;
      this.alertMessageType = type;
    },
    goToList() {
      this.$router.push({
        name: "QuestionAnswerList",
      });
    },
    async getDetail() {
      const loader = this.$loading.show();
      this.information.id = this.$route.params.id;
      const response = await QuestionAnswerRepository.getQuestionAnswer({
        id: this.information.id,
      });
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.information.question = _.get(
          response,
          "data.question_answer.question",
          ""
        );
        this.information.answer = _.get(
          response,
          "data.question_answer.answer",
          ""
        );
      }
    },
  },
};
</script>

