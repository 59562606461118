<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer 
        ref="validator"
        v-slot="{ handleSubmit }"
      >
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate">
            Thêm mới nhóm sản phẩm
          </template>
          <template v-else>
            Cập nhật thông tin nhóm sản phẩm
          </template>

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="hideModal"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Tên nhóm sản phẩm"
          >
            <v-text-field
              v-model="productGroup.name"
              label="Tên nhóm sản phẩm *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:10|alpha_num"
            name="Mã nhóm sản phẩm"
          >
            <v-text-field
              v-model="productGroup.code"
              label="Mã nhóm sản phẩm *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <div class="group-input">
            <ul>
              <li class="title">
                <h3 class="text-left">
                  Link Url:
                </h3>
              </li>
              <template v-for="(url, index) in productGroup.urls">
                <li
                  :key="url.id"
                  class="list-input"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    :rules="{
                      regex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                      max: 255,
                    }"
                    name="Url"
                    vid="url"
                  >
                    <v-text-field
                      v-model.trim="url.inputUrl"
                      label="Url"
                      color="primary"
                      :error-messages="errors"
                      clearable
                    />
                  </validation-provider>
                  <v-icon
                    v-if="index>0"
                    class="group-input-close"
                    @click="deleteRow(url.id)"
                  >
                    mdi-delete
                  </v-icon>
                </li>
              </template>
            </ul>
            <v-icon
              class="group-input-add"
              @click="addRow"
            >
              mdi-plus
            </v-icon>
          </div>
          
          <v-autocomplete
            v-model="productGroup.schoolLevel"
            attach
            :items="schoolLevelOptions"
            item-text="text"
            item-value="value"
            label="Cấp học"
            color="primary"
          />

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            name="Nhóm ngành"
            rules="required"
          >
            <v-autocomplete
              v-model="productGroup.schoolLevelId"
              :items="schoolLevels"
              item-text="name"
              item-value="id"
              label="Nhóm ngành *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            name="Mô tả sản phẩm"
            rules="max: 255"
          >
            <v-textarea
              v-model="productGroup.description"
              label="Mô tả sản phẩm"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn
            color="success"
            @click.prevent="handleSubmit(saveProductGroup)"
          >
            Lưu lại
          </v-btn>
          <v-btn
            class="ml-0"
            color="primary"
            @click="hideModal"
          >
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
  import { schoolLevelOptions } from '../../../../helpers/constants'
  import RepositoryFactory from '../../../../repositories/RepositoryFactory'
  const ProductGroupRepository = RepositoryFactory.get('product_group')
  export default {
    $_veeValidate: {
      validator: 'new',
    },
    props: {
      schoolLevels: {
        type: Array,
        default: () => {[]}
      }
    },
    data () {
      return {
        showModal: false,
        schoolLevelOptions,
        productGroup: {},
        isCreate: true,
        productGroups: [],
        errorMessage: '',
        listURL: [],
        urlsItem: [],
        duplicateURL: false
      }
    },
    created () {
      Bus.$on('product-group-show-form1', (productGroup) => {
        this.showModal = true
        const { urls = [] } = productGroup
        if (urls.length === 0) {
          productGroup.urls = [{
            id: (new Date()).getTime(),
            inputUrl: '',
          }]
        }
        this.productGroup = _.cloneDeep(productGroup)
        if (productGroup.id) this.isCreate = false
        else this.isCreate = true
        this.getListURL()
      })
    },
    methods: {
      hideModal: function () {
        this.showModal = false
        this.isCreate = true
        this.errorMessage = ''
        this.$refs.validator.reset()
      },
      addRow () {
        const productGroup = _.cloneDeep(this.productGroup)
        const { urls = [] } = productGroup
        urls.push({
          id: (new Date()).getTime(),
          inputUrl: '',
        })
        this.$set(this, 'productGroup', productGroup)
      },
      deleteRow (id) {
        const index = this.productGroup.urls.findIndex(u => u.id === id)
        if (index > -1) this.productGroup.urls.splice(index, 1)
      },
      async saveProductGroup () {
        this.checkUrl()
        if(this.duplicateURL == false ) return false
        const productGroup = {
          name: _.trim(this.productGroup.name),
          code: _.trim(this.productGroup.code),
          description: _.trim(this.productGroup.description),
          schoolLevel: this.productGroup.schoolLevel,
          urls: _.get(this.productGroup, 'urls', []).map(u => u.inputUrl).filter((obj) => obj),
          schoolLevelId: this.productGroup.schoolLevelId
        }
        const loader = this.$loading.show()
        let response = {}
        if (this.isCreate) {
          response = await ProductGroupRepository.create(productGroup)
          loader.hide()
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.errorMessage = ''
            this.showModal = false
            this.$swal({
              title: 'Thêm nhóm sản phẩm thành công',
              confirmButtonText: 'Đồng ý',
            }).then(() => {
              Bus.$emit('product-group-reload')
            })
          }
        } else {
          response = await ProductGroupRepository.update(this.productGroup.id, productGroup)
          loader.hide()
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.errorMessage = ''
            this.showModal = false
            this.$swal({
              title: 'Cập nhật nhóm sản phẩm thành công',
              confirmButtonText: 'Đồng ý',
            }).then(() => {
              Bus.$emit('product-group-reload')
            })
          }
        }
      },

      async getListURL () {
        const listURL = []
        const responseURL = await ProductGroupRepository.list({limit: 10000})
        if (responseURL.success) {
          _.get(responseURL, 'data', []).map(group => {
            _.get(group, 'urls', []).map(url => (
              listURL.push(url.url)
            ))
          })
        }
        this.listURL = listURL

        const urlsItem = this.productGroup.urls.map(e => e.inputUrl)
        this.urlsItem = urlsItem

        _.pull(this.listURL, ...urlsItem)
      },

      checkUrl() {
        const urlsItem = this.productGroup.urls.map(e => e.inputUrl)
        urlsItem.forEach(e => {
          if (this.listURL.indexOf(e) != -1) {
            this.$swal({
              title: 'Link đã tồn tại. Vui lòng kiểm tra lại',
            })
            this.duplicateURL = false
            return false
          }
          this.duplicateURL = true
        });
      }
    },
  }
</script>
