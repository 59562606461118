<template>
  <v-container id="sales-report" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <br />
    <div class="text-sm-right mb-2">
      <!-- <v-btn
        v-if="checkAuthorization('sales_report_download')"
        class="primary mx-0"
        @click="exportExcel"
      >
        <v-icon>mdi-download</v-icon>Download
      </v-btn> -->
    </div>
    <sales-report-table
      :salesData="salesData"
      :totalQuantity="totalQuantity"
      :totalSales="totalSales"
      :totalPercentDiscount="totalPercentDiscount"
      :totalPayment="totalPayment"
    />
    <!-- <v-row class="mt-4">
      <v-col cols="12" sm="6" />
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem, limit)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import FilterForm from "./components/FilterForm.vue";
import SalesReportTable from "./components/SalesReportTable.vue";
import moment from "moment";
import RepositoryFactory from "@/repositories/RepositoryFactory";
// import TokenService from "../../../helpers/token"

const ReportRepository = RepositoryFactory.get("report");

export default {
  components: { SalesReportTable, FilterForm },
  data() {
    return {
      filterForm: {
        dateRange: [],
        listCustomer: [],
        listProduct: [],
        listUser: [],
      },
      salesData: [],
      totalQuantity: 0,
      totalSales: 0,
      totalPercentDiscount: 0,
      totalPayment: 0,
      currentPage: 1,
      limit: 10,
      totalItem: 0,
    };
  },
  created() {
    this.getSalesReport();
    Bus.$on("sales-report-search-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getSalesReport();
    });
  },
  methods: {
    async getSalesReport() {
      const loading = this.$loading.show();
      const res = await ReportRepository.salesReport(this.filterForm);
      if (res.success) {
        this.salesData = _.get(res, "data.sales_statistics", []);
        this.totalQuantity = _.get(res, "data.total_quantity", []);
        this.totalSales = _.get(res, "data.total_sales", []);
        this.totalPercentDiscount = _.get(
          res,
          "data.total_percent_discount",
          []
        );
        this.totalPayment = _.get(res, "data.total_payment", []);
      } else {
        this.salesData = [];
      }
      loading.hide();
    },

    // async exportExcel() {
    //     const loader = this.$loading.show();
    //     let user = await TokenService.getUser()
    //     ReportRepository.exportExcelAccountReport({username: user.username})
    //     loader.hide();
    //     this.$swal({
    //         title: 'Dữ liệu đang được xử lý, sẽ thông báo sau khi kết thúc!'
    //     })
    // }
  },
};
</script>
