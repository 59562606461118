<template>
  <v-expansion-panels class="report-register-filter" style="z-index: 5">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0 overflow-visible" outlined>
          <validation-observer v-slot="{ handleSubmit }">
            <v-list-item class="pa-0" three-line>
              <v-list-item-content class="overflow-visible pa-0">
                <v-row>
                  <v-col cols="12" md="12">
                    <validation-provider name="Chọn ngày" v-slot="{ errors }">
                      <common-range-picker
                        v-model="selectRange"
                        :clearable="true"
                        :aLight="'left'"
                        :defaultRange="defaultRange"
                        @change-date-range="changeDateRange"
                      />
                      <div class="v-text-field__details mt-1">
                        <div
                          class="v-messages theme--light error--text"
                          role="alert"
                        >
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="text-center mb-4">
                  <v-btn
                    class="primary mx-0"
                    @click.prevent="handleSubmit(searchReportAccount)"
                  >
                    <v-icon>mdi-magnify</v-icon>Tìm kiếm
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </validation-observer>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import * as moment from "moment";

export default {
  name: "FilterForm",
  props: {
    filterForm: {
      default: () => ({}),
    },
  },
  data() {
    return {
      start: {
        menuStartTime: false,
        startTime: "",
        startDate: "",
      },
      end: {
        endTime: "",
        endDate: "",
        menuEndTime: false,
      },
      datePicker: false,
      defaultRange: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      selectRange: [
        {
          startDate: moment().format("YYYY-MM-DD 00:00:00"),
          endDate: moment().format("YYYY-MM-DD 23:59:59"),
        },
      ],
    };
  },
  methods: {
    searchReportAccount() {
      Bus.$emit("account-report-search-filter", this.filterForm);
    },

    changeDateRange(dateRange) {
      this.filterForm.dateRange = dateRange;
      this.selectRange = dateRange;
    },
  },
};
</script>
