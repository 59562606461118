<template>
  <v-container id="customer-profile" fluid tag="section">
    <filter-form :filter-form="filterForm" :departments="departments" />
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('customer_create')"
        class="primary mx-0"
        @click="addNewCustomer"
      >
        <v-icon>mdi-plus</v-icon>Thêm Mới
      </v-btn>
    </div>
    <v-card>
      <v-tabs v-model="tab" grow @change="getCustomerList()">
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-for="(item, key) in tabs" :key="key">
          {{ item }}
        </v-tab>

        <v-tab-item v-for="(item, key) in tabs" :key="key">
          <customer-table
            v-if="users.length"
            :filter-form="filterForm"
            :users="users"
            :departments="departments"
          />
          <common-no-result v-if="!loading && totalItem === 0" />
          <v-row class="mt-0">
            <v-col cols="12" sm="6" />
            <v-col cols="12" sm="6">
              <v-pagination
                v-if="totalItem > limit"
                v-model="currentPage"
                class="justify-sm-end"
                :length="getTotalItem(totalItem)"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <reset-password-form />
  </v-container>
</template>

<script>
import { PAGE_LIMIT } from "../../helpers/constants";
import ResetPasswordForm from "./components/ResetPasswordForm";
// import TokenService from "../../helpers/token";
import FilterForm from "./components/FilterForm.vue";
import CustomerTable from "./components/CustomerTable";
import RepositoryFactory from "../../repositories/RepositoryFactory";

const CustomerRepository = RepositoryFactory.get("customer");
const DepartmentRepository = RepositoryFactory.get("department");

export default {
  components: {
    CustomerTable,
    FilterForm,
    ResetPasswordForm,
  },
  data() {
    return {
      filterForm: {},
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      users: [],
      loading: false,
      departments: [],
      tabs: ["Đang hoạt động", "Chờ phê duyệt"],
      tab: 0,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getCustomerList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getCustomerList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  created() {
    this.getCustomerList();
    this.getDepartmentList();
    Bus.$on("customer-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getCustomerList();
    });

    Bus.$on("customer-list-reload", () => {
      this.getCustomerList();
    });
  },
  beforeDestroy() {
    Bus.$off("customer-list-filter");
  },
  methods: {
    async getDepartmentList() {
      const response = await DepartmentRepository.list({ limit: PAGE_LIMIT });
      if (_.get(response, "success", false)) {
        let departments = _.get(response, "data.list_department", []);
        // if (!this.isAdmin() && !this.checkAuthorization("customer_view_all")) {
        //   const dataUser = TokenService.getUser() || {};
        //   departments = departments.filter(
        //     (d) =>
        //       d.id == dataUser.departmentId ||
        //       dataUser.listDepartmentIds.includes(d.id)
        //   );
        // }
        this.departments = departments;
      }
    },
    addNewCustomer() {
      let route = this.$router.resolve({
        name: "CreateCustomer",
      });
      window.open(route.href, "_self");
    },
    async getCustomerList() {
      this.loading = true;
      const params = {
        limit: this.limit,
        page: this.currentPage,
        keyword: this.filterForm.keyword,
        departmentId: this.filterForm.departmentId || "",
        position: this.filterForm.position,
        status: this.tab == 0 ? "active" : "inactive",
        mobile: this.filterForm.mobile,
      };
      // if (
      //   !this.filterForm.departmentId &&
      //   !this.isAdmin() &&
      //   !this.checkAuthorization("customer_view_all")
      // ) {
      //   const dataUser = TokenService.getUser() || {};
      //   if (_.hasIn(dataUser, "departmentId")) {
      //     let departmentIds = _.cloneDeep(dataUser.listDepartmentIds);
      //     departmentIds.push(dataUser.departmentId);
      //     params.departmentId = departmentIds;
      //   }
      //   params.departmentId = dataUser.departmentId;
      // }
      const loader = this.$loading.show();
      const response = await CustomerRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.users = _.get(response, "data.customers", []);
        this.totalItem = _.get(response, "data.total_item", 0);
      }
    },
  },
};
</script>
