<template>
    <v-dialog
            v-model="showModal"
            max-width="40%"
            v-if="showModal">
        <v-card>
            <v-card-title class="font-weight-bold mb-0 d-flex justify-space-between pt-3">
                <template>
                    Danh sách khách hàng
                </template>
                <v-icon
                    aria-label="Close"
                    @click="hideModal"
                >
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text class="pa-3">
                <v-simple-table class="table-layout">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <td>STT</td>
                                <td>Tên khách hàng</td>
                                <td>SĐT</td>
                            </tr>
                        </thead>
                        <tbody v-if="resultData.length > 0">
                            <tr v-for="(item, index) in resultData" :key="index"> 
                                <td>{{ index + 1 }}</td>
                                <td>{{ _.get(item, 'fullName', '') }}</td>
                                <td>{{ _.get(item, 'mobile', '') }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="1000" class="text-center">
                                    <common-no-result/>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            resultData: []
        }
    },

    created() {
        Bus.$on("show-contact-list-contact-filter", data => {
            this.resultData = data
            this.showModal = true
        })
    },

    methods: {
        hideModal() {
            this.showModal = false
        },
    }
}
</script>

<style scoped>
/deep/ table {
    border-collapse: separate;
    border-spacing: 0px;
    border: 2px solid #DDDDDD;
    border-radius: 10px;
}

/deep/ table thead tr {
    height: 35px;
}

/deep/ table thead td {
    border-right: 2px solid #DDDDDD;
    height: 35px;
    padding: 4px 6px !important;
    font-size: 16px;
    font-weight: 500;
    background: #4caf50;
    color: white;
}

/deep/ table thead td:first-child {
    border-top-left-radius: 8px;
}

/deep/ table thead td:last-child {
    border-top-right-radius: 8px;
    border-right: unset;
}

/deep/ table tbody tr {
    height: 40px;
}

/deep/ table tbody td {
    border-top: 2px solid #DDDDDD;
    border-right: 2px solid #DDDDDD;
    height: 40px;
    padding: 6px !important;
    font-size: 16px;
    font-weight: 300;
}

/deep/ table tbody tr td:last-child {
    border-right: unset;
}
</style>