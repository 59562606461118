<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer 
        ref="validator"
        v-slot="{ handleSubmit }"
      >
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate">
            Thêm mới kho
          </template>
          <template v-else>
            Cập nhật thông tin kho
          </template>

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="hideModal"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Tên kho"
          >
            <v-text-field
              v-model="store.name"
              label="Tên kho *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:10|alpha_num"
            name="Mã kho"
          >
            <v-text-field
              v-model="store.code"
              label="Mã kho *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <div class="group-input">
            <ul>
              <li class="title">
                <h3 class="text-left">
                  Link Url:
                </h3>
              </li>
              <template v-for="(url, index) in store.urls">
                <li
                  :key="url.id"
                  class="list-input"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    :rules="{
                      regex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                      max: 255,
                    }"
                    name="Url"
                    vid="url"
                  >
                    <v-text-field
                      v-model.trim="url.url"
                      label="Url"
                      color="primary"
                      :error-messages="errors"
                      clearable
                    />
                  </validation-provider>
                  <v-icon
                    v-if="index>0"
                    class="group-input-close"
                    @click="deleteRow(url.id)"
                  >
                    mdi-delete
                  </v-icon>
                </li>
              </template>
            </ul>
            <v-icon
              class="group-input-add"
              @click="addRow"
            >
              mdi-plus
            </v-icon>
          </div>
<!--          <v-autocomplete-->
<!--            v-model="store.warehouse"-->
<!--            attach-->
<!--            :items="warehouseOptions"-->
<!--            item-text="text"-->
<!--            item-value="value"-->
<!--            label="Kho Warehouse"-->
<!--            color="primary"-->
<!--          />-->

        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn
            color="success"
            @click.prevent="handleSubmit(saveStore)"
          >
            Lưu lại
          </v-btn>
          <v-btn
            class="ml-0"
            color="primary"
            @click="hideModal"
          >
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import RepositoryFactory from '../../../repositories/RepositoryFactory'
  const StoreRepository = RepositoryFactory.get('store')


  export default {
    $_veeValidate: {
      validator: 'new',
    },
    props: {
      schoolLevels: {
        type: Array,
        default: () => {[]}
      }
    },
    data () {
      return {
        showModal: false,
        store: {},
        isCreate: true,
        dataStores: [],
        errorMessage: '',
        listURL: [],
        urlsItem: [],
        duplicateURL: false,
        warehouseOptions: [
          {
            text: 'Marketing',
            value: 'marketing'
          },
          {
            text: 'Sale',
            value: 'sale'
          }
        ]
      }
    },
    created () {
      Bus.$on('store-show-form', (dataStore) => {
        this.showModal = true
        const { urls = [] } = dataStore
        if (urls.length === 0) {
          dataStore.urls = [{
            id: (new Date()).getTime(),
            inputUrl: '',
          }]
        }
        this.store = _.cloneDeep(dataStore)
        if (dataStore.id) this.isCreate = false
        else this.isCreate = true
        this.getListURL()
      })
    },
    methods: {
      hideModal: function () {
        this.showModal = false
        this.isCreate = true
        this.errorMessage = ''
        this.$refs.validator.reset()
      },
      addRow () {
        const dataStore = _.cloneDeep(this.store)
        const { urls = [] } = dataStore
        urls.push({
          id: (new Date()).getTime(),
          inputUrl: '',
        })
        this.$set(this, 'store', dataStore)
      },
      deleteRow (id) {
        const index = this.store.urls.findIndex(u => u.id === id)
        if (index > -1) this.store.urls.splice(index, 1)
      },
      async saveStore () {
        this.checkUrl()
        if(this.duplicateURL == false ) return false
        const dataStore = {
          name: _.trim(this.store.name),
          code: _.trim(this.store.code),
          warehouse: this.store.warehouse ? _.trim(this.store.warehouse) : 'sale',
          urls: _.get(this.store, 'urls', []).map(u => u.url).filter((obj) => obj),
        }
        const loader = this.$loading.show()
        let response = {}
        if (this.isCreate) {
          response = await StoreRepository.create(dataStore)
          loader.hide()
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.errorMessage = ''
            this.showModal = false
            this.$swal({
              title: 'Thêm kho thành công',
              confirmButtonText: 'Đồng ý',
            }).then(() => {
              Bus.$emit('store-reload')
            })
          }
        } else {
          response = await StoreRepository.update(this.store.id, dataStore)
          loader.hide()
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.errorMessage = ''
            this.showModal = false
            this.$swal({
              title: 'Cập nhật kho thành công',
              confirmButtonText: 'Đồng ý',
            }).then(() => {
              Bus.$emit('store-reload')
            })
          }
        }
      },

      async getListURL () {
        const listURL = []
        const responseURL = await StoreRepository.list({limit: 10000})
        if (responseURL.success) {
          _.get(responseURL, 'data', []).map(item => {
            _.get(item, 'urls', []).map(url => (
              listURL.push(url.url)
            ))
          })
        } else {

        }
        this.listURL = listURL

        const urlsItem = this.store.urls.map(e => e.inputUrl)
        this.urlsItem = urlsItem

        _.pull(this.listURL, ...urlsItem)
      },

      checkUrl() {
        const urlsItem = this.store.urls.map(e => e.inputUrl)
        urlsItem.forEach(e => {
          if (this.listURL.indexOf(e) != -1) {
            this.$swal({
              title: 'Link đã tồn tại. Vui lòng kiểm tra lại',
            })
            this.duplicateURL = false
            return false
          }
          this.duplicateURL = true
        });
      }
    },
  }
</script>
