<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
    :retain-focus="false"
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới </template>
          <template v-else> Cập nhật thông tin </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Tên"
          >
            <v-text-field
              v-model="banner.title"
              label="Tên *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:1000"
            name="Link"
          >
            <v-text-field
              v-model="banner.link"
              label="Link *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Alt"
          >
            <v-text-field
              v-model="banner.alt"
              label="Alt *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <v-file-input
            v-model="inputFile"
            placeholder="Ảnh minh họa"
            label="Ảnh minh họa"
            prepend-icon="mdi-paperclip"
            :value="_.get(banner, 'image.originalName', '')"
            @change="uploadOneFile"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            name="Thứ tự hiển thị"
            rules="min: 0"
          >
            <v-text-field
              v-model="banner.displayOrder"
              label="Thứ tự hiển thị"
              :error-messages="errors"
              color="primary"
              type="number"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            name="Mô tả"
            rules="max: 255"
          >
            <v-textarea
              v-model="banner.description"
              label="Mô tả"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            name="Nhà tài trợ"
            rules="max: 255"
          >
            <v-textarea
              v-model="banner.sponsor"
              label="Nhà tài trợ"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider class="mb-2" name="Loại">
            <v-select
              v-model="banner.type"
              label="Loại"
              color="primary"
              :items="types"
              item-text="text"
              item-value="value"
            />
          </validation-provider>

          <validation-provider class="mb-2" name="Trạng thái">
            <v-select
              v-model="banner.status"
              label="Trạng thái"
              color="primary"
              :items="statusOptions"
              item-text="text"
              item-value="value"
            />
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveBanner)">
            Lưu lại
          </v-btn>
          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { bannerStatusOptions } from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const ImageRepository = RepositoryFactory.get("image");
const BannerRepository = RepositoryFactory.get("banner");

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      banner: {},
      isCreate: true,
      errorMessage: "",
      statusOptions: bannerStatusOptions,
      inputFile: [],
    };
  },
  created() {
    Bus.$on("banner-show-form", (banner) => {
      this.showModal = true;
      this.banner = _.cloneDeep(banner);
      if (banner.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  computed: {
    hasImage() {
      return this.displayImages.length ? "1" : "";
    },
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveBanner() {
      const banner = {
        title: _.trim(this.banner.title),
        alt: _.trim(this.banner.alt),
        link: _.trim(this.banner.link),
        type: this.banner.type,
        displayOrder: _.trim(this.banner.displayOrder),
        description: _.trim(this.banner.description),
        sponsor: _.trim(this.banner.sponsor),
        imageId: this.banner.imageId || null,
        status: this.banner.status,
      };
      const loader = this.$loading.show();
      let response = {};
      if (this.isCreate) {
        response = await BannerRepository.create(banner);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("banner-reload");
          });
        }
      } else {
        response = await BannerRepository.update(this.banner.id, banner);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("banner-reload");
          });
        }
      }
    },
    async uploadOneFile() {
      this.loading = this.$loading.show();
      const response = await ImageRepository.upload(this.inputFile);
      this.loading.hide();
      if (response.error) {
        this.setAlertMessage(true, "error", "Tải file thất bại!");
      } else {
        const newImage = _.get(response, "data", {});
        this.banner.imageId = newImage.id;
      }
    },
  },
};
</script>
