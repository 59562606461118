<template>
  <v-dialog
      :value="showModal"
      max-width="80%"
      persistent
  >
    <validation-observer v-slot="{ handleSubmit }">
    <v-card-title class="v-card-title pt-2">

      <template v-if="isCreate">
        Soạn tin nhắn mới
      </template>
      <template v-else>
        Chỉnh sửa tin nhắn
      </template>

      <v-icon
          aria-label="Close"
          @click="hideModal"
          style="position: absolute; right: 5px"
      >
        mdi-close
      </v-icon>
    </v-card-title>

    <v-col cols="7" style="height: 80px" class="pt-0">
    <v-card-text class="py-0">
        <v-row>
        <v-col cols="6" md="6" class="d-flex">
          <v-row>
            <v-col cols="4" class="pt-4 px-0">
              <label class="mt-2 font-weight-bold">Kênh gửi (*)</label>
            </v-col>
            <v-col cols="8">
              <validation-provider v-slot="{ errors }" rules="required" name="Kênh gửi">
                <v-autocomplete
                    v-model="formCreateUpdateSms.channel"
                    :items="codeSmsList"
                    item-text="name"
                    item-value="value"
                    :error-messages="errors"
                    placeholder="Chọn kênh"
                    outlined
                    label=""
                    clearable
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" md="6" class="d-flex">
          <v-row>
            <v-col cols="5" class="pt-4 px-0">
              <label class="font-weight-bold">Loại tin nhắn (*)</label>
            </v-col>
            <v-col cols="7">
              <validation-provider v-slot="{ errors }" rules="required" name="Loại tin">
                <v-autocomplete
                    v-model="formCreateUpdateSms.type"
                    :items="typeSmsList"
                    :error-messages="errors"
                    placeholder="Chọn loại"
                    outlined
                    label=""
                    clearable
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    </v-col>
    <v-progress-linear
        color="grey"
        rounded
        height="1"
        value="100"
        class="px-4"
    ></v-progress-linear>

    <v-card-text>
      <v-row>
        <v-col cols="12" :sm="formCreateUpdateSms.channel == 'zalo' ? 7 : 12">
          <v-row style="padding: 0px 20px" class="d-flex" v-if="formCreateUpdateSms.channel == 'facebook'">
              <v-col cols="2">
                <label class="font-weight-bold">Tên sự kiện *</label>
              </v-col>
              <v-col cols="10" class="px-1 py-0">
                <validation-provider v-slot="{ errors }" rules="required" name="Sự kiện">
                  <v-text-field
                      value=""
                      label=""
                      outlined
                      v-model="formCreateUpdateSms.eventName"
                      placeholder="Nhập tên sự kiện"
                      :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
          </v-row>
          <v-row style="padding: 0px 20px" class="d-flex">
              <v-col cols="2">
                <label class="font-weight-bold">Tiêu đề *</label>
              </v-col>
              <v-col cols="10" class="px-1 py-0">
                <validation-provider v-slot="{ errors }" rules="required" name="Tiêu đề">
                  <v-text-field
                      value=""
                      label=""
                      outlined
                      v-model="formCreateUpdateSms.title"
                      placeholder="Nhập tiêu đề"
                      :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
          </v-row>

          <v-row style="padding: 0px 20px" class="d-flex">
            <v-col cols="2">
              <label class="font-weight-bold">Trích dẫn</label>
            </v-col>
            <v-col cols="10" class="px-1 py-0">
              <v-textarea
                  label=""
                  outlined
                  v-model="formCreateUpdateSms.quote"
                  placeholder="Nhập trích dẫn"
                  rows="5"
                  auto-grow
              ></v-textarea>
            </v-col>
          </v-row>


          <v-row style="padding: 0px 20px" class="d-flex" v-if="formCreateUpdateSms.channel && formCreateUpdateSms.channel != 'facebook'">
            <v-col cols="2">
              <label class="font-weight-bold">Nội dung *</label>
            </v-col>

              <v-col cols="10" class="px-1 py-0 wraper-vue-editor">
                <!-- <vue-editor v-model="formCreateUpdateSms.content" :editorToolbar="customToolbar"></vue-editor> -->
                <validation-provider v-slot="{ errors }" rules="required" name="Tiêu đề">
                  <v-textarea 
                      v-model="formCreateUpdateSms.content" 
                      outlined 
                      placeholder="Nhập nội dung"
                      rows="5"
                      auto-grow
                      :hide-details="errors.length ? false : true"
                      :error-messages="errors"/>
                </validation-provider>
              </v-col>
          </v-row>

          <v-row style="padding: 0px 20px" class="d-flex content-note" v-if="formCreateUpdateSms.channel && formCreateUpdateSms.channel != 'facebook'">
            <v-col cols="2"></v-col>
            <v-col cols="10">
              Chú thích:
              <ul>
                <li>{ten_phu_huynh}: Tên phụ huynh</li>
                <li>{sdt}: Số điện thoại</li>
              </ul>
            </v-col>
          </v-row>

          <v-row style="padding: 0px 20px" class="d-flex" v-if="formCreateUpdateSms.channel == 'facebook'">
            <v-col cols="2">
              <label class="font-weight-bold">Tham số *</label>
            </v-col>

            <v-col cols="10" class="px-1 py-0 wraper-vue-editor">
              <ul class="hana-params">
                <template v-for="(item, index) in params">
                  <li :key="item.id">
                    <v-row>
                      <v-col cols="6">
                        <validation-provider v-slot="{ errors }" :rules="index == 0 ? 'required' : ''" name="Tham số">
                          <v-text-field 
                              v-model="item.name"
                              outlined 
                              placeholder="Nhập tên tham số"
                              auto-grow
                              :hide-details="errors.length ? false : true"
                              :error-messages="errors"/>
                        </validation-provider>
                      </v-col>
                      <v-col cols="5">
                        <validation-provider v-slot="{ errors }" :rules="index == 0 ? 'required' : ''" name="Giá trị tham số">
                          <v-autocomplete
                              outlined
                              v-model="item.value"
                              :items="hanaParams"
                              item-value="value"
                              item-text="name"
                              :hide-details="errors.length ? false : true"
                              :error-messages="errors"
                              clearable
                              eager
                              placeholder="Chọn giá trị tham số"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="1">
                        <v-tooltip bottom v-if="index > 0">
                          <template v-slot:activator="{ on }">
                            <v-btn 
                                @click="deleteRow(item.id)" 
                                v-on="on" 
                                class="group-input-close"
                                style="padding: 0px !important"
                                color="error">
                              Xóa
                              <v-icon size="16px" class="ml-1">
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Xóa tham số</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on }">
                            <v-btn 
                                @click="addRow()" 
                                v-on="on" 
                                class="group-input-add"
                                style="padding: 0px !important"
                                color="info">
                              Tạo
                              <v-icon size="16px" class="ml-1">
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Thêm tham số</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </li>
                </template>
              </ul>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="5" class="pr-5" v-if="formCreateUpdateSms.channel == 'zalo'">
          <!--tab-->
          <v-row class="d-flex justify-md-space-between px-3">
            <span class="font-weight-bold pt-1">Chọn ảnh/ video đại diện</span>
            <v-checkbox
                v-model="formCreateUpdateSms.ishowMedia"
                label="Hiển thị"
                class="mt-0 pt-0"
            ></v-checkbox>
          </v-row>

            <div class="wrapper-media-upload">
              <v-tabs
                  class="mt-0 px-3"
                  @change="changeTab"
                  v-model="tabIndex"
                  color="primary"
                  style="background-color: #FFFFFF; padding: 5px 0px 10px 5px;"
                  grow
              >
                <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab }}</v-tab>
                <v-tab-item>
                  <div class="upload-img-sms upload-image--user">
                    <v-tooltip>
                      <template v-slot:activator="{ on }">
                        <v-btn 
                            class="delete-img" 
                            @click="deleteImage" 
                            icon 
                            small 
                            color="error" 
                            v-on="on" 
                            v-if="hasImage">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Xóa</span>
                    </v-tooltip>
                    <img
                        v-if="hasImage"
                        :src="uploadImage"
                        @click="chooseImage"
                        style="width: 100%"
                    />
                    <v-img v-else src="https://kangsblackbeltacademy.com/wp-content/uploads/2017/04/default-image.jpg" @click="chooseImage" />
                    <input
                        ref="fileInput"
                        style="display: none"
                        type="file"
                        accept="image/*"
                        @input="onSelectFile"
                    />
                  </div>
                  <v-row class="d-flex justify-md-space-between mt-4 px-3" style="height: 30px">
                    <span class="font-weight-bold pt-1">Link đính kèm</span>
                    <v-checkbox
                        v-model="formCreateUpdateSms.ishowLinkAttachImage"
                        label="Hiển thị"
                        class="mt-0 pt-0"
                    ></v-checkbox>
                  </v-row>
                  <v-row class="px-3">
                    <v-text-field
                        placeholder="Link đính kèm"
                        outlined
                        hide-details
                        v-model="formCreateUpdateSms.linkImage"/>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <div class="upload-video-sms">
                    <!-- <v-text-field placeholder="Nhập link nhúng" v-model="uploadVideo" /> -->
                    <iframe
                        v-if="hasVideo"
                        height="300px"
                        width="100%"
                        :src="uploadVideo" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <v-row class="d-flex justify-md-space-between mt-4 px-3" style="height: 30px">
                    <span class="font-weight-bold pt-1">Nội dung button</span>
                  </v-row>
                  <v-row class="px-3">
                    <v-text-field
                        placeholder="Nhập nội dung button"
                        outlined
                        hide-details
                        v-model="buttonText"/>
                  </v-row>
                  <v-row class="d-flex justify-md-space-between mt-4 px-3" style="height: 30px">
                    <span class="font-weight-bold pt-1">Link đính kèm</span>
                    <v-checkbox
                        v-model="formCreateUpdateSms.ishowLinkAttachButton"
                        label="Hiển thị"
                        class="mt-0 pt-0"
                    ></v-checkbox>
                  </v-row>
                  <v-row class="px-3">
                    <v-text-field
                        placeholder="Link đính kèm"
                        outlined
                        hide-details
                        v-model="formCreateUpdateSms.linkButton"/>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </div>
        </v-col>

        <v-col cols="12" sm="12">
          <!--button-->
          <div class="text-center mb-4">
            <v-btn v-if="isCreate" color="green" class="mx-2" @click.prevent="handleSubmit(createNewSms)">Tạo mới
            </v-btn>
            <v-btn v-else color="green" class="mx-2" @click.prevent="handleSubmit(createNewSms)">Cập nhật
            </v-btn>
            <v-btn color="red" class="mx-0" @click.prevent="hideModal">Hủy
            </v-btn>
          </div>
        </v-col>

      </v-row>
    </v-card-text>
    </validation-observer>
  </v-dialog>
</template>

<script>
import * as moment from "moment";
import { VueEditor } from 'vue2-editor';
import RepositoryFactory from "@/repositories/RepositoryFactory"
const ImageRepository = RepositoryFactory.get("image")
const SmsRepository = RepositoryFactory.get("sms")

export default {
  // components: { VueEditor },
  props: {
      typeSmsList: {
        type: Array,
        default: () => []
      }
    },
    data() {
        return {
          userList: [],
          codeSmsList: ["zalo", "facebook", "email", "sms"],
          formCreateUpdateSms: {
            channel: '',
            type: '',
            title: '',
            quote: '',
            eventName: '',
            linkImage: '',
            linkButton: '',
            content: '',
            ishowLinkAttachImage: false,
            ishowLinkAttachButton: false,
            ishowMedia: false,
          },
          showModal: false,
          isCreate: true,
          tabs: ['Ảnh', 'Video', 'Button'],
          tabIndex: 0,
          hasImage: false,
          uploadImage: "",
          hasVideo: false,
          uploadVideo: "",
          buttonText: "",
          customToolbar: [[
            {
              header: [false, 1, 2, 3, 4, 5, 6]
            }], ["bold", "italic", "underline", "strike"], // toggled buttons
            [{
              align: ""
            }, {
              align: "center"
            }, {
              align: "right"
            }, {
              align: "justify"
            }], ["blockquote", "code-block"], [{
              list: "ordered"
            }, {
              list: "bullet"
            }, {
              list: "check"
            }], [{
              indent: "-1"
            }, {
              indent: "+1"
            }], // outdent/indent
            [{
              color: []
            }, {
              background: []
            }] // dropdown with defaults from theme
          ],
          params: [{
            id: (new Date()).getTime(),
            name: '',
            value: ''
          }],
          hanaParams: []
        }
    },
    created() {
      this.getTypeSms();
      this.getHanaParams();
      Bus.$on('sms-show-form-create-update', async (item) => {
        this.formCreateUpdateSms = _.cloneDeep(item)
        this.formCreateUpdateSms.content = _.get(item, 'content.content', '')

        if (!_.every(item.image, _.isEmpty)) {
          this.hasImage = true
          this.uploadImage = _.get(item, 'image.content', '')
          this.formCreateUpdateSms.linkImage = _.get(item, 'image.link', '')
          this.formCreateUpdateSms.ishowLinkAttachImage = _.get(item, 'image.linkStatus', 0) ? true : false
          this.formCreateUpdateSms.ishowMedia = _.get(item, 'image.detailStatus', 0) ? true : false
        }

        if (!_.every(item.video, _.isEmpty)) {
          // this.hasVideo = true
          // this.uploadVideo = _.get(item, 'video.content', '')
          // this.formCreateUpdateSms.link = _.get(item, 'video.link', '')
          // this.formCreateUpdateSms.ishowLinkAttach = _.get(item, 'video.linkStatus', 0) ? true : false
          // this.formCreateUpdateSms.ishowMedia = _.get(item, 'video.detailStatus', 0) ? true : false
        }

        if (!_.every(item.button, _.isEmpty)) {
          this.buttonText = _.get(item, 'button.content', '')
          this.formCreateUpdateSms.linkButton = _.get(item, 'button.link', '')
          this.formCreateUpdateSms.ishowLinkAttachButton = _.get(item, 'button.linkStatus', 0) ? true : false
          this.formCreateUpdateSms.ishowMedia = _.get(item, 'button.detailStatus', 0) ? true : false
        }

        if (!_.every(item.params, _.isEmpty)) {
          this.params = []
          let paramsSms = JSON.parse(_.get(item, 'params.content', ''))
          for (let key in paramsSms) {
            this.params.push({
              id: (new Date()).getTime(),
              name: key,
              value: paramsSms[key]
            })
          }
        }

        if (item.id) this.isCreate = false
        else this.isCreate = true

        this.showModal = true;
      })
    },
  computed: {
    editorContent: {
      get() { return this.formCreateUpdateSms.content},
      set(updatedContent) { this.formCreateUpdateSms.content = updatedContent }
    },
  },
  methods: {
        async createNewSms() {
          const data = {
            channel: this.formCreateUpdateSms.channel,
            type: this.formCreateUpdateSms.type,
            title: this.formCreateUpdateSms.title,
            quote: this.formCreateUpdateSms.quote,
            eventName: this.formCreateUpdateSms.eventName,
          }

          if (this.formCreateUpdateSms.content) {
            console.log(this.formCreateUpdateSms.content)
            data.content = {
              content: this.formCreateUpdateSms.content
            }
          }

          if (this.hasImage) {
            data.image = {
              content: this.uploadImage,
              detailStatus: this.formCreateUpdateSms.ishowMedia ? 1 : 0,
              link: this.formCreateUpdateSms.linkImage,
              linkStatus: this.formCreateUpdateSms.ishowLinkAttachImage ? 1 : 0
            }
          }

          if (this.hasVideo) {
            // data.video = {
            //   content: this.uploadVideo,
            //   detailStatus: this.formCreateUpdateSms.ishowMedia ? 1 : 0,
            //   link: this.formCreateUpdateSms.link,
            //   linkStatus: 0
            // }
          }

          if (this.buttonText) {
            if (this.formCreateUpdateSms.channel == 'zalo' && !this.hasImage) {
              this.$swal({
                title: 'Vui lòng thêm hình ảnh.',
                text: 'Gửi zalo có nút thì bắt buộc phải có hình ảnh.'
              })
              return
            }
            data.button = {
              content: this.buttonText,
              detailStatus: this.formCreateUpdateSms.ishowMedia ? 1 : 0,
              link: this.formCreateUpdateSms.linkButton,
              linkStatus: this.formCreateUpdateSms.ishowLinkAttachButton ? 1 : 0
            }
          }

          if (this.params[0].name != '' && this.params[0].value != '') {
            let paramsContent = {}
            this.params.forEach(item => {
              paramsContent[item.name] = item.value
            })
            data.params = {
              content: JSON.stringify(paramsContent),
              detailStatus: 1,
              link: '',
              linkStatus: 0
            }
          }

          if (this.isCreate) {
            const res = await SmsRepository.create(data)
            if (res.success) {
              this.hideModal()
              Bus.$emit('create-sms-reload')
            } else {
              this.$swal({
                title: "Lỗi!",
                text: res.message
              })
            }
          } else {
            const res = await SmsRepository.update(this.formCreateUpdateSms.id, data)
            if (res.success) {
              this.hideModal()
              Bus.$emit('update-sms-reload')
            } else {
              this.$swal({
                title: "Lỗi!",
                text: res.message
              })
            }
          }
        },
        clearAssignContact() {
           this.formCreateUpdateSms.clearRecently = true
        },
        changeDateRange (dateRange) {
          this.formCreateUpdateSms.dateRange = dateRange
        },
        getTypeSms() {

        },

        hideModal: function () {
          if (this.$route.query.backPage) {
            return this.$router.go(-1);
          }
          this.showModal = false
          this.formCreateUpdateSms = {
            channel: '',
            type: '',
            title: '',
            quote: '',
            eventName: '',
            linkImage: '',
            linkButton: '',
            content: '',
            ishowLinkAttachImage: false,
            ishowLinkAttachButton: false,
            ishowMedia: false,
          }
          this.params = [{
            id: (new Date()).getTime(),
            name: '',
            value: ''
          }]
          this.isCreate = true
          this.errorMessage = ''
        },
        changeTab() {
          if (this.tabIndex === 0) {
            this.filterForm.statusIds = [];
          } else if (this.tabIndex === 1) {
            this.filterForm = [];
          }
          this.currentPage = 1;
        },
        chooseImage() {
          this.$refs.fileInput.click();
        },
        async onSelectFile() {
          const loader = this.$loading.show();
          const input = this.$refs.fileInput;
          const files = input.files;
          if (files && files.length) {
            await this.uploadOneFile(files[0]);
          }
          loader.hide();
        },
      deleteImage() {
        this.uploadImage = ""
        this.hasImage = false
      },
      async uploadOneFile(file) {
        const uploadResponse = await ImageRepository.upload(file);
        if (_.get(uploadResponse, 'success', false)) {
          const newImage = _.get(uploadResponse, 'data', {});
          this.uploadImage = _.get(newImage, 'filePath', '')
          this.hasImage = true
        } else {
          this.$swal('Upload ảnh đại diện không thành công');
        }
      },

      async getHanaParams() {
        const response = await SmsRepository.getHanaParams()
        if (response.success) {
          this.hanaParams = _.get(response, 'data', [])
        } else {
          this.hanaParams = []
        }
      },

      addRow() {
        const params = _.cloneDeep(this.params)
        params.push({
          id: (new Date()).getTime(),
          name: '',
          value: ''
        })
        this.$set(this, 'params', params)
      },

      deleteRow(id) {
        const index = this.params.findIndex(u => u.id === id)
        if (index > -1) this.params.splice(index, 1)
      },
    }
}
</script>

<style lang="css" scoped>

  /deep/.v-dialog {
    background: white;
  }

  .v-input {
    /*width: 100%;*/
  }

  .v-card-title {
    position: relative;
  }

  /deep/.v-input__append-inner {
    margin-top: 0px;
  }

  /deep/.v-input__slot {
    min-height: 30px !important;
  }

  /deep/ .wraper-vue-editor {
    border-radius: 5px;
  }

  /deep/ .upload-image--user .v-image__image {
    border-radius: 3px;
  }

  /deep/ .wrapper-media-upload {
    border: solid 1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin-bottom: 10px;
  }

  ul li {
    list-style: disc;
    margin-left: 20px;
  }

  ul.hana-params li {
    list-style: none;
    margin-left: 0px;
  }
  
  .content-note {
    font-size: 14px;
    color: rgb(118, 118, 118);
  }
  
  .group-input-add {
    margin: 0px !important;
    min-width: 100% !important;
    height: 30px !important;
  }

  .group-input-close {
    margin: 0px !important;
    min-width: 100% !important;
    height: 30px !important;
  }
</style>