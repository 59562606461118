<template>
  <base-material-card outlined icon="mdi-account-outline">
    <template v-if="errorMessage">
      <v-alert type="error">{{ errorMessage }}</v-alert>
    </template>
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        Thay đổi mật khẩu
        <span class="body-1">— Hoàn thành hồ sơ của bạn</span>
      </div>
    </template>

    <v-form>
      <validation-observer v-slot="{ handleSubmit }">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:6|max:20"
                name="Mật khẩu cũ"
              >
                <v-text-field
                  v-model="currentPassword"
                  class="purple-input"
                  label="Mật khẩu cũ"
                  :error-messages="errors"
                  :append-icon="
                    visibleCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append="
                    () => (visibleCurrentPassword = !visibleCurrentPassword)
                  "
                  :type="visibleCurrentPassword ? 'password' : 'text'"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  min: 6,
                  regex: regex.isPassword,
                  max: 20,
                }"
                name="Mật khẩu mới"
                vid="password"
              >
                <v-text-field
                  v-model="newPassword"
                  class="purple-input"
                  label="Mật khẩu mới"
                  :error-messages="errors"
                  :append-icon="visibleNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="
                    () => (visibleNewPassword = !visibleNewPassword)
                  "
                  :type="visibleNewPassword ? 'password' : 'text'"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:6|confirmed:password|max:20"
                name="Nhập lại mật khẩu mới"
              >
                <v-text-field
                  v-model="confirmPassword"
                  class="purple-input"
                  label="Nhập lại mật khẩu mới"
                  :error-messages="
                    errors.length ? 'Nhập lại mật khẩu không chính xác' : ''
                  "
                  :append-icon="
                    visibleConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append="
                    () => (visibleConfirmPassword = !visibleConfirmPassword)
                  "
                  :type="visibleConfirmPassword ? 'password' : 'text'"
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn class="mr-2" @click="cancelChangePassword"> Huỷ bỏ </v-btn>
              <v-btn
                color="primary"
                class="mr-0"
                @click.prevent="handleSubmit(updatePassword)"
              >
                Cập nhật
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </validation-observer>
    </v-form>
  </base-material-card>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { regex } from "../../../helpers/constants";
import TokenService from "../../../helpers/token";
const AuthRepository = RepositoryFactory.get("auth");
export default {
  name: "ChangePassword",
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      regex,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      errorMessage: "",
      loading: false,
      visibleCurrentPassword: true,
      visibleNewPassword: true,
      visibleConfirmPassword: true,
    };
  },
  created() {},
  methods: {
    cancelChangePassword() {
      // window.location.reload()
      this.$router.go({
        path: "/profile",
      });
    },
    async updatePassword() {
      const loader = this.$loading.show();
      const response = await AuthRepository.changePassword({
        newPassword: this.newPassword,
        reNewPassword: this.confirmPassword,
        oldPassword: this.currentPassword,
      });
      if (_.get(response, "success", false)) {
        this.$swal({
          title: "Cập nhật mật khẩu thành công !",
          confirmButtonText: "Đồng Ý",
        }).then(() => {
          TokenService.removeRefreshToken();
          TokenService.removeToken();
          TokenService.removeUser();
          TokenService.removePermissions();
          this.$router.push("/login");
        });
      } else {
        this.errorMessage = _.get(response, "message", "");
      }
      loader.hide();
    },
  },
};
</script>
<style></style>
