var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto s-layout-small mt-0",staticStyle:{"border-color":"#fff !important","padding-top":"20px"},attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pa-0",staticStyle:{"justify-content":"space-between"}},[_c('p',{staticClass:"ma-0"},[_vm._v("Mã đơn hàng: "+_vm._s(_vm.contentOrder.code))]),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dataStatus))])]),_c('v-row',[_c('v-col',{staticClass:"order-products-wrapper",staticStyle:{"overflow":"auto"},attrs:{"cols":"12","sm":"12"}},[_c('v-simple-table',{staticClass:"table-layout table-small-screen mobile-hidden",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('td',{staticClass:"text-left"},[_vm._v("Tên sản phẩm")]),_c('td',{staticClass:"text-left"},[_vm._v("Đơn giá")]),_c('td',{staticClass:"text-left"},[_vm._v("Đơn giá đã giảm")]),_c('td',{staticClass:"text-left"},[_vm._v("Số lượng")]),_c('td',{staticClass:"text-left"},[_vm._v("Thành tiền")]),(_vm.stepOrder !== 3)?_c('td'):_vm._e()])]),_c('tbody',_vm._l((_vm.contentOrder.products),function(product,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"vid":'Tên sản phẩm ' + index,"name":"Tên sản phẩm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ma-0 pa-0",attrs:{"disabled":_vm.isReadOnly || _vm.stepOrder === 3,"items":_vm.products,"item-text":"name","item-value":"idTemp","label":"","error-messages":errors},on:{"change":function($event){return _vm.changeProduct(product)}},model:{value:(product.productId),callback:function ($$v) {_vm.$set(product, "productId", $$v)},expression:"product.productId"}})]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"name":"Đơn giá","vid":'Đơn giá' + index,"rules":{
                    required: true,
                    numeric: true,
                    regex: /^[0-9]+$/,
                    min_value: 0,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"readonly":_vm.isReadOnly ||
                      _vm.stepOrder === 3 ||
                      (_vm.checkAuthorization('order-ctv-create_read') &&
                        !_vm.checkAuthorization('contact_read')),"step":"5000","type":"number","label":"","error-messages":errors,"color":"primary"},model:{value:(product.cost),callback:function ($$v) {_vm.$set(product, "cost", $$v)},expression:"product.cost"}})]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"name":"Đơn giá đã giảm","vid":'Đơn giá đã giảm' + index,"rules":{
                    required: true,
                    numeric: true,
                    regex: /^[0-9]+$/,
                    min_value: 0,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"readonly":_vm.isReadOnly ||
                      _vm.stepOrder === 3 ||
                      (_vm.checkAuthorization('order-ctv-create_read') &&
                        !_vm.checkAuthorization('contact_read')),"step":"5000","type":"number","label":"","error-messages":errors,"color":"primary"},model:{value:(product.price),callback:function ($$v) {_vm.$set(product, "price", $$v)},expression:"product.price"}})]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"name":"Số lượng","vid":'Số lượng' + index,"rules":{
                    required: true,
                    numeric: true,
                    regex: /^[0-9]+$/,
                    min_value: 1,
                    max: 4,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"readonly":_vm.isReadOnly || _vm.stepOrder === 3,"type":"number","label":"","error-messages":errors,"color":"primary"},model:{value:(product.quantity),callback:function ($$v) {_vm.$set(product, "quantity", $$v)},expression:"product.quantity"}})]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_c('v-currency-field',{staticClass:"ma-0 pa-0",attrs:{"readonly":"","value":product.price * product.quantity}})],1),(_vm.stepOrder !== 3)?_c('td',{directives:[{name:"show",rawName:"v-show",value:(index > 0 && !_vm.isReadOnly),expression:"index > 0 && !isReadOnly"}],staticClass:"text-center",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"border-table-order"}),_c('v-btn',{staticStyle:{"text-transform":"inherit","font-weight":"bold","font-size":"12px","margin-top":"-15px"},attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.onClickRemoteProduct(index)}}},[_vm._v("Xóa")])],1):_vm._e()])}),0),_c('tfoot',[_c('tr',[_c('td',[(
                    ![_vm.orderStatus.SUCCESS, _vm.orderStatus.CANCELLED].includes(
                      _vm.contentOrder.statusId
                    )
                  )?_c('v-btn',{attrs:{"small":"","color":"blue"},on:{"click":_vm.onClickAddProduct}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Thêm sản phẩm ")],1):_vm._e()],1),_c('td',{staticStyle:{"font-weight":"bold","text-align":"right"},attrs:{"colspan":"2"}},[_vm._v(" Giá trị đơn hàng (Chưa trừ): ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"left"}},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.totalPrice))+" ")])])])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepOrder >= 2),expression:"stepOrder >= 2"}],attrs:{"name":"Đã thanh toán","rules":{
          required: true,
          min_value: 0,
          max_value: _vm.totalPrice,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-currency-field',{staticClass:"ma-0 pa-0",attrs:{"label":"Đã thanh toán","error-messages":errors},model:{value:(_vm.contentOrder.paidAmount),callback:function ($$v) {_vm.$set(_vm.contentOrder, "paidAmount", $$v)},expression:"contentOrder.paidAmount"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-currency-field',{attrs:{"readonly":"","label":"Giảm trừ khi thanh toán ngay","value":_vm.restDiscountPrice}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-currency-field',{attrs:{"readonly":"","label":"Còn lại","value":_vm.restPrice}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Hình thức thanh toán","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ma-0 pa-0",attrs:{"disabled":_vm.isReadOnly || _vm.stepOrder === 3,"items":_vm.orderPaymentTypes,"label":"Hình thức thanh toán *","error-messages":errors},model:{value:(_vm.contentOrder.paymentType),callback:function ($$v) {_vm.$set(_vm.contentOrder, "paymentType", $$v)},expression:"contentOrder.paymentType"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Thời gian giao hàng mong muốn","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-textarea',{staticClass:"ma-0 pa-0",attrs:{"readonly":true,"label":"Thời gian giao hàng mong muốn","rows":"3","error-messages":errors},model:{value:(_vm.contentOrder.shift),callback:function ($$v) {_vm.$set(_vm.contentOrder, "shift", $$v)},expression:"contentOrder.shift"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Ghi chú từ khách hàng","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-textarea',{staticClass:"ma-0 pa-0",attrs:{"readonly":true,"label":"Ghi chú từ khách hàng","rows":"3","error-messages":errors},model:{value:(_vm.contentOrder.description),callback:function ($$v) {_vm.$set(_vm.contentOrder, "description", $$v)},expression:"contentOrder.description"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Trạng thái đơn hàng","no-data-text":"Không có dữ liệu","items":_vm.orderStatusOptions,"item-text":"text","item-value":"value","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var attrs = ref.attrs;
        var item = ref.item;
        var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)]}}],null,true),model:{value:(_vm.contentOrder.statusId),callback:function ($$v) {_vm.$set(_vm.contentOrder, "statusId", $$v)},expression:"contentOrder.statusId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Hóa đơn","no-data-text":"Không có dữ liệu","items":_vm.vatStatusOptions,"item-text":"text","item-value":"value","error-messages":errors,"color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var attrs = ref.attrs;
        var item = ref.item;
        var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)]}}],null,true),model:{value:(_vm.contentOrder.isVatInvoice),callback:function ($$v) {_vm.$set(_vm.contentOrder, "isVatInvoice", $$v)},expression:"contentOrder.isVatInvoice"}})]}}])})],1),(_vm.contentOrder.isVatInvoice)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Tên đơn vị","rules":{
          max: 255,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên đơn vị","error-messages":errors,"color":"primary"},model:{value:(_vm.contentOrder.storeName),callback:function ($$v) {_vm.$set(_vm.contentOrder, "storeName", $$v)},expression:"contentOrder.storeName"}})]}}],null,false,2248252876)})],1):_vm._e(),(_vm.contentOrder.isVatInvoice)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Mã số thuế","rules":{
          max: 255,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mã số thuế","error-messages":errors,"color":"primary"},model:{value:(_vm.contentOrder.storeTax),callback:function ($$v) {_vm.$set(_vm.contentOrder, "storeTax", $$v)},expression:"contentOrder.storeTax"}})]}}],null,false,1523924687)})],1):_vm._e(),(_vm.contentOrder.isVatInvoice)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Địa chỉ đơn vị","rules":{
          max: 255,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Địa chỉ","error-messages":errors,"color":"primary"},model:{value:(_vm.contentOrder.storeAddress),callback:function ($$v) {_vm.$set(_vm.contentOrder, "storeAddress", $$v)},expression:"contentOrder.storeAddress"}})]}}],null,false,1267993974)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Ghi chú từ nhân viên","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-textarea',{staticClass:"ma-0 pa-0",attrs:{"label":"Ghi chú từ nhân viên","rows":"3","error-messages":errors},model:{value:(_vm.contentOrder.note),callback:function ($$v) {_vm.$set(_vm.contentOrder, "note", $$v)},expression:"contentOrder.note"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }