<template>
  <v-dialog v-model="showModal" max-width="700" persistent :retain-focus="false">
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới loại sản phẩm </template>
          <template v-else> Cập nhật thông tin loại sản phẩm </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Tên loại sản phẩm"
          >
            <v-text-field
              v-model="productFolder.name"
              label="Tên loại sản phẩm *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Slug"
          >
            <v-text-field
              v-model="productFolder.slug"
              label="Slug *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <v-file-input
            v-model="inputFile"
            placeholder="Ảnh minh họa"
            label="Ảnh minh họa"
            prepend-icon="mdi-paperclip"
            :value="_.get(productFolder, 'image.originalName', '')"
            @change="uploadOneFile"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            name="Thứ tự"
            rules="min: 0"
          >
            <v-text-field
              v-model="productFolder.displayOrder"
              label="Thứ tự"
              :error-messages="errors"
              color="primary"
              type="number"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            name="Mô tả sản phẩm"
            rules="max: 255"
          >
            <v-textarea
              v-model="productFolder.description"
              label="Mô tả sản phẩm"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider class="mb-2" name="Trạng thái">
            <v-select
              v-model="productFolder.status"
              label="Trạng thái"
              color="primary"
              :items="statusOptions"
              item-text="text"
              item-value="value"
            />
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn
            color="success"
            @click.prevent="handleSubmit(saveProductFolder)"
          >
            Lưu lại
          </v-btn>
          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  productFolderStatusOptions,
  MAX_SIZE_IMAGE,
} from "../../../../helpers/constants";
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const ImageRepository = RepositoryFactory.get("image");
const ProductFolderRepository = RepositoryFactory.get("product_folder");

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      productFolder: {},
      isCreate: true,
      productFolders: [],
      errorMessage: "",
      statusOptions: productFolderStatusOptions,
      inputFile: []
    };
  },
  created() {
    Bus.$on("product-folder-show-form", (productFolder) => {
      this.showModal = true;
      this.productFolder = _.cloneDeep(productFolder);
      if (productFolder.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  computed: {
    hasImage() {
      return this.displayImages.length ? "1" : "";
    },
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveProductFolder() {
      const productFolder = {
        name: _.trim(this.productFolder.name),
        slug: _.trim(this.productFolder.slug),
        displayOrder: _.trim(this.productFolder.displayOrder),
        description: _.trim(this.productFolder.description),
        status: _.trim(this.productFolder.status),
        imageId: this.productFolder.imageId,
      };
      const loader = this.$loading.show();
      let response = {};
      if (this.isCreate) {
        response = await ProductFolderRepository.create(productFolder);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("product-folder-reload");
          });
        }
      } else {
        response = await ProductFolderRepository.update(
          this.productFolder.id,
          productFolder
        );
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("product-folder-reload");
          });
        }
      }
    },
    async uploadOneFile() {
      this.loading = this.$loading.show();
      const response = await ImageRepository.upload(this.inputFile);
      this.loading.hide();
      if (response.error) {
        this.setAlertMessage(true, "error", "Tải file thất bại!");
      } else {
        const newImage = _.get(response, "data", {});
        this.productFolder.imageId = newImage.id;
      }
    },
  },
};
</script>
