<template>
  <v-container
    id="user-profile"
    class="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        v-if="showEditProfile"
        cols="12"
        md="12"
      >
        <edit-profile-form :user="user" />
      </v-col>
      <v-col
        v-if="showChangePassword"
        cols="12"
        md="12"
      >
        <change-password />
      </v-col>
      <v-col
        v-if="showViewProfile"
        cols="12"
        md="12"
      >
        <view-profile />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ViewProfile from './components/ViewProfile'
  import EditProfileForm from './components/EditProfileForm'
  import ChangePassword from './components/ChangePassword'
  import RepositoryFactory from '../../repositories/RepositoryFactory'
  import { formatDate } from '../../helpers/utils'
  const AuthRepository = RepositoryFactory.get('auth')
  export default {
    components: { ViewProfile, EditProfileForm, ChangePassword },
    data () {
      return {
        user: {},
        showEditProfile: false,
        showChangePassword: false,
        showViewProfile: true,
      }
    },
    created () {
      Bus.$on('profile-show-edit-profile', (user) => {
        this.showEditProfile = true
        this.showChangePassword = false
        this.showViewProfile = false
        this.user = user
        // this.user.birthday = formatDate(user.birthday)
      })
      Bus.$on('profile-hide-edit-profil', () => {
        this.showEditProfile = false
        this.showChangePassword = false
        this.showViewProfile = true
      })
      Bus.$on('profile-show-change-password', () => {
        this.showEditProfile = false
        this.showChangePassword = true
        this.showViewProfile = false
      })
      Bus.$on('profile-hide-change-password', () => {
        this.showEditProfile = false
        this.showChangePassword = false
        this.showViewProfile = true
      })
      this.getUserProfile()
    },
    methods: {
      async getUserProfile () {
        const userProfile = await AuthRepository.profile()
        if (_.get(userProfile, 'success', false)) {
          this.user = _.get(userProfile, 'data')
        } else {
          this.errorMessage = _.get(userProfile, 'message', '')
        }
      },
    },
  }
</script>
<style lang="sass">
.user-profile
  .v-card--material
    overflow: visible
</style>
