<template>
  <v-container
    id="treatment"
    fluid
    tag="section"
  >
    <filter-form :filter-form="filterForm" />
    <treatment-table :treatments="treatments" />
    <common-no-result
      v-if="!loading && totalItem === 0"
    />
    <v-row class="mt-4">
      <v-col
        cols="12"
        sm="6"
      />
      <v-col
        cols="12"
        sm="6"
      >
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import FilterForm from './components/FilterForm.vue'
  import TreatmentTable from './components/TreatmentTable'
  import RepositoryFactory from '../../../repositories/RepositoryFactory'
  const TreatmentRepository = RepositoryFactory.get('treatment')
  export default {
    components: { FilterForm, TreatmentTable },
    data () {
      return {
        filterForm: {
          keyword: '',
        },
        currentPage: 1,
        limit: 10,
        totalItem: 0,
        treatments: [],
        loading: false,
      }
    },
    watch: {
      currentPage: {
        handler () {
          this.getTreatmentList()
        },
      },
      limit: {
        handler () {
          if (this.currentPage === 1) {
            this.getTreatmentList()
          } else {
            this.currentPage = 1
          }
        },
      },
    },
    async created () {
      this.getTreatmentList()
      Bus.$on('treatment-list-filter', (filterForm) => {
        this.filterForm = filterForm
        this.currentPage = 1
        this.getTreatmentList()
      })
      Bus.$on('treatment-list-reload', () => {
        this.getTreatmentList()
      })
    },
    beforeDestroy () {
      Bus.$off('treatment-list-filter')
    },
    methods: {
      async getTreatmentList () {
        this.loading = true
        const params = {
          limit: this.limit,
          page: this.currentPage,
          keyword: this.filterForm.keyword,
        }
        const loader = this.$loading.show()
        const response = await TreatmentRepository.list(params)
        loader.hide()
        this.loading = false
        if (response.success) {
          this.treatments = _.get(response, 'data.list_treatment', [])
          this.totalItem = _.get(response, "data.total_item", 0);
        }
      },
    },
  }
</script>
