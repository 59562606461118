<template>
    <validation-observer v-slot="{ handleSubmit }">
        <v-card class="mx-auto pa-2" outlined>
            <v-row>
                <v-col cols="12" md="3" class="pl-10">
                    <v-switch
                        v-model="dataForm.assignContact"
                        label="Tự động phân bổ contact"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <validation-provider
                        v-slot="{ errors }"
                        class="mb-2"
                        name="Số contact phân bổ chung cho nhân viên"
                        rules="min_value:1|max_value:999|required"
                    >
                        <v-text-field
                            v-model="dataForm.contactForTelesales"
                            label="Số contact phân bổ chung cho nhân viên"
                            :error-messages="errors"
                            color="primary"
                            type="number"
                        />
                    </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                    <validation-provider
                        v-slot="{ errors }"
                        class="mb-2"
                        name="Số contact phân bổ chung cho trưởng nhóm"
                        rules="min_value:1|max_value:999"
                    >
                        <v-text-field
                            v-model="dataForm.contactForSaleLeader"
                            label="Số contact phân bổ chung cho trưởng nhóm"
                            :error-messages="errors"
                            color="primary"
                            type="number"
                        />
                    </validation-provider>
                </v-col>
                <v-col cols="12" md="1">
                </v-col>
                <v-col cols="12" md="2" class="d-flex" style="text-align: right">
                    <v-menu
                        :internal-activator="true"
                        v-model="filterForm.openMenu"
                        content-class="menu__customer--filter no-contain overflow-visible"
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on }">
                        <v-btn style="margin-right: 10px;" class="mb-2" color="primary" dark v-on="on">
                            <span>Tìm kiếm</span>
                            <v-icon style="margin-left: 10px; margin-right: -20px;">mdi-chevron-down</v-icon>
                        </v-btn>
                        </template>
                        <card-filter-form-v1
                            :filterForm="filterForm"
                        />
                    </v-menu>
                </v-col>
            </v-row>

            <div class="text-center mb-4">
                <v-btn v-if="checkAuthorization('setting-contact_update')" class="primary mx-0" @click.prevent="handleSubmit(saveSetting); scrollToError()">Lưu</v-btn>
            </div>
        </v-card>
    </validation-observer>
</template>

<script>
import CardFilterFormV1 from './CardFilterFormV1'
export default {
    components: {
      CardFilterFormV1
    },
    props: {
        dataForm: {
            type: Object,
            default: () => {}
        },
        filterForm: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        saveSetting() {
            Bus.$emit('save-contact-setting-v1', this.dataForm)
        }
    }
}
</script>