<template>
  <v-container id="product-report" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <br />
    <div class="text-sm-right mb-2">
      <!-- <v-btn
        v-if="checkAuthorization('product_report_download')"
        class="primary mx-0"
        @click="exportExcel"
      >
        <v-icon>mdi-download</v-icon>Download
      </v-btn> -->
    </div>
    <product-report-table :productData="productData" />
  </v-container>
</template>

<script>
import FilterForm from "./components/FilterForm.vue";
import ProductReportTable from "./components/ProductReportTable.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const ReportRepository = RepositoryFactory.get("report");
// import TokenService from "../../../helpers/token"

export default {
  components: { ProductReportTable, FilterForm },
  data() {
    return {
      filterForm: {
        dateRange: [],
      },
      productData: [],
    };
  },
  created() {
    this.getProductReport();
    Bus.$on("product-report-search-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getProductReport();
    });
  },
  methods: {
    async getProductReport() {
      const loading = this.$loading.show();
      const res = await ReportRepository.productReport({ ...this.filterForm });
      if (res.success) {
        this.productData = _.get(res, "data.product_statistics", []);
      } else {
        this.productData = [];
      }
      loading.hide();
    },

    // async exportExcel() {
    //     const loader = this.$loading.show();
    //     let user = await TokenService.getUser()
    //     ReportRepository.exportExcelAccountReport({username: user.username})
    //     loader.hide();
    //     this.$swal({
    //         title: 'Dữ liệu đang được xử lý, sẽ thông báo sau khi kết thúc!'
    //     })
    // }
  },
};
</script>
