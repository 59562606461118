<template>
  <v-expansion-panels class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm sản phẩm</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0" outlined>
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="filterForm.keyword"
                    label="Tìm kiếm theo sản phẩm, mã sản phẩm"
                    placeholder="Tên sản phẩm, mã...."
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.productFolderId"
                    :items="productFolders"
                    item-text="name"
                    item-value="id"
                    label="Tìm kiếm theo nhóm sản phẩm"
                    placeholder="Nhóm sản phẩm"
                    color="primary"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.unitId"
                    :items="units"
                    item-text="name"
                    item-value="id"
                    label="Tìm kiếm theo đơn vị"
                    placeholder="Đơn vị"
                    color="primary"
                    clearable
                    multiple
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.manufactureId"
                    :items="manufactures"
                    item-text="name"
                    item-value="id"
                    label="Tìm kiếm theo nhà sản xuất"
                    placeholder="Nhà sản xuất"
                    color="primary"
                    clearable
                    multiple
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.utilityId"
                    :items="utilities"
                    item-text="name"
                    item-value="id"
                    label="Tìm kiếm theo nhóm công dụng"
                    placeholder="Nhóm công dụng"
                    color="primary"
                    clearable
                    multiple
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.treatmentId"
                    :items="treatments"
                    item-text="name"
                    item-value="id"
                    label="Tìm kiếm theo nhóm điều trị"
                    placeholder="Nhóm điều trị"
                    color="primary"
                    clearable
                    multiple
                  />
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn class="primary mx-0" @click="searchReport">
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { formatDate } from "@/helpers/utils";
import * as moment from "moment";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { PAGE_LIMIT, FIRST_PAGE } from "../../../../helpers/constants";
const ProductFolderRepository = RepositoryFactory.get("product_folder");
const UnitRepository = RepositoryFactory.get("unit");
const ManufactureRepository = RepositoryFactory.get("manufacture");
const UtilityRepository = RepositoryFactory.get("utility");
const TreatmentsRepository = RepositoryFactory.get("treatment");

export default {
  props: {
    filterForm: {
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultRange: {
        startDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
      },
      productFolders: [],
      units: [],
      manufactures: [],
      utilities: [],
      treatments: [],
    };
  },
  created() {
    this.fetchdDataProductFolder();
    this.fetchdDataUnit();
    this.fetchdDataManufactures();
    this.fetchdDataUtilities();
    this.fetchdDataTreatments();
  },
  methods: {
    searchReport() {
      this.$emit("product-filter", this.filterForm);
    },
    async fetchdDataProductFolder() {
      const response = await ProductFolderRepository.list({
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      if (response.success) {
        this.productFolders = _.get(response, "data.list_folder", []);
      }
    },
    async fetchdDataUnit() {
      const response = await UnitRepository.list({
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      if (response.success) {
        this.units = _.get(response, "data.list_unit", []);
      }
    },
    async fetchdDataManufactures() {
      const response = await ManufactureRepository.list({
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      if (response.success) {
        this.manufactures = _.get(response, "data.list_manufacture", []);
      }
    },
    async fetchdDataUtilities() {
      const response = await UtilityRepository.list({
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      if (response.success) {
        this.utilities = _.get(response, "data.list_utility", []);
      }
    },
    async fetchdDataTreatments() {
      const response = await TreatmentsRepository.list({
        page: FIRST_PAGE,
        limit: PAGE_LIMIT,
      });
      if (response.success) {
        this.treatments = _.get(response, "data.list_treatment", []);
      }
    },
  },
};
</script>
<style scoped>
.hiden-filter-siteId {
  display: none;
}
</style>