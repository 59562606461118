var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","persistent":"","retain-focus":false},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[(_vm.isCreate)?[_vm._v(" Thêm mới ")]:[_vm._v(" Cập nhật thông tin ")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v(" mdi-close ")])],2),_c('v-card-text',{staticClass:"px-6"},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:255","name":"Tên"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên *","error-messages":errors,"color":"primary"},model:{value:(_vm.banner.title),callback:function ($$v) {_vm.$set(_vm.banner, "title", $$v)},expression:"banner.title"}})]}}],null,true)}),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"max:1000","name":"Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Link *","error-messages":errors,"color":"primary"},model:{value:(_vm.banner.link),callback:function ($$v) {_vm.$set(_vm.banner, "link", $$v)},expression:"banner.link"}})]}}],null,true)}),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:255","name":"Alt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Alt *","error-messages":errors,"color":"primary"},model:{value:(_vm.banner.alt),callback:function ($$v) {_vm.$set(_vm.banner, "alt", $$v)},expression:"banner.alt"}})]}}],null,true)}),_c('v-file-input',{attrs:{"placeholder":"Ảnh minh họa","label":"Ảnh minh họa","prepend-icon":"mdi-paperclip","value":_vm._.get(_vm.banner, 'image.originalName', '')},on:{"change":_vm.uploadOneFile},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.inputFile),callback:function ($$v) {_vm.inputFile=$$v},expression:"inputFile"}}),_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Thứ tự hiển thị","rules":"min: 0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Thứ tự hiển thị","error-messages":errors,"color":"primary","type":"number"},model:{value:(_vm.banner.displayOrder),callback:function ($$v) {_vm.$set(_vm.banner, "displayOrder", $$v)},expression:"banner.displayOrder"}})]}}],null,true)}),_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Mô tả","rules":"max: 255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Mô tả","error-messages":errors,"color":"primary"},model:{value:(_vm.banner.description),callback:function ($$v) {_vm.$set(_vm.banner, "description", $$v)},expression:"banner.description"}})]}}],null,true)}),_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Nhà tài trợ","rules":"max: 255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Nhà tài trợ","error-messages":errors,"color":"primary"},model:{value:(_vm.banner.sponsor),callback:function ($$v) {_vm.$set(_vm.banner, "sponsor", $$v)},expression:"banner.sponsor"}})]}}],null,true)}),_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Loại"}},[_c('v-select',{attrs:{"label":"Loại","color":"primary","items":_vm.types,"item-text":"text","item-value":"value"},model:{value:(_vm.banner.type),callback:function ($$v) {_vm.$set(_vm.banner, "type", $$v)},expression:"banner.type"}})],1),_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Trạng thái"}},[_c('v-select',{attrs:{"label":"Trạng thái","color":"primary","items":_vm.statusOptions,"item-text":"text","item-value":"value"},model:{value:(_vm.banner.status),callback:function ($$v) {_vm.$set(_vm.banner, "status", $$v)},expression:"banner.status"}})],1)],1),_c('v-card-actions',{staticClass:"px-0 group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveBanner)}}},[_vm._v(" Lưu lại ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy bỏ ")])],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }