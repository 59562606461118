<template>
    <v-expansion-panels
            class="mb-4"
    >
        <v-expansion-panel>
            <v-expansion-panel-header>Tìm kiếm thông báo</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card class="ma-0 overflow-visible" outlined>
                    <v-list-item class="pa-0" three-line>
                        <v-list-item-content class="pa-0 overflow-visible">
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-autocomplete
                                            v-model="filterForm.departmentIds"
                                            :items="departments"
                                            item-text="name"
                                            item-value="id"
                                            label="Tìm kiếm theo Nhóm khách hàng"
                                            placeholder="Chọn Nhóm khách hàng"
                                            clearable
                                            multiple
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                            v-model="filterForm.content"
                                            label="Tìm kiếm theo nội dung"
                                            placeholder="Nhập nội dung"
                                            clearable
                                            @keyup.enter="searchAnnouncement"
                                    />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <common-range-picker
                                        v-model="selectRange"
                                        :clearable="true"
                                        :aLight="'left'"
                                        :defaultRange="defaultRange"
                                        @change-date-range="changeDateRange"
                                    />
                                </v-col>
                            </v-row>
                            <div class="text-center mb-4">
                                <v-btn class="primary mx-0" @click="searchAnnouncement">
                                <v-icon>mdi-magnify</v-icon>Tìm kiếm
                                </v-btn>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
        </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
import TokenService from '../../../helpers/token';
import * as moment from "moment";
export default {
    name: 'FilterForm',
    props: {
        filterForm: {
            default: () => ({})
        },
        departments: {
            type: Array,
            default: () => {[]}
        }
    },
    data() {
        return {
            date: [],
            menuStart: false,
            defaultRange: {
              startDate: moment().startOf('month').format('YYYY-MM-DD'),
              endDate: moment().endOf('month').format('YYYY-MM-DD')
            },
            selectRange: [{
              startDate: moment().startOf('day').format('YYYY-MM-DD 00:00:00'),
              endDate: moment().endOf('day').format('YYYY-MM-DD 23:59:59'),
            }]
        };
    },
    methods: {
        searchAnnouncement() {
            Bus.$emit("announcement-list-filter", this.filterForm)
        },
      changeDateRange(dateRange) {
        this.filterForm.dateRange = dateRange;
        this.selectRange = dateRange
      }
    }
};
</script>