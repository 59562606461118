<template>
    <v-card class="ma-0">
        <div>
            <filter-form-mobile :filterForm="filterForm" 
                :filterContactStatusList="filterContactStatusList"
                :showCreate="showCreate"/>
        </div>
        <div>
            <order-table-mobile :orders="orders" :contactStatusList="contactStatusList" />
        </div>

        <v-row class="ma-0 pagination-container">
            <v-col cols="12" class="py-1">
                <v-pagination
                    v-if="totalItem > limit"
                    v-model="currentPage"
                    :length="getTotalItem(totalItem, limit)"
                    class="justify-sm-end"
                    prev-icon="mdi-menu-left"
                    next-icon="mdi-menu-right"
                />
            </v-col>
        </v-row>

        <v-row class="bottom-tabs-container ma-0"
            color="primary">
            <div class="tabs-slider"></div>
            <v-col cols="3" :class="'bottom-tab tab-' + index" 
                v-for="(tab, index) in tabs" 
                :key="index"
                @click="changeActiveTab(index)">
                {{ tab }}
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { contactStatus, contactStatusDefaultColor } from '../../helpers/constants'
import FilterFormMobile from './components/FilterFormMobile'
import OrderTableMobile from './components/OrderTableMobile'
import RepositoryFactory from '../../repositories/RepositoryFactory'
import TokenService from '../../helpers/token'
const OrderRepository = RepositoryFactory.get('order')
const ContactStatusRepository = RepositoryFactory.get('contact_status')

export default {
    components: { FilterFormMobile, OrderTableMobile },
    data() {
        return {
            filterForm: {
                keyword: '',
                openMenu: false,
                code: '',
                departmentId: '',
                userId: '',
                departmentIds: [],
                userIds: [],
                groupId: '',
                productId: '',
                contactSourceId: '',
                paymentType: '',
                createdAt: [],
                contactName: '',
                contactMobile: '',
                childName: '',
                childBirthday: '',
                statusIds: []
            },
            currentPage: 1,
            limit: 30,
            totalItem: 0,
            orders: [],
            tabs: ['Tất cả', 'Thành công', 'Đã lên đơn', 'Đã huỷ'],
            tabIndex: 0,
            contactStatusList: [],
            showCreate: false
        }
    },
    created() {
        this.showCreate = this.checkAuthorization('order-ctv-create_read')
        this.getOrderList()
        this.getContactStatusList()
        Bus.$on('search-orders-mobile', filterForm => {
            this.filterForm = filterForm
            this.getOrderList()
        })
        Bus.$on('update-orders-mobile', () => {
            this.getOrderList()
        })
    },
    mounted() {
        this.changeActiveTab(0)
        let tabs = document.getElementsByClassName('row bottom-tabs-container')
        let pagination = document.getElementsByClassName('row pagination-container')
        pagination[0].style.bottom = tabs[0].scrollHeight - 1 + 'px'
    },
    computed: {
        filterContactStatusList() {
            let contactStatusList = [];
            if (this.tabIndex === 0) {
                return this.contactStatusList;
            } else if (this.tabIndex === 1) {
                contactStatusList = this.contactStatusList.filter(
                    status => status.type === 'T5' || status.type === 'C5'
                );
            } else if (this.tabIndex === 2) {
                contactStatusList = this.contactStatusList.filter(
                    status => status.type === 'T3' || status.type === 'C3'
                );
            } else if (this.tabIndex === 3) {
                contactStatusList = this.contactStatusList.filter(
                    status => status.type === 'T2'|| status.type === 'C2'
                );
            }
            return contactStatusList;
        }
    },
    watch: {
        currentPage: {
            handler() {
                this.getOrderList();
            }
        }
    },
    methods: {
        changeActiveTab(index) {
            this.tabIndex = index

            let element = document.getElementsByClassName('bottom-tab active')
            if (element.length > 0) {
                element[0].classList.remove('active')
            }

            let active = document.getElementsByClassName('bottom-tab tab-' + index)
            if (active.length > 0) {
                active[0].classList.add('active')
            }
            
            let slider = document.getElementsByClassName('tabs-slider')
            if (slider.length > 0) {
                slider[0].style.left = index*25 + '%'
            }

            if (index === 0) {
                this.filterForm.statusIds = []
            } else if (index === 1) {
                this.filterForm.statusIds = [contactStatus.T5_COD, contactStatus.T5_BANK, contactStatus.C5_COD, contactStatus.C5_BANK]
            } else if (index === 2) {
                this.filterForm.statusIds = [contactStatus.T3_COD, contactStatus.T3_BANK, contactStatus.C3_COD, contactStatus.C3_BANK]
            } else if (index === 3) {
                this.filterForm.statusIds = [contactStatus.T2_HUY, contactStatus.C2_HUY]
            }
            this.currentPage = 1
            this.getOrderList()
        },

        async getContactStatusList() {
            const loading = this.$loading.show();
            const typeArray = ['T2', 'T3', 'T4', 'T5', 'C2', 'C3', 'C4', 'C5'];
            const response = await ContactStatusRepository.list({});
            loading.hide();
            if (response.success) {
                const contactStatusList = [];
                _.get(response, 'data', []).forEach(contactStatus => {
                    if (typeArray.includes(_.get(contactStatus, 'type', ''))) {
                        _.get(contactStatus, 'data', []).forEach(data => {
                            contactStatusList.push({
                                type: contactStatus.type,
                                id: _.get(data, 'id', 0),
                                name: _.get(data, 'name', 0),
                                color: _.get(data, 'color', contactStatusDefaultColor) ||
                                    contactStatusDefaultColor
                            });
                        });
                    }
                });
                this.contactStatusList = contactStatusList;
            } else {
                this.$swal({
                    type: 'error',
                    title: 'Lỗi !',
                    text: _.get(response, 'message', '')
                });
            }
        },

        async getOrderList() {
            const params = {
                limit: this.limit,
                page: this.currentPage - 1,
                keyword: this.filterForm.keyword,
                code: this.filterForm.code,
                departmentId: this.filterForm.departmentId,
                userId: this.filterForm.userId,
                departmentIds: this.filterForm.departmentIds,
                userIds: this.filterForm.userIds,
                groupId: this.filterForm.groupId,
                productId: this.filterForm.productId,
                contactSourceId: this.filterForm.contactSourceId,
                paymentType: this.filterForm.paymentType,
                createdAt: this.filterForm.createdAt.filter(Boolean),
                contactName: this.filterForm.contactName,
                contactMobile: this.filterForm.contactMobile,
                childName: this.filterForm.childName,
                childBirthday: this.filterForm.childBirthday,
                statusIds: this.filterForm.statusIds.filter(Boolean)
            };

            if (!this.checkAuthorization('order_view_all')) {
                const dataUser = TokenService.getUser() || {};
                if ( !params.userId && this.isStaff()) {
                    params.userId = _.get(dataUser, 'id', '');
                }
                // if (!params.departmentId && this.isLeader()) {
                //   params.departmentId = dataUser.departmentId;
                // }
            }

            if (this.tabIndex > 0 && params.statusIds.length == 0) {
                this.tabIndex = 0;
            }
            
            this.loading = true;
            const loader = this.$loading.show();
            const response = await OrderRepository.list(params);
            loader.hide();
            this.loading = false;
            if (response.success) {
                this.orders = this.mappingOrderList(_.get(response, 'data.data', []));
                this.totalItem = _.get(response, 'data.totalItem', 0);
            } else {
                this.$swal({
                    type: 'error',
                    title: 'Lỗi !',
                    text: _.get(response, 'message', '')
                });
                this.orders = [];
                this.totalItem = 0;
            }
        },

        mappingOrderList(orders) {
            return orders.map(order => {
                const orderTemp = order;
                let objProductCombo = {};
                let productComboList = [];
                _.get(orderTemp, 'combos', []).forEach(dataCombo => {
                    const quantity = _.get(dataCombo, 'OrderProduct.quantity', 0);
                    let productComboId = dataCombo.id + '_combo';
                    if (!objProductCombo[productComboId]) {
                        objProductCombo[productComboId] = {
                            name: dataCombo.name,
                            quantity: 0
                        };
                    }
                    objProductCombo[productComboId].quantity += quantity;
                });
                _.get(orderTemp, 'products', []).forEach(dataProduct => {
                    const quantity = _.get(dataProduct, 'OrderProduct.quantity', 0);
                    let productName = dataProduct.name;
                    let productId = dataProduct.id;
                    if (!objProductCombo[productId]) {
                        objProductCombo[productId] = {
                            name: productName,
                            quantity: 0
                        };
                        objProductCombo[productId].quantity += quantity;
                    }
                });

                for (const productId in objProductCombo) {
                    productComboList.push(objProductCombo[productId]);
                }
                orderTemp.rowspan =
                    productComboList.length === 0 ? 1 : productComboList.length;
                orderTemp.productComboList = productComboList;
                orderTemp.expanded = false
                return orderTemp;
            });
        },

        showContactsIndex() {
            let maxIndex = parseInt(this.currentPage)*parseInt(this.limit)
            if (maxIndex >= this.totalItem) {
                return (maxIndex - parseInt(this.limit) + 1) + ' - ' + this.totalItem  
            } else {
                return (maxIndex - parseInt(this.limit) + 1) + ' - ' + maxIndex
            }
        }
    }
}
</script>

<style scoped>
.v-card {
    min-height: calc(100vh - 45px);
}

.bottom-tabs-container {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: white;
    z-index: 10;
    border-top: 1px solid #ababab
}

.bottom-tab {
    word-break: break-word;
    margin: auto;
    text-align: center;
    font-size: 15px;
}

.bottom-tab.active {
    color: #e91e63 !important;
}

.tabs-slider {
    height: 2px;
    background-color: #e91e63;
    position: absolute;
    width: 25%;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.pagination-btn-left, .pagination-btn-right {
  max-width: 30px !important;
  min-width: 30px !important;
  background-color: #ffffff !important;
}

.pagination-btn-left i, .pagination-btn-right i {
  color: rgba(0,0,0,.54) !important
}

.pagination-btn-left {
  margin: 0px 5px 2px 10px !important;
}

.pagination-btn-right {
  margin: 0px 10px 2px 5px !important;
}

.pagination-container {
    position: fixed;
    background-color: white;
    z-index: 10;
    text-align: center;
    width: 100%;
}
</style>