<template>
  <div>
    <v-row class="mx-0">
      <v-col cols="12" sm="6" class="text-left">
        <v-btn
          v-if="checkAuthorization('mkt-sms-customer_create')"
          class="primary mb-0 mx-2"
          @click="addNewCustomer"
          height="30px"
          style="padding: 0px 25px !important"
        >
          <v-icon>mdi-plus</v-icon>Tạo tệp mới
        </v-btn>
      </v-col>

      <v-col cols="12" sm="6" class="text-right">
        <v-menu
            :internal-activator="true"
            v-model="openMenu"
            content-class="menu__customer--filter no-contain overflow-visible"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn style="padding: 0px 25px !important; margin-right: 10px" class="" color="primary" dark v-on="on" height="30px" >
              <span>Tìm kiếm</span>
              <v-icon style="margin-left: 10px; margin-right: -20px;">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <filter-form-customer
              :customerList="customerList"
              :users="users"
          />
        </v-menu>
      </v-col>
    </v-row>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Ngày tạo</th>
            <th>Người tạo</th>
            <th>Tệp</th>
            <th>Điều kiện</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody v-if="!loadingContact && totalItemCustomerFile > 0">
          <template v-for="(item) in customerList">
            <tr
              :key="item.id"
            >
              <td>{{ _.get(item, 'createdAt') }}</td>
              <td>{{ _.get(item, 'createdUser') }}</td>
              <td>{{ _.get(item, 'title') }}</td>
<!--              <td>{{ _.get(item, 'condition') }}</td>-->
              <td>
                <span style="display: block" v-for="(value, key) in _.get(item, 'condition')">{{ key }} {{ value }}</span>
              </td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip bottom v-if="checkAuthorization('mkt-sms-customer_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn class="mr-2"  @click="addToSend(item)" icon small color="primary" dark v-on="on"><v-icon>mdi-plus-circle</v-icon></v-btn>
                    </template>
                    <span>Chọn tệp khách hàng để gửi</span>
                  </v-tooltip>
                    <v-tooltip bottom v-if="checkAuthorization('mkt-sms-customer_update')">
                        <template v-slot:activator="{ on }">
                            <v-btn class="mr-2"  @click="editCustomerFile(item)" icon small color="primary" dark v-on="on"><v-icon>mdi-eye</v-icon></v-btn>
                        </template>
                        <span>Chi tiết tệp khách hàng</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="checkAuthorization('mkt-sms-customer_delete')">
                        <template v-slot:activator="{ on }">
                            <v-btn @click="onClickDelete(item)" icon small color="default" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                        </template>
                        <span>Xóa tệp khách hàng</span>
                    </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-if="!loadingContact && totalItemCustomerFile === 0">
          <tr>
            <td colspan="1000" class="text-center">
              <common-no-result/>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row>
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
            v-if="totalItemCustomerFile > limitCustomerFile"
            v-model="currentPageCustomerFile"
            :length="getTotalItem(totalItemCustomerFile, limitCustomerFile)"
            class="justify-sm-end"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>

    <FormCreateDetailCustomerFile
        :users="users"
        :productGroups="productGroups"
        :contactStatusList="contactStatusList"
        :contactSources="contactSources"
        :products="products"
    />
  </div>
</template>
<script>
  import RepositoryFactory from '../../../../repositories/RepositoryFactory'
  import FilterFormCustomer from "./FilterFormCustomer";
  import FormCreateDetailCustomerFile from "./FormCreateDetailCustomerFile";
  import * as moment from "moment";
  import CustomerFileRepository from "@/repositories/CustomerFileRepository";

  const SmsRepository = RepositoryFactory.get('sms')
  export default {
    components: {FormCreateDetailCustomerFile, FilterFormCustomer },
    props: {
      customerList: {
        type: Array,
        default: () => []
      },
      users: {
        type: Array,
        default: () => []
      },
      productGroups: {
        type: Array,
        default: () => []
      },
      contactStatusList: {
        type: Array,
        default: () => []
      },
      contactSources: {
        type: Array,
        default: () => []
      },
      products: {
        type: Array,
        default: () => []
      },
      limitCustomerFile: {
        type: Number,
        default: 10
      },
      totalItemCustomerFile: {
        type: Number,
        default: 0
      },
      loadingContact: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        selectedCustomerFile: {},
        modalAlert: false,
        errorMessage: '',
        openMenu: false,
        currentPageCustomerFile: 1
      }
    },
    watch: {
      currentPageCustomerFile: {
        deep: true,
        handler() {
          Bus.$emit("change-page-customer-file", this.currentPageCustomerFile)
        }
      }
    },
    computed: {
      showAction () {
        return this.checkAuthorization('mkt-sms-customer_update') || this.checkAuthorization('mkt-sms-customer_delete');
      }
    },
    methods: {
      async onClickDelete (customerFile) {
        this.$swal({
          title: 'Xóa tệp khách hàng',
          text: 'Bạn có thực sự muốn xóa ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const respond = await CustomerFileRepository.delete(customerFile.id)
            loader.hide()
            if (_.get(respond, 'success', false)) {
              this.$swal({
                title: 'Đã xóa thành công !',
                confirmButtonText: 'Đồng ý',
              },
              ).then(() => {
                  Bus.$emit('create-customer-file-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(respond, 'message', ''),
              })
            }
          }
        })
      },

      addNewCustomer() {
        this.selectedCustomerFile = {
          operatorValue: {},
          operatorType: {}
        };
        Bus.$emit('customer-show-form-create-detail', this.selectedCustomerFile);
      },
      async editCustomerFile(item) {
        // console.log(item)
        // this.loading =  true;
        // const loader = this.$loading.show();
        // const res = await  CustomerFileRepository.detail(item.id);
        // console.log(res)
        // loader.hide();
        // this.loading =  false;
        // if(res.success) {
        //   this.selectedCustomerFile = _.get(res, 'data', {});
          this.selectedCustomerFile = item;
          Bus.$emit('customer-show-form-create-detail', this.selectedCustomerFile)
        // } else {
        //   Bus.$emit('customer-show-form-create-detail', this.selectedCustomerFile)
          // this.$swal({
          //   type: 'error',
          //   title: 'Lỗi !',
          //   text: _.get(res, 'message', ''),
          // })
        // }
      },
      addToSend(item) {
        Bus.$emit("add-customer-file-to-send", item.id)
      }
    }
  }
</script>

<style>
  .menu__customer--filter {
    top: calc(30% - 45px) !important;
    left: 96% !important;
    transform: translateX(-96%) !important;
  }
</style>
