<template>
    <div>
        <validation-observer
            ref="validator"
            v-slot="{ handleSubmit }"
        >
            <v-card-text class="px-6" :style="mobileStyle">
                <v-row>
                    <v-col cols="12" md="12" sm="12">
                        <validation-provider
                            v-slot="{ errors }"
                            class="mb-3"
                            :rules="{ regex: regex.isVNPhoneMobile, required: true }"
                            name="SĐT"
                        >
                            <v-text-field
                                v-model="mobile"
                                label="Số điện thoại *"
                                :error-messages="errors"
                                color="primary"
                                placeholder="Nhập số điện thoại..."
                                clearable
                            >
                                <template slot="label">
                                    <div>Số điện thoại <span style="color: red">*</span></div>
                                </template>
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6" sm="12">
                        <validation-provider
                            v-slot="{ errors }"
                            class="mb-3"
                            name="Tùy chọn"
                        >
                            <v-autocomplete
                                v-model="request"
                                attach
                                :items="requestActions"
                                :error-messages="errors"
                                label="Tùy chọn hỗ trợ *"
                                color="primary"
                                placeholder="Chọn tùy chọn hỗ trợ..."
                                item-text="requests"
                                item-value="code"
                                clearable
                            >
                                <template slot="label">
                                    <div>Ticket thường</div>
                                </template>
                            </v-autocomplete>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <validation-provider
                            v-slot="{ errors }"
                            class="mb-3"
                            name="Tùy chọn"
                        >
                            <v-autocomplete
                                v-model="urgentRequest"
                                attach
                                :items="urgentList"
                                :error-messages="errors"
                                label="Tùy chọn hỗ trợ (gấp)"
                                color="primary"
                                placeholder="Chọn tùy chọn hỗ trợ..."
                                clearable
                            >
                                <template slot="label">
                                    <div>Ticket gấp</div>
                                </template>
                            </v-autocomplete>
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row class="mb-5">
                    <v-col cols="12" md="12" sm="12">
                        <validation-provider
                            v-slot="{ errors }"
                            class="mb-2"
                            :rules="{ required: true }"
                            name="Chi tiết"
                        >
                            <v-textarea
                                v-model="descriptionRequest"
                                :error-messages="errors"
                                label="Chi tiết yêu cầu *"
                                color="primary"
                                placeholder="Nhập chi tiết yêu cầu..."
                                rows="5"
                                auto-grow
                                clearable
                            >
                                <template slot="label">
                                    <div>Chi tiết yêu cầu <span style="color: red">*</span></div>
                                </template>
                            </v-textarea>
                        </validation-provider>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="group-btn--form create-support-btn-container">
                <v-btn color="success" class="create-support-btn" @click.prevent="handleSubmit(createRequest)">Gửi</v-btn>
            </v-card-actions>
        </validation-observer>
    </div>
</template>

<script>
import { regex } from '@/helpers/constants'
import RepositoryFactory from '../../../repositories/RepositoryFactory'
const SupportRepository = RepositoryFactory.get('support')

export default {
    props: {
        mobileStyle: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            regex,
            mobile: '',
            requestActions: [],
            descriptionRequest: '',
            request: '',
            urgentRequest: '',
            urgentList: []
        }
    },
    created() {
        this.getUrgentList()
        this.getRequestAction()
    },
    methods: {
        async getRequestAction() {
            const loading = this.$loading.show()
            let res = await SupportRepository.getRequestAction()
            loading.hide()
            if (res.success) {
                this.requestActions = _.get(res, 'data', [])
            }
        },

        async createRequest() {
            if ((this.request == '' && this.urgentRequest == '') 
                || (this.request != '' && this.urgentRequest != '')) {
                this.$swal({
                    title: 'Chỉ chọn 1 trong 2 loại ticket!'
                })
                return
            }
            const loading = this.$loading.show()
            const params = {
                mobile: this.mobile,
                request: this.request,
                descriptionRequest: this.descriptionRequest,
                urgentRequest: this.urgentRequest
            }
            let res = await SupportRepository.create(params)
            loading.hide()
            if (res.success) {
                this.$swal({
                    title: "Tạo thành công!"
                })
                this.resetForm()
            } else {
                this.$swal({
                    title: 'Lỗi !',
                    text: _.get(res, 'message', ''),
                })
            }
        },

        resetForm() {
            this.mobile = ''
            this.request = ''
            this.descriptionRequest = ''
            this.urgentRequest = ''
        },

        async getUrgentList() {
            let res = await SupportRepository.urgentList()
            if (res.success) {
                this.urgentList = res.data
            } else {
                this.$swal({
                    title: 'Lỗi !',
                    text: _.get(res, 'message', ''),
                })
            }
        }
    }
}
</script>

<style scoped>
.create-support-btn-container {
    max-width: 100% !important;
    width: 100%;
    padding: 10px !important;
    position: fixed;
    bottom: 5px;
    z-index: 10;
}

/deep/.v-select-list {
    max-height: 250px;
    overflow-y: auto;
    font-size: 16px;
}

/deep/.v-list-item {
    min-height: 30px;
}

/deep/input ,/deep/textarea{
    font-size: 16px !important;
}

/deep/.v-label {
    font-size: 16px !important;
}

/deep/.v-messages {
    font-size: 14px !important;
}
</style>