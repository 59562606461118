<template>
  <v-container
    id="product-group"
    fluid
    tag="section"
  >
    <filter-form :filter-form="filterForm" :schoolLevels="schoolLevels" />
    <product-group-table :product-groups="productGroups" :schoolLevels="schoolLevels" />
    <v-row class="mt-4">
      <v-col
        cols="12"
        sm="6"
      />
      <v-col
        cols="12"
        sm="6"
      >
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem, limit)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import FilterForm from './components/FilterForm.vue'
  import ProductGroupTable from './components/ProductGroupTable'
  import RepositoryFactory from '../../../repositories/RepositoryFactory'
  import { productStatusOptions } from '../../../helpers/constants'
  const ProductGroupRepository = RepositoryFactory.get('product_group')
  const SchoolLevelRepository = RepositoryFactory.get('school_level')
  export default {
    components: { FilterForm, ProductGroupTable },
    data () {
      return {
        filterForm: {
          keyword: '',
          schoolLevelIds: []
        },
        currentPage: 1,
        limit: 10,
        totalItem: 0,
        productGroups: [],
        loading: true,
        schoolLevels: []
      }
    },
    watch: {
      currentPage: {
        handler () {
          this.getProductGroupList()
        },
      },
      limit: {
        handler () {
          if (this.currentPage === 1) {
            this.getProductGroupList()
          } else {
            this.currentPage = 1
          }
        },
      },
    },
    async created () {
      await this.getListSchoolLevel()
      this.getProductGroupList()
      Bus.$on('product-group-list-filter', (filterForm) => {
        this.filterForm = filterForm
        this.currentPage = 1
        this.getProductGroupList()
      })
      Bus.$on('product-group-reload', () => {
        this.getProductGroupList()
      })
    },
    beforeDestroy () {
      Bus.$off('product-group-list-filter')
    },
    methods: {
      async getProductGroupList () {
        this.loading = true
        const params = {
          limit: this.limit,
          page: this.currentPage - 1,
          keyword: this.filterForm.keyword,
          status: this.filterForm.status,
          schoolLevelIds: this.filterForm.schoolLevelIds
        }
        const loader = this.$loading.show()
        const response = await ProductGroupRepository.list(params)
        loader.hide()
        this.loading = false
        if (response.success) {
          this.productGroups = _.get(response, 'data', []).map((group,index) => ({
            ...group,
            index: index + 1,
            statusText: _.get(productStatusOptions.find(s => s.value === group.status), 'text', ''),
            urls: _.get(group, 'urls', []).map((url, index) => ({
              id: `${index}_${(new Date()).getTime()}`,
              inputUrl: url.url,
            })),
          }))
          this.totalItem = _.get(response, "data.total_item", 0);
        }
      },
      async getListSchoolLevel() {
        const res = await SchoolLevelRepository.list({limit: 1000})
        if (res.success) {
          this.schoolLevels = _.get(res, 'data', [])
        } else {
          this.schoolLevels = []
        }
      }
    },
  }
</script>
