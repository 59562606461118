var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{ref:"validation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[[_vm._v(" Quản lý kinh doanh - sản phẩm - khách hàng ")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v(" mdi-close ")])],2),_c('v-card-text',{staticClass:"px-6",staticStyle:{"padding-top":"30px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Chọn quản lý","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.users,"item-text":"username","item-value":"id","label":"Chọn quản lý *","color":"primary","clearable":"","error-messages":errors},model:{value:(_vm.instance.userId),callback:function ($$v) {_vm.$set(_vm.instance, "userId", $$v)},expression:"instance.userId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Chọn sản phẩm","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.products,"item-text":"name","item-value":"id","label":"Chọn sản phẩm *","color":"primary","clearable":"","error-messages":errors,"multiple":""},model:{value:(_vm.instance.productIds),callback:function ($$v) {_vm.$set(_vm.instance, "productIds", $$v)},expression:"instance.productIds"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Chọn khách hàng","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.customers,"item-text":"username","item-value":"id","label":"Chọn khách hàng *","color":"primary","clearable":"","error-messages":errors,"multiple":""},model:{value:(_vm.instance.customerIds),callback:function ($$v) {_vm.$set(_vm.instance, "customerIds", $$v)},expression:"instance.customerIds"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveLinkUserToProductCustomer)}}},[_vm._v(" Thêm mới ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy bỏ ")])],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }