<template>
  <v-card class="my-0">
    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tháng</th>
            <th>Khách hàng thường</th>
            <th>Công ty</th>
            <th>Nhà thuốc</th>
            <th>Phòng khám</th>
          </tr>
        </thead>
        <tbody v-if="_.isArray(accountData) && accountData.length">
          <template v-for="(item, index) in accountData">
            <tr :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.month }}</td>
              <td>{{ formatPrice(item.regularCustomer) }}</td>
              <td>{{ formatPrice(item.company) }}</td>
              <td>{{ formatPrice(item.pharmacy) }}</td>
              <td>{{ formatPrice(item.clinic) }}</td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100">
              <common-no-result />
            </td>
          </tr>
        </tbody>
        <tfoot style="position: sticky; bottom: 0">
          <!-- <tr class="foot-line">
            <td class="th-id">#</td>
            <td>Tháng</td>
            <td>Khách hàng thường</td>
            <td>Công ty</td>
            <td>Nhà thuốc</td>
            <td>Phòng khám</td>
          </tr> -->
          <tr>
            <td>
              <h3 class="text-center">TỔNG CỘNG:</h3>
            </td>
            <td></td>
            <td>
              <h3 class="text-center">{{ totalRegularCustomer }}</h3>
            </td>
            <td>
              <h3 class="text-center">{{ totalCompany }}</h3>
            </td>
            <td>
              <h3 class="text-center">{{ totalPharmacy }}</h3>
            </td>
            <td>
              <h3 class="text-center">{{ totalClinic }}</h3>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  props: {
    accountData: {
      type: Array,
      default: () => {
        [];
      },
    },
    totalRegularCustomer: {
      type: Number,
    },
    totalCompany: {
      type: Number,
    },
    totalPharmacy: {
      type: Number,
    },
    totalClinic: {
      type: Number,
    },
  },
};
</script>

<style scoped>
/* .table-layout tbody tr {
    height: 40px !important;
}

.table-layout th {
    font-size: 18px !important;
}

.table-layout td {
    font-size: 16px !important;
} */
</style>