<template>
  <v-dialog
    v-model="showModal"
    max-width="1000"
    persistent
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer 
        ref="validation"
        v-slot="{ handleSubmit }"
      >
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate">
            Thêm mới combo
          </template>
          <template v-else>
            Cập nhật combo
          </template>

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="hideModal"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên combo"
              >
                <v-text-field
                  v-model="combo.name"
                  label="Tên combo *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Mã combo"
                :rules="{ required: true, max:255, regex: regex.isUsername }"
              >
                <v-text-field
                  v-model="combo.code"
                  label="Mã combo *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Công ty"
              >
                <v-autocomplete
                  v-model="combo.siteId"
                  :items="sites"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Công ty"
                  color="primary"
                  clearable
                />
              </validation-provider>
            </v-col>
          </v-row>
          <div class="group-input mb-4">
            <ul>
              <li class="title">
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <h3 class="text-left">
                      Combo sản phẩm
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <h3 class="text-left">
                      Giá sản phẩm ưu đãi từng sản phẩm
                    </h3>
                  </v-col>
                </v-row>
              </li>
              <template v-for="(product,index) in combo.products">
                <li
                  :key="product.id"
                  class="list-input"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        class="mb-2"
                        rules="required"
                        name="Chọn sản phẩm"
                      >
                        <v-autocomplete
                          v-model="product.productId"
                          :items="getProductOptions(product)"
                          :error-messages="errors"
                          item-text="name"
                          item-value="id"
                          label="Chọn sản phẩm *"
                          color="primary"
                          @change="selectProduct(product)"
                          :readonly="!updateCombo"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-currency-field
                        label="Giá sản phẩm *"
                        v-model="product.price"
                        color="primary"
                        :readonly="true"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        class="mb-2"
                        :rules="`required|numeric|max_value:${product.price}`"
                        name="Giá ưu đãi"
                      >
                        <v-currency-field
                          label="Giá ưu đãi *"
                          :error-messages="errors"
                          v-model="product.discountPrice"
                          color="primary"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-icon
                    v-if="index>0 && updateCombo"
                    class="group-input-close"
                    @click="deleteRow(product.id)"
                  >
                    mdi-delete
                  </v-icon>
                </li>
              </template>
            </ul>
            <v-btn
              class="group-input-add m--icon-add"
              color="primary"
              @click="addRow"
              v-if="updateCombo"
            >
              <v-icon>mdi-plus</v-icon>Thêm lựa chọn
            </v-btn>
          </div>
          <v-row>
            <v-col
              cols="12"
              md="8"
            >
              <v-currency-field
                label="Tổng giá gốc combo *"
                :value="totalPrice"
                color="primary"
                :readonly="true"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            />
            <v-col
              cols="12"
              md="8"
            >
              <v-currency-field
                label="Giá combo ưu đãi *"
                :value="discountPrice"
                color="primary"
                :readonly="true"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            />
          </v-row>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn
            color="success"
            @click.prevent="handleSubmit(saveCombo)"
          >
            Lưu lại
          </v-btn>

          <v-btn
            class="ml-0"
            color="primary"
            @click="hideModal"
          >
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import RepositoryFactory from '../../../../repositories/RepositoryFactory'
  import {regex} from '../../../../helpers/constants';
  import { formatPrice } from '../../../../helpers/utils'
  const ProductRepository = RepositoryFactory.get('product')
  const ComboRepository = RepositoryFactory.get('combo')
  const SiteRepository = RepositoryFactory.get('site')
  export default {
    $_veeValidate: {
      validator: 'new',
    },
    props: {
      updateCombo: {
        type: Boolean,
        default:() => false
      }
    },
    data () {
      return {
        regex,
        showModal: false,
        combo: {},
        isCreate: true,
        combos: [],
        errorMessage: '',
        sites: []
      }
    },
    computed: {
      totalPrice () {
        let total = 0
        this.combo.products.forEach(product => {
          total = total + (product.price || 0)
        })
        return total
      },
      discountPrice () {
        let totalSale = 0
        this.combo.products.forEach(product => {
          totalSale = parseInt(totalSale) + parseInt((product.discountPrice || 0))
        })
        return totalSale
      },
    },
    created () {
      this.getProductList()
      this.getSites()
      Bus.$on('combo-show-form', (combo) => {
        this.showModal = true
        const { products = [] } = _.cloneDeep(combo)
        if (products.length === 0) {
          combo.products = [{
            id: `${products.length}_${(new Date()).getTime()}`,
            productId: '',
            price: '',
            discountPrice: '',
          }]
        }
        this.combo = _.cloneDeep(combo)
        if (combo.id) this.isCreate = false
        else this.isCreate = true
      })
    },
    methods: {
      hideModal: function () {
        this.showModal = false
        this.isCreate = true
        this.errorMessage = ''
        this.$refs.validation.reset()
      },
      getProductOptions (product) {
        const productIds = this.combo.products.map(p => p.productId)
        return this.products.filter(p => (!productIds.includes(p.id) || p.id === product.productId))
      },
      selectProduct (product) {
        // find in combo.products
        const foundIndex = this.combo.products.findIndex(p => p.id === product.id)
        if (foundIndex > -1) {
          const foundProduct = this.products.find(p => p.id === product.productId)
          const combo = _.cloneDeep(this.combo)
          combo.products[foundIndex] = {
            id: product.id,
            productId: product.productId,
            price: foundProduct.price,
            discountPrice: '',
          }
          this.$set(this, 'combo', combo)
        }
      },
      async getProductList () {
        this.loading = true
        const loader = this.$loading.show()
        const response = await ProductRepository.list({ limit: 100, statusArray: ['draft', 'active'] })
        loader.hide()
        this.loading = false
        if (response.success) {
          this.products = _.get(response, 'data', [])
        }
      },
      addRow () {
        const combo = _.cloneDeep(this.combo)
        const { products = [] } = combo
        products.push({
          id: `${products.length}_${(new Date()).getTime()}`,
          productId: '',
          price: '',
          discountPrice: '',
        })
        this.$set(this, 'combo', combo)
      },
      deleteRow (id) {
        const index = this.combo.products.findIndex(u => u.id === id)
        if (index > -1) this.combo.products.splice(index, 1)
      },
      async saveCombo () {
        if (this.isCreate) {
          const combo = {
            name: this.combo.name,
            code: this.combo.code,
            products: this.combo.products.map(p => ({ productId: p.productId, price: p.discountPrice })),
            siteId: _.get(this.combo, 'siteId', null)
          }
          const loader = this.$loading.show()
          const response = await ComboRepository.create(combo)
          loader.hide()
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.errorMessage = ''
            this.showModal = false
            this.$swal({
              title: 'Thêm combo thành công',
              confirmButtonText: 'Đồng ý',
            }).then(() => {
              Bus.$emit('combo-list-reload')
            })
          }
        } else {
          const combo = {
            name: this.combo.name,
            code: this.combo.code,
            products: this.combo.products.map(p => ({ productId: p.productId, price: p.discountPrice })),
            siteId: _.get(this.combo, 'siteId', null)
          }
          const loader = this.$loading.show()
          const response = await ComboRepository.update(this.combo.id, combo)
          loader.hide()
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.errorMessage = ''
            this.showModal = false
            this.$swal({
              title: 'Cập nhật combo thành công',
              confirmButtonText: 'Đồng ý',
            }).then(() => {
              Bus.$emit('combo-list-reload')
            })
          }
        }
      },
      async getSites() {
        let res = await SiteRepository.list()
        if (res.success) {
          this.sites = _.get(res, 'data', [])
        } else {
          this.sites = []
        }
      }
    },
  }
</script>
