<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('product_delete')"
        v-show="(selected.length ? true : false)"
        color="default"
        dark
        class="mr-2"
        @click="deleteMultipleItem"
      >
        Xóa tất cả
      </v-btn>
      <v-btn
        v-if="checkAuthorization('product_create')"
        class="primary mx-0"
        @click="addNewCombo"
      >
        <v-icon>mdi-plus</v-icon>Thêm Mới
      </v-btn>
    </div>
    <v-tabs
      class="mt-0 pl-3"
      @change="changeTab"
      v-model="tabIndex"
      color="primary"
      style="background-color: #FFFFFF; padding: 5px 0px 10px 5px;"
    >
      <v-tab :key="0">Tất Cả</v-tab>
      <v-tab v-for="(tab, i) in productStatusOptions" :key="i+1">{{ tab.text }}</v-tab>
    </v-tabs>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="combos"
      :single-select="singleSelect"
      item-key="id"
      :show-select="checkAuthorization('product_delete')"
      class="table-layout pa-5"
      hide-default-footer
    >
      <template v-slot:top />
      <template v-slot:no-data>
        <common-no-result />
      </template>

      <template v-slot:item.price="{ item }">
        <div v-if="item.products.length">
          {{
            formatPrice(item.products.map(s => s.discountPrice).reduce((sum, x) => +sum + +x))
          }}
        </div>
      </template>

      <template v-slot:item.products="{ item }">
        {{ item.products.map(s => s.name).join(', ') }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip v-if="item.status === 'active'" bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" @click="convertStatus(item.id, 'inactive')" color="info" icon v-on="on">mdi-block-helper</v-icon>
          </template>
          <span>Chuyển sang trạng thái ngừng hoạt động</span>
        </v-tooltip>

        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="24" @click="convertStatus(item.id, 'active')" color="success" icon v-on="on">mdi-checkbox-marked-circle</v-icon>
          </template>
          <span>Chuyển sang trạng thái hoạt động</span>
        </v-tooltip>
        <v-tooltip bottom v-if="checkAuthorization('product_update')">
          <template v-slot:activator="{ on }">
            <v-icon class="mr-2 ml-2" @click="editCombo(item)" color="primary" icon v-on="on">mdi-pencil</v-icon>
          </template>
          <span>Cập nhật thông tin Combo</span>
        </v-tooltip>
        <v-tooltip bottom v-if="checkAuthorization('product_delete')">
          <template v-slot:activator="{ on }">
            <v-icon @click="onClickDelete(item)" color="default" icon v-on="on">mdi-delete</v-icon>
          </template>
          <span>Xóa Combo</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <combo-form :updateCombo="updateCombo"/>
  </div>
</template>
<script>
  import ComboForm from './ComboForm.vue'
  import RepositoryFactory from '../../../../repositories/RepositoryFactory'
  import { productStatusOptions } from '../../../../helpers/constants'
  import { formatPrice } from '../../../../helpers/utils'

  const ComboRepository = RepositoryFactory.get('combo')
  export default {
    components: { ComboForm },
    props: {
      combos: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        selectedCombo: {},
        errorMessage: '',
        headers: [
          {
            text: '',
            value: 'index',
            width: "20px",
            align: 'center',
          },
          { text: 'Tên combo', value: 'name', align: 'center' },
          { text: 'Giá combo', value: 'price', align: 'center' },
          { text: 'Mã combo', value: 'code', align: 'center' },
          { text: 'Các sản phẩm', value: 'products', align: 'center' },
          { text: 'Trạng thái', value: 'statusText', align: 'center' },
        ],
        singleSelect: false,
        selected: [],
        productStatusOptions,
        tabIndex: 0,
        filterForm: {},
        updateCombo: false
      }
    },
    created () {
       if ( this.checkAuthorization('product_update') || this.checkAuthorization('product_create')) {
        this.headers.push({ text: 'Thao tác', value: 'action', sortable: false, align: 'center' });
      }
    },
    methods: {
      async convertStatus (id, status) {
        const statusCombo = {
          status,
        }
        this.$swal({
          title: 'Chuyển đổi trạng thái',
          text: 'Bạn có thực sự chắc chắn ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const response = await ComboRepository.updateStatus(id, statusCombo)
            loader.hide()
            if (response.success) {
              this.$swal({
                  title: 'Chuyển đổi thành công !',
                  confirmButtonText: 'Đồng ý',
                },
              ).then(() => {
                Bus.$emit('combo-list-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(response, 'message', ''),
              })
            }
          }
        })
      },
      addNewCombo () {
        this.selectedCombo = {}
        this.updateCombo = true
        Bus.$emit('combo-show-form', this.selectedCombo)
      },
      editCombo (combo) {
        this.selectedCombo = combo
        if (combo.orders.length == 0) {
          this.updateCombo = true
        } else {
          this.updateCombo = false
        }
        Bus.$emit('combo-show-form', this.selectedCombo)
      },
      async onClickDelete (combo) {
        this.$swal({
          title: 'Xóa combo',
          text: 'Bạn có thực sự muốn xóa ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const respond = await ComboRepository.deleteOneItem(combo.id)
            loader.hide()
            if (_.get(respond, 'success', false)) {
              this.$swal({
                title: 'Đã xóa thành công !',
                confirmButtonText: 'Đồng ý',
              },
              ).then(() => {
                Bus.$emit('combo-list-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(respond, 'message', ''),
              })
            }
          }
        })
      },
      async deleteMultipleItem () {
        this.$swal({
          title: 'Xóa toàn bộ combo',
          text: 'Bạn có thực sự muốn xoá các combo này?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show()
            const response = await ComboRepository.deleteManyItems(this.selected.map(s => s.id))
            loader.hide()
            if (response.success) {
              this.selected = []
              this.$swal({
                title: 'Xoá thành công !',
                confirmButtonText: 'Đồng ý',
              }).then(() => {
                Bus.$emit('combo-list-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(response, 'message', ''),
              })
            }
          }
        })
      },
      changeTab() {
        if (this.tabIndex === 0) {
          this.filterForm.status = '';
        } else if (this.tabIndex === 1) {
          this.filterForm.status = 'draft';
        } else if (this.tabIndex === 2) {
          this.filterForm.status = 'active';
        } else if (this.tabIndex === 3) {
          this.filterForm.status = 'inactive';
        }
        Bus.$emit('combo-list-filter', this.filterForm)
      },
    },
  }
</script>
