<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <h3 style="text-align: center">Xử lý thông tin</h3>
          <v-spacer />
          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>

        <v-card-text class="px-8" style="padding-top: 20px">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="contactWork.name"
                label="Tên khách hàng"
                color="primary"
                disabled
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="contactWork.phone"
                label="Số điện thoại"
                color="primary"
                disabled
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="contactWork.email"
                label="Email"
                color="primary"
                disabled
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="contactWork.content"
                label="Nội dung"
                color="primary"
                disabled
              />
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Ghi chú"
                rules="required"
              >
                <v-text-field
                  v-model="contactWork.note"
                  label="Ghi chú"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider v-slot="{ errors }">
                <v-select
                  v-model="contactWork.status"
                  label="Trạng thái"
                  no-data-text="Không có dữ liệu"
                  :items="contactWorkStatusOptions"
                  item-text="text"
                  item-value="value"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveContactWork)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const ContactWorkRepository = RepositoryFactory.get("contact_work");
import { contactWorkStatusOptions } from "../../../helpers/constants";
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      contactWork: {
        note: "",
        status: "",
      },
      contactWorkStatusOptions: contactWorkStatusOptions,
      isCreate: true,
      errorMessage: "",
    };
  },
  created() {
    Bus.$on("contact-us-show-form", (contactWork) => {
      this.showModal = true;
      this.contactWork = _.cloneDeep(contactWork);
      if (contactWork.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveContactWork() {
      const data = {
        note: _.trim(this.contactWork.note),
        status: this.contactWork.status,
      };
      const loader = this.$loading.show();
      const response = await ContactWorkRepository.update(
        this.contactWork.id,
        data
      );
      if (!response.success) {
        this.errorMessage = _.get(response, "message", "");
      } else {
        this.showModal = false;
        this.errorMessage = "";
        this.$swal({
          title: "Cập nhật thành công",
          confirmButtonText: "Đồng ý",
        }).then(() => {
          Bus.$emit("contact-us-list-reload");
        });
      }
      loader.hide();
    },
  },
};
</script>
