<template>
    <v-card
        class="mx-auto overflow-visible"
        width="400px"
        style="margin-top: 0px; margin-bottom: 0px;"
        outlined
    >
        <v-list-item three-line>
            <validation-observer v-slot="{ handleSubmit }">
                <v-list-item-content class="overflow-visible">
                    <v-row style="width: 100%; padding: 0px 20px">
                        <v-autocomplete
                            v-model="filterForm.store"
                            :items="stores"
                            item-text="name"
                            item-value="value"
                            label="Chọn kho"
                            placeholder="Chọn kho"
                            clearable
                            @keyup.enter.native="handleSubmit(searchUser)"
                        />
                    </v-row>
                    <v-row style="width: 100%; padding: 0px 20px">
                        <v-text-field
                            type="number"
                            min="0"
                            step="1"
                            label="Số lượng contact được phân bổ"
                            placeholder="Nhập số lượng contact được phân bổ"
                            v-model="filterForm.number_of_contact"
                            clearable
                            @keyup.enter.native="handleSubmit(searchUser)">
                        </v-text-field>
                    </v-row>
                    <v-row style="width: 100%; padding: 0px 20px">
                        <v-autocomplete
                            v-model="filterForm.assignContact"
                            :items="assignContacts"
                            item-text="name"
                            item-value="value"
                            label="Trạng thái phân bổ"
                            placeholder="Chọn trạng thái phân bổ"
                            clearable
                            @click:clear="clearAssignContact()"
                            @keyup.enter.native="handleSubmit(searchUser)"
                        />
                    </v-row>
                    <v-row style="width: 100%; padding: 0px 20px">
                        <v-text-field
                            label="Nhân viên"
                            placeholder="Nhập tên nhân viên"
                            v-model="filterForm.name"
                            @keyup.enter.native="handleSubmit(searchUser)">
                        </v-text-field>
                    </v-row>

                    <v-row style="width: 100%; padding: 0px 20px">
                      <v-text-field
                          label="Nhóm khách hàng"
                          placeholder="Nhập tên Nhóm khách hàng"
                          v-model="filterForm.departmentName"
                          @keyup.enter.native="handleSubmit(searchUser)">
                      </v-text-field>
                    </v-row>


                  <div class="text-center mb-4">
                        <v-btn class="primary mx-0" @click.prevent="handleSubmit(searchUser)">
                            <v-icon>mdi-magnify</v-icon>Tìm kiếm
                        </v-btn>
                    </div>
                </v-list-item-content>
            </validation-observer>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
    props: {
        filterForm: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            stores: [
                {
                    name: 'Kho T',
                    value: 'T'
                },
                {
                    name: 'Kho C',
                    value: 'C'
                },
                {
                    name: 'Kho G',
                    value: 'G'
                }
            ],
            assignContacts: [
                {
                    name: 'Tự động',
                    value: 'true'
                },
                {
                    name: 'Không tự động',
                    value: 'false'
                }
            ]
        }
    },
    methods: {
        searchUser() {
            Bus.$emit('search-setting-contact', this.filterForm)
        },
        clearAssignContact() {
           this.filterForm.clearRecently = true
        }
    }
}
</script>