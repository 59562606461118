<template>
  <v-container id="order-history" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <br>
    <order-history-table
      :filter-form="filterForm"
      :orderHistories="orderHistories"
    />
    <v-row class="mt-4">
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem, limit)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FilterForm from "./components/FilterForm.vue";
import OrderHistoryTable from "./components/OrderHistoryTable.vue";
import RepositoryFactory from "../../repositories/RepositoryFactory";
const OrderHistoryRepository = RepositoryFactory.get("order_history");

export default {
  components: { FilterForm, OrderHistoryTable },
  data() {
    return {
      filterForm: {
        code: "",
        status: undefined,
        customers: [],
        users: [],
        dateRange: [],
      },
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      orderHistories: [],
      loading: true,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getOrderHistoryList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getOrderHistoryList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  async created() {
    this.getOrderHistoryList();
    Bus.$on("order-history-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getOrderHistoryList();
    });
    Bus.$on("order-history-reload", () => {
      this.getOrderHistoryList();
    });
  },
  beforeDestroy() {
    Bus.$off("order-history-list-filter");
  },
  methods: {
    async getOrderHistoryList() {
      this.loading = true;
      const params = {
        limit: this.limit,
        page: this.currentPage,
        code: this.filterForm.code,
        status: this.filterForm.status,
        users: this.filterForm.users,
        customers: this.filterForm.customers,
        dateRange: this.filterForm.dateRange,
      };

      const loader = this.$loading.show();
      const response = await OrderHistoryRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.orderHistories = _.get(response, "data.list_order_log", []);
        this.totalItem = _.get(response, "data.total_item", 0);
      }
    },
  },
};
</script>
