<template>
  <v-container id="action-history" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <br>
    <action-history-table
      :filter-form="filterForm"
      :logs="logs"
    />
    <v-row class="mt-4">
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem, limit)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { accountTypes } from "../../helpers/constants";
import FilterForm from "./components/FilterForm.vue";
import ActionHistoryTable from "./components/ActionHistoryTable.vue";
import RepositoryFactory from "../../repositories/RepositoryFactory";
const BehaviorRepository = RepositoryFactory.get("behavior");

export default {
  components: { FilterForm, ActionHistoryTable },
  data() {
    return {
      filterForm: {
        type: accountTypes.USER,
        status: undefined,
        customers: [],
        users: [],
        dateRange: [],
      },
      currentPage: 1,
      limit: 20,
      totalItem: 0,
      logs: [],
      loading: true,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getOrderHistoryList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getOrderHistoryList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  async created() {
    this.getOrderHistoryList();
    Bus.$on("action-history-user-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getOrderHistoryList();
    });
    Bus.$on("action-history-user-reload", () => {
      this.getOrderHistoryList();
    });
  },
  beforeDestroy() {
    Bus.$off("action-history-user-list-filter");
  },
  methods: {
    async getOrderHistoryList() {
      this.loading = true;
      const params = {
        limit: this.limit,
        page: this.currentPage,
        type: this.filterForm.type,
        users: this.filterForm.users,
        customers: this.filterForm.customers,
        dateRange: this.filterForm.dateRange,
      };

      const loader = this.$loading.show();
      const response = await BehaviorRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.logs = _.get(response, "data.list_behavior_log", []);
        this.totalItem = _.get(response, "data.total_item", 0);
      }
    },
  },
};
</script>
