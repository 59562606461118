<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('icon_create')"
        class="primary mx-0"
        @click="addNewIcon"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên</th>
            <th>Icon hiển thị</th>
            <th>Thẻ alt</th>
            <th>Trạng thái</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(icon, index) in icons">
            <tr :key="icon.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(icon, "title") }}</td>
              <td>
                <div class="d-inline-flex align-center">
                  <v-img :src="_.get(icon, 'image.filePath')" max-width="120" />
                </div>
              </td>
              <td>{{ _.get(icon, "alt") }}</td>
              <td>{{ _.get(icon, "statusText") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip bottom v-if="checkAuthorization('icon_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editIcon(icon)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="checkAuthorization('icon_delete')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(icon)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <icon-form :types="types" />
  </div>
</template>
<script>
import IconForm from "./IconForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const IconRepository = RepositoryFactory.get("icon");

export default {
  components: { IconForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    icons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedIcon: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("icon_update") ||
        this.checkAuthorization("icon_delete")
      );
    },
  },
  methods: {
    addNewIcon() {
      this.selectedIcon = {};
      Bus.$emit("icon-show-form", this.selectedIcon);
    },
    editIcon(icon) {
      this.selectedIcon = icon;
      Bus.$emit("icon-show-form", this.selectedIcon);
    },
    async onClickDelete(icon) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await IconRepository.deleteOneItem(icon.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("icon-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
