<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer 
        v-slot="{ handleSubmit }"
        ref="validator"
      >
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate">
            Thêm mới đơn vị
          </template>
          <template v-else>
            Cập nhật thông tin đơn vị
          </template>

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="hideModal"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên đơn vị"
              >
                <v-text-field
                  v-model="unit.name"
                  label="Tên đơn vị *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đường dẫn"
                rules="required|max:255"
              >
                <v-text-field
                  v-model="unit.slug"
                  label="Đường dẫn *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn
            color="success"
            @click.prevent="handleSubmit(saveUnit)"
          >
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn
            color="primary"
            @click="hideModal"
          >
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import RepositoryFactory from '../../../../repositories/RepositoryFactory'
  const UnitRepository = RepositoryFactory.get('unit')
  export default {
    $_veeValidate: {
      validator: 'new',
    },
    props: {
      
    },
    data () {
      return {
        showModal: false,
        unit: {
          name: '',
          description: '',
          type: '',
        },
        isCreate: true,
        units: [],
        errorMessage: '',
      }
    },
    created () {
      Bus.$on('unit-show-form', (unit) => {
        this.showModal = true
        this.unit = _.cloneDeep(unit)
        if (unit.id) this.isCreate = false
        else this.isCreate = true
      })
    },
    methods: {
      hideModal: function () {
        this.showModal = false
        this.isCreate = true
        this.errorMessage = ''
        this.$refs.validator.reset()
      },
      async saveUnit () {
        if (this.isCreate) {
          const unit = {
            name: _.trim(this.unit.name),
            slug: _.trim(this.unit.slug),
          }
          const loader = this.$loading.show()
          const response = await UnitRepository.create(unit)
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.showModal = false
            this.errorMessage = ''
            this.$swal({
                      title: 'Thêm đơn vị thành công',
                      confirmButtonText: 'Đồng ý',
                    },
            ).then(() => {
              Bus.$emit('unit-list-reload')
            })
          }
          loader.hide()
        } else {
          const unit = {
            name: _.trim(this.unit.name),
            slug: _.trim(this.unit.slug),
          }
          const loader = this.$loading.show()
          const response = await UnitRepository.update(this.unit.id, unit)
          if (!response.success) {
            this.errorMessage = _.get(response, 'message', '')
          } else {
            this.showModal = false
            this.errorMessage = ''
            this.$swal({
                      title: 'Cập nhật đơn vị thành công',
                      confirmButtonText: 'Đồng ý',
                    },
            ).then(() => {
              Bus.$emit('unit-list-reload')
            })
          }
          loader.hide()
        }
      },
    },
  }
</script>
