<template>
  <div class="pa-0" style="position: relative">
      <v-row class="s-btn-filter">
        <v-col cols="12" class="d-flex">
          <v-menu
            :internal-activator="true"
            v-model="filterForm.openMenu"
            content-class="menu__customer--filter no-contain overflow-visible"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn style="margin-right: 10px;" class="mb-2" color="primary" dark v-on="on">
                <span>Lọc theo điều kiện</span>
                <v-icon style="margin-left: 10px; margin-right: -20px;">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <card-filter-form
              :filterForm="filterForm"
              :filterContactStatusList="filterContactStatusList"
            />
          </v-menu>
        </v-col>
      </v-row>
  </div>
</template>
<script>
import CardFilterForm from './CardFilterForm';
export default {
  components: { CardFilterForm },
  props: {
    filterForm: {
      default: () => ({})
    },
    filterContactStatusList: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    selectContactStatusList() {
      const contactStatusList = [];
      const checkType = {};
      this.contactStatusList.forEach(contactStatus => {
        const typeContactStatus = contactStatus.type;
        if (!checkType[typeContactStatus]) {
          checkType[typeContactStatus] = true;
          if (contactStatusList.length > 0) {
            contactStatusList.push({ divider: true });
          }
          contactStatusList.push({ header: typeContactStatus });
        }
        contactStatusList.push(contactStatus);
      });
      return contactStatusList;
    }
  },
  methods: {
    searchContact() {
      Bus.$emit('order-list-filter', this.filterForm);
    },
    remove(item) {
      const filterFormTemp = this.filterForm;
      const index = filterFormTemp.userIds.indexOf(item.id);
      if (index >= 0) filterFormTemp.userIds.splice(index, 1);
    }
  }
};
</script>
<style scoped>
/deep/.m--contact-assignedAt .daterangepicker.openscenter {
  left: calc(50% + 100px) !important;
}
/deep/.m--contact-appointmentAt .daterangepicker.openscenter {
  left: 0 !important;
}
.group-btn-search {
  position: absolute;
  top: 8px;
  right: -15px;
  max-width: 130px;
}
.group-btn-search .search-contact--dropdown {
  position: absolute;
  right: 0;
  z-index: 1;
}
.menu__customer--filter {
  top: calc(30% - 45px) !important;
  left: 96% !important;
  transform: translateX(-96%) !important;
}
  .s-btn-filter {
    position: absolute;
    z-index: 1;
    right: 0;
  }
</style>