<template>
  <v-container fluid>
    <filter-form-v1 :dataForm="dataForm" :filterForm="filterForm" />

    <v-card>
      <setting-table-v1 :users="users" :dataForm="dataForm" :filterForm="filterForm"/>

<!--      <common-no-result-->
<!--          v-if="!loading && totalItem === 0"-->
<!--      />-->

      <v-row>
        <v-col
            cols="12"
            sm="6"
        />
        <v-col
            cols="12"
            sm="6"
        >
          <v-pagination
              v-if="totalItem > limit"
              v-model="currentPage"
              class="justify-sm-end"
              :length="getTotalItem(totalItem)"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import FilterForm from './components/FilterForm'
import SettingTableV1 from './components/SettingTableV1'
import {userPositions} from '@/helpers/constants'
import RepositoryFactory from '../../repositories/RepositoryFactory';
import FilterFormV1 from "@/pages/settings/components/FilterFormV1";
const SettingRepository = RepositoryFactory.get('setting');
const UserRepository = RepositoryFactory.get('user');
const DepartmentRepository = RepositoryFactory.get('department');

export default {
  components: {
    FilterFormV1,
    SettingTableV1
  },
  data() {
    return {
      dataForm: {
        assignContact: '',
        contactForTelesales: '',
        contactForSaleLeader: '',
      },
      filterForm: {
        store: '',
        number_of_contact: '',
        assignContact: 'true',
        name: '',
        clearRecently: false,
        openMenu: false,
        departmentName: '',
        departmentIds: []
      },
      users: [],
      totalItem: 0,
      currentPage: 1,
      limit: 1000,
      loading: false,
      userPositions,
      departmentList: [],
      groupUsers: []
    };
  },
  async created() {
    await this.getAutoAssignContact();
    await this.fetchDepartmentList();
    this.getUserList()
    Bus.$on('save-contact-setting-v1', async (dataForm) => {
      this.dataForm = dataForm
      await this.saveSetting()
      this.getUserList()
    })
    Bus.$on('update-user-setting-contact-v1', (user) => {
      this.updateUser(user)
    })
    Bus.$on('search-setting-contact-v1', (filterForm) => {
      this.filterForm = filterForm
      this.currentPage = 1
      this.getUserList()
    })
  },
  watch: {
    currentPage: {
      handler () {
        this.getUserList()
      },
    },
  },
  methods: {
    async getAutoAssignContact() {
      const loading = this.$loading.show();
      const response = await SettingRepository.detail();
      loading.hide();
      this.dataForm = {
        assignContact: _.get(response, 'data.value', '') == 'on' ? true : false,
        contactForTelesales: _.get(response, 'data.maxContactForTelesales', 0),
        contactForSaleLeader: _.get(response, 'data.maxContactForSaleLeader', 0),
      };
    },
    async saveSetting() {
      const loading = this.$loading.show();
      const params = {
        value: this.dataForm.assignContact ? 'on' : 'off',
        maxContactForTelesales: +this.dataForm.contactForTelesales,
        maxContactForSaleLeader: +this.dataForm.contactForSaleLeader,
      };
      const response = await SettingRepository.update(params);
      loading.hide();
      if (response.success) {
        this.$swal({
          title: 'Cập nhật thành công !',
          confirmButtonText: 'Đồng ý',
        },);
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
      }
    },
    async getUserList() {
      const loading = this.$loading.show()
      this.loading = true
      let params = {
        limit: this.limit,
        page: this.currentPage - 1,
        store: this.filterForm.store,
        offAssignContact: this.filterForm.assignContact == '' ? '' : (this.filterForm.assignContact == 'true' ? false : true),
        departmentIds: this.filterForm.departmentIds,
        departmentType: 'retail'
      }
      if (this.filterForm.clearRecently) {
        params.offAssignContact = ''
        this.filterForm.clearRecently = false
      }
      const response = await SettingRepository.list(params)
      loading.hide()
      this.loading = false
      if (response.success) {
        let users = _.get(response.data, 'rows', []).map(u => ({
          ...u,
          assignContact: !u.offAssignContact,
          fullName: u.lastName + ' ' + u.firstName,
          real_contacts_m: u.offAssignContact ? 0 : (u.number_of_contact_m == null ? (this.dataForm.assignContact ? (u.position == 'telesales' ? this.dataForm.contactForTelesales : this.dataForm.contactForSaleLeader) : 0) : u.number_of_contact_m),
          real_contacts_a: u.offAssignContact ? 0 : (u.number_of_contact_a == null ? (this.dataForm.assignContact ? (u.position == 'telesales' ? this.dataForm.contactForTelesales : this.dataForm.contactForSaleLeader) : 0) : u.number_of_contact_a),
          real_contacts_e: u.offAssignContact ? 0 : (u.number_of_contact_e == null ? (this.dataForm.assignContact ? (u.position == 'telesales' ? this.dataForm.contactForTelesales : this.dataForm.contactForSaleLeader) : 0) : u.number_of_contact_e),
          showEdit: {
            number_of_contact_m: false,
            number_of_contact_a: false,
            number_of_contact_e: false,
            store: false,
          }
        }))
        let totalItem = response.data.totalItem

        if (this.filterForm.name != '') {
          users = users.filter(user => user.fullName.toLowerCase().indexOf(this.filterForm.name.toLowerCase()) > -1)
          totalItem = users.length
        }
        if (this.filterForm.number_of_contact != '') {
          users = users.filter(user => user.real_contacts_m  == this.filterForm.number_of_contact || user.real_contacts_a  == this.filterForm.number_of_contact || user.real_contacts_e  == this.filterForm.number_of_contact)
          totalItem = users.length
        }

        let usersTemp = []
        let arrDepartment = []
        // sap xep users theo phong ban

        this.groupUsers.forEach(item => {
          let index = users.findIndex(u => u.id == item.id)
          if(index !== -1) {
            if(!arrDepartment.includes(item.departmentId)){
              arrDepartment.push(item.departmentId)
            }
            let temp = users[index];
            temp.departmentName = item.group
            temp.departmentId = item.departmentId
            usersTemp.push(temp)
          }
        })
        // count rowSpan
        arrDepartment.forEach(item => {
          let indexTemp = usersTemp.findIndex(u => u.departmentId === item)
          if(indexTemp !== -1) {
            usersTemp[indexTemp].rowSpan = users.filter(u => u.departmentId === item).length;
          }
        })

        this.users = usersTemp
        this.totalItem = totalItem
      } else {
        this.users = [];
        this.totalItem = 0;
      }
    },
    async updateUser(user) {
      let params = {
        number_of_contact_m: user.number_of_contact_m === '' ? null : user.number_of_contact_m,
        number_of_contact_a: user.number_of_contact_a === '' ? null : user.number_of_contact_a,
        number_of_contact_e: user.number_of_contact_e === '' ? null : user.number_of_contact_e,
        store: user.store == '' ? 'C' : user.store,
        offAssignContact: !user.assignContact
      }
      // if (typeof(user.number_of_contact) != "number") {
      //   if (params.offAssignContact) {
      //     params.number_of_contact = 0
      //   } else {
      //     if (user.position === userPositions.TELESALES) {
      //       params.number_of_contact = this.dataForm.contactForTelesales
      //     } else if (user.position === userPositions.SALE_LEADER) {
      //       params.number_of_contact = this.dataForm.contactForSaleLeader
      //     }
      //   }
      // }
      const response = await SettingRepository.updateUser(user.id, params)
      if (response.success) {
        this.$swal({
          title: 'Cập nhật thành công !',
          confirmButtonText: 'Đồng ý',
        },);
      } else {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', '')
        });
      }
      this.getUserList()
    },
    async fetchDepartmentList () {
      this.loading = this.$loading.show();

      const response = await DepartmentRepository.listUsers({
        // keyword: '',
        // status: 'active',
        // departmentType: 'retail'
      })
      this.groupUsers = []
      if (response.error) {
      } else {
        let departmentList = _.get(response, 'data', []).filter(d => _.get(d, 'users', []).length > 0);
        this.departmentList = departmentList;
        // const groupUsers = []
        this.departmentList.forEach(department => {
          const { users = [] } = department
          if (users.length) {
            // this.groupUsers.push({
            //   header: department.name,
            //   name: _.get(department, 'name'),
            //   departmentId: department.id,
            // })

            users.forEach((user, index) => {
              this.groupUsers.push({
                userIndex: index,
                name: _.get(user, 'fullName'),
                group: _.get(department, 'name'),
                id: user.id,
                avatar: _.get(user, 'avatar.filePath'),
                departmentId: department.id,
              })
            })
          }
        })
      }
      this.loading.hide()
    },
  }
};
</script>
